import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject, Subscription, shareReplay, takeUntil } from 'rxjs';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { MdmService } from 'src/app/services/mdm.service';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { invokeTrPipelineNominationList,updateNomination,initialisePipelineNomFilters, savePipelineNomFiltersUpdated } from 'src/app/shared/store/action/app.action';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectPipelineTRFiltersResponse, trPipelineNominationList, selectPipelineNomFiltersResponse } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';

@Component({
  selector: 'app-create-nomination-pipeline-side-panel',
  templateUrl: './create-nomination-pipeline-side-panel.component.html',
  styleUrls: ['./create-nomination-pipeline-side-panel.component.scss']
})
export class CreateNominationPipelineSidePanelComponent implements OnInit {
  @Input() isOpen: boolean=false;
  isDraftAccordionExpanded: boolean = false;
  isPublishedAccordionExpanded = false;
  isUnpublishedAccordionExpanded = false;
  isPublishedAccordionExpandedInternal : any;
  isUnPublishedAccordionExpandedInternal : any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  selectedDesk$ = this.store.pipe(select(selectDesk));
  selectPipelineTRFiltersResponse$ = this.store.pipe(select(selectPipelineTRFiltersResponse));
  trPipelineNominationList$ = this.appState.pipe(select(trPipelineNominationList));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  desk: string = '';
  payLoad: any;
  sidePanelData: any = []
  draftNominations: any = []
  publishedNominations: any = []
  unpublishedNominations: any = []
  pipelineFilterResponse: any;
  savePipelineNomFiltersUpdated: any ;
  pipelineNomFilterResponse: any ;
  tempVarSPNFR: any ;
  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  selectedDataContent: any = '';
  SelectedDeskName : any = '';
  nomSidepanelData: any;
  getClickedLineItem: any = {};
  getMatchedlineItem: any;
  region: any;
  selectedDeskParameter: any;
  selectedDeskWithoutDeskappend: any;
  subscription!: Subscription;
  isCargo:any=false; 
  isCCAView: any;

  constructor(private appStore: Store<AppState>, private nominationService: NominationService, public toasterService: ToastService, private utilities: Utilities, private store: Store, private router: Router, private appState: Store<AppState>, public datePipe: DatePipe,private myMonitoringService : MyMonitoringService, private data: NominationService,
    private mdmService : MdmService) { }

  ngOnInit(): void {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
      this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (response: any) => {
          if (response.isPipelineNomFilterUpdated) {
            this.pipelineNomFilterResponse = response;
          }
        },
        error: (err: any) => { },
        complete: () => { }
  
      });
    //subscribing to get selected desk
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          this.selectedDeskParameter = response.selectedDeskParam;
          this.selectedDeskWithoutDeskappend = this.desk.split('+')[this.desk.split('+').length -1].trim().split(' ')[0];
          this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
          this.isCargo = null; 
          this.isCCAView = this.utilities.checkIfCCAView(this.desk);
          this.SelectedDesk = response.selectedDesk;
          this.SelectedDeskParam = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId;
          this.SelectedDeskName = this.desk.split('+')[this.desk.split('+').length -1];
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.trPipelineNominationList$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if(response && Array.isArray(response)){
            response = this.transformSidePanelDataForNonCP(response);
            this.sidePanelData = response
            this.filterSidePanelData();
          }
         
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    //subscribing to get filter data and forming payload for sidepanel api
    this.pipelineTRFilterResponse();
  
    this.subscription = this.nominationService.pipelineSidePanelData$.pipe(shareReplay()).subscribe((data: any) => {
      if (Object.keys(data).length) {
        this.getClickedLineItem = data
        this.getTerminalNominationAF(this.payLoad);
        this.filterSidePanelData();
      }
    }
    )

    if (!Object.keys(this.getClickedLineItem).length) {
      this.getTerminalNominationAF(this.payLoad);
      this.filterSidePanelData();
    }
  }

  pipelineTRFilterResponse(){
    this.selectPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.pipelineFilterResponse = JSON.parse(JSON.stringify(response));
        if (response || response.isPipelineFilterUpdated) {
          this.payLoad = {
            desk:  this.desk.includes('clean products + latam') ? this.selectedDeskWithoutDeskappend : "newPipeline",
            fromDate: response.pipelineFromDate,
            toDate: response.pipelineToDate,
            terminal: this.filterResponse(response, 'terminal'),
            product: this.filterResponse(response, 'product'),
            status: "All",
            transportsystem: this.filterResponse(response, "transport system"),
            region: this.region,
            deskFilter: this.desk.includes('clean products + latam') ? undefined : `pl${this.selectedDeskParameter}`,
          };
          this.utilities.getPipelineNominationMasterData(this.payLoad)
          this.pipelineFilterResponse.pipelineFilterValues.forEach((element: any) => {
            switch (true) {
              case (element.headerName === 'mode of transport') :
                this.payLoad['modeOfTransport'] = this.getCheckedNames(element);
                break;
                case element.headerName === 'a':
                  break
              default:
                break;
            }
          });
          
        }
        if(this.nominationService.sidePanel$.value && response.isPipelineFilterUpdated){
          this.draftNominations= [];
          this.publishedNominations= [];
          this.unpublishedNominations= [];
          this.getTerminalNominationAF(this.payLoad);
        }
      },
  
    })
  }

 

  transformSidePanelDataForNonCP(response:any){
    const transformedResponse:any = [];
    // Finding unique id's from masterGridSelectedRows
    let uniqueIDs = [...new Set(response.map((a:any) => a.nominationNo))];
    // Transforming masterGridSelectedRows with headerToItemNav
    uniqueIDs.forEach((uniqueId:any)=>{
    let temp = response;
    if(response.some((obj:any)=>obj.headerToItemNav)){
      temp = response.filter((a:any)=>!a.headerToItemNav)
    }
    let tempMasterGridSelectedRows = JSON.parse(JSON.stringify(temp));
    const tempHeaderToItemNav = JSON.parse(JSON.stringify(tempMasterGridSelectedRows.filter((b:any)=>b.nominationNo === uniqueId)));
    const uniqueNominationIdIndex = tempMasterGridSelectedRows.findIndex((obj:any)=>obj.nominationNo === uniqueId);
    tempMasterGridSelectedRows[uniqueNominationIdIndex]["headerToItemNav"] = JSON.parse(JSON.stringify(tempHeaderToItemNav));
    // Removing other nomination objects without headerToItemNav from this.masterGridSelectedRows
    // Filtering obj with headerToItemNav
    tempMasterGridSelectedRows = tempMasterGridSelectedRows.filter((c:any)=>c.headerToItemNav);
    // Removing existing objs from with matching uniqueId 
    //response = response.filter((c:any)=>c.id !== uniqueId);
    // Adding back filtered obj with headerToItemNav to this.masterGridSelectedRows
    transformedResponse.push(...tempMasterGridSelectedRows);
    })
  
    return transformedResponse;
    
    }

  getTerminalNominationAF(payload: any) {
    this.utilities.getPipelineNominationMasterData(payload)
    payload.screenName = this.isCCAView && this.router.url === '/replenishmentPlanning' ? 'TR' : undefined,
    this.appState.dispatch(invokeTrPipelineNominationList({ payload }));
  }

  getCheckedNames(element: any) {
    const list = element?.list?.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.name);
    return list;
  }

  //filtering id's for sidepanel api payload from filter data
  filterResponse(response: any, filter: any) {
    let data = response?.pipelineFilterValues?.find(
      (value: any) =>
        value?.headerName?.toLowerCase() === filter
    )?.list;

     data = data.length ? data.filter((ele: any) => (ele.checked && ele.name !== 'all')).map((item: any) => item.id) : [];
    return data;
  }

  // filtering side panel api respose for DRAFT,published,unpublished
  filterSidePanelData() {
    if (this.sidePanelData?.length) {
      if (Object.keys(this.getClickedLineItem).length) {
        const fieldType = this.getClickedLineItem?.colDef?.field;
        let fieldDataName = fieldType.split('_')[3] ? fieldType.split('_').slice(0, 3).join('_') : fieldType;

        if(this.getClickedLineItem?.data[`${fieldDataName}_status_Child`] || this.getClickedLineItem?.data[`${fieldDataName}_status`]){
          this.getMatchedlineItem=this.sidePanelData.find((val:any)=>
          val.headerToItemNav.find((item:any)=>
          item.id===(this.getClickedLineItem?.data[`${fieldDataName}_id_Child`] ?? this.getClickedLineItem?.data[`${fieldDataName}_id`])));
  
          this.getMatchedlineItem=this.getMatchedlineItem?.headerToItemNav.find((item:any)=>
          item.id===(this.getClickedLineItem?.data[`${fieldDataName}_id_Child`] ?? this.getClickedLineItem?.data[`${fieldDataName}_id`]))
        }

      }
      this.nomSidepanelData = JSON.parse(JSON.stringify(this.sidePanelData));
      this.formateScheduledDate();
      this.formatingDraftNoms();
      this.nomScheduled()
    }
  }
  formatingDraftNoms(){
    if(this.isCargo){
      this.draftNominations = this.nomSidepanelData.filter((nom: any) => nom.sys_status?.toLowerCase() === "draft" || nom?.status?.toLowerCase() === "draft");
    } else{
     this.draftNominations = [];
     const headerToItemNav = this.nomSidepanelData.reduce((acc:any,curr:any)=>{
        if(curr?.headerToItemNav?.some((obj:any)=>obj?.nominationStatus?.toLowerCase() === "draft")){
          acc = [...acc,...curr.headerToItemNav];
        }
        return acc;
      },[]);
      this.draftNominations.push({headerToItemNav})
    }
  }
  nomScheduled(){
    if (this.getMatchedlineItem?.nominationStatus?.toLowerCase() === 'draft') {
      this.draftNominations[0].headerToItemNav.unshift(...this.draftNominations[0].headerToItemNav.splice(this.draftNominations[0].headerToItemNav.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
      this.isDraftAccordionExpanded = true
      this.isPublishedAccordionExpanded = false;
      this.isUnpublishedAccordionExpanded = false;
    }
    if(this.isCargo){
      this.publishedNominations = this.nomSidepanelData.filter((nom: any) => nom.sys_status?.toLowerCase() === "published" || nom?.status?.toLowerCase() === "published");
    }else{
      this.publishedNominations = this.nomSidepanelData.filter((nom: any) => nom?.headerToItemNav?.some((obj:any)=>obj?.udmItemStatus?.toLowerCase() === "published"));
    }
    if (this.isCargo?this.getMatchedlineItem?.sys_status?.toLowerCase() === 'published':this.getMatchedlineItem?.nominationStatus?.toLowerCase() === 'published') {
      const findNomIndex = this.publishedNominations.map((nom:any)=>nom.headerToItemNav).findIndex((val:any)=>val.some((item:any)=>item.id === this.getMatchedlineItem.id));
      this.publishedNominations.unshift(...this.publishedNominations.splice(findNomIndex, 1));
      this.isDraftAccordionExpanded = false;
      this.isPublishedAccordionExpanded = true;
      this.isUnpublishedAccordionExpanded = false;
    }
    if(this.isCargo){
      this.unpublishedNominations = this.nomSidepanelData.filter((nom: any) => (nom.sys_status?.toLowerCase() === "created" || nom.sys_status?.toLowerCase() === "unpublished") ||
      (nom.status?.toLowerCase() === "created" || nom.status?.toLowerCase() === "unpublished"));
    }else{
      this.unpublishedNominations = this.nomSidepanelData.filter((nom: any) => nom?.headerToItemNav?.some((obj:any)=>obj?.udmItemStatus?.toLowerCase() === "unpublished"));
    }
    if (this.isCargo?this.getMatchedlineItem?.sys_status?.toLowerCase() === 'unpublished':this.getMatchedlineItem?.nominationStatus?.toLowerCase() === 'unpublished') {
      const findNomIndex = this.unpublishedNominations.map((nom:any)=>nom.headerToItemNav).findIndex((val:any)=>val.some((item:any)=>item.id === this.getMatchedlineItem.id));
      this.unpublishedNominations.unshift(...this.unpublishedNominations.splice(findNomIndex, 1));
      this.isDraftAccordionExpanded = false;
      this.isPublishedAccordionExpanded = false;
      this.isUnpublishedAccordionExpanded = true;
    }
  }
  formateScheduledDate() {
    if (this.nomSidepanelData.length > 1) {
      this.nomSidepanelData.forEach((el: any) => {
        el.scheduledDate = this.dateCellRenderer(el?.scheduledDate);
        el.headerToItemNav.forEach((lineItem: any) => {
          lineItem.scheduledDate = this.dateCellRenderer(lineItem?.scheduledDate);
        })
      });
    }

  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  closeNominationsPanel() {
    this.nominationService.sidePanel$.next(false);
  }
  expandDraftAccordion() {
    this.isDraftAccordionExpanded = !this.isDraftAccordionExpanded;
    this.isPublishedAccordionExpanded = false;
    this.isUnpublishedAccordionExpanded = false;
  }

  expandPublishedAccordion() {
    this.isPublishedAccordionExpanded = !this.isPublishedAccordionExpanded;
    this.isDraftAccordionExpanded = false;
    this.isUnpublishedAccordionExpanded = false;
  }

  expandUnpublishedAccordion() {
    this.isUnpublishedAccordionExpanded = !this.isUnpublishedAccordionExpanded;
    this.isDraftAccordionExpanded = false;
    this.isPublishedAccordionExpanded = false;
  }
  expandPublishedAccordionInternal(accordianId: any)
  {
    if(this.isPublishedAccordionExpandedInternal==accordianId)
      {
        this.isPublishedAccordionExpandedInternal=false;
      }else{
  this.isPublishedAccordionExpandedInternal=accordianId;
  
      }
  }

  expandUnPublishedAccordionInternal(accordianId: any)
  {
    if(this.isUnPublishedAccordionExpandedInternal==accordianId)
      {
        this.isUnPublishedAccordionExpandedInternal=false;
      }else{
  this.isUnPublishedAccordionExpandedInternal=accordianId;
  
      }
  }

 
  async onCreatePipelineNominationFromTR(nomination: any) {
    let params ={data:nomination};
    if(nomination.udmItemStatus !== 'draft'){
      params = {...params, ...{type:'group'}};
    }
    const pipelineNomination: any = await this.utilities.getPipelineNomPayload(params,this.userEmail,this.desk,true);
    pipelineNomination["screenName"] = 'side-panel-tr';
    if (!pipelineNomination.scheduledDate.includes('/')) {
      pipelineNomination.scheduledDate = this.dateCellRenderer(pipelineNomination.scheduledDate);
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'onCreatePipelineNominationFromTR',
      type: '',
      values: pipelineNomination
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
      this.closeNominationsPanel();
  }

  deleteNomination(nominationDetails: any){
    this.data.popupAction("delete");
    this.mdmService.invokeReplenishmentToggleMethod(true); 
    this.mdmService.actionConfirmed$.subscribe((actionConfirmed: any)=>{
      if(actionConfirmed){
        if(nominationDetails !== ''){
          this.deleteNominationAfterConfirm(nominationDetails);
        }        
       this.mdmService.actionConfirm(false);
       this.mdmService.invokeReplenishmentToggleMethod(false);
       nominationDetails = ''; 
      }
    });
  }
  //delete nomination from sidepanel
  deleteNominationAfterConfirm(nominationDetails: any) {
    const deletedNomStatus = nominationDetails?.sys_status ? nominationDetails?.sys_status : nominationDetails?.nominationStatus;
    let deletedItemNomKey = nominationDetails?.nominationKey;
    deletedItemNomKey = deletedItemNomKey?.length;
    
    const reqPayload = [];
    const requestBody = {
      id: nominationDetails.id,
      deskFilter: this.isCCAView ? 'ccadesk' : "pipelinedesk",
      deletionIndicator: 'X',
      sys_status:  deletedItemNomKey?"InProcess":deletedNomStatus,
      textLine: `{Author:${nominationDetails.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
    }
    reqPayload.push(requestBody);
    this.store.dispatch(updateNomination({ payload: reqPayload, publishOrDeleteNominationApiResponse: {} }));
    this.myMonitoringService.logEvent(`Deleted clean products nomination`, {category:"Replenishment Planning"})
    this.toasterService.setToastNotification({
      show: true,
      type: 'success',
      msg: "Nomination has been deleted",
    });
    setTimeout(() => {
      this.getTerminalNominationAF(this.payLoad);
      const selectedTabFunction = {
        tab: this.router.url,
        function: this.isCCAView ? 'deleteCCANomination' : 'deletePipelineNomination',
        type: '',
      }
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }, 3000)
  }

  goToNominations(nom: any) {
    // This set of code is for the transfer of desk
    let deskOj = { deskValue:this.SelectedDesk , deskParameter: this.SelectedDeskParam , id: this.SelectedDeskId };
    if(!this.SelectedDesk && !this.SelectedDeskParam){
       deskOj = this.SelectedDeskName;
    }
  localStorage.setItem('gTNDeskValue',JSON.stringify(deskOj));
    // this set of code is for the transfer of nomiantion details
    let deskListArray =["clean products + usec + florida desk" , "clean products + usec + pascagoula desk" , "clean products + uswc + pnw desk" , "clean products + uswc + richmond desk" ];
    if(deskListArray.includes(deskOj.deskValue)){
      localStorage.setItem('nomKey', nom.nominationKey);
    }
    else {
      localStorage.setItem('nomId', nom.nominationNo);
    }
    //this set of code is for the transfer of filters 
    this.tempVarSPNFR = this.pipelineFilterResponse;
    localStorage.setItem('sPNFR',JSON.stringify(this.tempVarSPNFR));
    this.nominationService.goToNominationData$.next({ isTrue: true, data: nom })
    if (!this.pipelineNomFilterResponse?.pipelineNomFilterValues) {
      this.store.dispatch(initialisePipelineNomFilters({ isPipelineNomFilterInitialised: true, screenName: "nominations" }));
    } else {
      this.store.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: true }));
    }
    // this set of code onpens the nomination in a new tab 
    window.open("/nominations");
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
