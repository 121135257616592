import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-hide-list',
  templateUrl: './header-hide-list.component.html',
  styleUrls: ['./header-hide-list.component.scss']
})
export class HeaderHideListComponent{

  @Input() header!: string;
  @Input() listLength!: number;

  hideList: boolean;

  toggleListVisibility(): void{
    this.hideList = !this.hideList;
  }
  constructor(){

    this.hideList = true;

  }

}
