export enum BCPView {
  Summary = 'summary',
  Terminal = 'terminal',
  Map = 'map'
}

export interface BCPSummaryData {
  desk: string,
  location: string,
  materialCode: string,
  product: string,
	threeDaysLifting: number,
	sevenDaysLifting: number,
	fourteenDaysLifting: number,
	demandForecast: number,
	availableInventory: number,
	openingInventory: number,
	threeDaysDOS: number,
	sevenDaysDOS: number,
	fourteenDaysDOS: number,
	forecastDaysDOS: number,
  forecastDaysDOSDOverride:number,
  upcomingBatchDate: string,
  upcomingBatchVolume: number,
  nominationData: BCPNominationData[]
}

export interface BCPNominationData {
  locationCode: string,
  materialCode: string,
  product: string,
  detailProductName: string,
  scheduledDate: string,
  startDate: string,
  endDate: string,
  pipelineName: string,
  vesselName: string,
  nominationNumber: string,
  nominationKey: string
}

export interface BCPRefData {
  regionName: string,
  deskId: string,
  deskName: string,
  locationCode: string,
  materialCode: string,
  maktMaterialShortText: string,
  levelValue3Description: string,
  levelValue4Description: string,
  activeFlag: string,
  referenceSourceSystemCode: string,
  id: string
}

export interface BCPProductsFilter {
  product: string,
  checked: boolean,
  productName:string,
  deskId: string,
  deskName:string
}

export interface BCPLocationsFilter {
  location: string,
  checked: boolean,
  products: BCPProductsFilter[],
  deskId: string,
  deskName: string
}

export interface BCPFilters {
  locationsFilter: BCPLocationsFilter[],
  productsFilter: BCPProductsFilter[],
  desksFilter: BCPDeskFilter[],
  offTakers: BCPOfftakers[]
}

export interface BCPDeskFilter{
  deskId: string,
  deskName: string,
  deskRegion: string,
  checked: boolean, 
  functionGroup : string,
  disabled:boolean
}

export interface BCPPayLoadFilter{
  deskId:string,
  deskName: string,
  locations: any[],
  materials: any[],
  offtakers: any[],
}

export interface BCPOfftakers
{
    name:string,
    value:boolean
}