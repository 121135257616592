export enum TRViewConst {
    Gant = 'GANTT',
    Table = 'TABLE',
}

export enum Location {
    BOISE = 'BOISE',
    BOISEWPT = 'BOISE ID TRM WESTPOINT TRANS',
}

export enum TRScreen {
    ReplenishmentPlanning = 'replenishmentplanning',
    Nominations = 'nominations',
    BCPDashboard = 'bcpdashboard',
    CB = 'contract-balancing',
    CargoPlanning = 'cargoplanning',
    JetAId = '216103990',
    JetBId = '216150990',
    PEvergl = 'p evergl',
    PEvertran = 'p evertran'
}

export enum UDMDesk {
    Panama = 'panama',
    Ecca = 'ecca',
    Wcca = 'wcca',
    EthanolUswc = 'ethanol + uswc',
    EthanolUsec = 'ethanol + usec',
    PipelineUswcSaltLakeCityDesk = "clean products + uswc + salt lake city desk",
    PipelineUswcLADesk = "clean products + uswc + LA desk",
    PipelineUswcPNWDesk = "clean products + uswc + pnw desk",
    PipelineUswcBayAreaDesk = "clean products + uswc + bay area desk",
    PipelineUswcElPasoDesk = "clean products + uswc + el paso desk",
    PipelineUsecTexasDesk = "clean products + usec + texas desk",
    PipelineUsecFloridaDesk = "clean products + usec + florida desk",
    PipelineUsecPascagoulaDesk ="clean products + usec + pascagoula desk",
    PipelineUsecRichmondDesk = "clean products + uswc + richmond desk",
    PipelineUsecColonialPlantationDesk = "clean products + usec + colonial plantation desk",
    ECCAdesk = "clean products + latam + ecca desk",
    WCCAdesk = "clean products + latam + wcca desk",
    CCApanamaDesk = "clean products + latam + panama desk",
    Ethanol = 'ethanol',
    Pipeline = 'pipeline',
    Usec ='usec',
    Uswc ='uswc',
    EthanolUsecVal = 'ethanolusec',
    EthanolUswcVal = 'ethanoluswc',
    NewEthanolUswcVal = 'uswc ethanol',
    NewEthanolUsecVal = 'usec ethanol',
    PipelineUswcSaltLakeCityDeskVal = "salt lake city",
    PipelineUswcLADeskVal = "la",
    PipelineUswcPNWDeskVal = "pnw",
    PipelineUswcBayAreaDeskVal = "bay area",
    PipelineUswcElPasoDeskVal = "el paso",
    PipelineUsecTexasDeskVal = "texas",
    PipelineUsecFloridaDeskVal = "florida",
    PipelineUsecColonialPlantationDeskVal = "colonial plantation",
    PipelineUsecPascagoulaDeskVal = "pascagoula",
    PipelineUswcWCOriginMarineDeskVal = "wc origin marine",
    PipelineUswcRichmondDeskVal = "richmond",
    NaphthaDeskVal = "naphtha",
    DistillateDeskVal = "distillate",
    ComponentsDeskVal = "components",
    PasadenaDeskVal = "pasadena",
    CleanProductVal="clean products"
}
export const deskLink: any = {
    11: "clean products + uswc + salt lake city desk",
    7: "clean products + uswc + LA desk",
    9: "clean products + uswc + pnw desk",
    8: "clean products + uswc + bay area desk",
    10: "clean products + uswc + el paso desk",
    4: "clean products + usec + texas desk",
    5: "clean products + usec + florida desk",
    17: "clean products + usec + pascagoula desk",
    16: "clean products + uswc + richmond desk",
    6: "clean products + usec + colonial plantation desk",

}

export const cpSpecific: any = {
    5: "plfloridadesk",
    17: "plpascagouladesk",
    16: "plrichmonddesk",
    9: 'plpnwdesk',
    4: 'pltexasdesk',
    6: 'plcolonialplantationdesk',
    7: 'plladesk',
    8: 'plbayareadesk',
    10: 'plelpasodesk',
    11: 'plsaltlakecitydesk'
}
export const nomPopSpecific: any = {
    5: "floridadesk",
    17: "pascagouladesk",
    16: "richmonddesk",
    9: 'pnwdesk',
    4: 'texasdesk',
    6: 'colonialplantationdesk',
    7: 'ladesk',
    8: 'bayareadesk',
    10: 'elpasodesk',
    11: 'saltlakecitydesk'
}
export const region: any = {
    11: "uswc",
    7: "uswc",
    9: "uswc",
    8: "uswc",
    10: "uswc",
    4: "usec",
    5: "usec",
    17: "usec",
    16: "uswc",
    6: "usec"

}

export const deskName: any = {
    5: "Florida",
    17: "Pascagoula",
    16: "Richmond",
    9: 'PNW',
    4: 'Texas',
    6: 'Colonial Plantation',
    7: 'LA',
    8: 'Bay Area',
    10: 'El Paso',
    11: 'Salt Lake City',
    15: "WC Origin Marine",
    2: "USWC Ethanol",
    3: "USEC Ethanol",
    18: "ECCA",
    19: "WCCA",
    20: "Panama"
}

export const ccaSpecific: any = {
    19: "clean products + latam + ecca desk",
    20: "clean products + latam + wcca desk",
    21: "clean products + latam + panama desk",
}
