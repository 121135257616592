import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CellClickedEvent, CellValueChangedEvent, ColDef, GetMainMenuItemsParams, GridApi, GridReadyEvent, ICellRendererParams, IDetailCellRendererParams, ISelectCellEditorParams, SelectionChangedEvent, IsRowMaster, FirstDataRenderedEvent, IRowNode, ColumnApi } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { CreateNominationEthanolComponent } from 'src/app/core/components/create-nomination-ethanol/create-nomination-ethanol.component';
import { NominationService } from 'src/app/services/nomination.service';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import { MoreMenuComponent } from 'src/app/shared/modules/more-menu/more-menu.component';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectEthanolNomFiltersResponse, selectUDMScreen } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import * as Nom from '../../../shared/constants/nominations.constant'
import { ToastService } from 'src/app/services/toast.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AutoCompleteComponent } from '../../autocomplete/autocomplete.component';
import { VendorNotificationPreviewComponent } from 'src/app/core/components/vendor-notification-preview/vendor-notification-preview.component';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { saveEthanolNomFiltersResponse, setSelectedDesk } from 'src/app/shared/store/action/app.action';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { LoaderService } from 'src/app/services/loader.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { CellInfoHoverComponent } from 'src/app/shared/modules/cell-info-hover/cell-info-hover.component';

@Component({
  selector: 'app-nomination-ethanol-view',
  moduleId: module.id,
  templateUrl: 'nomination-ethanol-view.component.html',
  styleUrls: ['nomination-ethanol-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NominationEthanolViewComponent implements OnInit {
  public gridApi!: GridApi;
  public gridColumnApi!: ColumnApi;
  frameworkComponents;
  destroy$: Subject<boolean> = new Subject<boolean>();
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  selectEthanolTRFiltersResponse$ = this.appStore.pipe(select(selectEthanolNomFiltersResponse));
  selectedDeskOption!: any;
  getEthanolFilterResponse!: any;
  supplierFilterData!: any[];
  terminalFilterData!: any[];
  carrierFilterData!: any[];
  public selectedRows: any[] = [];
  updatePayload: any[] = [];
  headerToItemNav: any[] = [];
  currentParentRow!: any;
  detailHeaderToItemNav: any = [];
  public rowData!: any[];
  createNominationEthanolModal = { open: false, modal: 'ethanolCreateNominationModal' }
  warningPopup: boolean = false;
  vendorNotificationPreview = { open: false, modal: 'createPreviewForNomination' }
  @ViewChild(CreateNominationEthanolComponent) createNominationEthanolComponent!: CreateNominationEthanolComponent;
  @ViewChild(VendorNotificationPreviewComponent) vendorPreviewNotificationComponent!: VendorNotificationPreviewComponent;

  masterGridSelectedRows: any[] = [];
  detailGridSeectedRows: any[] = [];
  copyRowData: any[] = [];
  mainGridIndex!: number;
  modeOfTransport!: any[];
  public bblTrucks: boolean = false;
  public bblTrucks3: boolean = false;
  public materialCode: any[] = [
    {
      name: "ETHANOL-FUEL GRADE W/ RIN",
      value: "296669990",
    },
    {
      name: "ETHANOL-FUEL GRADE",
      value: "296660990",
    }
  ];
  getonRowSelect: any = [];
  suppliers: any = [];
  sapContracts: any[] = [];
  currentlyActiveLineRow: any;
  differentHeaderId: any;
  nomType: any = [];
  rpFilterData : any = localStorage.getItem('gEFRRP');
  filterListDataFromRP : any = JSON.parse( this.rpFilterData );
  SAPContractPopup: boolean = false;
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  motType: any;
  goToNominationId: any;
  currentScreen: any;
  allowForGetCustomView: boolean = false;
  userFullEmail: any;
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 5000;
  terminalData!: any[];
  getTruckBbls: string = '';
  customerFilterData!: any[];
  selectedDeskParams: any;
  isPipelineNomEdit: boolean = false;

  constructor(private appStore: Store<AppState>, private nominationService: NominationService, private store: Store, private router: Router, private utilities: Utilities, private serv: NominationService, private datePipe: DatePipe, private toaterService: ToastService, private ethanolService: EthanolTruckService, private loaderService: LoaderService, private myMonitoringService: MyMonitoringService) {
    this.frameworkComponents = { datePicker: DatePickerComponent };
  }

  ngOnInit(): void {
    this.columnDefs.forEach((columnDef: any) => {
      if (columnDef.editable) {
        columnDef.cellClass = 'highlightEditableCell';
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.goToNominationId = null
      }
    })
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
            this.userFullEmail = response.userDetails.email;
            localStorage.setItem("userEmail", this.userFullEmail);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if(this.selectedDeskOption !== response.selectedDesk){
            this.myMonitoringService.logPageView(response.selectedDesk.split(" + ").join(' ') + ' Nominations');
          }
          this.selectedDeskOption = response.selectedDesk;
          this.selectedDeskParams = response.selectedDeskParam;
        },
        error: (err: any) => { },
        complete: () => { },
      });

    this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isNomFilterUpdated && (this.serv.restrictNomApiGetCall$.value || this.allowForGetCustomView)) {
          this.getEthanolFilterResponse = response;
          this.getEthanolTruckNominations(response);
        }
      },
      error: (err: any) => { },
      complete: () => { }

    });
    this.utilities.sendActionToChild$.subscribe(action => {
      if (action === 'expand') {
        this.gridApi.forEachNode(index => {
          index.setExpanded(true);
        })
      }
      else {
        this.gridApi.forEachNode(index => {
          index.setExpanded(false);
        })
      }
    });

    this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
      if (response.function === "previewVendor") {
        this.toggleNominationPreviewModal();
      }

    });

    this.nominationService.allowNomApiCalls$.subscribe(res => {
      if (res) {
        this.allowForGetCustomView = true;
      }
    })
    this.nominationService.applyCustomView$.subscribe(res => {
      if (res && localStorage.getItem("selectedView") && this.router.url === '/nominations') {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    })
    this.nominationService.applyEditedViewToUi$.subscribe(res => {
      if (res && this.router.url === '/nominations' && localStorage.getItem("selectedView")) {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    })
    this.nominationService.applyTrCoulmnOrder$.subscribe(res => {
      this.callColOrderAndExpansion(res);
    })
    this.nominationService.resetToDefaultView$.subscribe(res => {
      if (res) {
        this.gridColumnApi?.resetColumnState();
      }
    });
    this.nominationService.goToNominationData$.subscribe((value: any) => {
      if (Object.keys(value).length && value?.isTrue) {
        const getId = value?.data?.id || value?.data?.data[`${value?.data?.colDef?.field}_id`]
        this.goToNominationId = getId
      }
    })

    this.callGetViewByNameMethod();
  }
  callGetViewByNameMethod() {
    let selectedView = localStorage.getItem("selectedView");
    if (selectedView) {
      this.getCustomViewByName(selectedView);
    }
  }

  callResetColumnStateMethod(res: any) {
    if (res) {
      this.gridColumnApi?.resetColumnState()
    }
  }

  callCustomViewMethod(res: any) {
    if (res && localStorage.getItem("selectedView")) {
      this.getCustomViewByName(res);
      this.allowForGetCustomView = true
    }
  }

  callColOrderAndExpansion(res: any) {
    let val: any = Object.values(res);
    if (val.length && localStorage.getItem("selectedView")) {
      this.applyColOrderAndExpansion(res);
    }
  }



  getCustomViewByName(res: any) {
    let payload = { emailId: this.userFullEmail, viewName: res, desk: this.utilities.updateAPIParams() };
    this.nominationService.applyView(payload).subscribe(res => {
      if (res.statusCode === 200) {
        this.formPayloadForFilters(res.value)
      }
    })
  }

  formPayloadForFilters(res: any) {
    let filterList: any[] = [];
    if (this.selectedDeskOption?.startsWith("ethanol")) {
      this.customViewEthanolFiltersApply(res, filterList);
    }
    this.nominationService.updatedFIlterValuesFromView$.next(filterList);
  }

  customViewEthanolFiltersApply(res: any, filterList: any) {
    let sDate, eDate;
    const { supplier, terminalOwnership, modeOfTransport, terminal, unitOfMeasure, carrier } = res.customViews[0].ethanolFilters;

    filterList.push({ headerName: "terminal ownership", list: [...terminalOwnership] });
    filterList.push({ headerName: "mode of transport", list: [...modeOfTransport] });
    filterList.push({ headerName: "unit of measure", list: [...unitOfMeasure] });
    if (res.customViews[0].ethanolFilters.terminalStatus) {
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }
    else {
      terminal.forEach((terminal: any) => {
        terminal.checked = true
      });
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }

    if (res.customViews[0].ethanolFilters.supplierStatus) {
      filterList.push({ headerName: "supplier", list: [...supplier] });
    }
    else {
      supplier.forEach((supplier: any) => {
        supplier.checked = true
      });
      filterList.push({ headerName: "supplier", list: [...supplier] });
    }
    if (res.customViews[0].ethanolFilters.carrierStatus) {
      filterList.push({ headerName: "carrier", list: [...carrier] });
    }
    else {
      carrier.forEach((carrier: any) => {
        carrier.checked = true
      });
      filterList.push({ headerName: "carrier", list: [...carrier] });
    }
    sDate = this.nominationService?.defaultFromDate;
    eDate = this.nominationService?.defaultToDate;
    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(filterList)), ethanolFromDate: sDate, ethanolToDate: eDate, isNomFilterUpdated: true }));
    this.applyColOrderAndExpansion({ colOrder: res.customViews[0].ethanolFilters?.columnOrders, colExpand: res.customViews[0].ethanolFilters?.expandedColumns });
  }

  applyColOrderAndExpansion(res: any) {
    if (res.colOrder?.nominationColumnOrder?.length > 0) {
      setTimeout(() => {
        this.gridColumnApi?.applyColumnState({
          state: res.colOrder?.nominationColumnOrder,
          applyOrder: true,
        })
        this.allowForGetCustomView = false;

      }, 5000)
    }
    else {
      this.gridColumnApi?.resetColumnState();
    }
  }

  public isRowMaster: IsRowMaster = (dataItem: any) => {
    if (dataItem?.headerToItemNav?.length > 1) {
      return true;
    } else if (dataItem?.headerToItemNav?.length === 1 && dataItem?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    let gTNDeskValue: any = localStorage.getItem('gTNDeskValue');
    var DeskValueTemp : any = JSON.parse(gTNDeskValue);
    this.store.dispatch(setSelectedDesk({ selectedDesk: DeskValueTemp.deskValue , selectedDeskParam: DeskValueTemp.deskParameter , selectedDeskId: DeskValueTemp.id }));
    let rowFound = false;
    let sDate, eDate;
    let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = this.filterListDataFromRP.ethanolFilterValues ;
    sDate = this.filterListDataFromRP.ethanolFromDate;
    eDate = this.filterListDataFromRP.ehtanolToDate;
    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.filterListDataFromRP.ethanolFilterValues)), ethanolFromDate: sDate, ethanolToDate: eDate, isNomFilterUpdated: true }));
    const nodesToSelect: IRowNode[] = [];
    setTimeout(() => {

      params.api.forEachNode((node: IRowNode) => {
        if (node?.data?.id === this.goToNominationId) {
          this.nominationService.goToNominationData$.next({ isTrue: false, data: null });
          params.api.getRowNode(`${node.id}`)?.setSelected(true);
          params.api.ensureIndexVisible(Number(node.id), 'top');
        }
        if (localStorage.getItem('nomId')) {
          let tempValue = localStorage.getItem('nomId');
          if( node?.data?.id === tempValue )
            {
              this.nominationService.goToNominationData$.next({ isTrue: false, data: null });
              params.api.getRowNode(`${node.id}`)?.setSelected(true);
              params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
              localStorage.setItem('nomId', '');
              rowFound = true ;
            }
        }
      });
      localStorage.removeItem('nomId');
      localStorage.removeItem('gTNDeskValue');
      localStorage.removeItem('gEFRRP');
    }, 2000);
  }

  onRowSelect(event: any) {
    this.isSameSupplierSelected()

  }

  isSameSupplierSelected() {
    let allowForTruck;
    this.motType = this.gridApi.getSelectedRows().map(item => item.modeOfTransportDesc);
    this.nomType = this.gridApi.getSelectedRows().map((item => item.headerToItemNav.every((lineItem: { udmItemStatus: string; }) => lineItem.udmItemStatus?.toLowerCase() === 'published')));

    if (this.motType.indexOf("Truck") >= 0) {
      allowForTruck = true;
    }

    this.motType.forEach((mot: any) => {
      let truckIndex = this.motType.indexOf("Truck");
      if (truckIndex >= 0) {
        this.motType.splice(truckIndex, 1);
        this.nomType.splice(truckIndex, 1);
      }
    })

    this.nomType = this.nomType.every(this.checkForTrue);

    if ((this.nomType && allowForTruck) || this.nomType) {
      this.nominationService.isSelectedPublished$.next(true);
    }
    else {
      this.nominationService.isSelectedPublished$.next(false);
    }
  }


  checkForTrue(value: any) {
    return value === true;
  }


  toggleNominationPreviewModal(): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.vendorPreviewNotificationComponent.selectedRows(selectedRows)
    const modal = { open: !this.vendorNotificationPreview.open, modal: 'createPreviewForNomination' }
    this.vendorNotificationPreview = JSON.parse(JSON.stringify(modal));
  }

  closePreviewModal() {
    this.gridApi?.deselectAll();
    const modal = { open: false, modal: 'createPreviewForNomination' }
    this.vendorNotificationPreview = JSON.parse(JSON.stringify(modal));
  }

  getEthanolTruckNominations(response: any) {
    if(this.filterListDataFromRP){
      this.getEthanolFilterResponse = this.filterListDataFromRP;
    }
    else {
      this.getEthanolFilterResponse = response;
    }
    this.nominationService?.isTriggeredFromEthanolNomModalPopup$?.next(false);
    this.masterGridSelectedRows = [];
    this.detailGridSeectedRows = [];
    this.selectedRows = [];
    this.serv.motCheck$.next(false);
    const supplierFilterArray = this.getEthanolFilterResponse?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "supplier");
    const terminalFilterArray = this.getEthanolFilterResponse?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "terminal");
    const carrierFilterArray = this.getEthanolFilterResponse?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "carrier");
    const customerFilterArray = this.getEthanolFilterResponse?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "customer");
    const modeOfTransportFilterArray = this.getEthanolFilterResponse?.ethanolFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "mode of transport");
    this.supplierFilterData = [];
    this.terminalFilterData = [];
    this.terminalData = [];
    this.carrierFilterData = [];
    this.customerFilterData = [];
    this.modeOfTransport = [];
    this.setSupplierFilterData(supplierFilterArray);
    this.setTerminalFilterData(terminalFilterArray);
    this.setCarrierFilterData(carrierFilterArray);
    this.setCustomerFilterData(customerFilterArray);
    this.setModeOfTransport(modeOfTransportFilterArray);
    let postData!: any;
    /**selectedDesk$ and if else desk conditions to be removed and maintain only postData code here after routing changes implmented */
    if (this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc || this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUsec) {
      postData = {
        desk: (this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUsec) ? UDMDesk.EthanolUsecVal : UDMDesk.Ethanol,
        fromDate: this.getEthanolFilterResponse?.ethanolFromDate,
        modeOfTransport: this.modeOfTransport,
        toDate: this.getEthanolFilterResponse?.ehtanolToDate,
        terminal: this.terminalFilterData,
        carrier: this.carrierFilterData,
        supplier: this.supplierFilterData,
        customer: this.customerFilterData
      }
      this.serv.getEthanolNominationListPayload = postData;
    }

    if (postData) {
      this.getNominationsAf(postData);
    }
  }

  setSupplierFilterData(supplierFilterArray: any) {
    if (supplierFilterArray?.length > 0) {
      supplierFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.supplierFilterData.push(dt?.id);
        }
      });
    }
  }
  setTerminalFilterData(terminalFilterArray: any) {
    if (terminalFilterArray?.length > 0) {
      terminalFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.terminalData.push(dt);
          this.terminalFilterData.push(dt?.id);
        }
      });
    }
  }
  setCarrierFilterData(carrierFilterArray: any) {
    if (carrierFilterArray?.length > 0) {
      carrierFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.carrierFilterData.push(dt?.id);
        }
      });
    }
  }
  setCustomerFilterData(customerFilterArray: any) {
    if (customerFilterArray?.length > 0) {
      customerFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.customerFilterData.push(dt?.id);
        }
      });
    }
  }

  setModeOfTransport(modeOfTransportFilterArray: any) {
    if (modeOfTransportFilterArray?.length > 0) {
      modeOfTransportFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked) {
          if (dt?.name === "tank") {
            this.modeOfTransport.push("pipeline");
          } else {
            this.modeOfTransport.push(dt?.name);
          }
        }
      });
    }
  }

  getNominationsAf(postData: any) {
    this.serv.restrictNomApiGetCall$.next(false);
    if ((this.carrierFilterData?.length > 0 || this.supplierFilterData?.length > 0 || this.terminalFilterData?.length > 0) || (postData.terminal?.length > 0 || postData.supplier?.length > 0)) {
      this.utilities.getEthanolNomPyaloadMasterData(postData);
      this.serv.getFilterNominations(postData).subscribe(data => {
        this.rowData = data.result;
        this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEthanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEthanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEthanolFilterResponse.ehtanolToDate, isNomFilterUpdated: false }));
        this.rowData.forEach(el => {
          el.scheduledDate = this.dateCellRenderer(el.scheduledDate);
          el.modeOfTransportDesc = this.setMotIfVessel(el);
          el.nominationReferenceDocumentItem = this.utilities.removeLeadingZeros(el.nominationReferenceDocumentItem);
          if (el?.headerToItemNav?.length > 0) {
            const headerToItemNavValue = el?.headerToItemNav;
            headerToItemNavValue.forEach((val: any) => {
              val.lineItem = true;
              val.modeOfTransportDesc = this.setMotIfVessel(val);
              val.scheduledDate = this.dateCellRenderer(val?.scheduledDate);
              val.nominationReferenceDocumentItem = this.utilities.removeLeadingZeros(val.nominationReferenceDocumentItem);
              if (val?.udmDailyBreakdownQty?.length > 0) {
                const udmDailyBreakdownQtyValue = val?.udmDailyBreakdownQty;
                udmDailyBreakdownQtyValue.forEach((dt: any) => { //NOSONAR lightweight logging
                  dt.scheduledDate = this.dateCellRenderer(dt?.scheduledDate);
                });
              }
            });
          }
        });
        this.copyRowData = JSON.parse(JSON.stringify(this.rowData));
      });
    }
  }

  setMotIfVessel(value: any) {
    return value?.modeOfTransportDesc?.toLowerCase() === 'vessel' ? 'Barge' : value.modeOfTransportDesc;
  }

  masterGridPublishDeleteRecord(event: any, nomPayload: any) {
    this.masterGridSelectedRows?.forEach(val => {
      const hToItemNavValue: any = [];
      const isStatusDelete = event.trim() === 'delete';
      if (val) {
        if (val.headerToItemNav?.length > 0) {
          this.setHeaderToItemNav(val, hToItemNavValue, isStatusDelete);
        }
        nomPayload.push(
          {
            id: val.id,
            deskFilter: this.selectedDeskParams,
            modeOfTransportDesc: event.trim() === 'delete' ? val?.modeOfTransportDesc : undefined,
            sys_status: event.trim() === 'delete' ? val?.sys_status : Nom.INPROCESS,
            headerToItemNav: hToItemNavValue
          }
        )
      }
    })
  }
  setHeaderToItemNav(val: any, hToItemNavValue: any, isStatusDelete: any) {
    val?.headerToItemNav?.forEach((el: any, i:number) => {
      hToItemNavValue?.push({
        itemId: el.itemId,
        udmItemStatus: isStatusDelete ? val?.sys_status : Nom.INPROCESS,
        deletionIndicator: isStatusDelete ? 'X' : undefined, // check
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
      });
    });
  }

  publishOrDeleteNomination(event: any) {
    this.selectedRows = [...this.masterGridSelectedRows, ...this.detailGridSeectedRows];
    let nomPayload: any[] = [];
    this.masterGridPublishDeleteRecord(event, nomPayload);
    // only one innergrid for now
    let headerItem: any = [];
    this.detailGridSeectedRows?.forEach(val => {
      val.detailType?.forEach((lineItem: any) => {
        headerItem.push({
          itemId: lineItem.itemId,   // need to change  id to itemId
          udmItemStatus: event.trim() === 'delete' ? lineItem.udmItemStatus : Nom.PUBLISHED,
          deletionIndicator: event.trim() === 'delete' ? 'X' : undefined, 
          textLine: `{Author:${lineItem.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
        })
      })
      headerItem = [...new Set(headerItem)];
      nomPayload.push(
        {
          id: val.id,// check what should be for single nom with detailtype=[]
          deskFilter: this.selectedDeskParams,
          modeOfTransportDesc: event.trim() === 'delete' ? val?.modeOfTransportDesc : undefined,
          sys_status: event.trim() === 'delete' ? val.sys_status : Nom.PUBLISHED,
          headerToItemNav: headerItem
        }
      );
    });
    this.setWarningMsgorApi(nomPayload, event)

  }
  setWarningMsgorApi(nomPayload: any, event: any) {
    let i = 0;
    while (i < this.selectedRows.length && event.trim() !== 'delete') {
      /* Do not call update api for missing values, send error popup */
      if ((nomPayload?.length > 0) && (this.selectedRows[i].demandMaterial === null) || (this.selectedRows[i].scheduledMaterial === null)) {
        this.warningPopup = true;
      }
      i = i + 1
    }
    /**Call update nominations api */
    if (nomPayload?.length > 0 && !this.warningPopup) {
      this.publishDeleteApiCall(event, nomPayload);

    }
  }

  showWarningPopup() {
    this.warningPopup = true;
  }
  publishDeleteApiCall(event: any, nomPayload: any) {
    if (this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc || this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUsec) {
      this.utilities.getCreateUpdateMasterDataPayload(nomPayload);
      this.serv.updateNomination(nomPayload).subscribe({
        next: () => {
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.selectedRows = [];
          nomPayload = []
          this.toaterService.setToastNotification({
            show: true,
            type: 'success',
            msg: event.trim() === "delete" ? "Nomination has been deleted" : "Nomination is being sent to TSW",
          });
        },
        error: (err: any) => { },
        complete: () => { },
      });
      this.loaderService.setLoading(true);
      setTimeout(() => {
        this.getEthanolTruckNominations(this.getEthanolFilterResponse);
        this.gridApi.deselectAll();
      }, 5000)
    }
  }

  save() {
    if (this.updatePayload?.length > 0) {
      this.masterGridSelectedRows = [];
      this.detailGridSeectedRows = [];
      this.utilities.getCreateUpdateMasterDataPayload(this.updatePayload);
      this.serv.updateNomination(this.updatePayload).subscribe({
        next: (response: any) => {
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.getEthanolTruckNominations(this.getEthanolFilterResponse);
          this.toaterService.setToastNotification({
            show: true,
            type: "success",
            msg: "Nomination has been updated",
          });
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
        },
        error: (err: any) => {
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
        },
        complete: () => { },
      });
    }
  }

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'subtitle-6 font-gotham-narrow-book',
    headerClass: 'font-gotham-bold'
  };
  columnDefs = [{
    field: '',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 50,
    maxWidth: 50,
    resizable: false,
    sortable: false,
    lockVisible: true,
    suppressMovable: false,
  },
  {
    field: "actions", headerName: 'actions', width: 100,
    maxWidth: 100,
    cellRendererFramework: MoreMenuComponent,
    cellRendererParams: {
      totalRecords: this.rowData?.length,
      isDetailGrid: false,
    }
  },
  {
    field: "sys_status", headerName: "status",
    cellRendererFramework: CellInfoHoverComponent,
    valueGetter: (params: any) => {
      let sameValue = false;
      if (params?.data?.headerToItemNav?.length > 1) {
        const headerToItemNav = params?.data?.headerToItemNav;
        for (let i = 0; i < headerToItemNav.length - 1; i++) {
          if (headerToItemNav[i]?.udmItemStatus?.toLowerCase() === headerToItemNav[i + 1]?.udmItemStatus?.toLowerCase()) {
            sameValue = true;
          } else {
            sameValue = false;
            break;
          }
        }
      } else {
        sameValue = true;
      }
      if (sameValue) {
        return params?.data?.sys_status;
      } else {
        return "multiple";
      }
    },
    cellRendererSelector: (params: any) => {
      const rowExpansion = {
        component: "agGroupCellRenderer",
      }
      if (params?.data?.headerToItemNav?.length > 1 || (params?.data?.headerToItemNav?.length === 1 && params?.data?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0)) {
        return rowExpansion;
      }
      return undefined;
    },
    cellRendererParams: (params: any) => {
      return {
      innerRenderer: CellInfoHoverComponent
      }
    },
    cellClass: (params: any) => {
      if (params?.data?.sys_status?.toLowerCase() === "unpublished") {
        return "unpublished";
      } else if (params?.data?.sys_status?.toLowerCase() === "published") {
        return "published";
      } else {
        return "";
      }
    }
  },
  { field: "nominationNo", headerName: "nomno.", editable: false, },
  {
    field: "partnerNominationNumber", headerName: "partner nomno.", editable: (params: any) => params?.data?.headerToItemNav?.length <= 1,
    valueGetter: (params: any) => {
      let sameValue = false;
      if (params?.data?.headerToItemNav?.length > 1) {
        const headerToItemNav = params?.data?.headerToItemNav;
        for (let i = 0; i < headerToItemNav.length - 1; i++) {
          if (headerToItemNav[i]?.partnerNominationNumber === headerToItemNav[i + 1]?.partnerNominationNumber) {
            sameValue = true;
          } else {
            sameValue = false;
            break;
          }
        }
      } else {
        sameValue = true;
      }
      if (sameValue) {
        return params?.data?.partnerNominationNumber;
      } else {
        return "multiple";
      }
    },
  },
  {
    field: "nominationKey", headerName: "nomkey",
  },
  {
    field: "scheduledMaterialName",
    headerName: "scheduled material",
    editable: (params: any) => params?.data?.headerToItemNav?.length <= 1,
    cellEditor: AutoCompleteComponent,
    cellEditorParams: () => {
      return {
        propertyRendered: 'name',
        showedColumn: 'name',
        rowData: this.materialCode,
        columnDefs: [
          {
            filter: 'agTextColumnFilter',
            field: 'name',
            valueFormatter: (event: any) => {
              let data = event.data;
              return data.name;
            },
          },
          {
            field: 'name',
            filter: 'agTextColumnFilter',
            hide: true,
          },
        ],
        popup: true,
      }
    }
  },
  {
    field: "demandMaterialName",
    headerName: "demand material",
    editable: (params: any) => params?.data?.headerToItemNav?.length <= 1,
    cellEditor: AutoCompleteComponent,
    cellEditorParams: () => {
      return {
        propertyRendered: 'name',
        showedColumn: 'name',
        rowData: this.materialCode,
        columnDefs: [
          {
            filter: 'agTextColumnFilter',
            field: 'name',
            valueFormatter: (event: any) => {
              let data = event.data;
              return data.name;
            },
          },
          {
            field: 'name',
            filter: 'agTextColumnFilter',
            hide: true,
          },
        ],
        popup: true,
      }
    }
  },
  { field: "scheduledQty", headerName: "scheduled volume", editable: (params: any) => params?.data?.headerToItemNav?.length <= 1, },
  { 
    field:"actualPostedQuantity",headerName: "actual volume",
  },
  { field: "unitOfMeasurementName", headerName: "unit of measure" },
  {
    field: "modeOfTransportDesc", headerName: "mot", valueFormatter: (event: any) => {
      if (event?.value?.toLowerCase() === 'pipeline') {
        return 'Tank';
      } else return event.value
    },
    filterParams: {
      valueFormatter: (event: any) => {
        if (event?.value?.toLowerCase() === 'pipeline') {
          return 'Tank';
        } else return event.value
      },
    },
  },
  { field: "supplierName", headerName: "supplier", tooltipField: 'supplierName' },
  { field: "carrierName", headerName: "carrier", tooltipField: 'carrierName' },
  { field: "customerName", headerName: "customer", tooltipField: 'customerName' },
  { field: "tswNominationType", headerName: "nom type" },
  { field: "scheduleType", headerName: "TSW nom type" },
  { field: "referenceDocumentIndicator", headerName: "ref doc id" },
  {
    field: "locationName",
    headerName: "location",
    valueGetter: (params: any) => {
      let sameValue = false;
      if (params?.data?.headerToItemNav?.length > 1) {
        const headerToItemNav = params?.data?.headerToItemNav;
        for (let i = 0; i < headerToItemNav.length - 1; i++) {
          if (headerToItemNav[i]?.locationId === headerToItemNav[i + 1]?.locationId) {
            sameValue = true;
          } else {
            sameValue = false;
            break;
          }
        }
      } else {
        sameValue = true;
      }
      if (sameValue) {
        return params?.data?.locationId;
      } else {
        return "multiple";
      }
    },
  },
  {
    field: "scheduledDate",
    headerName: "scheduled date",
    editable: (params: any) => params?.data?.headerToItemNav?.length <= 1 && params?.data?.modeOfTransportDesc?.toLowerCase() !== Nom.TRUCK.name?.toLowerCase(),
    cellEditor: DatePickerComponent
  },
  // { field: "scenario", headerName: "scenario" }, // need to remove
  {
    field: "nominationReferenceDocument", headerName: "sap contract", width: 200, editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data?.headerToItemNav?.length <= 1,
  },
  {
    field: "nominationReferenceDocumentItem", headerName: "contract line item", width: 200, editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data?.nominationReferenceDocument && params?.data?.headerToItemNav?.length <= 1,
  },
  {
    field: 'nominationItemStatus',
    headerName: 'TSW Status',
    valueGetter: (params: any) => {
      let sameValue = false;
      if (params?.data?.headerToItemNav?.length > 1) {
        const headerToItemNav = params?.data?.headerToItemNav;
        for (let i = 0; i < headerToItemNav.length - 1; i++) {
          if (headerToItemNav[i]?.nominationItemStatus?.toLowerCase() === headerToItemNav[i + 1]?.nominationItemStatus?.toLowerCase()) {
            sameValue = true;
          } else {
            sameValue = false;
            break;
          }
        }
      } else {
        sameValue = true;
      }
      if (sameValue) {
        return params?.data?.nominationItemStatus;
      } else {
        return "multiple";
      }
    },
  },
  { field: "contractVolume", headerName: "contract volume" },
  { field: "contractTolerance", headerName: "contract tolerance" },
  {
    field: "udmComments",
    headerName: "comments",
    editable: (params: any) => params?.data?.headerToItemNav?.length <= 1,
  },
  {
    headerName: "Confirm",
    field: "confirmationIndicator",
    editable:true,
    cellRenderer: (params:any) => {
      if(params?.data?.headerToItemNav?.length === 1){
        const input = document.createElement('input');
        input.type = 'checkbox';
        input.checked = params?.data?.headerToItemNav[0]?.confirmationIndicator === "X";
        input.addEventListener('change', (event :any)=> {
          this.onCellValueChanged(params,event);
        });
        return input;
      }
      return null;
    },
    cellClassRules: {
      'disable-option disable-option-with-opacity': (params : any) => !params?.node?.group && (params?.data?.nominationItemStatus === "5")
    },
  },
  { field: "createdBy", headerName: "created by" },
  { field: "modifiedBy", headerName: "modified by" , valueFormatter: (event: any) => {
    return (!!event?.value)?event?.value:''
  }
 },
  { field: "createdOn", headerName: "creation date", valueFormatter: (event: any) => {
    return (!!event?.value)?this.formattedDate(event?.value):'';
  }
},
];

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  public groupDefaultExpanded = 1;

  getSecondGridFormation() {
    return [
      {
        field: "actions", headerName: 'actions', width: 100,
        maxWidth: 100,
        cellRendererFramework: MoreMenuComponent,
        cellRendererParams: {
          parentRecord: this.currentlyActiveLineRow,
          isDetailGrid: true,
        }
      },
      {
        field: "udmItemStatus", headerName: "status",
        cellRendererSelector: (params: any) => {
          const rowExpansion = {
            component: "agGroupCellRenderer",
          }
          if (params?.data?.udmDailyBreakdownQty?.length > 0) {
            return rowExpansion;
          }
          return undefined;
        },
        cellClass: (params: any) => {
          if (params?.data?.udmItemStatus?.toLowerCase() === "unpublished") {
            return "unpublished";
          } else if (params?.data?.udmItemStatus?.toLowerCase() === "published") {
            return "published";
          } else {
            return "";
          }
        }
      },
      { field: "nominationNo", headerName: "nomno.", width: 200 },
      { field: "partnerNominationNumber", headerName: "partner nomno.", editable: true, },
      {
        field: "nominationKey", headerName: "nomkey", width: 200
      },
      {
        field: "scheduledMaterialName",
        headerName: "scheduled material",
        width: 200,
        editable: true,
        cellEditor: AutoCompleteComponent,
        cellEditorParams: () => {
          return {
            propertyRendered: 'name',
            showedColumn: 'name',
            rowData: this.materialCode,
            columnDefs: [
              {
                filter: 'agTextColumnFilter',
                field: 'name',
                valueFormatter: (event: any) => {
                  let data = event.data;
                  return data.name;
                },
              },
              {
                field: 'name',
                filter: 'agTextColumnFilter',
                hide: true,
              },
            ],
            popup: true,
          }
        }
      },
      {
        field: "demandMaterialName",
        headerName: "demand material",
        width: 200,
        editable: true,
        cellEditor: AutoCompleteComponent,
        cellEditorParams: () => {
          return {
            propertyRendered: 'name',
            showedColumn: 'name',
            rowData: this.materialCode,
            columnDefs: [
              {
                filter: 'agTextColumnFilter',
                field: 'name',
                valueFormatter: (event: any) => {
                  let data = event.data;
                  return data.name;
                },
              },
              {
                field: 'name',
                filter: 'agTextColumnFilter',
                hide: true,
              },
            ],
            popup: true,
          }
        }
      },
      { field: "scheduledQty", headerName: "scheduled volume", editable: true, width: 200 },
      { 
        field:"actualPostedQuantity",headerName: "actual volume",
      },
      { field: "unitOfMeasurementName", headerName: "unit of measure" },
      {
        field: "modeOfTransportDesc", headerName: "mot", valueFormatter: (event: any) => {
          if (event?.value?.toLowerCase() === 'pipeline') {
            return 'Tank';
          } else return event.value
        }, width: 200,
        filterParams: {
          valueFormatter: (event: any) => {
            if (event?.value?.toLowerCase() === 'pipeline') {
              return 'Tank';
            } else return event.value
          },
        },
      },
      { field: "supplierName", headerName: "supplier", width: 200, tooltipField: 'supplierName' },
      { field: "carrierName", headerName: "carrier", width: 200, tooltipField: 'carrierName' },
      { field: "customerName", headerName: "customer", width: 200, tooltipField: 'customerName' },
      { field: "tswNominationType", headerName: "nom type", width: 200 },
      { field: "scheduleType", headerName: "TSW nom type", width: 200 },
      { field: "referenceDocumentIndicator", headerName: "ref doc id", width: 200 },
      {
        field: "locationId", headerName: "locations", width: 200,
        editable: (params: any) => { return (this.currentParentRow?.sys_createdBy === 'SCDF' && (params?.data?.locationName === null || params?.data?.locationName === '') && params?.data?.scheduleType.toLowerCase() === 'o') },
        cellEditor: AutoCompleteComponent,
        cellEditorParams: {
          propertyRendered: 'terminalName',
          showedColumn: 'terminalName',
          rowData: this.setLocationData(),
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'terminalName',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.Name;
              },
            },
            {
              field: 'terminalName',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      },
      {
        field: "scheduledDate",
        headerName: "scheduled date",
        editable: (params: any) => params?.data?.modeOfTransportDesc?.toLowerCase() !== Nom.TRUCK.name?.toLowerCase(),
        cellEditor: DatePickerComponent, width: 200
      },
      // { field: "scenario", headerName: "scenario", width: 200 }, // need to remove
      { field: "nominationReferenceDocument", headerName: "sap contract", editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName, width: 200 },
      { field: "nominationReferenceDocumentItem", headerName: "contract line item", editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data?.nominationReferenceDocument, width: 200 },
      {
        field: 'nominationItemStatus',
        headerName: 'TSW Status',
      },
      { field: "contractVolume", headerName: "contract volume", width: 200 },
      { field: "contractTolerance", headerName: "contract tolerance", width: 200 },
      { field: "udmComments", headerName: "comments", editable: true, width: 200, },
      {
        headerName: "Confirm",
        field: "confirmationIndicator",
        editable:true,
        cellRenderer: (params:any) => {
          if(!params?.node?.group){
            const input = document.createElement('input');
            input.type = 'checkbox';
            input.checked = params.value;
            const clonedParamsDataObj = {...params.data};
            input.addEventListener('change', (event :any)=> {
              clonedParamsDataObj.confirmationIndicator = event.target.checked;
              const confirmationIndicatorValue = this.utilities.getConfirmationIndicatorValue(clonedParamsDataObj,true);
              params.node.setDataValue(params.colDef.field, confirmationIndicatorValue); 
            });
            return input;
          }
          return null;
        },
        valueGetter: (params: any) => {
          return params?.data?.confirmationIndicator === "X";
        },
        cellClassRules: {
          'disable-option disable-option-with-opacity': (params : any) => !params?.node?.group && (params?.data?.nominationItemStatus === "5")
        },
      },
      { field: "createdBy", headerName: "created by" },
      { field: "modifiedBy", headerName: "modified by" , valueFormatter: (event: any) => {
        return (!!event?.value)?event?.value:''
      }
    },
      { field: "createdOn", headerName: "creation date" , valueFormatter: (event: any) => {
        console.log(event?.value);
        
        return (!!event?.value)?this.formattedDate(event?.value):'';
      }
    },
    ]
  }

  public detailCellRendererParams: any = (params: ICellRendererParams) => {
    const res = {} as IDetailCellRendererParams;
    this.currentParentRow = params.data;
    this.mainGridIndex = this.copyRowData.map(el => el.id).indexOf(params?.data?.id);
    if (params?.data?.headerToItemNav?.length > 1) {
      this.formationOfSecondLevelGrid(res, params);
    } else if (params?.data?.headerToItemNav?.length === 1 && params?.data?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0) {
      this.thirdLevelGridFormation(res, params?.data?.headerToItemNav[0]?.udmDailyBreakdownQty[0]);
    }
    return res;
  };

  setLocationData() {
    console.log(this.terminalData);
    const key = "terminalName";
    const objectArray = this.terminalData?.map((value: any) => ({ [key]: value.name }));
    console.log(objectArray);
    return objectArray;
  }

  formationOfSecondLevelGrid(res: any, params: any) {
    let getIndexForField:any
    this.currentlyActiveLineRow = params?.data;
    res.getDetailRowData = function (params: any) {
      params.successCallback(params.data.headerToItemNav);
    };
    res.detailGridOptions = {
      stopEditingWhenCellsLoseFocus : true,
      columnDefs: this.getSecondGridFormation(),
      rowSelection: 'multiple',
      enableRangeSelection:true,
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'subtitle-6 font-gotham-narrow-book',
        headerClass: 'font-gotham-bold'
      },
      masterDetail: true,
      detailCellRendererParams: {
        detailGridOptions: {
          stopEditingWhenCellsLoseFocus : true,
          onGridReady:(params:GridReadyEvent)=>{
            params.api.forEachNode(function(node:any, index:any) {
            getIndexForField = Object.entries(node.data).filter(([key, value]) => key.includes("bbl") && value !== null)
            })
            const newColumnDef:ColDef[]=[
              {
                field: 'scheduledDate',
                headerName: 'date',
              },
              {
                field: 'scheduledQty',
                headerName: 'scheduled mov. volumes',
              },
              {
                headerName: `${getIndexForField[0][0].substring(3,6)} ${getIndexForField[0][0].substring(0,3)} ${getIndexForField[0][0].substring(6,getIndexForField[0][0].length)}`,
                field:getIndexForField[0][0]
                
              } as any
            ]
            params.api.setColumnDefs(newColumnDef)
          },
          defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            cellClass: 'subtitle-6 font-gotham-narrow-book',
            headerClass: 'font-gotham-bold'
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.udmDailyBreakdownQty);
        },
      } as IDetailCellRendererParams,
      onCellValueChanged: (event: CellValueChangedEvent) => {
        this.existanceOfPayloadCheck();
        const rowIndex = event?.rowIndex ? event?.rowIndex : 0;
        const rowDetail = this.copyRowData[this.mainGridIndex]["headerToItemNav"];
        const keys = Object.keys(rowDetail[0]);
        const headerToItemPayload: any = {
          itemId: event?.data?.itemId,
          scheduledDate: this.datePipe.transform(event?.data?.scheduledDate, "yyyMMdd"),
          udmItemStatus: this.statusDeclaration(event?.data?.udmItemStatus, event?.column?.getColId()),
          udmDisableValidations: true,
          referenceDocumentIndicator: event?.data?.referenceDocumentIndicator, 

        }
        for (const key of keys) {
          this.addUpdatedRowsToPayload(rowDetail, rowIndex, key, event, headerToItemPayload)
        }
        if (!(this.detailHeaderToItemNav?.map((el: any) => el.itemId).includes(event?.data?.itemId))) {
          this.detailHeaderToItemNav.push(headerToItemPayload);
        } else {
          const detailPayloadIndex = this.detailHeaderToItemNav?.map((el: any) => el.itemId)?.indexOf(event?.data?.itemId);
          this.detailHeaderToItemNav[detailPayloadIndex] = headerToItemPayload;
        }
        const updatedComments = this.detailHeaderToItemNav?.filter((el: any) => !!el.udmComments);
        const payload = {
          id: this.currentParentRow?.id,
          sys_status: this.statusDeclaration(event?.data?.udmItemStatus, event?.column?.getColId(), this.currentParentRow?.sys_status),
          deskFilter:`${this.selectedDeskOption?.split("+").join("").replaceAll(" ",'')}`,
          modeOfTransportDesc: event?.data?.modeOfTransportDesc,
          UdmModeOfTransportDesc: event?.data?.modeOfTransportDesc,
          headerToItemNav: this.detailHeaderToItemNav,
          isUdmCommentsUpdated: updatedComments?.length > 0 ? true : undefined,
          udmSkipTswPublish: updatedComments?.length > 0 ? true : undefined,
          udmTimeFrameOption:null
        }
        if(event.data.modeOfTransportDesc.toLowerCase() === 'truck'){
          payload.udmTimeFrameOption = event.data.udmTimeFrameOption;
        }
        if(Object.keys(payload.headerToItemNav[0]).includes("partnerNominationNumber") && !payload?.headerToItemNav[0]['partnerNominationNumber']){
          payload.headerToItemNav[0].partnerNominationNumber = " ";
        }
        this.finalUpdatePayloadFormation(payload);
        this.serv.enableNominationsSaveButton$.next(true);
      }
    };
  }

  finalUpdatePayloadFormation(payload: any) {
    if (!(this.updatePayload?.map(el => el.id).includes(this.currentParentRow?.id))) {
      this.updatePayload.push(payload);
    } else {
      const payloadIndex = this.updatePayload.map(el => el.id).indexOf(this.currentParentRow?.id);
      this.updatePayload[payloadIndex] = payload;
    }
  }

  existanceOfPayloadCheck() {
    if (this.differentHeaderId !== this.currentlyActiveLineRow?.id) {
      this.detailHeaderToItemNav = [];
    }
    this.differentHeaderId = JSON.parse(JSON.stringify(this.currentlyActiveLineRow?.id));
    if (this.updatePayload?.map(el => el?.id)?.includes(this.currentlyActiveLineRow?.id)) {
      const ind = this.updatePayload?.map(el => el?.id)?.indexOf(this.currentlyActiveLineRow?.id);
      this.detailHeaderToItemNav = this.updatePayload[ind]?.headerToItemNav
    }
  }

  addUpdatedRowsToPayload(rowDetail: any, rowIndex: any, key: any, event: any, headerToItemPayload: any) {
    const isTruck = event?.data?.modeOfTransportDesc?.toLowerCase() === Nom.TRUCK.name?.toLowerCase();
    const textLine =`{Author:${rowDetail[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`;
    if (rowDetail[rowIndex][key] !== event?.data[key] && key !== "itemId" && key !== "udmItemStatus" && key !== "udmDailyBreakdownQty") {
      if (key === "scheduledMaterialName") {
        headerToItemPayload["scheduledMaterial"] = this.materialCode.find(el => el.name === event?.data[key])?.value;
        headerToItemPayload["scheduledMaterialDesc"] = this.materialCode.find(el => el.name === event?.data[key])?.name;
      } else if (key === "demandMaterialName") {
        headerToItemPayload["demandMaterial"] = this.materialCode.find(el => el.name === event?.data[key])?.value;
        headerToItemPayload["demandMaterialDesc"] = this.materialCode.find(el => el.name === event?.data[key])?.name;
      } else if (key === 'locationName') {
        headerToItemPayload["locationId"] = this.terminalData.find(el => el.name === event?.data[key])?.id;
      } else if (key !== "udmComments") {
        if(key === "scheduledDate"){
          headerToItemPayload[key] = this.datePipe.transform(event?.data[key], "yyyMMdd");
        } else {
          headerToItemPayload[key] = event?.data[key];
        }
        headerToItemPayload["isColUpdated"] = true;
      } else if (key === "udmComments") {
        if(headerToItemPayload["isColUpdated"]){
          headerToItemPayload[key] = event?.data[key];
          headerToItemPayload["isUdmCommentsUpdated"] = false;
        } else {
          headerToItemPayload[key] = event?.data[key];
          headerToItemPayload["isUdmCommentsUpdated"] = true;
        }
      }else if(key === "nominationReferenceDocumentItem"){ 
        headerToItemPayload[key] =this.utilities.removeLeadingZeros(headerToItemPayload[key] = event?.data[key])
      }
      headerToItemPayload['textLine'] = textLine;
      if(isTruck){
        headerToItemPayload['shipperText'] = `{Type:null,Carrier:${this.utilities.padSupplierCarrier(event?.data?.carrier) || null},Supplier:${this.utilities.padSupplierCarrier(event?.data?.supplier) || null}}`
      }
    }
  }

  thirdLevelGridFormation(res: any, data:any) {  
    let updateBBLData:string[] = Object.entries(data).filter(([key, value]) => key.includes("bbl") && value !== null)
    .map(([key]) => key);    
    res.getDetailRowData = function (params: any) {
      params.successCallback(params.data.headerToItemNav[0].udmDailyBreakdownQty);
    }
    res.template = '<div ref="eDetailGrid" style="width: 600px; margin-left: 450px"></div>';
    res.detailGridOptions = {
      stopEditingWhenCellsLoseFocus : true,
      columnDefs:  [
        {
          field: 'scheduledDate',
          headerName: 'date',
          width: 200
        },
        {
          field: 'scheduledQty',
          headerName: 'scheduled mov. volumes',
          width: 200
        },
        {
          headerName:`${updateBBLData[0].substring(3,6)} ${updateBBLData[0].substring(0,3)} ${updateBBLData[0].substring(6,updateBBLData[0].length)}`,
          field: `${updateBBLData[0]}`,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [1, 2, 3],
          },
          width: 200,
        }
      ] ,
      enableRangeSelection:true,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'subtitle-6 font-gotham-narrow-book',
        headerClass: 'font-gotham-bold',
        autoHeight: true,
      },
    };
  }

  formationOfHeaderPayload(rowIndex: any, key: any, headerToItemNav: any, event: any) {
    const isTruck = event?.data?.modeOfTransportDesc?.toLowerCase() === Nom.TRUCK.name?.toLowerCase();
    const textLine =`{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`;
    if (this.copyRowData[rowIndex][key] !== event?.data[key] && key !== "id" && key !== "itemId" && key !== "sys_status" && key !== "headerToItemNav") {
      if (key === "scheduledMaterialName") {
        headerToItemNav["scheduledMaterial"] = this.materialCode.find(el => el.name === event?.data[key])?.value;
        headerToItemNav["scheduledMaterialDesc"] = this.materialCode.find(el => el.name === event?.data[key])?.name;
      } else if (key === "demandMaterialName") {
        headerToItemNav["demandMaterial"] = this.materialCode.find(el => el.name === event?.data[key])?.value;
        headerToItemNav["demandMaterialDesc"] = this.materialCode.find(el => el.name === event?.data[key])?.name;
      } else if(key !== "udmComments") {
        if(key === "scheduledDate"){
          headerToItemNav[key] = this.datePipe.transform(event?.data[key], "yyyMMdd");
        } else {
          headerToItemNav[key] = event?.data[key];
        }
        headerToItemNav["isColUpdated"] = true;
      } else if (key === "udmComments") {
        if(headerToItemNav["isColUpdated"]){
          headerToItemNav[key] = event?.data[key];
          headerToItemNav["isUdmCommentsUpdated"] = false;
        } else {
        headerToItemNav[key] = event?.data[key];
        headerToItemNav["isUdmCommentsUpdated"] = true;
        }
      } else if (key !== "demandMaterialName" && key !== "scheduledMaterialName" && key !== "scheduledDate") {
        headerToItemNav[key] = event?.data[key];
      }
      headerToItemNav['textLine'] = textLine;
      if(isTruck){
        headerToItemNav['shipperText'] = `{Type:null,Carrier:${this.utilities.padSupplierCarrier(event?.data?.carrier) || null},Supplier:${this.utilities.padSupplierCarrier(event?.data?.supplier) || null}}`
      }
    }
  }
  /* For clicking on SAP contract without product filled in */
  onSAPContractSelect(event: CellClickedEvent) {
    if (event.column.getColId() === 'nominationReferenceDocument' && (event.data.demandMaterial === null || event.data.scheduledMaterial === null)) {
      this.SAPContractPopup = true;
    }
  }

  statusDeclaration(event: any, column: any, additionalParamStatus?: any) {
    if (event?.toLowerCase() === Nom.PUBLISHED && column === "udmComments") {
      return Nom.PUBLISHED;
    } else if ((event?.toLowerCase() === Nom.PUBLISHED || event?.toLowerCase() === "failed") && column !== "udmComments") {
      return Nom.UNPUBLISHED;
    } else if (additionalParamStatus) {
      return additionalParamStatus;
    } else {
      return event;
    }
  }

  /**For master grid cell value change payload formation*/
  onCellValueChanged(event: CellValueChangedEvent,checkBoxEvent?:any) {
    const rowIndex = this.copyRowData?.map(el => el?.id).indexOf(event?.data?.id);
    const keys = Object.keys(this.copyRowData[rowIndex]);
    const headerToItemNav: any = {
      itemId: event?.data?.itemId,
      udmItemStatus: this.statusDeclaration(event?.data?.udmItemStatus, event?.column?.getColId(), event?.data?.headerToItemNav[0]?.udmItemStatus),
      referenceDocumentIndicator: event?.data?.referenceDocumentIndicator, 
      scheduledDate: this.datePipe.transform(event?.data?.scheduledDate, "yyyMMdd"),
      udmDisableValidations: true,
    };
    if(event?.colDef?.field === 'confirmationIndicator'){
      const clonedParamsDataObj = {...event.data};
      clonedParamsDataObj.confirmationIndicator = checkBoxEvent.target.checked;
      const confirmationIndicatorValue = this.utilities.getConfirmationIndicatorValue(clonedParamsDataObj,true);
      headerToItemNav["confirmationIndicator"] = confirmationIndicatorValue; 
      // For unchecking and checking confirmation indicator once unchecked from UI for CP desks, as it is only for parent rows we are directly consuming headerToItemNav[0]
      event.data.headerToItemNav[0]["confirmationIndicator"]=confirmationIndicatorValue;
    }
    for (const key of keys) {
      this.formationOfHeaderPayload(rowIndex, key, headerToItemNav, event);
    }
    this.headerToItemNav = [headerToItemNav];
    const updatedComments = this.headerToItemNav?.filter((el: any) => !!el?.udmComments);
    const payload = {
      id: event?.data?.id,
      sys_status: this.statusDeclaration(event?.data?.sys_status, event?.column?.getColId()),
      deskFilter:`${this.selectedDeskOption?.split("+").join("").replaceAll(" ",'')}`,
      modeOfTransportDesc: event?.data?.modeOfTransportDesc,
      UdmModeOfTransportDesc: event?.data?.modeOfTransportDesc,
      headerToItemNav: this.headerToItemNav,
      isUdmCommentsUpdated: updatedComments?.length > 0 ? true : undefined,
      udmSkipTswPublish: updatedComments?.length > 0 ? true : undefined,
      udmTimeFrameOption:null
    }
    if(event.data.modeOfTransportDesc.toLowerCase() === 'truck'){
      payload.udmTimeFrameOption = event.data.udmTimeFrameOption;
    }
    payload.headerToItemNav.forEach((item:any) => {
      if(Object.keys(item).includes("partnerNominationNumber") && !item['partnerNominationNumber']){
        item.partnerNominationNumber = " ";
      }
    })
    
    if (!(this.updatePayload.map(el => el.id).includes(event?.data?.id))) {
      this.updatePayload.push(payload);
    } else {
      const payloadIndex = this.updatePayload.map(el => el.id).indexOf(event?.data?.id);
      this.updatePayload[payloadIndex] = payload;
    }
    this.gridApi?.redrawRows();
    this.serv.enableNominationsSaveButton$.next(true);
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.selectedRows = event.api.getSelectedRows();
    let truckNoms = this.selectedRows.filter(eachRow => {
      return eachRow?.modeOfTransportDesc?.toLowerCase() === 'truck';
    })

    this.serv.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
    this.serv.motCheck$.next(false);
    this.serv.enableNominationsSaveButton$.next(true);
    this.serv.selectedNom$.next(this.selectedRows);
    this.masterGridSelectedRows = this.selectedRows;
    if (this.selectedRows.length > 0) {
      this.serv.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: true });
    }
    if (truckNoms.length) {
      this.serv.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: true }); // Making it true to enable publish button for truck nominations for GAT testing
    }
    if (this.selectedRows.length === 1) {
      this.serv.motCheck$.next(true);
    }
  }

  formattedDate(value: any) {
   if(value){
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const dateFormat = new Date(year, month , day);
    return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
   }
   return '';
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  createNominationModal() {
    this.ethanolService.modalPopoOpen$.next(true);
    const modal = { open: true, modal: 'ethanolCreateNominationModal' }
    this.createNominationEthanolModal = JSON.parse(JSON.stringify(modal));
    this.createNominationEthanolComponent.initialValues();
  }

  editNominationLatest(data:any){
    this.ethanolService.modalPopoOpen$.next(true);
    this.nominationService.isModalPopupOpen$.next(false);
    this.isPipelineNomEdit = data?.editNomination;
    this.createNominationEthanolComponent.createNominationWithReference(data);
    const modal = { open: true, modal: 'ethanolCreateNominationModal' }
    this.createNominationEthanolModal = JSON.parse(JSON.stringify(modal));
  }

  createNominationWithReference(data: any) {
    
    this.nominationService.isModalPopupOpen$.next(false);
    this.ethanolService.modalPopoOpen$.next(true);
    if (data) {
      this.createNominationEthanolComponent.trNominationForEthanol = data;
      this.isPipelineNomEdit = data?.isEditNomination === 'editNomination';
      this.createNominationEthanolComponent.createNominationWithReference(data);
    } else {
      this.createNominationEthanolComponent.trNominationForEthanol = this.selectedRows[0];
      this.createNominationEthanolComponent.createNominationWithReference(this.selectedRows[0]);
    }
    const modal = { open: true, modal: 'createTerminalNominationWithReference' };
    this.createNominationEthanolModal = JSON.parse(JSON.stringify(modal));
  }

  closeTerminalNominationModalForEthanol(val: any) {
    let postData: any;

    if (val && val.success === true) {

      postData = {
        desk: (this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUsec) ? UDMDesk.EthanolUsecVal : UDMDesk.Ethanol,
        fromDate: this.getEthanolFilterResponse.ethanolFromDate,
        toDate: this.getEthanolFilterResponse.ehtanolToDate,
        modeOfTransport: this.modeOfTransport,
        terminal: this.terminalFilterData,
        carrier: this.carrierFilterData,
        supplier: this.supplierFilterData,
        customer: this.customerFilterData
      }

      this.serv.getEthanolNominationListPayload = postData;
       if(this.nominationService?.isTriggeredFromEthanolNomModalPopup$.value){
        setTimeout(() => {
          this.getNominationsAf(postData);
        }, 5000);
      }else{
        this.getNominationsAf(postData);
      }

    }
    this.gridApi?.deselectAll();
    const modal = { open: false, modal: 'ethanolCreateNominationModal' }
    this.createNominationEthanolModal = JSON.parse(JSON.stringify(modal));
  }

  onColumnMoved(event: any) {
    const allState = event.columnApi.getColumnState();
    const columnOrderId = allState.map((state: { colId: any; hide: any; }) => {
      return { colId: state.colId, hide: state.hide }
    })
    const nomColData = { colOrder: columnOrderId };
    localStorage.setItem("nomColData", JSON.stringify(nomColData));
  }

  columnValueChanged() {
    const allState = this.gridColumnApi?.getColumnState();
    let columnOrderId = allState.map(state => {
      return { colId: state.colId, hide: state.hide }
    })
    const nomColData = { colOrder: columnOrderId };
    localStorage.setItem("nomColData", JSON.stringify(nomColData));
  }

  saveColumnOrder(payload: any) {
    const allState = this.gridColumnApi?.getColumnState();
    const columnOrderId = allState.map(state => {
      return { colId: state.colId, hide: state.hide }
    })
    payload.payload["nominationColumnOrderState"] = payload.doColumnOrder ? columnOrderId : [];
    this.ethanolService.customViewPayload$.next(payload.payload);
  }

  editCustomViewFilters(payload: any) {
    if (payload.columnOrderChecked) {
      const allState = this.gridColumnApi?.getColumnState();
      const columnOrderId = allState?.map(state => {
        return { colId: state.colId, hide: state.hide }
      })
      payload.filterValues.ethanolFilters.columnOrders["nominationColumnOrder"] = columnOrderId;
    } else {
      payload.filterValues.ethanolFilters.columnOrders["nominationColumnOrder"] = [];
    }
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
