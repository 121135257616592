import { ToggleSwitchComponent } from './../toggle-switch/toggle-switch.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FilterPanelComponent } from 'src/app/core/components/filter-panel/filter-panel.component';
import { NextPage } from '@cvx/nextpage';
import { NpModalComponent } from './np-modal/np-modal.component';
import { ChipRendererComponent } from './ag-grid-renderers/chip-renderer/chip-renderer.component';
import { NpNotificationComponent } from './np-notification/np-notification.component';
import { CustomHeaderComponent } from 'src/app/nominations/custom-header.component';
import { DatePickerComponent } from './datapicker/datepicker.component';
import { NpHeaderComponent } from 'src/app/core/components/np-header/np-header.component';
import { CreateNominationsSidePanelComponent } from '../../core/components/create-nominations-side-panel/create-nominations-side-panel.component';
import { CreateTreminalNominationComponent } from '../../core/components/create-treminal-nomination/create-treminal-nomination.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { EditNominationComponent } from 'src/app/core/components/edit-nomination/edit-nomination.component';
import { DateChangeConfirmModelComponent } from 'src/app/core/components/date-change-confirm-model/date-change-confirm-model.component';
import { CustomDropdownComponent } from '../custom-dropdown/custom-dropdown.component';
import { CreateNominationEthanolComponent } from 'src/app/core/components/create-nomination-ethanol/create-nomination-ethanol.component';
import { NumberCellEditorComponent } from './number-cell-editor/number-cell-editor.component';
import { ContractBalancingFilterComponent } from 'src/app/core/components/contract-balancing-filter/contract-balancing-filter.component';
import { EthanolMoreMenuComponent } from './ethanol-more-menu/ethanol-more-menu.component';
import { CreateNominationEthanolSidePanelComponent } from 'src/app/core/components/create-nomination-ethanol-side-panel/create-nomination-ethanol-side-panel.component';
import { CreateAlertsComponent } from 'src/app/core/components/create-alerts/create-alerts.component';
import { DenyEditAlertComponent } from '../../core/components/create-alerts/deny-edit-alert/deny-edit-alert.component'
import { MatRadioModule } from '@angular/material/radio';
import { CreatePipelineNominationComponent } from 'src/app/core/components/create-pipeline-nomination/create-pipeline-nomination.component';
import { CreateNominationPipelineSidePanelComponent } from 'src/app/core/components/create-nomination-pipeline-side-panel/create-nomination-pipeline-side-panel.component';
import { PipelineMoreMenuComponent } from './pipeline-more-menu/pipeline-more-menu.component';
import { BCPDashboardFilterComponent } from 'src/app/core/components/bcp-dashboard-filter/bcp-dashboard-filter.component';
import { DeleteAlertsComponent } from 'src/app/core/components/delete-alerts/delete-alerts.component';
import { DenyDeleteAlertComponent } from '../../core/components/delete-alerts/deny-delete-alert/deny-delete-alert.component';
import { RestoreAlertsComponent } from 'src/app/core/components/restore-alerts/restore-alerts.component';
import { VesselScheduleUpdateComponent } from '../../core/components/vessel-schedule-update/vessel-schedule-update.component';
import { LocationsFilterComponent } from '../..//core/components/admin-filter/locations-filter/locations-filter.component';
import { DesksFilterComponent } from '../../../app/core/components/admin-filter/desks-filter/desks-filter.component';
import { MaterialMappingFilterComponent } from '../../core/components/admin-filter/material-mapping-filter/material-mapping-filter.component';
import { SubscriberAlertsComponent } from 'src/app/core/components/subscriber-alerts/subscriber-alerts.component';
import { CreateAlertNotificationsSidePanelComponent } from '../../core/components/create-alert-notifications-side-panel/create-alert-notifications-side-panel.component';
import { WorkspaceAlertsSummaryCardComponent } from 'src/app/core/components/workspace-alerts-summary-card/workspace-alerts-summary-card.component';
import { DismissWorkspaceAlertComponent } from 'src/app/core/components/dismiss-workspace-alert/dismiss-workspace-alert.component';
import { FilterDeskBlockPanelComponent } from 'src/app/core/components/create-alert-notifications-side-panel/filter-desk-block-panel/filter-desk-block-panel.component';
import { HeaderHideListComponent } from '../header-hide-list/header-hide-list.component';
import { SaveAlertsComponent } from 'src/app/core/components/save-alerts/save-alerts.component';
import { GridAlertTooltipRendererComponent } from 'src/app/core/components/create-alert-notifications-side-panel/grid-alert-tooltip-renderer/grid-alert-tooltip-renderer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SettingsAlertRulesFilterComponent } from 'src/app/core/components/settings-alert-rules-filter/settings-alert-rules-filter.component';
import { SettingsParametersFilterComponent } from 'src/app/core/components/settings-parameters-filter/settings-parameters-filter.component';
import { TransportFilterComponent } from '../../../app/core/components/admin-filter/transport-filter/transport-filter.component';
import { RestrictedInputLengthCellEditorComponent } from './ag-grid-renderers/restricted-input-length-cell-editor-component/restricted-input-length-cell-editor.component';
import { CellInfoHoverComponent } from './cell-info-hover/cell-info-hover.component';
import { MoreMenuNonCPComponent } from './more-menu-nonCP/more-menu-nonCP.component';
import {SearchPanelComponent} from 'src/app/core/components/search-panel/search-panel.component';
import { MoreMenuSearchComponent } from './more-menu-search/more-menu-search.component'
import { PushMcatModalComponent } from 'src/app/core/components/push-mcat-modal/push-mcat-modal.component'; 
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateNominationCcaComponent } from 'src/app/core/components/create-nomination-cca/create-nomination-cca.component';
import { DuplicateCargoPopupCcaComponent } from 'src/app/core/components/duplicate-cargo-popup-cca/duplicate-cargo-popup-cca.component';
import { MoreMenuCcaCpComponent } from './more-menu-cca-cp/more-menu-cca-cp.component';
import { CreateCargoCcaPopupComponent } from 'src/app/core/components/create-cargo-cca-popup/create-cargo-cca-popup.component';
import { InventoryFieldComponent } from 'src/app/core/components/create-alerts/rule-creation-fields/inventory-field/inventory-field.component';
import { ReconciliationFieldComponent } from 'src/app/core/components/create-alerts/rule-creation-fields/reconciliation-field/reconciliation-field.component';
import { MoreMenuCargoParentComponent } from './more-menu-cargo-parent/more-menu-cargo-parent.component';
import { NominationEditDeleteConfirmModelComponent } from '../nomination-edit-delete-confirm-model/nomination-edit-delete-confirm-model.component';

@NgModule({
  declarations: [
    ContractBalancingFilterComponent,
    BCPDashboardFilterComponent,
    LocationsFilterComponent,
    DesksFilterComponent,
    MaterialMappingFilterComponent,
    TransportFilterComponent,
    FilterPanelComponent,
    NpModalComponent,
    ChipRendererComponent,
    NpNotificationComponent,
    CustomHeaderComponent,
    DatePickerComponent,
    NpHeaderComponent,
    CreateNominationsSidePanelComponent,
    CreateNominationPipelineSidePanelComponent,
    CreateTreminalNominationComponent,
    CreateAlertsComponent,
    DenyEditAlertComponent,
    DeleteAlertsComponent,
    DenyDeleteAlertComponent,
    InventoryFieldComponent,
    ReconciliationFieldComponent,
    SubscriberAlertsComponent,
    RestoreAlertsComponent,
    SaveAlertsComponent,
    SelectDropdownComponent,
    MoreMenuComponent,
    EditNominationComponent,
    DateChangeConfirmModelComponent,
    CustomDropdownComponent,
    ToggleSwitchComponent,
    CreateNominationEthanolComponent,
    NumberCellEditorComponent,
    EthanolMoreMenuComponent,
    CreateNominationEthanolSidePanelComponent,
    CreatePipelineNominationComponent,
    CreateNominationCcaComponent,
    PipelineMoreMenuComponent,
    CreateAlertNotificationsSidePanelComponent,
    WorkspaceAlertsSummaryCardComponent,
    DismissWorkspaceAlertComponent,
    FilterDeskBlockPanelComponent,
    HeaderHideListComponent,
    GridAlertTooltipRendererComponent,
    SettingsAlertRulesFilterComponent,
    SettingsParametersFilterComponent,
    VesselScheduleUpdateComponent,
    RestrictedInputLengthCellEditorComponent,
    CellInfoHoverComponent,
    MoreMenuNonCPComponent,SearchPanelComponent, MoreMenuSearchComponent,
    MoreMenuCcaCpComponent,
    PushMcatModalComponent,
    CreateCargoCcaPopupComponent,
    PushMcatModalComponent,
    DuplicateCargoPopupCcaComponent,
    MoreMenuCargoParentComponent,
    NominationEditDeleteConfirmModelComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NextPage,
    DateRangePickerComponent,
    MatRadioModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  providers: [DatePipe],
  exports: [
    CommonModule,
    CustomHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ContractBalancingFilterComponent,
    BCPDashboardFilterComponent,
    LocationsFilterComponent,
    DesksFilterComponent,
    MaterialMappingFilterComponent,
    TransportFilterComponent,
    FilterPanelComponent,
    DatePipe,
    NpModalComponent,
    ChipRendererComponent,
    NpNotificationComponent,
    NpHeaderComponent,
    CreateNominationsSidePanelComponent,
    CreateNominationPipelineSidePanelComponent,
    CreateTreminalNominationComponent,
    CreateAlertsComponent,
    DenyEditAlertComponent,
    DeleteAlertsComponent,
    DenyDeleteAlertComponent,
    SubscriberAlertsComponent,
    RestoreAlertsComponent,
    SaveAlertsComponent,
    SelectDropdownComponent,
    MoreMenuComponent,
    EditNominationComponent,
    DateChangeConfirmModelComponent,
    CustomDropdownComponent,
    CreateNominationEthanolComponent,
    ToggleSwitchComponent,
    CreateNominationEthanolComponent,
    CreateNominationEthanolSidePanelComponent,
    CreatePipelineNominationComponent,
    CreateNominationCcaComponent,
    VesselScheduleUpdateComponent,
    MoreMenuNonCPComponent,
    MoreMenuCcaCpComponent,
    SearchPanelComponent,    
    CreateAlertNotificationsSidePanelComponent,
    DismissWorkspaceAlertComponent,
    FilterDeskBlockPanelComponent,
    HeaderHideListComponent,
    PushMcatModalComponent,
    CreateCargoCcaPopupComponent,
    DuplicateCargoPopupCcaComponent,
    MoreMenuCargoParentComponent,
    NominationEditDeleteConfirmModelComponent
  ]
})
export class SharedModule { }
