import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { takeUntil, Subject } from 'rxjs';
import { getUserDetails, selectDesk, selectUDMScreen } from '../../store/selector/app.selector';
import { Store, select } from '@ngrx/store';
import { UDMDesk } from '../../constants/terminal-replenishment.constant';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { ToastService } from 'src/app/services/toast.service';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
import { NominationService } from 'src/app/services/nomination.service';
import { Router } from '@angular/router';
import { Utilities } from '../../utilities/utilities';
import { AppState } from '../../store/reducer/app.reducer';
import { reInitiateFiltersForCCANomLinking } from '../../store/action/app.action';
import { latamDesks } from '../../constants/cca-dummy.constatnt';

@Component({
  selector: 'app-more-menu-cca-cp',
  templateUrl: './more-menu-cca-cp.component.html',
  styleUrls: ['./more-menu-cca-cp.component.scss']
})
export class MoreMenuCcaCpComponent implements ICellRendererAngularComp {

  getVesselData: any;
  deskId: any;
  constructor(
    private appStore: Store<AppState>,
    private utilities: Utilities,
    private router: Router,
    private toasterService: ToastService,
    private nominationService: NominationService,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private store: Store,
    private toaterService: ToastService,
    private myMonitoringService: MyMonitoringService
  ) { }
  public value: any;
  public params: any;
  public parentRecord: any;
  public totalRecords: any = 35;
  public rowIndex: any;
  public details: any;
  public customer: any;
  public counterPart: any;
  public isEditable = true;
  public isOpen = false;
  public moremenuIconShow = true;
  public lineItem = false;
  public desk: any;
  PipelineUswcPNWDesk:string = UDMDesk.PipelineUswcPNWDesk; 
  PipelineUsecFloridaDesk:string = UDMDesk.PipelineUsecFloridaDesk;
  PipelineUsecRichmondDesk:string = UDMDesk.PipelineUsecRichmondDesk;
  PipelineUsecpascagoulaDesk:string = UDMDesk.PipelineUsecPascagoulaDesk;
  // public disablePublish: any;
  selectedDesk$ = this.store.pipe(select(selectDesk));
  screen$ = this.store.pipe(select(selectUDMScreen));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  pipelineParentKey: string='';
  pipelineParentObj:any;
  childGridData:any;
  // @ViewChild(CreatePipelineNominationComponent) createPipelineNominationComponent!: CreatePipelineNominationComponent;

  agInit(params: ICellRendererParams<any, any, any>): void {
    // if(!params.data && params.node.key){
    //   this.pipelineParentKey = params.node.key;
    //   this.pipelineParentObj = params.node;
    // this.rowIndex = params.node.rowIndex;
    //   this.details = this.pipelineParentObj.aggData;
    //   this.childGridData = this.pipelineParentObj?.allLeafChildren[0]?.data;
    // }
    if(params.data){
      this.rowIndex = params.node?.rowIndex;
      this.pipelineParentKey = params.data.uid;
      this.pipelineParentObj = params.data;
    }
    this.getUserandDeskDetails();
  }

  getUserandDeskDetails() {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.deskId = response.selectedDeskId;
          this.desk = response.selectedDesk;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  moremwnu() {
    this.isOpen = !this.isOpen;
  }

    
  async deleteCargoCca(data: string) {
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'deleteCargoChildGrid',
      type: 'single',
      desk: this.desk,
      values: data
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }


  navigateToNom(data:any, routeUrl:string, routeDesk:string) {
    this.nominationService.navigateToNom(data, routeUrl, routeDesk, this.deskId)
  }

  async createNomWithRef(data:any) {
    this.nominationService.isModalPopupOpen$.next(false);
    let selectedTabFunction: any;
    if (this.desk?.includes('clean products')) {
      this.nominationService.isNomEditorRefCca = !!this.desk?.includes('latam');
      selectedTabFunction = {
        tab: this.router.url,
        function: 'CcaCreateNominationWithReference',
        type: 'nomType',
        desk: this.desk,
        values: data,
        editNomination: false
      };
      let deskObj = latamDesks.find((res:any)=>{return (res.region === data?.udmItineraryPlanName?.toString() || res.deskId === data?.udmItineraryPlanName?.toString())});
      const navigateToDesk: any = {
        deskValue: deskObj?.deskValue,
        deskParameter: deskObj?.deskName,
        id: deskObj?.deskId,
        screenName:'nominations'
      }
      this.store.dispatch(reInitiateFiltersForCCANomLinking({ reInitiateFilters: true, deskFilterValue: navigateToDesk }));
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }
    this.myMonitoringService.logEvent(`Create ${this.desk} nomination`, {category:"Nomination"})

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.utilities.clearDataToChild();
    this.utilities.unsubscribeDataToChild();
  }
}

