import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-inventory-field',
  templateUrl: './inventory-field.component.html',
  styleUrls: ['./inventory-field.component.scss']
})
export class InventoryFieldComponent implements OnInit {

  //selectedReference = '';
  public referenceDropdown = ["next"]; //Removed "previous"
  //selectedNoOfDays = '';
  public amountOfDaysDropdown = ["5", "10"];
  //selectedRules = '';
  public ruleDropdown = ["<=",">="];
  //selectedParameters = '';
  public minParameterDropdown = ["MinESL","MinInventory"];
  public maxParameterDropdown = ["MaxInventory","MaxESL"];
  @Input() selectedReference!: string;
  @Input() selectedNoOfDays!: string;
  @Input() selectedRules!: string;
  @Input() selectedParameters!: string;
  @Output() public inventoryData = new EventEmitter<{selectedReference: string, selectedNoOfDays: string, selectedRules: string, selectedParameters: string}>();

  constructor() { }

  ngOnInit(): void {
    
  }

  shareInventoryDetails(){
    this.inventoryData.emit({selectedReference: this.selectedReference, selectedNoOfDays: this.selectedNoOfDays,selectedRules: this.selectedRules,selectedParameters: this.selectedParameters});
  }
}
