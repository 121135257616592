import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { Utilities } from 'src/app/shared/utilities/utilities';


@Component({
  selector: 'app-vessel-schedule-update',
  templateUrl: './vessel-schedule-update.component.html',
  styleUrls: ['./vessel-schedule-update.component.scss'],

})
export class VesselScheduleUpdateComponent {
  @Output() closeModalPopup = new EventEmitter();

  vesselScheduleUpdateForm!: FormGroup;
  vesselName: any;
  nominationNo: any;
  oldLaycanEndDate: any;
  laycanStartDate: any;
  nomId: any;
  vesselCode: any;
  vehicleCode: any;
  updateDateDiff: any;
  fromUrl?: any;
  replenishmentData: any
  constructor(private fb: FormBuilder, private datePipe: DatePipe, private nominationService: NominationService, private toasterService: ToastService,
    private utilities: Utilities
  ) {
    this.vesselScheduleUpdateForm = this.fb.group({
      vesselName: ['', Validators.required],
      nominationNo: ['', [Validators.required]],
      oldLaycanDate: ['', [Validators.required]],
      newLaycanDate: ['', [Validators.required]]
    });
  }


  updateVesselScheduleDate(response: any) {
    this.fromUrl = response.tab;
    const scheduledDatesArray: any[] = [];
    if (response.tab === '/replenishmentPlanning') {
      this.replenishmentData = response;
      response?.values?.headerToItemNav[0]?.forEach((el: any) => {
        scheduledDatesArray?.push(el?.scheduledDate);
      });
      const getRowData = response?.values;
      this.vesselName = getRowData?.vehicleName;
      this.nominationNo = response?.values?.nominationNo;
      this.nomId = response?.values?.id;
      this.vehicleCode = response?.values?.tdVehNo;
      this.oldLaycanEndDate = this.datePipe.transform(getRowData?.scheduledDate, 'yyyy-MM-dd');
      this.laycanStartDate = this.datePipe.transform(getRowData?.scheduledDate, 'yyyy-MM-dd');
    }
    else {
      response?.values?.data?.headerToItemNav?.forEach((el: any) => {
        scheduledDatesArray?.push(el?.scheduledDate);
      });
      const getRowData = response?.values?.data;
      this.vesselName = getRowData?.vehicleName;
      this.nominationNo = response?.values?.nomNo;
      this.nomId = response?.values?.nomData?.id;
      this.vehicleCode = response?.values?.nomData?.vehicleCode;
      this.oldLaycanEndDate = this.datePipe.transform(getRowData?.scheduledDate, 'yyyy-MM-dd');
      this.laycanStartDate = this.datePipe.transform(getRowData?.scheduledDate, 'yyyy-MM-dd');
    }
  }

  getUpadtedNewLaycanDate() {
    const oneDay = 24 * 60 * 60 * 1000;
    let newLaycanEndDate: any = this.vesselScheduleUpdateForm.get('newLaycanDate')?.value
    this.updateDateDiff = (+new Date(newLaycanEndDate).getTime() - +new Date(this.oldLaycanEndDate).getTime()) / oneDay;
  }
  onVesselUpdate() {
    let payload = {
      nomId: this.nomId,
      vehicleCode: this.vehicleCode,
      laycanStartDate: this.oldLaycanEndDate,
      dateDiff: this.updateDateDiff
    }

    let obj: any;
    if (this.replenishmentData) {
      obj = {
        'desk': this.replenishmentData.desk,
        'panamaData': undefined,
        'panamaChildRecord': undefined,
        'childGridData': this.replenishmentData.values.headerToItemNav ? this.replenishmentData : "",
        'getChildId': this.replenishmentData.values.id,
        'userEmail': this.replenishmentData.userEmail,
        'isCargo': false,
        'details': this.replenishmentData.values,
        'parentRecord': undefined,
        'isCCAView': false,
        'fromUrl': this.fromUrl
      }
    }

    if (this.updateDateDiff) {
      this.nominationService.vesselScheduleUpdate(payload).subscribe({
        next: async (response) => {
          if (response.success) {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: "Vessel schedule date has been updated successfully",
            });
            this.closeModalPopUp()

            if (this.fromUrl === '/replenishmentPlanning') {

              await this.utilities.publish(this.replenishmentData.values.nominationNo, obj);

              const selectedTabFunction = {
                tab: this.fromUrl,
                function: 'afterPipelineVesselScheduleUpdate',
                type: '',
              }
              const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
              this.utilities.addUnsubscribableList(modalPopupAction);
            }
            else
              this.nominationService.getTerminalNominationAFAPI$.next(true);

          }

        },
        error: (err: any) => {
          console.log(err);
          this.toasterService.setToastNotification({
            show: true,
            type: 'error',
            msg: err?.result,
          });
          this.closeModalPopUp()
        },
        complete: () => { }
      })
    }

  }

  closeModalPopUp() {
    this.vesselScheduleUpdateForm.reset({
      vesselName: '',
      nominationNo: '',
      oldLaycanDate: '',
      newLaycanDate: ''
    })
    this.updateDateDiff = '';
    this.closeModalPopup.emit(true)
  }

}
