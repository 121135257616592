import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

    //return date in format: month/day/year
  //EX: 1/24/2024
  getStandardDateFromISOString(iso: string): string{
    if(iso == null){
      return "- - ";
    }

    let date = new Date(iso);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }


  //return date in format: MonthName Day Year
  //EX: June 10 2024
  getSpelledDateFromISOString(iso: string): string{
    if(iso == null){
      return "-";
    }

    let date = new Date(iso);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return `${months[month]} ${day} ${year}`;
  }

    //Return the time of an iso string in a standard time zone reading
  //EX: 10:45 am CDT
  getLocalTimeFromISO(iso: string){

    if(iso == null){
      return "";
    }

    let date = new Date(iso);
    const hour = date.getHours();
    const minutes = date.getMinutes() < 10 ? date.getMinutes().toString().padStart(2, '0') : date.getMinutes().toString();
    
    let hourFormatted = hour > 12 ? hour - 12 : hour;
    if(hourFormatted == 0){
      hourFormatted = 12;
    }
    const amPm = hour >= 12 ? "pm" : "am"; // Determine AM or PM
    const timeZone = date.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]; //Get time from the date object 

    return `${hourFormatted}:${minutes} ${amPm} ${timeZone}`;
  }

  /* Convert time from HHMMSS format into HH:MM AM/PM format
   */
  getTimeFromSixDigitFormat(timeString: string){

    let hourString = timeString.substring(0,2);
    let minuteString: string = timeString.substring(3,5);

    let timeSetup = parseInt(hourString) < 13? "AM": "PM";
    let hourNumber = parseInt(hourString) < 12 ? parseInt(hourString): parseInt(hourString) - 12;
    if(hourNumber == 0){
      hourString = "12";
    }else{
      hourString = hourNumber.toString().padStart(2, '0');
    }

    return `${hourString}:${minuteString} ${timeSetup}`

    
       

  }


}
