<div class="pipeline-nomination-list">
  <ag-grid-angular class="ag-theme-alpine ag-grid-table" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
  [rowData]="rowData" [rowSelection]="rowSelection" [masterDetail]="true" [isRowMaster]="isRowMaster" [suppressRowClickSelection]="true"
  (gridReady)="onGridReady($event)" [embedFullWidthRows]="true" 
  [frameworkComponents]="frameworkComponents" (firstDataRendered)="onFirstDataRenderedNonCP($event)" (cellValueChanged)="onCellValueChangedNonCP($event)" [enableRangeSelection]="true" [autoGroupColumnDef]="autoGroupColumnDef" [aggFuncs]="aggFuncs"
  (cellClicked)="onSAPContractSelect($event)" [suppressAggFuncInHeader]="true" (rowSelected)="onRowSelectNonCp($event)" [stopEditingWhenCellsLoseFocus]="true">
  </ag-grid-angular>
  </div>
  <np-modal class="pipeline-nomination-modal" [show]="pipelineNominationModal.open" [headerTitle]="isPipelineNomEdit ? 'edit pipeline nomination.' : modalTitle"
  [subHeaderText]="'*required fields'" [noModalButtons]="true">
 <app-create-pipeline-nomination (closeModalPopup)="closePipelineNominationModal($event)" [isPipelineNomEdit]="isPipelineNomEditRestrict"></app-create-pipeline-nomination>
</np-modal>
<np-modal class="vendor-notification-modal" [show]="vendorNotificationPreview.open" [headerTitle]="'selected nominations'" [noModalButtons]="true">
  <app-vendor-notification-preview (closeModalPopup)="closePipelineVNModal()">
  </app-vendor-notification-preview>
</np-modal>
<np-modal class="pipeline-vessel-schedule-update-modal" [show]="vesselScheduleUpdateModal.open" [headerTitle]="'vessel schedule update'"
[noModalButtons]="true">
<app-vessel-schedule-update (closeModalPopup)="closePipelinevesselScheduleUpdateModal($event)"></app-vessel-schedule-update>
</np-modal>