import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducer/app.reducer';
import * as App from '../reducer/app.reducer';

export const selectAppState = createFeatureSelector<AppState>('appState');

export const selectUpdateURLParams = createSelector(
    selectAppState,
    App.updatedURLParams
);

export const selectDefaultTRFilters = createSelector(
    selectAppState,
    App.getDefaultTRFilters
);

export const getUserDetails = createSelector(
    selectAppState,
    App.loggedInUserDetails
);

export const selectAppliedTRFilters = createSelector(
    selectAppState,
    App.getAppliedTRFilters
);

export const selectisClearFilterEnabled = createSelector(
    selectAppState,
    App.checkisClearFilterEnabled
);

export const selectisTRSave = createSelector(
    selectAppState,
    App.checkisTRSave
);

export const selectisTRSaveEnabled = createSelector(
    selectAppState,
    App.checkisTRSaveEnabled
);

export const selectisTankParamSave = createSelector( 
    selectAppState, 
    App.checkisTankParamSave 
); 

export const selectisNotifParamSave = createSelector( 
    selectAppState, 
    App.checkisNotifParamSave 
); 

export const selectIsAdminSettingsSave = createSelector(
  selectAppState,
  App.checkIsAdminSettingsSave
);  

  export const selectisTankParamSaveEnabled = createSelector( 
    selectAppState, 
    App.checkisTankParamSaveEnabled 
  ); 
 
  export const selectisNotifParamSaveEnabled = createSelector( 
    selectAppState, 
    App.checkisNotifParamSaveEnabled 
  ); 

export const selectIsAdminSettingsSaveEnabled = createSelector(
  selectAppState,
  App.checkIsAdminSettingsSaveEnabled
);

export const selectAdminView = createSelector(
  selectAppState,
  App.getAdminView
);

export const selectisAlertDeletedShowHide = createSelector( 
    selectAppState, 
    App.checkisAlertDeletedShowHide 
); 

export const enableTableView = createSelector(
    selectAppState,
    App.checkIsTableViewEnabled
);

export const selectSwitchView = createSelector(
    selectAppState,
    App.getSwitchView
);

export const getEthanolTableHeaders = createSelector(
    selectAppState,
    App.getAllTableHeaders
);

export const selectDesk = createSelector(
    selectAppState,
    App.getSelectedDesk
);

export const selectEthanolTRFiltersResponse = createSelector(
    selectAppState,
    App.getEthanolTRFiltersResponse
);

export const selectEthanolNomFiltersResponse = createSelector(
    selectAppState,
    App.getEthanolNomFiltersResponse
);

export const trNominationList = createSelector(
    selectAppState,
    state => state.trNominationList,
);

export const trPipelineNominationList = createSelector(
    selectAppState,
    state => state.trPipelineNominationList,
);

export const selectIsReceivedResponseBoolean = createSelector(
    selectAppState,
    App.checkReceivedResponseBoolean
);

export const createNominationData = createSelector(
    selectAppState,
    state => state.createNominationApiResponse,
);

export const trTruckApiResponse = createSelector(
    selectAppState,
    state => state.trTruckApiResponse,
);

export const selectUDMScreen = createSelector(
    selectAppState,
    App.getUDMScreen
);

export const selectTRDataPayload = createSelector(
    selectAppState,
    App.getTRDataPayload
);

export const selectTankParamDataPayload = createSelector( 
    selectAppState, 
    App.getTankParamDataPayload 
); 
   
  export const selectNotifParamDataPayload = createSelector( 
    selectAppState, 
    App.getNotifParamDataPayload 
); 


export const selectCBHeaderCheckboxState = createSelector(
    selectAppState,
    App.getCBHeaderCheckboxesState
);

export const selectBcpView = createSelector(
    selectAppState,
    App.getBCPView
);

export const updateNominationData = createSelector(
    selectAppState,
    state => state.updateNominationApiResponse,
);

export const ethanolModalPopRef = createSelector(
    selectAppState,
    state => state.ethanolModalPopupRefResponse,
);
export const screen = createSelector(
    selectAppState,
    state => state.screen,
);

export const selectPipelineTRFiltersResponse = createSelector(
    selectAppState,
    App.getPipelineTRFiltersResponse
);

export const selectPipelineNomFiltersResponse = createSelector(
    selectAppState,
    App.getPipelineNomFiltersResponse
);

export const selectCargoPlanCcaFiltersResponse = createSelector(
    selectAppState,
    App.getCargoPlanCcaFiltersResponse
);
export const selectedPipelineCompactView = createSelector(
    selectAppState,
    App.getPipelineCompactView
);
export const selectGetIsPipelineNomFilterInitialised = createSelector(
    selectAppState,
    App.getIsPipelineNomFilterInitialised
);

export const selectTrPipelineApiResponse = createSelector(
    selectAppState,
    App.getTrPipelineApiResponse,
);

export const selectPipelineSwitchView = createSelector(
    selectAppState,
    App.getPipelineSwitchView
);

export const getPipelineTableHeaders = createSelector(
    selectAppState,
    App.getAllPipelineTableHeaders
);
export const getPipleineFilteredValues = createSelector(
    selectAppState,
    App.getPipelineFiltersResponse
);

export const selectisPipelineFilterUpdated = createSelector(
    selectAppState,
    App.getIsPipelineFilterUpdated
);
export const selectisPipelineNomFilterUpdated = createSelector(
    selectAppState,
    App.getIsPipelineNomFilterUpdated
);
export const selectPipelineNomDateDetails = createSelector(
    selectAppState,
    App.getPipelineNomDateDetails
);
export const searchNomResponse = createSelector(
    selectAppState,
    App.getSearchNomResponse
);
export const selectBcpSwitchView = createSelector(
    selectAppState,
    App.getBcpSwitchView
);
export const selectBCPCompactView = createSelector(
  selectAppState,
  App.getBCPCompactView
);


export const searchDeskRecord = createSelector(
    selectAppState,
    App.getSavePipelineNomSearchResponse
)

export const selectedDeskFromSearch =  createSelector (
    selectAppState,
    App.getdeskIDSearchedResponse
)

export const selectedMdmSearch =  createSelector (
    selectAppState,
    App.getSaveMdmSearchResponse
)

export const selectCargoCCAFilters = createSelector(
    selectAppState,
    state => ({cargoCCAFilters: state.cargoCCAFilters, cargoCCAStartDate: state.cargoCCAStartDate, cargoCCAEndDate: state.cargoCCAEndDate, isCargoCCAUpdated: state.isCargoCCAUpdated})
)
export const reinitiateFiltersForNomLink = createSelector(
    selectAppState,
    state => ({reInitiateFiltersForCCA: state.reInitiateFiltersForCCA, deskFilterValues: state.deskFilterValue})
)
export const selectALLDeskInfo = createSelector (
    selectAppState,
    App.getAllDeskInfoResponse
)


export const selectDeskWiseLocationsAndProducts = createSelector (
    selectAppState,
    App.getDeskWiseLocationsAndProducts
)