import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectDesk } from '../store/selector/app.selector';
import { NominationService } from 'src/app/services/nomination.service';

@Component({
  selector: 'custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit {

  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  deskList: any = [];
  displayMenuItems: boolean = false
  selectedDataContent: any = '';

  @Input() optionList: { name: string, groupName?: string, label?: string, selected?: boolean }[] = []
  @Output() change = new EventEmitter();
  @ViewChild('menu') divElementRef: ElementRef | undefined;
  @ViewChild('toggleButton') toggleButton: ElementRef | undefined;
  divElement: HTMLElement | undefined;
  selectedDesk$ = this.store.pipe(select(selectDesk));
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private renderer: Renderer2, private store: Store,private nominationService: NominationService) {
    this.divElement = this.divElementRef?.nativeElement
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (e.target !== this.toggleButton?.nativeElement && e.target !== this.divElement) {
        this.displayMenuItems = false;
      }
    });
  }

  ngOnInit() {
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.SelectedDesk = response.selectedDesk;
          this.SelectedDeskParam = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.optionList.forEach((ele: any) => {
      if (ele.selected) {
        this.selectedDataContent = ele.dataContent
      }
      if (ele.groupName) {
        let index = this.deskList.findIndex((x: any) => x.name === ele.groupName);
        if (index > -1) {
          this.deskList[index].items.push(ele)
        } else {
          this.deskList.push(
            {
              name: ele.groupName,
              items: [ele]
            }
          )
        }
      } else {
        this.deskList.push(ele)
      }
    })
    if (this.SelectedDesk) {
      const deskOj = { deskValue: this.SelectedDesk, deskParameter: this.SelectedDeskParam, id: this.SelectedDeskId  };
      this.change.emit(deskOj)
    } else {
      this.SelectedDesk = "Select one"
    }

    this.nominationService.setCustomDropDownValue$.subscribe(res => {
      if (res) {
        this.setSelectedPivotProductTerminal(res);
      }
    });

  }



  updateSelectedDesk(event: any, deskName: any) {
    this.SelectedDesk = deskName.label ? deskName.label : deskName.name;
    this.selectedDataContent = deskName.dataContent
    this.displayMenuItems = false;
    const deskParameter = deskName.parameter ?? null;
    let deskOj = { deskValue: deskName.value, deskParameter, id: deskName.id };
    if(!deskName.value && !deskParameter){
      deskOj = deskName.name;
    }
    this.change.emit(deskOj); //deskName.value
    event.stopPropagation()
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.resetToDefaultView$.next(true);  
    localStorage.removeItem("selectedView");
    localStorage.removeItem("nomColData");
    localStorage.removeItem("trColExpandData");
    localStorage.removeItem("trColOrder");
    localStorage.removeItem("locationSelected");
    this.change.emit(deskOj); //deskName.value
      this.nominationService.triggerGetAllFromCreateView$.next({ isTrigger: true, selectedView: null })
    event.stopPropagation()
  }

  displayMenu(event: any) {
    event.stopPropagation();
    this.displayMenuItems = !this.displayMenuItems;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setSelectedPivotProductTerminal(deskName: any) {
    let selectedDesk: any = this.optionList.find(ele => ele.name === deskName);
    if (selectedDesk) {
      this.selectedDataContent = selectedDesk.dataContent;
      this.SelectedDesk = selectedDesk.name;
      this.change.emit({ deskValue: selectedDesk.name, deskParameter: selectedDesk.parameter, id: selectedDesk.id });
    }
  }
  
}
