<div class="panel-content">
    <ng-container>
        <div [class]="{'filter-collapse': deskFilterAccord}"  *ngIf="selectedDesk?.includes('clean products')">
            <div (click)="deskAccordionFn()" 
                            class="content__header d-flex justify-content-between cursor-pointer">
                            <span>Desk Filter</span>
                            <span
                                [class]="{'icon-chevron-up': !deskFilterAccord, 'icon-chevron-down': deskFilterAccord}"></span>                       
            </div>
         <form class="branded">
                     <div class="bcp-dashboard-side-panel">
                    <p *ngFor="let obj of deskList let i = index;" 
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="deskCheckbox"  [id]="obj.deskId"
                            [value]="obj.deskName" [disabled]="obj.disabled" [checked]="obj.checked" (change)="filterByDesk($event)">
                        <label class="text-ellipsis" [title]="obj.deskName" [htmlFor]="obj.deskName">
                            {{obj.deskName}}
                        </label>
                    </p>
                </div>
        </form> 
        <hr>
        </div>
        <div [class]="{'filter-collapse': locFilterAccord}">
            <div (click)="locAccordionFn()"
                            class="content__header d-flex justify-content-between cursor-pointer">
                            <span>location</span>
                            <span
                                [class]="{'icon-chevron-up': !locFilterAccord, 'icon-chevron-down': locFilterAccord}"></span>
                        </div>
            <form class="branded">
                <input class="form-control bcp-dashboard-search " [name]="'searchText0'" type="search"
                    placeholder="search" (input)="updateLocationFilterList($any($event.target).value)"
                    [(ngModel)]="searchValue[0]">
                <div class="bcp-dashboard-side-panel">
                    <p *ngFor="let obj of updateLocationSearchData let i = index;" 
                        class="align-items-center d-flex" [ngClass]="{'sticky-header' : obj.location === 'all'}">
                        <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.location"
                            [value]="obj.location" [checked]="obj.checked" (change)="filterByLocation($event)">
                        <label class="text-ellipsis" [title]="obj.location" [htmlFor]="obj.location">
                            {{obj.location}}
                        </label>
                    </p>
                </div>
            </form>
            <hr>
        </div>
        <div  [class]="{'filter-collapse': prodFilterAccord}">
            <div (click)="prodAccordionFn()"
            class="content__header d-flex justify-content-between cursor-pointer">
            <span>product</span>
            <span
                [class]="{'icon-chevron-up': !prodFilterAccord, 'icon-chevron-down': prodFilterAccord}"></span>
        </div>
            <form class="branded">
                <input class="form-control bcp-dashboard-search " [name]="'searchText1'" type="search"
                    placeholder="search" (input)="updateProductFilterList($any($event.target).value)"
                    [(ngModel)]="searchValue[1]">
                <div class="bcp-dashboard-side-panel">
                    <p *ngFor="let obj of updateProductSearchData; let i = index;"
                        class="align-items-center d-flex" [ngClass]="{'sticky-header' : obj.product === 'all'}">
                        <input type="checkbox" class="flex-shrink-0" name="productCheckbox" [id]="obj.product"
                            [value]="obj.product" [checked]="obj.checked" (change)="filterByProduct($event)">

                        <label class="text-ellipsis" [title]="obj.product" [htmlFor]="obj.product">
                            {{obj.productName}}
                        </label>
                    </p>
                </div>
                </form>
        </div>
    </ng-container>
</div>

<div class="panel-footer d-flex justify-content-between p-3">
    <span class="clear-filter" (click)="clearFilters()">clear</span>
    <span class="clear-filter" (click)="applyFilter()">apply</span>
</div>
