<div class="create-alert-notification-panel-wrapper">
    <div class="panel-header d-flex justify-content-between">
        <div class="panel-title">notifications</div>
        <button class="notification-btn notification-panel notification" (click)="closeAlertNotificationPanel()">
            <span class="icon-remove open"></span>
        </button>
    </div>
    <div class="panel-content">
        <div class="accordion-item">
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-6 branded">
                    <label for="" class="label">desk</label>
                    <select class="form-control dropDown-select" [(ngModel)]="selectedDesk" (change)="updateForTerminalSelection()">
                        <option value="" selected>all</option>
                        <option *ngFor="let desk of desksList">
                            {{desk}}</option>
                    </select> 
                </div>
                <div class="form-group col-md-6 branded">
                    <label class="label">terminal</label>
                    <select class="form-control dropDown-select" [(ngModel)]="this.selectedTerminal" (change)="updateForMaterialSelection()">
                        <option class="dropDown-select-option" value="" selected>all</option>
                        <option class="dropDown-select-option" *ngFor="let terminal of locList">
                            {{terminal}}</option>
                    </select>
                </div>
            </div>
            <div class="form-row pb-1 pr-2">
                <div class="form-group col-md-6 branded">
                    <label for="terminal" class="label">material</label>
                    <select class="form-control dropDown-select" [(ngModel)]="selectedMaterial" (change)="updateForRuleSelection()">
                        <option class = "dropDown-select-option" value="" selected>all</option>
                        <option class = "dropDown-select-option" *ngFor="let material of matNameList" [value]="material">
                            {{material}}</option>
                    </select>
                </div>
                <div class="form-group col-md-6 branded">
                    <label class="label">rule type</label>
                    <select class="form-control dropDown-select"  [(ngModel)]="selectedRuleType" (change)="this.ruleDropDownUpdate()">
                        <option class="dropDown-select-option" value="" selected>all</option>
                        <option class="dropDown-select-option" *ngFor="let ruleType of rulesDropdown" [value]="ruleType">
                            {{ruleType}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<ul *ngIf="this.deskFilterList.length > 0">
    <app-filter-desk-block-panel *ngFor = 'let deskFilter of deskFilterList' [cardValues]="deskFilter" (choseFilterOption)="changeDeskFilterUponClick($event)">
    </app-filter-desk-block-panel>
</ul>   

<div>
    <app-header-hide-list *ngFor="let divider of this.dividedCardList" [header]="divider.divider" [listLength]="divider.alertList.length">
        <app-workspace-alerts-summary-card *ngFor="let alertPayload of divider.alertList" [cardAlert]="alertPayload" (closeCard)="removeDismissedAlert($event)">
        </app-workspace-alerts-summary-card>
    </app-header-hide-list>
</div>

