import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject, takeUntil } from 'rxjs';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { setReceivedResponseBoolean, triggerTRSave, updatedOfftakersFalse } from '../../store/action/app.action';
import { AppState } from '../../store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectUDMScreen } from '../../store/selector/app.selector';
import { Utilities } from '../../utilities/utilities';
import * as Nom from '../../constants/nominations.constant';
import { LoaderService } from 'src/app/services/loader.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { UDMDesk } from '../../constants/terminal-replenishment.constant';
import { CreatePipelineNominationComponent } from 'src/app/core/components/create-pipeline-nomination/create-pipeline-nomination.component';
import { PopupWindowService } from 'src/app/services/popup-window.service';
import { nominationBehavior } from '../../interface/activity-tracker';
import { MdmService } from 'src/app/services/mdm.service';

export interface MyCellRendererParams {
  rowDataRecord: any;
  parentRecord: any;
  totalRecords: any;
  isDetailGrid: any;
  isIntankMot: any;
}
@Component({
  selector: 'more-menu-non-cp',
  templateUrl: './more-menu-nonCP.component.html',
  styleUrls: ['./more-menu-nonCP.component.scss']
})
export class MoreMenuNonCPComponent implements ICellRendererAngularComp {
  constructor(
    private appStore: Store<AppState>,
    private utilities: Utilities,
    private router: Router,
    private toasterService: ToastService,
    private nominationService: NominationService,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private store: Store,
    private toaterService: ToastService,
    private myMonitoringService: MyMonitoringService,
    public popupWindowService: PopupWindowService,
    private data: NominationService,
    private mdmService : MdmService
  ) { }
  public value: any;
  public params: any;
  public parentRecord: any;
  public totalRecords: any = 35;
  public isDetailGrid: any;
  public rowIndex: any;
  public details: any;
  public customer: any;
  public counterPart: any;
  public isEditable = true;
  public isOpen = false;
  public moremenuIconShow = true;
  public nominationTab: any;
  public lineItem = false;
  public desk: any;
  public rowDataRecord: any;
  getChildId: any
  PipelineUswcPNWDesk:string = UDMDesk.PipelineUswcPNWDesk; 
  PipelineUsecFloridaDesk:string = UDMDesk.PipelineUsecFloridaDesk;
  PipelineUsecRichmondDesk:string = UDMDesk.PipelineUsecRichmondDesk;
  PipelineUsecpascagoulaDesk:string = UDMDesk.PipelineUsecPascagoulaDesk;
  // public disablePublish: any;
  selectedDesk$ = this.store.pipe(select(selectDesk));
  screen$ = this.store.pipe(select(selectUDMScreen));
  destroy$: Subject<boolean> = new Subject<boolean>();
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  pipelineParentKey: string='';
  pipelineParentObj:any;
  childGridData:any;
  pipelineCurrentDesk: string = '';
  getVesselData: any;
  getDesk: any;
  isCargo: boolean = false;

  isCCAView: any;
  isIntank: any;
  panamaChildRecord: any;
  @ViewChild(CreatePipelineNominationComponent) createPipelineNominationComponent!: CreatePipelineNominationComponent;

  agInit(params: ICellRendererParams<any, any, any> & MyCellRendererParams): void {
    this.selectedDesk$.subscribe((desk:any)=>{
      this.getDesk = desk
      this.isCargo = this.getDesk.selectedDesk.toLowerCase() === UDMDesk.PipelineUsecFloridaDesk || this.getDesk.selectedDesk.toLowerCase() === UDMDesk.PipelineUsecPascagoulaDesk || this.getDesk.selectedDesk.toLowerCase() === UDMDesk.PipelineUswcPNWDesk || this.getDesk.selectedDesk.toLowerCase() === UDMDesk.PipelineUsecRichmondDesk ? true : false
    })
    if(!params.data && params.node.key){      
      this.pipelineParentObj = params.node;
      this.rowIndex = params.node.rowIndex;
      this.details = this.pipelineParentObj.aggData;
      this.pipelineCurrentDesk = this.pipelineParentObj.aggData.udmDesk;
      this.childGridData = this.pipelineParentObj?.allLeafChildren[0]?.data;
      this.pipelineParentKey =  this.isCargo ? this.childGridData?.nominationNo :  params.node.key;
    }
    if(params.data){
      this.rowIndex = params.node.parent?.rowIndex;
      this.pipelineParentKey = params.data.nominationNo;
      this.panamaChildRecord = params.data;
      this.getChildId = params?.data?.id;
    }
    this.isIntank = params.isIntankMot;
    this.isDetailGrid = params.data?true:false;
    this.lineItem = params.data?.lineItem;
    this.nominationTab = this.router.url === '/nominations';
    this.customer = params?.colDef?.field?.split('.')[0];
    this.counterPart = this.customer.split('_')[1];
    this.rowDataRecord = params.rowDataRecord;
    this.getUserandDeskDetails();
    this.getVesselData = {data:params?.node?.aggData, nomNo:this.isCargo ? this.childGridData?.nominationNo : params?.node?.key, nomData: (params?.node?.allLeafChildren?params?.node?.allLeafChildren[0]?.data:null)}
    if (params.data && this.desk?.includes("clean products")) {
      this.parentRecord = params.parentRecord;
      this.rowIndex = params.rowIndex;
      this.details = params.data;
    }

    let transformedDate;
    let rowDate = params?.data?.date;
    if(rowDate && params?.data['modeOfTransport']?.toLowerCase() === 'pipeline'){
      rowDate = params?.data?.scheduledDate;
    }
    if (rowDate && params?.data['modeOfTransport']?.toLowerCase() !== 'pipeline') {
      transformedDate = this.datePipe.transform(
        new Date(rowDate),
        'MM/dd/yyyy'
      );

      const currentRowDate = transformedDate?.split('/');

      this.setMoreMenuflag(currentRowDate);
    }
    this.initalizeValues(params)
  }

  setMoreMenuflag(currentRowDate: any){
    let currentRowFormatedDate;
    if (currentRowDate) {
      currentRowFormatedDate = new Date(
        `${currentRowDate[0]}/${currentRowDate[1]}/${currentRowDate[2]}`
      );
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      currentRowFormatedDate.setHours(0, 0, 0, 0);
      if (currentRowFormatedDate < today || (this.customer.includes('nomination') && (this.counterPart === 'DELTA' || this.counterPart === 'TERPEL'))
        || this.counterPart === 'CPFL' || this.counterPart === 'TOTAL' || (this.customer.includes('heels') && (this.counterPart?.toLowerCase() === 'psat'))) {
        this.moremenuIconShow = false;
      }
      else if (currentRowFormatedDate > today) {
        this.moremenuIconShow = true;
      }
    }
  }

  getUserandDeskDetails() {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          this.isCCAView = this.utilities.checkIfCCAView(response.selectedDesk);
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
  }
  initalizeValues(params: any) {
    if(!params.data) return;
    let qty;
    this.isEditable = params.data[`isEditable_${this.counterPart}`] || (params.data['sys_status']?.toLowerCase() === 'unpublished'|| params.data['sys_status']?.toLowerCase() === 'failed' || params.data['sys_status']?.toLowerCase() === 'published' || params.data['sys_status']?.toLowerCase() === 'multiple');
    const deltaorTerple_Utilization = this.customer.includes('utilization');
    if (deltaorTerple_Utilization) {
      qty = params.data[`utilization_${this.counterPart}`]
    }
    else if (this.customer.includes('nomination')) {
      qty = params.data[`nomination_${this.counterPart}`]
    }
    else if (this.customer.includes('heels')) {
      qty = params.data[`heels_${this.counterPart}`];
    }
    else if (this.customer.includes('reserve')) {
      qty = params.data[`reserve_${this.counterPart}`];
    }
    else if (this.customer.includes('safety')) {
      qty = params.data[`safety_${this.counterPart}`]
    }
    else {
      qty = ""
    }
    this.value = this.utilities.formatNumber(qty);
    this.assignDetails(params);
    this.params = params;
  }

  assignDetails(params: any) {
    if ((this.customer.includes('nomination') || this.customer.includes('utilization'))) {
      this.details = params.data[`nomination_${this.counterPart}_nominationDetails`];
    }
    else if (this.customer.includes('heels')) {
      this.details = params.data[`nomination_${this.counterPart}_heelsDetails`]
    }
    else if (this.customer.includes('reserve')) {
      this.details = params.data[`nomination_${this.counterPart}_reserveDetails`]
    }
    else if (this.customer.includes('safety')) {
      this.details = params.data[`nomination_${this.counterPart}_safetyDetails`]
    }
  }




  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  moremwnu() {
    this.isOpen = !this.isOpen;
  }

  notDetailGrid(nominationDetails: any, requestBodyArr: any) {
    const deletedNomStatus = nominationDetails?.sys_status;
    nominationDetails.headerToItemNav.forEach((elem: any) => {
        const deletedItemNomKey = elem?.map((val:any)=>val.nominationKey).every((item:any)=>item?.length>0);
        let tempRequestBody:any = {
        id: elem[0]?.id,
        deskFilter: this.isCCAView ? "ccadesk" : "pipelinedesk",
          sys_status:  deletedItemNomKey?"InProcess":deletedNomStatus,
      }
      tempRequestBody.headerToItemNav = [];
      elem.forEach((ele: any) => {
        const deletedUdmItemStatus = ele?.sys_status;
        let deletedItemNomKey = ele?.nominationKey;
        deletedItemNomKey = deletedItemNomKey?.length;
        const obj = {
          itemId: ele.itemId,
            udmItemStatus: deletedItemNomKey?"InProcess":deletedUdmItemStatus,
          deletionIndicator: "X",
          textLine: `{Author:${nominationDetails.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
        }
          if((requestBodyArr.length === 0)||(requestBodyArr.every((e:any)=>e.id!==ele.id) && requestBodyArr.length)){
          tempRequestBody.headerToItemNav.push(obj);
        }
      })
      requestBodyArr.push(tempRequestBody)

    })
    return requestBodyArr;
  }

 async pipelineDeletePayload(scenario: any, createdBy: any,data:any) {
    const groupingTempPayload ={
      type: 'group',
      values: data,
        ...(this.isCargo && {nomId:this.childGridData?.id || this.getChildId})
    }
    const nominationDetails = await this.utilities.getPipelineNomPayload(groupingTempPayload, this.userEmail, this.desk, true);

    let requestBodyArr:any=[];
    // Checking if we delete from parent row in nomination module
    if(!this.isDetailGrid){
      requestBodyArr = this.notDetailGrid(nominationDetails, requestBodyArr)
    }else{ // If we are deleting a line item individually in nomination module
      requestBodyArr = this.isADetailGrid(requestBodyArr, createdBy)
    }
    return requestBodyArr;
  }

  isADetailGrid(requestBodyArr: any, createdBy: any) {

    const deletedUdmItemStatus = this.details?.udmItemStatus;
    let deletedItemNomKey = this.details?.nominationKey;
    deletedItemNomKey = deletedItemNomKey?.length;

    let tempRequestBody: any = {
      id: this.details?.id,
      deskFilter: this.isCCAView ? "ccadesk" : "pipelinedesk",
      sys_status: deletedItemNomKey ? "InProcess" : this.details?.nominationStatus,
    }
    tempRequestBody.headerToItemNav = [{
      itemId: this.details.itemId,
      deletionIndicator: 'X',
      udmItemStatus: deletedItemNomKey ? "InProcess" : deletedUdmItemStatus,
      textLine: `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
    }]
    requestBodyArr.push(tempRequestBody);
    return requestBodyArr;
  }

  ethanolUswcDeletePayload(scenario: any, createdBy: any) {
    const data = this.parentRecord ? this.parentRecord : this.details;
    const isTruck = data?.modeOfTransportDesc?.toLowerCase() === Nom.TRUCK.name?.toLowerCase();
    const textLine = isTruck ? `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}` : `{Type:${scenario},Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`;
    const shipperText = `{Type:${scenario},Carrier:${data?.carrier || null},Supplier:${data?.supplier || null}}`;
    let requestBody: any = {
      id: this.parentRecord ? this.parentRecord?.id : this.details?.id,
      isEthanolNominationType: true,
      sys_status: this.parentRecord ? this.parentRecord?.sys_status : this.details?.sys_status,
      headerToItemNav: []
    }
      this.details.headerToItemNav?.map((val:any, i:number)=>{
      requestBody.headerToItemNav.push({
        itemId: val.itemId,
        deletionIndicator: 'X',
        udmItemStatus: val.udmItemStatus,
        textLine: textLine,
      })
        if(isTruck){
        requestBody.headerToItemNav[i].shipperText = shipperText
      }
      return val;
    })
    if (!this.details?.headerToItemNav?.length) {
      requestBody.headerToItemNav = [{
        itemId: this.details.itemId,
        deletionIndicator: 'X',
        udmItemStatus: this.details?.sys_status,
        textLine: textLine,
      }];
      if(isTruck){
        requestBody.headerToItemNav[0].shipperText = shipperText
      }
    }

    return requestBody
  }

  getScenario() {
    return this.details?.headerToItemNav?.length > 0 ? this.details?.headerToItemNav[0].scenario : this.details?.scenario;
  }

  async updateRequestBody(requestBody:any,scenario:any,createdBy:any,data?:any){
    if (this.desk?.toLowerCase() === 'ethanol + uswc' || this.desk?.toLowerCase() === 'ethanol + usec') {
      requestBody = this.ethanolUswcDeletePayload(scenario, createdBy)
    } else if (this.desk?.includes('clean products')) {
      requestBody = await this.pipelineDeletePayload(scenario, createdBy, data);
    }
    else {
      requestBody = {
        id: this.details?.id,
        sys_status: this.details?.status?.toLowerCase() === 'published' ? 'Unpublished' : this.details?.status,
        headerToItemNav: [{
          itemId: this.details.itemId,
          deletionIndicator: 'X',
          udmItemStatus: this.details?.status?.toLowerCase() === 'published' ? 'Unpublished' : this.details?.status,
          textLine: `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
        }]
      }
    }
    return requestBody;
  }

  async delete(data:string, panamaData: any = this.rowDataRecord){
    this.data.popupAction("delete");
    this.mdmService.invokeToggleMethod(true);
    this.mdmService.actionConfirmed$.subscribe((actionConfirmed)=>{
      if(actionConfirmed){
        if(data !== ''){
          this.deleteAfterConfirm(data, panamaData);
          this.mdmService.actionConfirm(false);
          this.mdmService.invokeToggleMethod(false);
          data = '';
        }

      }
    });
  }

 async deleteAfterConfirm(data:string, panamaData: any = this.rowDataRecord) {
    const payload: any[] = [];
    let requestBody;
    let scenario = this.getScenario();
    const recordDataForPayload = this.desk?.includes("+ latam + panama") ? (panamaData || this.panamaChildRecord) : data;

    let createdBy = this.details?.headerToItemNav?.length > 0 ? this.details?.headerToItemNav[0]?.createdBy : this.details?.createdBy;
    requestBody = await this.updateRequestBody(requestBody,scenario,createdBy,recordDataForPayload);
    if (Array.isArray(requestBody) && requestBody?.length) {
      payload.push(...requestBody)
    }else{
      payload.push(requestBody)
    }
    this.nominationService.restrictUpdateFromTRApiCall$.next(true);
    this.nominationService.restrictUpdateNomApiCall$.next(true);
    //return;
    this.screen$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.screen === "replenishmentplanning" && this.nominationService.restrictUpdateFromTRApiCall$.value) {
        this.nominationService.updateNominationFromTRScreen(payload).subscribe({
          next: (response) => {
            this.loaderService.setLoading(true);
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: "Nomination has been deleted",
            });
            this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true }));
            this.myMonitoringService.logEvent(`Delete nomination`, {category:"Replenishment Planning"})
          },
          error: (err) => {
            this.toasterService.setToastNotification({ show: true, type: 'error', msg: `Request coudn't get completed, try again in sometime.` });
          },
          complete: () => {
            const selectedTabFunction = {
              tab: this.router.url,
              function: 'onDeleteEthanol',
              type: '',
              values: '',
            };
            const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
            this.utilities.addUnsubscribableList(modalPopupAction);
          },
        });
      } else if (data.screen === "nominations" && this.nominationService.restrictUpdateNomApiCall$.value) {
        this.nominationService.updateNomination(payload).subscribe({
          next: (response) => {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: "Nomination has been deleted",
            });
            this.myMonitoringService.logEvent(`Delete nomination`, {category:"Nominations"})
          },
          error: (err) => {
            this.toasterService.setToastNotification({ show: true, type: 'error', msg: `Request coudn't get completed, try again in sometime.` });
          },
          complete: () => {
            const selectedTabFunction = {
              tab: this.router.url,
              function: 'onDeleteEthanol',
              type: '',
              values: '',
            };
            const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
            this.utilities.addUnsubscribableList(modalPopupAction);
            this.loaderService.setLoading(false);
          },
        });
      }
    })

  }

  goToNominations() {
    if (this.desk?.toLowerCase() === "ethanol + uswc" || this.desk?.toLowerCase() === 'ethanol + usec') {
      this.nominationService.restrictNomApiGetCall$.next(true);
    }
    this.store.dispatch(updatedOfftakersFalse({ updatedOfftakers: false }));
    this.appStore.dispatch(setReceivedResponseBoolean({ isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: true, isTRGrid: false }));
    this.router.navigate(['/nominations']);
    this.nominationService.goToNominationData$.next({ isTrue: true, data: this.rowDataRecord })
    if (this.desk?.toLowerCase() === "panama") {
      this.nominationService.getTerminalNominationAFAPI$.next(true)
    }
  }

  seeInNominationPannel() {
    this.nominationService.sidePanel$.next(true);
    this.nominationService.sidePanelData$.next(this.params)
  }

  onCreateDraftNomination(ref?: any) {
    let formatedValue = this.value.replaceAll(',', '');

    let nominationDetails: any = {
      'counterPart': this.counterPart,
      'product': this.params.data.productName,
      'destinationDate': this.params.data.date,
      'transportSystem': this.params.data[`transportSystem_${this.counterPart}`],
      'uDMNominationGrouping': this.params.data.uDMNominationGrouping,
      'status': ref === 'reference' ? undefined : this.details?.status,
      'id': ref === 'reference' ? undefined : this.details?.id,
    }

    if (this.counterPart === 'DELTA') {
      nominationDetails['destinationLocation'] = this.params.data[`locationId_${this.counterPart}`];
      this.params.data[`nomination_${this.counterPart}_nominationDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_nominationDetails`].nominationQty;
      nominationDetails['inventoryDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_nominationDetails`]
      ];
      this.params.data[`nomination_${this.counterPart}_safetyDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_safetyDetails`].safetyQty;
      nominationDetails['safetyDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_safetyDetails`]
      ];
    } else if (this.counterPart === 'TERPEL') {
      nominationDetails['destinationLocation'] = this.params.data[`locationId_${this.counterPart}`];
      this.params.data[`nomination_${this.counterPart}_nominationDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_nominationDetails`].nominationQty;
      nominationDetails['inventoryDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_nominationDetails`]
      ];
      this.params.data[`nomination_${this.counterPart}_reserveDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_reserveDetails`].reserveQty;
      nominationDetails['reserveDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_reserveDetails`]
      ];
      this.params.data[`nomination_${this.counterPart}_heelsDetails`]['scheduledQty'] = this.params.data[`nomination_${this.counterPart}_heelsDetails`].heelsQty;
      nominationDetails['heelsDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_heelsDetails`]
      ];
    } else {
      nominationDetails['destinationLocation'] = this.params.data[`locationId_${this.counterPart}`];
      nominationDetails['scheduledQty'] = formatedValue;

      nominationDetails['inventoryDetailType'] = [
        this.params.data[`nomination_${this.counterPart}_nominationDetails`]
      ];
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'createDraftNomination',
      type: '',
      values: nominationDetails,
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);

  }

  async editNomination(data: string, panamaData: any = this.rowDataRecord) {
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'editPopupNomNo',
      editNomination: true,
      type: 'group',
      desk: this.desk,
      values: this.desk?.includes("+ latam + panama") ? panamaData : data,
      ...(this.isCargo && {nomId:this.childGridData?.id})
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  async createNomWithRef(data:string, panamaData: any = this.rowDataRecord) {
    this.nominationService.isModalPopupOpen$.next(false);
    let selectedTabFunction: any;
    if (this.desk?.includes('clean products')) {
      selectedTabFunction = {
        tab: this.router.url,
        function: 'pipelineCreateNominationWithReferenceNonCP',
        type: 'group',
        desk: this.desk,
        values: this.desk?.includes("+ latam + panama") ? panamaData : data,
        editNomination: false,
        ...(this.isCargo && {nomId:this.childGridData?.id})
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }
    this.myMonitoringService.logEvent(`Create ${this.desk} nomination`, {category:"Nomination"});

  }
  contractBalancing() {
    console.log('contractBalancing clicked');
  }
  vesselScheduleUpdate() {
    let selectedTabFunction: any
    selectedTabFunction = {
      tab: this.router.url,
      function: 'pipelineVesselScheduleUpdate',
      type: '',
      values: this.getVesselData
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  publishEthanol(deskCondition: any) {
    if (deskCondition && !this.details?.demandMaterial && !this.details?.scheduledMaterial) {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `publishEthanolDataFromMoreMenu`,
        type: '',
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);
      this.myMonitoringService.logEvent(`Published ${this.desk} nomination`, {category:"Nomination"})
    }
  }

  publishPipeline(){
    if ((this.desk?.includes('clean products')) && !this.details?.demandMaterial && !this.details?.scheduledMaterial) {
      const selectedTabFunction = {
        tab: this.router.url,
        function: `publishPipelineDataFromMoreMenu`,
        type: '',
      };
      const publishOrDelete = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(publishOrDelete);
      this.myMonitoringService.logEvent(`Published ${this.desk} nomination`, {category:"Nomination"})
    }
  }

  async publish(data: string, panamaData: any = this.rowDataRecord) {

    const obj = {
      'desk': this.desk,
      'panamaData': this.rowDataRecord,
      'panamaChildRecord': this.panamaChildRecord,
      'childGridData': this.childGridData,
      'getChildId': this.getChildId,
      'userEmail': this.userEmail,
      'isCargo': this.isCargo,
      'details': this.details,
      'parentRecord': this.parentRecord,
      'isCCAView': this.isCCAView
    }

    await this.utilities.publish(data, obj);
    
  }


  async seeDetails() {
    localStorage.setItem('nomId', this.rowDataRecord?.id);
    localStorage.setItem('nominationNo', this.pipelineParentKey);
    this.router.navigate(['nominations/nominationDetails'])
  }

  disableVesselOption(details:any){
    if (((details?.modeOfTransportDesc?.toLowerCase() === 'pipeline' || details?.modeOfTransportDesc?.toLowerCase() === 'intank') && (details?.tdVehIdentifier?.toLowerCase() !== 's' || details?.tdVehIdentifier?.toLowerCase() !== 'b')) || details?.nominationItemStatus ===5) {
      return true
    }
    return  false
  }

  openActivityTracker(){
    let nominationData :nominationBehavior[] = [];

    //two cases, one is the line item selection
    if(this.isDetailGrid){
      let nomInfo: nominationBehavior = {
        nominationId: this.details.id,
        itemID: this.details.itemId,
        nominationNo: this.details.nominationNo,
        nominationKey: this.details.nominationKey,
        nominationKeyItem: this.details.nominationKeyItem,
      }
      nominationData.push(nomInfo);

    }else{ //in the case of all line items, must handle all 
      this.pipelineParentObj.childrenAfterAggFilter.forEach((currentNom: any) => {

        let nomInfo: nominationBehavior = {
          nominationId: currentNom.data.id,
          itemID: currentNom.data.itemId,
          nominationNo: currentNom.data.nominationNo,
          nominationKey: currentNom.data.nominationKey,
          nominationKeyItem: currentNom.data.nominationKeyItem,
        }
        nominationData.push(nomInfo);
      })
    }
    this.popupWindowService.openWindow('activityTracker', nominationData);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.utilities.clearDataToChild();
    this.utilities.unsubscribeDataToChild();
  }
}
