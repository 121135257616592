<div class="delete-alerts">
    <div class="label-3">this rule will be remain in the system for 90 days and you can restore it until then.</div>
    <div>&nbsp;</div>
    <div class="label-2">you are about to delete {{ selectedRuleName }}:</div>
    <div class="label-1">{{selectedSummary}}</div>
    <div class="label-2">on the frequency</div>
    <div class="label-1">{{selectedFreqFull}}</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-6 branded">
            <button class="cancel-btn" (click)="closePopup()">cancel</button>
        </div>
        <div class="form-group col-md-6 branded">
            <button class="delete-rule-btn" (click)="deleteRule()">delete rule <img src="../../assets/icons/icon-alerts-delete-white.svg" alt="delete-icon"></button>
        </div>
    </div>
</div>
