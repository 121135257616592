import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { firstValueFrom, from, lastValueFrom, Observable} from 'rxjs';

import { environment } from 'src/environments/environment';
import { CalAngularService } from '@cvx/cal-angular';
import { MICOR_SERVICE_TYPE } from './services/api.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthTokenServiceInterceptor implements HttpInterceptor {
  constructor(private authService: CalAngularService, private location: Location) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const targetMicroService = request.context.get(MICOR_SERVICE_TYPE);
    
    let tokenURL = '';
    if(targetMicroService === 'nomination'){
      tokenURL = environment.nominationTokenURL;
    } else if(targetMicroService === 'replenishmentPlanning'){
      tokenURL = environment.replenishmentPlanningTokenURL;
    } else if(targetMicroService === 'bcpDashboard'){
      tokenURL = environment.bcpDashboardTokenURL;
    } else if(targetMicroService === 'contractBalancing'){
      tokenURL = environment.contractBalancingTokenURL;
    } else if(targetMicroService === 'cbDashboard'){
      tokenURL = environment.contractBalancingTokenURL;
    } else if(targetMicroService === 'mdm'){
      tokenURL = environment.mdmTokenURL;
    } else if(targetMicroService === 'stateManagement'){
      tokenURL = environment.stateManagementApiTokenURL;
    }else if(targetMicroService === 'alert'){
      tokenURL = environment.alertTokenURL;
    }else if(targetMicroService === 'cargo'){
      tokenURL = environment.cargoTokenURL;
    } else if(targetMicroService === 'cargoPlanning' ||
      targetMicroService === 'cargoDuplicate' ||
      targetMicroService === 'cargoRefData'
    ) {
      tokenURL = environment.ccaCargoTokenURL;
    }else if(targetMicroService === 'activityTracker'){
      tokenURL = environment.activityTrackerApiTokenURL;
    } 
    
    const tok = await firstValueFrom(this.authService.getAADToken([tokenURL], false));
    const requesTypeDef = 'json';
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${tok}`),
      responseType: requesTypeDef
    });
    
    return await lastValueFrom(next.handle(request));
  }
}