import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { BCPDeskFilter, BCPFilters, BCPLocationsFilter, BCPProductsFilter, BCPRefData, BCPSummaryData } from '../../../shared/interface/bcp-dashboard.interface';
import { getUserDetails, selectALLDeskInfo, selectDesk } from '../../../shared/store/selector/app.selector';
import { getBCPRefData, selectBCPData } from '../../../bcp-dashboard/store/selector/bcp-dashboard.selector';
import { setDefaultBCPFilters, updateBCPFilters } from '../../../bcp-dashboard/store/action/bcp-dashboard.action';
import { UDMDesk } from '../../../shared/constants/terminal-replenishment.constant';
import { MdmService } from 'src/app/services/mdm.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { MDMDeskValues } from 'src/app/shared/interface/mdm.interface';
import { Router } from '@angular/router';
import { NominationService } from 'src/app/services/nomination.service';
import { setDeskWiseLocationsAndProducts } from 'src/app/shared/store/action/app.action';

@Component({
  selector: 'app-bcp-dashboard-filter',
  templateUrl: './bcp-dashboard-filter.component.html',
  styleUrls: ['./bcp-dashboard-filter.component.scss']
})
export class BCPDashboardFilterComponent implements OnInit {
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  updateLocationSearchData: BCPLocationsFilter[] = [];
  updateProductSearchData: BCPProductsFilter[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  bcpRefDataResponse: BCPRefData[] = [];
  filters: BCPFilters = { locationsFilter: [], productsFilter: [] ,desksFilter:[], offTakers:[] };
  updatedLocationsFilter: BCPLocationsFilter[] = [];
  updatedProductsFilter: BCPProductsFilter[] = [];
  public bcpGridData: BCPSummaryData[] = [];
  deskValues: MDMDeskValues[] = [];
  deskList: BCPDeskFilter[] = [];
  selectedDeskId : any = '';
  initialDeskId: any = localStorage.getItem("deskId");
  
  bcpData$ = this.store.pipe(select(selectBCPData));
  getBCPRefData$ = this.store.pipe(select(getBCPRefData));
  desk$ = this.store.pipe(select(selectDesk));
  deskDetails$ = this.store.pipe(select(selectALLDeskInfo));
  userDetails$ = this.store.pipe(select(getUserDetails));
  selectedDesk: any;
  filterApiInvoked: boolean = false;
  bcpDataReceived: boolean = false;
  deskDataReceived:boolean = false;
  globalAggregatorResponse:any;
  masterPipelineAggregatorResponse:any;
  deskApiInvoked:any=false;
  prevDesk:string='';
  DeskfilterObj:any;
  allowForGetCustomView: boolean = false;
  userFullEmail: any;
  userEmail: any;
  alreadyRanOnce:boolean=false;
  constructor(private store: Store, private mdmService : MdmService, private utilities:Utilities,private router: Router,
    private nominationService:NominationService) {}

  ngOnInit(): void {
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.selectedDesk != "") {
          this.selectedDesk = response.selectedDesk?.toLowerCase();
          localStorage.setItem('selectedDesk', this.selectedDesk);
          this.selectedDeskId = +response.selectedDeskId;
        } else {
          const savedDesk = localStorage.getItem('selectedDesk');
          if (savedDesk) {
            this.selectedDesk = savedDesk;
          } else {
            this.selectedDesk = UDMDesk.Panama;
          }
          if(this.initialDeskId)
          {
            this.selectedDeskId = +this.initialDeskId;
          }          
        }
        if(!this.deskApiInvoked || (this.prevDesk !== this.selectedDesk))
        {
          this.deskApiInvoked = true;
          this.prevDesk = this.selectedDesk;   
          this.filterApiInvoked = false;      
          this.deskDataReceived = false; 
          //this.getFiltersData();
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });
    this.userDetails$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (response.userDetails.isUser) {
          this.userEmail = this.utilities.getUserName(response.userDetails.email);
          this.userFullEmail = response.userDetails.email;
          localStorage.setItem("userEmail", this.userFullEmail);
        }
      },
      error: (err: any) => { },
      complete: () => { },
    });
    this.deskDetails$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next:(response: any) => {
       this.deskValues = response.deskInfo;
       if(this.deskValues.length>0 && !this.deskDataReceived){
          this.deskList = this.getRequiredFilterDeskDetails(this.deskValues,this.selectedDesk)
          this.getFiltersData(this.deskList); 
          this.deskDataReceived = true;
       }
        }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.bcpData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (response.value && !this.bcpDataReceived) {
          if(response.value?.data.length > 0 && response.value?.data[0].desk == this.selectedDeskId)
          {
            this.bcpDataReceived = true;
            this.store.dispatch(setDefaultBCPFilters({ BCPFilters: JSON.parse(JSON.stringify(this.filters)), updateBCPSummaryTable:false }));          
          }       
        } 
        else {
          this.bcpGridData = [];
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });

    this.nominationService.applyCustomView$.subscribe(res => {
      if (res && localStorage.getItem("selectedView") && this.router.url === '/bcpDashboard') {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    });

    this.nominationService.applyEditedViewToUi$.subscribe(res => {
      if (res && localStorage.getItem("selectedView") && this.router.url === '/bcpDashboard') {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    })

    this.nominationService.triggerClearFilter$.pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (res: any) => {
        if(res && this.router.url === '/bcpDashboard')
          this.clearFilters();
      }
    });

    this.nominationService.applyDefaultCustomView$.pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res) {
          if (!this.alreadyRanOnce || this.router.url.toLowerCase().includes("bcpdashboard")) {
              this.alreadyRanOnce = true;               
              this.clearFilters()          
          } 
        }
      })
   
  }
  
  filterByDesk(event:any): void{
   
    if(event.target.checked){
      const checkedDesk = event.target.value;
      const selectDeskDetails = this.DeskfilterObj.terminal.filter((item:any)=> item.deskName.toLowerCase() == checkedDesk.toLowerCase());      
      this.filters.desksFilter.forEach((dname:BCPDeskFilter)=> { if(dname.deskName == checkedDesk) dname.checked = true; });            
      this.sortSelectedDesk(selectDeskDetails);
      this.filters.productsFilter = this.sortmaterialsList(this.filters.productsFilter); 
      this.updateLocationSearchData = this.filters.locationsFilter;
      this.updateProductSearchData = this.filters.productsFilter;
    }
    else {
       const checkedDesk = event.target.value;
       this.filters.desksFilter.forEach((dname:BCPDeskFilter)=> { if(dname.deskName == checkedDesk) dname.checked = false; });       
       this.filters.locationsFilter = this.filters.locationsFilter.filter(item => item.deskName != event.target.value);
       this.filters.productsFilter = this.filters.productsFilter.filter(item=>item.deskName != event.target.value);
       this.filters.productsFilter = this.sortmaterialsList(this.filters.productsFilter); 
       this.updateLocationSearchData = this.filters.locationsFilter;
       this.updateProductSearchData = this.filters.productsFilter;
    }    
  }

  updateLocationFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    let alllocations = this.filters.locationsFilter[0];
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.filters.locationsFilter.filter((ele: any) => ele.location.toLowerCase().includes(text.value.toLowerCase()) && ele.location !== 'all')];
        updatedFilterList.unshift(alllocations);
        this.updateLocationSearchData = JSON.parse(JSON.stringify(updatedFilterList));
      }
      else {
        this.updateLocationSearchData = this.filters.locationsFilter;
      }
    });
  }

  updateProductFilterList(event: any): void {
    const searchObj: { value: string } = { value: event?.toLowerCase() };
    this.searchText.push(searchObj);
    let allProducts = this.filters.productsFilter[0];
    this.searchText.forEach((text: any, i: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.filters.productsFilter.filter((ele: any) => ele.productName.toLowerCase().includes(text.value.toLowerCase()) && ele.productName !== 'all')];
        updatedFilterList.unshift(allProducts);
        this.updateProductSearchData = this.sortmaterialsList(JSON.parse(JSON.stringify(updatedFilterList)));
      }
      else {
        this.updateProductSearchData = this.sortmaterialsList(this.filters.productsFilter);
      }
    });
  }

  filterByLocation(event: any): void {
    if (event.target.value === 'all') {
      this.updateAllLocationFilter(event);
    } else {
      this.updateSelectedLocationFilter(event);
    }
    this.filters.productsFilter = this.sortmaterialsList(this.filters.productsFilter);    
    this.updateProductSearchData = this.filters.productsFilter;
    this.updateLocationSearchData = this.filters.locationsFilter;   
  }

  updateAllLocationFilter(event: any): void {
    this.updatedProductsFilter = [];
    this.filters.locationsFilter.forEach((locationFilter: BCPLocationsFilter) => {
      locationFilter.checked = event.target.checked;
      if (event.target.checked) {
        locationFilter.products.forEach((productFilter: BCPProductsFilter) => {
          if(this.updatedProductsFilter.filter((updatedProduct: BCPProductsFilter) => updatedProduct.product === productFilter.product).length === 0) {
            if (productFilter.product === 'all') {
              this.updatedProductsFilter.push({ product: productFilter.product,productName:productFilter.productName, checked: false ,deskName:productFilter.deskName,deskId:productFilter.deskId});
            } else {
              productFilter.checked = true;
              this.updatedProductsFilter.push(productFilter);
            }
          }
        });
      }
    });
    if (event.target.checked) {
      this.formatProductsFilter();
    }
    this.filters.locationsFilter[0].products = this.updatedProductsFilter;
    this.filters.productsFilter = this.updatedProductsFilter;
  }

  formatProductsFilter() {
    if (this.updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productFilter.product === 'all').length === 0) {
      let allProductsChecked: boolean = this.updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productFilter.checked === true).length === this.updatedProductsFilter.length;
      this.updatedProductsFilter.unshift({product: 'all',productName:'all', checked: allProductsChecked,deskName:'all',deskId:'all'});
    } else {
      this.updatedProductsFilter[0].checked = true;
    }
  }

  updateSelectedLocationFilter(event: any): void {
    this.updatedLocationsFilter = this.filters.locationsFilter;
    this.updatedProductsFilter = this.filters.productsFilter;
    let selectedLocationFilter = this.filters.locationsFilter.find((locationFilter: BCPLocationsFilter) => locationFilter.location === event.target.value);
    selectedLocationFilter!.checked = event.target.checked;
    let selectedLocationsCount = this.filters.locationsFilter.filter((locationFilter: BCPLocationsFilter) => locationFilter.checked === event.target.checked && locationFilter.location !== 'all').length;
    let locationsCount = this.filters.locationsFilter.filter((locationFilter: BCPLocationsFilter) => locationFilter.location !== 'all').length;

    if (event.target.checked) {
      this.formatCheckedLocationFilter(selectedLocationFilter, locationsCount, selectedLocationsCount);
    } else {
      this.formatUncheckedLocationFilter(selectedLocationFilter, locationsCount, selectedLocationsCount);
    }
    this.updatedLocationsFilter[0].products = this.updatedProductsFilter;
    this.filters.locationsFilter = this.updatedLocationsFilter;
    this.filters.productsFilter = this.updatedProductsFilter;
  }

  formatCheckedLocationFilter(selectedLocationFilter: any, locationsCount: any, selectedLocationsCount: any) {
    selectedLocationFilter?.products.forEach((productForLocation: BCPProductsFilter) => {
      if (this.updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productForLocation.product === productFilter.product).length === 0) {
        this.updatedProductsFilter.push(productForLocation);
      }
    });
    if (locationsCount === selectedLocationsCount) {
      this.updatedLocationsFilter[0].checked = true;
      this.updatedLocationsFilter[0].products = this.updatedProductsFilter;
    }
    let allProductsChecked: boolean = this.updatedProductsFilter
      .filter((productFilter: BCPProductsFilter) => productFilter.product !== 'all').length === this.updatedProductsFilter
      .filter((productFilter: BCPProductsFilter) => productFilter.product !== 'all' && productFilter.checked === true).length;
    let allProductsFilterExist: boolean = this.updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productFilter.product == 'all' || productFilter.productName == 'all').length > 0;
    if (allProductsFilterExist) {
      this.updatedProductsFilter[0].checked = allProductsChecked;
    } else {
      this.updatedProductsFilter.unshift({ product: 'all',productName:'all', checked: allProductsChecked,deskName:'all',deskId:'all' });
    }
  }

  formatUncheckedLocationFilter(selectedLocationFilter: any, locationsCount: any, selectedLocationsCount: any){
    selectedLocationFilter?.products.forEach((productForLocation: BCPProductsFilter) => {
      if (this.updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productForLocation.product === productFilter.product).length > 0) {
        let removeProduct = true;
        this.updatedLocationsFilter
          .filter((locationFilter: BCPLocationsFilter) => locationFilter.location !== selectedLocationFilter?.location && locationFilter.checked && locationFilter.location !== 'all')
          .forEach((otherLocationFilter: BCPLocationsFilter) => {
            otherLocationFilter.products.forEach((otherProduct: BCPProductsFilter) => {
              if (otherProduct.product === productForLocation.product) {
                removeProduct = false;
              }
            });
          });
        if (removeProduct) {
          this.updatedProductsFilter = this.updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productForLocation.product !== productFilter.product);
        }
      }
    });
    if (locationsCount > selectedLocationsCount) {
      this.updatedLocationsFilter[0].checked = false;
    }
    if (this.updatedProductsFilter.length === 1) {
      this.updatedProductsFilter = [];
    } else if (this.updatedProductsFilter.length > 1) {
      let allProductsChecked: boolean = this.updatedProductsFilter
        .filter((productFilter: BCPProductsFilter) => productFilter.product !== 'all').length === this.updatedProductsFilter
        .filter((productFilter: BCPProductsFilter) => productFilter.product !== 'all' && productFilter.checked === true).length;
        this.updatedProductsFilter[0].checked = allProductsChecked;
    }
  }

  filterByProduct(event: any): void {
    let selectedProductFilter: BCPProductsFilter = this.filters.productsFilter.find((productFilter: BCPProductsFilter) => productFilter.product === event.target.value)!;
    if (event.target.value === 'all') {
      this.updateAllProductFilter(event);
    } else {
      this.updateSelectedProductFilter(selectedProductFilter);
    }
    this.filters.productsFilter = this.sortmaterialsList(this.filters.productsFilter);
    this.updateProductSearchData = this.filters.productsFilter;
    this.updateLocationSearchData = this.filters.locationsFilter;
  }

  updateAllProductFilter(event: any): void {
    this.filters.productsFilter.forEach((productFilter: BCPProductsFilter) => {
      productFilter.checked = event.target.checked;
    });
    this.filters.locationsFilter
      .filter((locationFilter: BCPLocationsFilter) => locationFilter.checked === true && locationFilter.location !== 'all')
      .forEach((locationFilter: BCPLocationsFilter) => {
        locationFilter.products.forEach((productFilter: BCPProductsFilter) => {
          productFilter.checked = event.target.checked;
        });
      });
    this.filters.locationsFilter[0].products = this.filters.productsFilter;
  }

  updateSelectedProductFilter(selectedProductFilter: BCPProductsFilter): void {
    let updatedLocationsFilter: BCPLocationsFilter[] = [];
    let updatedProductsFilter: BCPProductsFilter[] = [];
    updatedLocationsFilter.unshift({ location: 'all', checked: this.filters.locationsFilter[0].checked, products: this.filters.locationsFilter[0].products,deskName:'all',deskId:'all' });
    this.filters.locationsFilter
      .filter((locationFilter: BCPLocationsFilter) => locationFilter.location !== 'all')
      .forEach((locationFilter: BCPLocationsFilter) => {
        let newLocationFilter: BCPLocationsFilter = { location: locationFilter.location, checked: locationFilter.checked, products: [] ,deskName:locationFilter.deskName,deskId:locationFilter.deskId };
        locationFilter.products.forEach((productForLocation: BCPProductsFilter) => {
          if (productForLocation.product === selectedProductFilter.product) {
            newLocationFilter.products.push({ product: selectedProductFilter.product,productName:selectedProductFilter.productName, checked: !selectedProductFilter.checked,deskName: productForLocation.deskName ,deskId:productForLocation.deskId });
          } else {
            newLocationFilter.products.push(productForLocation);
          }
          if (updatedProductsFilter.filter((updatedProduct: BCPProductsFilter) => updatedProduct.product === productForLocation.product).length === 0 && locationFilter.checked) {
            updatedProductsFilter.push(newLocationFilter.products[newLocationFilter.products.length-1]);
          }
        });
        updatedLocationsFilter.push(newLocationFilter);
      });
    updatedProductsFilter = updatedProductsFilter.filter((productFilter: BCPProductsFilter) => productFilter.product !== 'all');
    if (updatedProductsFilter.filter((updatedProduct: BCPProductsFilter) => updatedProduct.checked === true).length === updatedProductsFilter.length) {
      updatedProductsFilter.unshift({ product: 'all',productName:'all', checked: true,deskName:'all',deskId:'all'  });
    } else {
      updatedProductsFilter.unshift({ product: 'all', productName:'all', checked: false,deskName:'all' ,deskId:'all'  });
    }
    updatedLocationsFilter[0].products = updatedProductsFilter;
    this.filters.locationsFilter = updatedLocationsFilter;
    this.filters.productsFilter = updatedProductsFilter;
  }

  clearFilters(): void {
    //let updatedProductsFilter: BCPProductsFilter[] = [];
    this.filters= { locationsFilter: [], productsFilter: [] ,desksFilter:[], offTakers:[] }; 
    this.deskList=[];
    this.getRequiredFilterDeskDetails(this.deskValues,this.selectedDesk);
    if(this.DeskfilterObj)
      this.reformatFilters(this.DeskfilterObj);
    this.filters.desksFilter = this.deskList;
    this.filterApiInvoked = true;
    this.store.dispatch(setDefaultBCPFilters({ BCPFilters: JSON.parse(JSON.stringify(this.filters)), updateBCPSummaryTable:true }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getRequiredFilterDeskDetails(deskdetails:any,currDesk:any){
    this.deskList = [];
    if(deskdetails.length>0){
      if(currDesk.includes(UDMDesk.CleanProductVal)){
          deskdetails.forEach((ele:any)=>{      
              ele?.regionDeskMapping.forEach((regionDesk: any) => {
                if(regionDesk.region!= null && regionDesk.region.toLowerCase() === currDesk.split('+')[1].trim() && ele.functionalGroup === UDMDesk.CleanProductVal){
                  regionDesk.deskMappings.forEach((desk: any) => { 
                    this.deskList.push({deskId:desk.id,deskName: desk.name,deskRegion: regionDesk.region.toLowerCase,disabled:currDesk.split('+')[2].toLowerCase().trim() == desk.name.toLowerCase() +' desk' ? true: false,
                                        checked:currDesk.split('+')[2].toLowerCase().trim() == desk.name.toLowerCase() +' desk' ? true: false,functionGroup:ele.functionalGroup                            
                    })     
                  });
                }
              })
            })
      }
      else{
        this.deskList.push({deskId:this.selectedDeskId,deskName:this.selectedDesk,deskRegion:'',checked:false,functionGroup:'',disabled:false})
      }      
    }
    return this.deskList;
  }

  getFiltersData(deskIds:any)
  { 
    if(deskIds.length>0){
      const currDeskIds = deskIds.map((item:any) =>item.deskId).join(',');
      this.filters= { locationsFilter: [], productsFilter: [] ,desksFilter:[], offTakers:[] };  
      let listIds = "";
      this.mdmService.getMDMDataForBCP(currDeskIds).subscribe({
      next: (response: any) => {
        let res = response.value.value;               
        this.globalAggregatorResponse = res;
        this.utilities.globalMasterDataForBCP = res;   
        this.DeskfilterObj = this.getRequiredFilterJsonStructure(res);
        this.store.dispatch(setDeskWiseLocationsAndProducts({deskWiseInfo:this.DeskfilterObj}));
        this.masterPipelineAggregatorResponse = this.DeskfilterObj;  
        this.reformatFilters(this.DeskfilterObj);              
        this.filterApiInvoked = true;
        this.filters.desksFilter = this.deskList;
        this.bcpDataReceived = false;        
        this.store.dispatch(setDefaultBCPFilters({ BCPFilters: JSON.parse(JSON.stringify(this.filters)), updateBCPSummaryTable:true }));   
      }
    });
  }
  }

 
  getRequiredFilterJsonStructure(response:any){
    let filterObj: any = { terminal: [] }
    response.forEach((currDesk:any) => {
      currDesk?.deskLocations.forEach((loc:any) => {      
      let locationCode:string = '', locationName:string='';
      if(loc.locationCode && !loc.exchangeTerminal && loc.materialLocations.length > 0){  
        locationCode = (this.selectedDesk == UDMDesk.Panama && (loc.locationCode == 'LMINASCHEV' || loc.locationCode == 'LMINASTRM')) ? 'LASMINAS' : loc.locationCode;
        locationName = (this.selectedDesk == UDMDesk.Panama && (loc.locationCode == 'LMINASCHEV' || loc.locationCode == 'LMINASTRM')) ? 'LASMINAS' : loc.locationName;
        if(filterObj?.terminal?.findIndex((x:any)=>x.terminalCode == locationCode) < 0)
            filterObj.terminal.push({ terminalCode: locationCode, terminalName: locationName, products:[] ,deskName :currDesk.deskName,deskId:currDesk.deskId });         
            
        loc.materialLocations.forEach((products:any) =>{
          
          if(products.materialNumber && products.trackInv)
          { 
            if(this.selectedDesk == UDMDesk.Panama)
            {
                filterObj?.terminal?.filter((loc : any) => loc.terminalCode == locationCode).forEach((x:any)=>{
                      if(!x.products?.find((prod : any) => prod.productCode == products.materialNumber))
                          x.products.push({productName : products.materialDescription, productCode:products.materialNumber,deskName:currDesk.deskName,deskId:currDesk.deskId});
                });
            }
            else
            { 
              filterObj?.terminal?.filter((loc : any) => loc.terminalCode == locationCode).forEach((location:any)=>{
                  location.products.push({ productName: products.materialDescription, productCode: products.materialNumber,deskName:currDesk.deskName ,deskId:currDesk.deskId});
              });
            }
          }
        });  
      }
    });
  });
    return filterObj;
  }

  locFilterAccord: boolean = false;
  locAccordionFn() {
    this.locFilterAccord = !this.locFilterAccord;
  }

  prodFilterAccord: boolean = false;
  prodAccordionFn() {
    this.prodFilterAccord = !this.prodFilterAccord;
  }

  deskFilterAccord: boolean = false;
  deskAccordionFn(){
    this.deskFilterAccord = !this.deskFilterAccord;
  }


  reformatFilters(filterObj:any){
    let selectDeskDetails:any;
    if(this.selectedDesk.includes('clean products')){
    selectDeskDetails = filterObj?.terminal.filter((item:any)=> item.deskName.toLowerCase() == this.selectedDesk.split("+")[2].trim().replace('desk',' ').trim().toLowerCase())
    }
    else{
      selectDeskDetails =filterObj?.terminal;
    }
    this.sortSelectedDesk(selectDeskDetails);
    if(this.filters.locationsFilter.length > 0)
      {
        this.filters.productsFilter = [...this.filters.locationsFilter[0].products]; 
        this.filters.locationsFilter.unshift({ location: 'all', checked: this.filters.locationsFilter.length === 1, products: [...this.filters.productsFilter],deskName:'all',deskId:'all' });
        this.filters.productsFilter.unshift({ product: 'all',productName:'all', checked: true,deskName:'all',deskId:'all' });        
      }
    this.filters.productsFilter = this.sortmaterialsList(this.filters.productsFilter);
    this.updateLocationSearchData = this.filters.locationsFilter;
    this.updateProductSearchData = this.filters.productsFilter;
  }

  sortSelectedDesk(selectDeskDetails:any){
    selectDeskDetails.forEach((terminal:any) =>{ 
      let listofProduct:any = [];      
      let checkProduct:boolean = false;       
        if(this.filters.locationsFilter.length<1)
        { 
          checkProduct = true;
          terminal?.products?.forEach((element :any)=> {
            listofProduct.push({product:element.productCode,productName:element.productName, checked:checkProduct,deskName:terminal.deskName,deskId:terminal.deskId}); 
          });                        
          this.filters.locationsFilter?.push({location:terminal.terminalCode, checked:true,products:listofProduct,deskName:terminal.deskName,deskId:terminal.deskId});            
        }
        else
        { 
          terminal?.products?.forEach((element :any)=> {
            listofProduct.push({product:element.productCode,productName:element.productName, checked:true,deskName:terminal.deskName,deskId:terminal.deskId}); 
          });  
          this.filters.locationsFilter?.push({location:terminal.terminalCode, checked:false,products:listofProduct,deskName:terminal.deskName,deskId:terminal.deskId});        
        }   
    });
  }



  sortmaterialsList(productFilter:BCPProductsFilter[])
  {
    return productFilter.sort((a:any,b:any) => {
       if(a.productName == 'all') return -1;
       if(b.productName == 'all') return 1;
       return a.productName.localeCompare(b.productName);
    });
  }

  applyFilter()
  {
    let listDeskId = this.filters.locationsFilter.filter((item:any)=> item.deskName!== 'all')
    .map(item => ({id:item.deskId,name:item.deskName}))
    .filter((item,index,self)=>self.findIndex(t=>t.id === item.id && t.name === item.name) === index); 

    if(listDeskId.some((item:any)=>item.name.toLowerCase() === UDMDesk.Panama)){
      const deskLocationsList = this.filters.locationsFilter.filter((item:any)=>item.deskName.toLowerCase() === UDMDesk.Panama);
      const deskProductsList = this.filters.productsFilter.filter((item:any)=>item.deskName.toLowerCase() === UDMDesk.Panama);
      this.getOffTakers({deskLocationsList,deskProductsList},listDeskId);
    }
    this.nominationService.customViewSidePanel$.next(false);
    this.store.dispatch(updateBCPFilters({ BCPFilters: JSON.parse(JSON.stringify(this.filters)) }));
  }

  getCustomViewByName(res: any) {
    let payload = { emailId: this.userFullEmail, viewName: res, desk: this.utilities.updateAPIParams() };
    this.nominationService.applyView(payload).subscribe(res => {
      if (res.statusCode === 200) {        
          this.formPayloadForFilters(res.value)
      }
    });
  }

  formPayloadForFilters(res:any)
  {
    this.filters = { locationsFilter: [], productsFilter: [] ,desksFilter:[], offTakers:[] };
      const { multiDesks, locations, products,offTakers} = res.customViews[0]?.bcpFilters;
      if(this.selectedDesk.startsWith("clean products") || this.selectedDesk?.startsWith("pipeline"))
      {
        if(res.customViews[0]?.bcpFilters!.multiDeskStatus)
          this.filters.desksFilter = [...multiDesks]
        else
        {
          multiDesks.forEach((desk:any) => { desk.checked = true; });        
          this.filters.desksFilter = [...multiDesks];
        }
      }

      if(this.selectedDesk?.includes("panama"))
      {
          this.filters.offTakers = [...offTakers];
      }

      if(res.customViews[0]?.bcpFilters!.locationStatus)
      {
          this.filters.locationsFilter = [...locations];
      }
      else
      {
          locations.forEach((location:any)=> { location.checked = true; });
          this.filters.locationsFilter = [...locations];
      }

      if(res.customViews[0]?.bcpFilters!.productStatus)
      {
          this.filters.productsFilter = [...products];
      }
      else
      {
          products.forEach((product:any)=> { product.checked = true; });
          this.filters.productsFilter = [...products];
      }
      this.deskList = this.filters.desksFilter;
      this.updateLocationSearchData = this.filters.locationsFilter;
      this.updateProductSearchData = this.filters.productsFilter;
      this.store.dispatch(setDefaultBCPFilters({ BCPFilters: JSON.parse(JSON.stringify(this.filters)), updateBCPSummaryTable:true }));          
  }

  getOffTakers(deskBcpData:any,listDeskId:any){
    let materialList: any[] = [];
    deskBcpData.deskProductsList.filter((productFilter: BCPProductsFilter) => productFilter.product !== 'all' && productFilter.checked)
      .forEach((productFilter: BCPProductsFilter) => {
        materialList.push(productFilter.product);
    });

    let terminalList: any[] = [];
    deskBcpData.deskLocationsList
      .filter((locationFilter: BCPLocationsFilter) => locationFilter.location !== 'all' && locationFilter.checked)
      .forEach((locationFilter: BCPLocationsFilter) => {
        terminalList.push(locationFilter.location);
    });

    let offtaker: any[] = [];
    if(terminalList.length > 0 && materialList.length > 0)
    {
      offtaker = this.utilities.getOffTakers(terminalList,listDeskId); 
      offtaker.forEach((customer) => { this.filters.offTakers.push({name:customer,value:true})})
    }    
  }
}