import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { TextFilter, TransportFunctionalGroup, DeskTransportFilters, TransportRegionDeskMapping, DeskTsMapping, TransportSystem } from '../../../../shared/interface/mdm-admin.interface';
import { LoaderService } from '../../../../../app/services/loader.service';
import { selectDeskTs } from '../../../../../app/admin-transport/store/selector/admin-transport.selector';
import { setDefaultTransportsFilter, updateTransportsFilter } from '../../../../../app/admin-transport/store/action/admin-transport.action';

@Component({
  selector: 'app-transport-filter',
  templateUrl: './transport-filter.component.html',
  styleUrls: ['./transport-filter.component.scss']
})
export class TransportFilterComponent implements OnInit {
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  deskTsData : TransportFunctionalGroup[] = [];
  updateDeskTsFilter: DeskTransportFilters = {
    transportFunctionalGroup: [],
    region: [],
    transportSystem: [],
    modeOfTransport: [],
    transportSystemName:[]
  };
  deskTsFilter: DeskTransportFilters = {
    transportFunctionalGroup: [],
    region: [],
    transportSystem: [],
    modeOfTransport: [],
    transportSystemName:[]
  };

  destroy$: Subject<boolean> = new Subject<boolean>();
  deskTsData$ = this.store.pipe(select(selectDeskTs));
  public rowData: TransportFunctionalGroup[] = [];
  desksTable: TransportFunctionalGroup[] = [];

  constructor(private store: Store, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.deskTsData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
          if (response.deskTs.length && response.initializeTransportFilter) {
          this.deskTsData = JSON.parse(JSON.stringify(response.deskTs));
          this.formatDefaultDeskTsFilters();
          this.store.dispatch(setDefaultTransportsFilter({
            transportFilter: JSON.parse(JSON.stringify(this.deskTsFilter)),
            deskTs: JSON.parse(JSON.stringify(this.deskTsData))
          }));
        }

        this.rowData = JSON.parse(JSON.stringify(this.desksTable));
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });

  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.deskTsFilter[filterName as keyof typeof this.deskTsFilter].filter((filter: TextFilter) =>
          filter.name.toLowerCase().includes(text.value.toLowerCase())
          && filter.name !== 'all')];
        this.updateDeskTsFilter[filterName as keyof typeof this.deskTsFilter] = JSON.parse(JSON.stringify(updatedFilterList));
      } else {
        this.updateDeskTsFilter[filterName as keyof typeof this.deskTsFilter] = JSON.parse(JSON.stringify(this.deskTsFilter[filterName as keyof typeof this.deskTsFilter]));
      }
    });
  }

  formatDefaultDeskTsFilters() {
    this.deskTsFilter = {
      transportFunctionalGroup: [],
      region: [],
      transportSystem: [],
      modeOfTransport: [],
      transportSystemName:[]
    };
    const checkFilter: boolean = true;
    const showFilter: boolean = true;
    this.deskTsFilter.transportFunctionalGroup.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.deskTsFilter.region.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.deskTsFilter.transportSystem.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.deskTsFilter.modeOfTransport.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.deskTsFilter.transportSystemName.push({ name: 'all', checked: checkFilter, show: showFilter });

    const uniqueModeOfTransport = new Set<string>();
    const uniqueRegions = new Set<string>();
    const uniqueTransportSystem = new Set<string>();
    const uniqueTransportSystemName = new Set<string>();
     this.deskTsData.forEach(functionalGroup => {
      // Functional Group Check
      if (functionalGroup.functionalGroup !== null && functionalGroup.functionalGroup !== '' && functionalGroup.functionalGroup !== 'null') {
        this.deskTsFilter.transportFunctionalGroup.push({ name: functionalGroup.functionalGroup, checked: checkFilter, show: showFilter });
      }
      functionalGroup.show = showFilter;
      functionalGroup.regionDeskMapping.forEach(region => {
        // Region Check
        if (region.region !== null && region.region !== '' && region.region !== 'null' ) {
          this.deskTsFilter.region.push({ name: region.region, checked: checkFilter, show: showFilter });
        }
        else if(!uniqueRegions.has(region.region)) {
          uniqueRegions.add(region.region);
          this.deskTsFilter.region.push({ name: "n/a", checked: checkFilter, show: showFilter });
        }
        region.show = showFilter;
        this.setDeskTsFilters(region, showFilter, checkFilter, uniqueModeOfTransport, uniqueTransportSystem, uniqueTransportSystemName);
      });
    });
    //set all on first index of array
    const tsIndex =  this.deskTsFilter.transportSystem.findIndex(x => x.name === 'all');
    if (tsIndex) {
    const [ ts ] =  this.deskTsFilter.transportSystem.splice(tsIndex, 1); // delete ts
    this.deskTsFilter.transportSystem.unshift(ts);
    }//add at first index

    const motIndex =  this.deskTsFilter.modeOfTransport.findIndex(x => x.name === 'all');
    if (motIndex) {
    const [ mot ] =  this.deskTsFilter.modeOfTransport.splice(motIndex, 1); // delete mot
    this.deskTsFilter.modeOfTransport.unshift(mot);
    }//add at first index

    const tsnIndex =  this.deskTsFilter.transportSystemName.findIndex(x => x.name === 'all');
    if (tsnIndex) {
    const [ tsn ] =  this.deskTsFilter.transportSystemName.splice(tsnIndex, 1); // delete tsn
    this.deskTsFilter.transportSystemName.unshift(tsn);
    }//add at first index

    this.updateDeskTsFilter = JSON.parse(JSON.stringify(this.deskTsFilter));
  }

  setDeskTsFilters(region: TransportRegionDeskMapping, showFilter: boolean, checkFilter: boolean, uniqueModeOfTransport: Set<string>, uniqueTransportSystem: Set<string>, uniqueTransportSystemName: Set<string>): void {
    region.deskTsMappings.forEach(deskTsMapping => {
      deskTsMapping.show = showFilter;
      deskTsMapping.transportSystems.forEach(transport => {
        if (transport.transportSystem !== null && transport.transportSystem !== '' && transport.transportSystem !== 'null' ) {
          if (!uniqueTransportSystem.has(transport.transportSystem)) {
            uniqueTransportSystem.add(transport.transportSystem);
            this.deskTsFilter.transportSystem.push({ name: transport.transportSystem, checked: checkFilter, show: showFilter });
          }
          if (!uniqueModeOfTransport.has(transport.modeOfTransport)) {
            uniqueModeOfTransport.add(transport.modeOfTransport);
            this.deskTsFilter.modeOfTransport.push({ name: transport.modeOfTransport, checked: checkFilter, show: showFilter });
          }
          if (!uniqueTransportSystemName.has(transport.transportSystemName)) {
            uniqueTransportSystemName.add(transport.transportSystemName);
            this.deskTsFilter.transportSystemName.push({ name: transport.transportSystemName, checked: checkFilter, show: showFilter });
          }
          transport.show = showFilter;
        }
        });
        //sort the array A-Z
        this.deskTsFilter.transportSystem.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.deskTsFilter.modeOfTransport.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.deskTsFilter.transportSystemName.sort((a, b) => (a.name < b.name ? -1 : 1));
    });
  }

  showDeskTsMappings(region: TransportRegionDeskMapping): void {
    region.deskTsMappings.forEach(deskTsMapping => {
      deskTsMapping.show = true
      deskTsMapping.transportSystems.forEach(transport => {
        transport.show = true
      });
    });
  }

  filterTable(filterNames: { filterName: string, propertyName: string }[]): void {
    this.deskTsData.forEach(functionalGroup => {
      functionalGroup.show = true
      functionalGroup.regionDeskMapping.forEach(region => {
        region.show = true
        this.showDeskTsMappings(region);
      });
    });
    filterNames.forEach((filter: { filterName: string, propertyName: string }) => {
      this.deskTsFilter[filter.filterName as keyof typeof this.deskTsFilter].filter((filterOption: TextFilter) => filterOption.name !== 'all' && filterOption.show).forEach((filterOption: TextFilter) => {
        if (!filterOption.checked) {
          // hide deselected functional groups
          if(filter.filterName === "transportFunctionalGroup") {
            const functionalGroup: TransportFunctionalGroup = this.deskTsData.find((functionalGroup: TransportFunctionalGroup) => functionalGroup.functionalGroup === filterOption.name)!;
            functionalGroup.show = filterOption.checked;
          }
          // hide deselected regions and transports
          else if (filter.filterName === "region" || filter.filterName === "transportSystem" || filter.filterName === "transportSystemName" || filter.filterName === "modeOfTransport") {
            this.deskTsData.forEach(functionalGroup => {
              this.hideRegionsAndTransports(functionalGroup, filter, filterOption);
            });
          }
        }
      });
    });
    this.hideNestedTables();
  }

  hideRegionsAndTransports(functionalGroup: TransportFunctionalGroup, filter: { filterName: string, propertyName: string }, filterOption: TextFilter): void {
    functionalGroup.regionDeskMapping.forEach(region => {
      if (((filterOption.name !== 'n/a' && region.region === filterOption.name)
        || ((region.region === null || region.region === '' || region.region === 'null') && filterOption.name === 'n/a'))
        && filter.filterName === "region"
      ) {
        region.show = filterOption.checked;
      } else if (filter.filterName === "transportSystem" || filter.filterName === "transportSystemName" || filter.filterName === "modeOfTransport") {
        region.deskTsMappings.forEach(deskTsMapping => {
          deskTsMapping.transportSystems.forEach((transportSystem: TransportSystem) => {
            if ((transportSystem.transportSystem === filterOption.name && filter.filterName === "transportSystem")
              || (transportSystem.modeOfTransport === filterOption.name && filter.filterName === "modeOfTransport")
            ) {
              transportSystem.show = filterOption.checked;
            }
          });
        });
      }
    });
  }

  hideNestedTables(): void {
    this.deskTsData.forEach((functionalGroup: TransportFunctionalGroup) => {
      const showFunctionalGroups: boolean = functionalGroup.regionDeskMapping.filter((region: TransportRegionDeskMapping) => region.show).length > 0;
      if (!showFunctionalGroups) {
        functionalGroup.show = showFunctionalGroups;
      }
      functionalGroup.regionDeskMapping.forEach(region => {
        const showRegions: boolean = region.deskTsMappings.filter((desk: DeskTsMapping) => desk.show).length > 0;
        if (!showRegions) {
          region.show = showRegions;
        }
      });
    });
  }

  filterTransport(): void {
    this.loaderService.setLoading(true);
    const filterNames: { filterName: string, propertyName: string }[] = [
      { filterName: 'transportFunctionalGroup', propertyName: 'transportFunctionalGroup' },
      { filterName: 'region', propertyName: 'region' },
      { filterName: 'transportSystem', propertyName: 'transportSystem' },
      { filterName: 'modeOfTransport', propertyName: 'modeOfTransport' },
      { filterName: 'transportSystemName', propertyName: 'transportSystemName' },
    ];
    this.filterTable(filterNames);
    this.updateDeskTsFilter = JSON.parse(JSON.stringify(this.deskTsFilter));
    this.store.dispatch(updateTransportsFilter({
      transportFilter: JSON.parse(JSON.stringify(this.deskTsFilter)),
      deskTs: JSON.parse(JSON.stringify(this.deskTsData))
    }));
  }
  applyCheckboxFilters(filterName: string, filterItem: any): void {
    const relatedFilters: { relatedFilterNames: string[] }[] = [
      { relatedFilterNames: [ 'transportSystem','transportSystemName' ] }
    ];
    // checks for selected filter whether it has a related filter
    const isRelatedFilter: boolean = relatedFilters.filter((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName)).length > 0;
    // gets the set of filters for particular categories
    const foundFilter: TextFilter[] = this.deskTsFilter[filterName as keyof typeof this.deskTsFilter];
    if (filterItem.target.value !== 'all') {
      // 251 - 255 determine whether or not to keep "all" option selected (e.g. if one deselcts anyone option "all" would no longer be checked)
      const filterOption: TextFilter = foundFilter.find((filterOption: TextFilter) => filterOption.name === filterItem.target.value)!; // for set of filters in section finds particular item selected and stores for later change
      filterOption.checked = filterItem.target.checked; // change selected filter to match user selection (check or unchecked)
      const allFilterCount: number = foundFilter.filter((filterOption: TextFilter) => filterOption.show && filterOption.name !== 'all').length; // see how many of items in filter exist besides "all"
      const checkedFilterCount: number = foundFilter.filter((filterOption: TextFilter) => filterOption.checked && filterOption.show && filterOption.name !== 'all').length; // counts filters that are checked as well (in order to verify if "all" needs to be selected)
      foundFilter.find((filterOption: TextFilter) => filterOption.name === 'all')!.checked = allFilterCount === checkedFilterCount; //update all option to be checked or unchecked based on comparison
      if (isRelatedFilter) { // if there is a related filter to one being updated and duplicates logic for unchecking "all"
        const relatedFilterOptionIndex: number = this.deskTsFilter[filterName as keyof typeof this.deskTsFilter].findIndex((filterOption: TextFilter) => filterOption.name === filterItem.target.value); // gets position in list of filters of filter item being updated
        const relatedFilterGroup: { relatedFilterNames: string[] } = relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.deskTsFilter[relatedFilterName as keyof typeof this.deskTsFilter];
          foundRelatedFilter[relatedFilterOptionIndex].checked = filterItem.target.checked; //use position to change properties
          const allRelatedFilterCount: number = foundRelatedFilter.filter((filterRelatedOption: TextFilter) => filterRelatedOption.show && filterRelatedOption.name !== 'all').length;
          const checkedRelatedFilterCount: number = foundRelatedFilter.filter((filterRelatedOption: TextFilter) => filterRelatedOption.checked && filterRelatedOption.show && filterRelatedOption.name !== 'all').length;
          foundRelatedFilter.find((filterRelatedOption: TextFilter) => filterRelatedOption.name === 'all')!.checked = allRelatedFilterCount === checkedRelatedFilterCount;
        });
      }
    } else { //if all was checked it will either check or uncheck the set and related filters
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        const relatedFilterGroup: { relatedFilterNames: string[] } = relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.deskTsFilter[relatedFilterName as keyof typeof this.deskTsFilter];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
    }
    setTimeout(() => {
      this.loaderService.setLoading(false);
    }, 7000);
    this.updateDeskTsFilter = JSON.parse(JSON.stringify(this.deskTsFilter));
  }

  clearFilters(): void {
    this.loaderService.setLoading(true);
    this.searchText = [];
    this.searchValue = [];
    this.formatDefaultDeskTsFilters();
    this.store.dispatch(setDefaultTransportsFilter({
      transportFilter: JSON.parse(JSON.stringify(this.deskTsFilter)),
      deskTs: JSON.parse(JSON.stringify(this.deskTsData))
    }));
    setTimeout(() => {
      this.loaderService.setLoading(false);
    }, 7000);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
