<div class="create-alerts">
  <div class="form-row pb-1 pr-2">
      <div class="form-group col-md-6 branded">
          <label for="terminal" class="label">terminal</label>
          <select class="form-control" (change)="getMaterialData(selectedTerminal); setCreateBoolean()" [(ngModel)]="selectedTerminal">
              <option value="" disabled selected>select</option>
              <option *ngFor="let terminal of locList">
                  {{terminal}}</option>
          </select>
      </div>
      <div class="form-group col-md-6 branded">
          <label class="label">material</label>
          <select class="form-control" [(ngModel)]="selectedMaterial" (change)="getRulesByTerminalMaterial(selectedTerminal, selectedMaterial); setCreateBoolean()">
              <option value="" disabled selected>select</option>
              <option *ngFor="let material of matNameList" [value]="material">
                  {{material}}</option>
          </select>
      </div>
  </div>
  <hr>
  <div class="form-row pb-1 pr-3">
    <div class="form-group col-md-4">
        <label for="ruletype" class="label">rule type</label>
        <select class="form-control" *ngIf="selectedMaterial == '' || selectedTerminal == ''" disabled>
          <option value="" disabled selected>select</option>
        </select>
        <select class="form-control" *ngIf="selectedMaterial != '' && selectedTerminal != ''" [(ngModel)]="selectedRuleType" (change)="setCreateBoolean()">
            <option  value="" disabled selected>select</option>
            <option *ngFor="let ruletype of ruletypeDropdown">
                {{ruletype}}</option>
        </select>
    </div>
      <div class="form-group col-md-4">
          <label>&nbsp;</label>
          <button class="delete-rule-btn" [disabled]="checkDisableDelete()" (click)="showDeleteRule(selectedTerminal, selectedMaterial, selectedRuleName)">delete rule <img src="../../assets/icons/icon-alerts-delete-grey.svg" alt="delete-icon"></button>
        </div>
      <div class="form-group col-md-4">
        <label>&nbsp;</label>
          <button class="create-rule-btn" [disabled]="checkDisableCreate()" (click)="checkForDuplicatesCreate(selectedTerminal, selectedMaterial)">new rule <img src="../../assets/icons/icon-alerts-newrule.svg" alt="add-icon"></button>
        </div>
      <div class="form-group col-md-4">
        <label class="label">rules</label>
        <select class="form-control" *ngIf="selectedMaterial == '' || selectedTerminal == ''" disabled>
          <option value="" disabled selected>select</option>
        </select>
        <select class="form-control" *ngIf="selectedMaterial != '' && selectedTerminal != ''" [(ngModel)]="selectedRuleName" (change)="getRuleDetails(selectedTerminal, selectedMaterial, selectedRuleName)">
           <option value="" disabled selected>select</option>
           <option *ngFor="let rule of activeRulesDropdown">
               {{rule.ruleName}}</option>
        </select>
    </div>
  </div>
  <hr>
  <app-inventory-field *ngIf="selectedRuleType == 'Inventory'" [selectedReference]="selectedReference" [selectedNoOfDays]="selectedNoOfDays" [selectedRules]="selectedRules" [selectedParameters]="selectedParameters"
      (inventoryData)="getInventoryDetails($event)">
  </app-inventory-field>
  <app-reconciliation-field *ngIf="selectedRuleType == 'Reconciliation'" [selectedPercent]="selectedPercent" (reconciliationData)="getReconciliationDetails($event)">
  </app-reconciliation-field>
  <span *ngIf="disableCreate" class="label-4r">this rule already exists or was recently deleted. please change the values or check deleted alerts</span>
  <hr>
  <div>
    <label class="label-2">select frequency</label>   
    <mat-radio-group [disabled]="radioButtonDisable()" [(ngModel)]="selectedFreq" (change)="setCreateBoolean()">
      <div>
          <mat-radio-button value="I"><span class="frequency-bold">immediately </span><span class="frequency-italic">(as soon as an alert occurs)</span></mat-radio-button>
      </div>
      <div>
          <mat-radio-button value="H"><span class="frequency-bold">hourly </span><span class="frequency-italic">(an hourly roundup)</span></mat-radio-button>
      </div>
      <div>
          <mat-radio-button value="D"><span class="frequency-bold">daily </span><span class="frequency-italic">(a daily summary of all alerts at 10:30am ct)</span></mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  &nbsp;
  <div class="form-row pb-1 pr-3">
      <div class="form-group col-md-4">
          <button class="create-rule-btn" (click)="closePopup()">cancel</button>
      </div>
      <div class="form-group col-md-4">
        <button class="delete-rule-btn" [disabled]="checkDisableDelete()" (click)="showDeleteRule(selectedTerminal, selectedMaterial, selectedRuleName)">delete rule <img src="../../assets/icons/icon-alerts-delete-grey.svg" alt="delete-icon"></button>
      </div>
      <div class="form-group col-md-4">
        <button class="create-rule-btn" [disabled]="checkDisableUpdate()" (click)="checkForDuplicatesUpdate(selectedTerminal, selectedMaterial, selectedRuleName)">update rule</button>
      </div>
  </div>
</div>
