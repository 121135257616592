<div class="main-container d-flex flex-row justify-content-center">
    <div class="activity-container d-flex flex-column">
        <div class="d-flex align-items-center">
            <h1 class="title-text"><span *ngIf="isMultipleView">multi-nomination </span>activity tracker - {{this.selectedNomination.nominationNo}}</h1>
        </div>
        <div *ngIf="isMultipleView" class="d-flex flex-column mb-2">
            <label class="m-0 label-2">View Nomination</label>
            <select class="form-control wide-dropdown" [(ngModel)]="selectedNomination" (change)="updateViewActivityNomination()">
                <option [ngValue]="option" *ngFor="let option of nominationDataSet; let i = index">
                    <span *ngIf="option.nominationKey != null && option.nominationKeyItem != null">{{option.nominationKey}}-{{option.nominationKeyItem}}</span>
                    <span *ngIf="option.nominationKey != null && option.nominationKeyItem == null">{{option.nominationKey}}- missing key item value</span>
                    <span *ngIf="option.nominationKey == null && option.nominationKeyItem == null"> Line {{i + 1}} (Missing nom key and key item)</span>
                </option>
            </select>
        </div>
        <div class="d-flex">
            <button *ngFor="let header of tooltipHeader; let i = index" (click)="updateDropdown(header, i)" 
            [ngClass]="this.selectedHeaderIndex == i ? 'header-button-selected': 'header-button'"> 
                {{header}}</button>
        </div>
        <hr class="m-0 gray-color mb-3">    

        <div class="dropdown mb-1">
            <select class="form-control" [(ngModel)]="selectedOption" (change)="updateRowData()">
                <option [ngValue]="option" *ngFor="let option of this.selectedDropdown">{{option.displayName}}</option>
            </select>
        </div>

        <div class="d-flex align-items-center table-header bottom-margin">
            <p class="table-title">{{this.selectedOption.displayName}} Changes</p>
        </div>
        <div class="table-responsive" *ngIf="rowData.length != 0">
            <table class="table table-sm">
                <thead>
                    <tr style="border-bottom: 1px solid #000;">
                        <th resizable>New {{this.selectedOption.displayName}}</th>
                        <th resizable>Original {{this.selectedOption.displayName}}</th>
                        <th resizable>Changed By</th>
                        <th resizable>Changed On</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of this.rowData" class="cell-style">
                        <td class="cell-value p-0 pl-3">{{row.newValue}}</td>
                        <td class="cell-value p-0 pl-3">{{row.oldValue}}</td>
                        <td class="cell-value p-0 pl-3">{{row.changedBy}}</td>
                        <td class="cell-value p-0 pl-3">{{row.changedAt}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p *ngIf="this.rowData.length == 0 && successApi" class="ml-3 noChangeText">
                No {{this.selectedOption.displayName}} Changes To Show</p>
        <p *ngIf="!successApi" class="ml-3 noChangeText">Activity Tracker Failed</p>
        <div *ngIf="true">
            <hr>
            <p class="title-text">Comments</p>
            <p>Coming soon!</p>
        </div>
    </div>
    <div class="loading d-flex justify-content-center align-items-center" *ngIf="apiLoading">
        <p class="loadingText">Loading</p>
    </div>
</div>



