<div class="notification-wrapper" *ngIf="!this.alertDisabled">
    <div class="blue-header">
        <p class="header-text">
            <span>
            <span class="header-boldText">{{alertDesk}}</span>
            <img src="../../../../assets/icons/icon-chevron-grey-r.svg" alt="Directional Indicator Right">
            <span class="header-boldText">{{alertTerminal.toLowerCase()}}</span>
            <img src="../../../../assets/icons/icon-chevron-grey-r.svg" alt="Directional Indicator Right">
            <span class="header-boldText">{{alertMaterial.toLowerCase()}}</span>
            </span>
        </p>
        <!--<button style="border:none;background:none;cursor:pointer;" matTooltip="edit rule"
        [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"> <img
            src="../../assets/icons/icon-alerts-pencil.svg" alt="edit-icon"
            (click)="gotoAlertNotifications()"></button>-->
    </div>
    <div class="text-body">
        <p class="body-boldText" *ngIf="this.cardAlert.ruleType === 'Reconciliation'">{{this.alertBoldText}}</p>
        <p><span class='body-boldText' *ngIf="this.cardAlert.ruleType === 'Inventory'">{{this.alertBoldText}}</span>
            {{this.alertIndicatorText}} <span class="control-links"
                *ngIf="this.cardAlert.ruleType === 'Reconciliation'">see details </span>
        </p>
        <p class="dateText">{{this.cardDateText}}</p>
    </div>
    <div class="button-row">
        <div [ngClass]="{'rightAlign': !this.showDismiss}">
            <p class="control-links" (click)="this.openDismissModal()">dismiss
                <span><img src="../../../../assets/icons/icon_circle_x.svg" alt="right arrow" class="blue-x"></span>
            </p>
        </div>
        <div *ngIf="this.showDismiss">
            <p class="control-links" (click)="takeUserToIssue()">take me to issue <span><img
                        src="../../../../assets/icons/icon-chevron-blue-r.svg" alt="right arrow"></span></p>
        </div>
    </div>
</div>