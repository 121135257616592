import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AlertRuleResponse } from 'src/app/shared/interface/settings.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { NotificationState } from 'src/app/shared/interface/workspace-alert.interface';

@Component({
  selector: 'app-grid-alert-tooltip-renderer',
  templateUrl: './grid-alert-tooltip-renderer.component.html',
  styleUrls: ['./grid-alert-tooltip-renderer.component.scss']
})
export class GridAlertTooltipRendererComponent implements ICellRendererAngularComp {

  params!: ICellRendererParams;
  @Input() cellValue: number = 0;
  @Input() cellAlert!: AlertRuleResponse;
  formattedCellValue: string = "";

  constructor(private alertService: AlertsService) { }

  agInit(params: any): void{
    this.params = params;
    this.cellValue = params.value;
    this.formattedCellValue = this.cellValue.toLocaleString('en-US', {maximumFractionDigits:2})
    this.cellAlert = params.alert;   
  }

  refresh(params: any): boolean{
    this.params = params;
    this.cellValue = params.value;
    this.formattedCellValue = this.cellValue.toLocaleString('en-US', {maximumFractionDigits:2})
    this.cellAlert = params.alert;
    return true;
  }

  generateAlertText(): string{

    if(this.cellAlert.ruleType === "Inventory"){
      return "forcasted " + this.cellAlert.ruleType;
    }else if (this.cellAlert.ruleType === "UnReconciled"){
      return this.cellAlert.ruleType + "inventory";
    }else{
      return "not an alert of this type"
    }
  }

  //Upon tooltip click, we will user alertService to control what state notification panel is in 
  openNotificationPanel(){
    this.alertService.openSidePanel(NotificationState.TR, [this.cellAlert], true);
  }
}
