export enum AdminView {
  Locations = 'locations',
  Desks = 'desks',
  MaterialMapping = 'materialMapping',
  Transport = 'transport'
}

export enum SpmDates {
  minDate = '01/01/2024',
  maxDate = '12/31/9999'
}

export interface LocationData {
  deskId?: number,
  exchangeTerminal?: boolean,
  locationName: string,
  locationCode: string,
  locationType: string,
  locationTypeDescription: string,
  latitude?: number,
  longitude?: number,
  numHubs?: number,
  numSpokes?: number,
  ethanolTruckVol: number,
  ethanolRailVol: number,
  spokeLocationCodes?: string[],
  createdOn?: string,
  created?: string,
  createdBy?: string,
  changedOn?: string,
  changed?: string,
  changedBy?: string,
  blockingIndicator: string,
  deletionIndicator: string,
  stockProjectionActIndicator: string,
  id: number,
  deskLocationId: string,
  show: boolean,
  isLatitudeValueChanged: boolean,
  isLongitudeValueChanged: boolean,
  isEthanolTruckVolValueChanged: boolean,
  isEthanolRailVolValueChanged: boolean
}

export interface TransportData {
  id: string,
  transportSystem: string,
  transportSystemName: string,
  shippingType: string,
  shippingTypeDesc: string,
  modeOfTransport: string,
  modeOfTransportDesc: string,
  bookTransportIndicator: string,
  locationSequenceIndex: string,
  stockProjectionActivationIndicator: string,
  defaultVehicleNumber: string,
  businessType: string,
  tSystemIntransitPlant: string,
  tSystemIntransitStorageLocation: string,
  creation: string,
  creationTime: string,
  createdBy: string,
  changedOnDate: string,
  changedOnTime: string,
  changedBy: string,
  defaultTsShipperCode: string,
  tSystemCarrier: string,
  tSystemCarrierName: string,
  carrierLeadTime: string,
  confirmationLeadTimeUom: string,
  confirmationLeadTimeUomDesc: string,
  tdShipmentType: string,
  cycleDuration: string,
  unitOfMeasure: string,
  nomBatchMask: string,
  balanceCheck: string,
  clipboardLgort: string,
  shipmentType: string,
  blockingInd: string,
  deletionInd: string,
  transportationPlanningPoint: string,
  transportationPlanningPointDesc: string,
  tsStandardVolumeUom: string,
  tsStandardWeightUom: string,
  indicatorUnlimitedOverdeliveryAllowed: string,
  absoluteToleranceQtyUnder: string,
  absoluteToleranceUom: string,
  absoluteToleranceQtyOver: string,
  quantityToleranceUnder: string,
  quantityToleranceOverPercentage: string,
  udmCreatedOn: string,
  udmCreatedBy: string,
  udmLastModifiedOn: string,
  udmLastModifiedBy: string
}

export interface CreateDeskLocationMapping {
  deskId: number,
  locationCode: string
}

export interface DropdownLocations {
  deskId: number,
  locationCode: string[]
}

export interface DropdownTransports {
  deskId: number,
  transportSystem: string[]
}

export interface LocationNoDetails {
  deskId?: number,
  locationName: string,
  locationCode: string,
  deskLocationId: number,
  show: boolean
}

export interface LocationFilters {
  locationName: TextFilter[],
  locationCode: TextFilter[],
  exchangeTerminal: TextFilter[]
}

export interface CoordinateFilter {
  filterName: string,
  filterValue: number
}

export interface ExpandedLocation {
  locationCode: string,
  expanded: boolean,
  show: boolean,
}

export interface EditedLocationData {
  exchangeTerminal?: boolean,
  locationCode: string,
  latitude?: number | null,
  longitude?: number | null,
  ethanolTruckVol?: number | null,
  ethanolRailVol?: number | null
}

export interface Desks {
  id: number,
  name: string,
  functionalGroup: string,
  region: string,
  active?: boolean,
  udmCreatedOn: string,
  udmCreatedBy: string,
  udmLastModifiedOn: string,
  udmLastModifiedBy: string
}

export interface DeskFilter {
  locationData: Desks,
  functionalGroupChecked: boolean,
  regionChecked: boolean,
  locationChecked: boolean,
  sisLocationCodeChecked: boolean,
}

export interface NewDesk {
  functionalGroup: string,
  region: string,
  deskName: string,
  active: boolean,
  udmLastModifiedBy: string
}

export interface DeskTransportFilters {
  transportFunctionalGroup: TextFilter[],
  region: TextFilter[],
  transportSystem: TextFilter[],
  modeOfTransport: TextFilter[]
  transportSystemName: TextFilter[]
}

export interface CreateDeskTsMapping {
  deskId: number,
  transportSystem: string
}

export interface TransportFunctionalGroup {
  functionalGroup: string,
  regionDeskMapping: TransportRegionDeskMapping[],
  show: boolean
}
export interface ExpandedTransport {
  functionalGroup: string,
  expanded: boolean,
  show: boolean,
}

export interface TransportRegionDeskMapping {
  region: string,
  deskTsMappings: DeskTsMapping[],
  show: boolean
}

export interface DeskTsMapping {
  deskId: number,
  deskName: string,
  transportSystems: TransportSystem[],
  show: boolean
}

export interface TransportSystem {
  id: number,
  transportSystem: string,
  transportSystemName: string,
  modeOfTransport: string,
  show: boolean
}

export interface TransportSystemRequest {
  modeOfTransports: string[],
  transportSystems: string[]
}

export interface DeskFunctionalGroup {
  functionalGroup: string,
  regionDeskMapping: RegionDeskLocationMapping[],
  show: boolean
}
export interface RegionDeskLocationMapping {
  region: string,
  deskLocationMappings: DeskLocationMapping[],
  show: boolean
}
export interface DeskLocationMapping {
  deskId: number,
  deskName: string,
  locations: LocationNoDetails[],
  show: boolean
}

export interface ExpandedDesk {
  name: string,
  expanded: boolean,
  show: boolean,
}

export interface MaterialMappingNested {
  locationCode: string,
  locationName: string,
  locationMaterials: MaterialMappingData[],
  show: boolean
}


export interface MaterialMappingData {
  locationCode: string,
  materialNumber: string,
  materialDescription: string,
  udmNickname: string,
  active: boolean,
  trackInv: boolean,
  aggregateUnderGroup: boolean,
  materialGroupDescription: string,
  materialGroup: number | null,
  spmInvStartDate: string,
  spmInvEndDate: string,
  spmDemandForecastStartDate: string,
  spmDemandForecastEndDate: string,
  spmActualLiftStartDate: string,
  spmActualLiftEndDate: string,
  show: boolean
}

export interface DeskLocation {
  id: number,
  deskId: number,
  deskName?: string,
  locationCode: string,
  udmCreatedOn: string,
  udmCreatedBy: string,
  udmLastModifiedOn: string,
  udmLastModifiedBy: string
}

export interface DeskTransport {
  id: number,
  deskId: number,
  transportSystem?: string,
  transportSystemName: string,
  udmCreatedOn: string,
  udmCreatedBy: string,
  udmLastModifiedOn: string,
  udmLastModifiedBy: string
}

export interface MaterialMappingFilter {
  deskIds: number[],
  locationIds: string[],
  activeOnly: boolean
}

export interface EditedMaterialMappingData {
  active?: boolean,
  materialNumber: number | null,
  aggregateUnderGroup?: boolean,
  locationCode: string,
  spmInvStartDate: string,
  spmInvEndDate: string,
  spmDemandForecastStartDate: Date,
  spmDemandForecastEndDate: Date,
  spmActualLiftStartDate: Date,
  spmActualLiftEndDate: Date,
  trackInv: boolean,
  materialSubgroup: number,
  materialSubgroupDescription: string
}

export interface TextFilter {
  name: string,
  checked: boolean,
  show: boolean
}

export interface MaterialMappingFilters {
  locationName: TextFilter[],
  status: TextFilter[],
  materialDescription: TextFilter[],
  materialNumber: TextFilter[],
  groupMaterial: TextFilter[],
  materialGroupName: TextFilter[],
  materialGroupNumber: TextFilter[],
  trackInv: TextFilter[],
}

export interface DeskFilters {
  functionalGroup: TextFilter[],
  region: TextFilter[],
  locationName: TextFilter[],
  locationCode: TextFilter[]
}

export interface DesksNested {
  locationCode: string,
  locationName: string,
  locationMaterials: Desks[],
  show: boolean
}
