<div>
    <div *ngIf="this.header !== ''" (click)="toggleListVisibility()" class="list-header-text">
        <p class="text-offset">{{header}} ({{listLength}})</p>
        <div ngClass="{'image-offset': true}" [ngClass]="{'image-rotate': !hideList, 'image-offest': true}">
            <img src="../../assets/icons/down-arrow.svg" alt="subscriber-icon">
        </div>
    </div>

    <div *ngIf="!hideList || this.header === ''">
        <ng-content></ng-content>
    </div>
</div>