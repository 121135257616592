import { getEthanolTableHeaders, selectEthanolNomFiltersResponse, selectGetIsPipelineNomFilterInitialised, selectPipelineNomFiltersResponse, selectPipelineSwitchView, selectPipelineTRFiltersResponse, selectUDMScreen, selectUpdateURLParams, searchNomResponse, reinitiateFiltersForNomLink } from './../../../shared/store/selector/app.selector';
import { saveEthanolNomFiltersResponse, savePipelineNomFiltersResponse, savePipelineTRFiltersResponse, setPipelineFiltersResponse, setPipelineSwitchView, setSwitchView, setTableHeaders, initialisePipelineNomFilters, updateURLParams, saveCargoPlanCcaFiltersResponse, saveCargoFiltersCCA, reInitiateFiltersForCCANomLinking,setAllDeskInfo } from './../../../shared/store/action/app.action';

import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Input,
  QueryList,
  ViewChildren,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { Subject, Subscription, firstValueFrom, takeUntil } from 'rxjs';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { selectCustomerDetails, selectTRData } from 'src/app/replenishment-planning/store/selector/terminal-replenishment.selector';
import { selectDefaultTRFilters, selectAppliedTRFilters, selectDesk, selectEthanolTRFiltersResponse, selectAppState, selectSwitchView } from 'src/app/shared/store/selector/app.selector';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { saveAppliedTRFilters, saveDefaultTRFilters, saveEthanolTRFiltersResponse, enableClearFilter, modifyUpdatedFiltersSelection } from 'src/app/shared/store/action/app.action';
import { NominationService } from 'src/app/services/nomination.service';
import { HomeComponent } from 'src/app/home/home.component';
import { selectNominationList } from 'src/app/nominations/store/selector/nomination.selector';
import { ContractBalancingService } from 'src/app/services/contract-balancing.service';
import { 
  
  UDMDesk,
  Location,
  TRScreen
 } from 'src/app/shared/constants/terminal-replenishment.constant';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { MdmService } from 'src/app/services/mdm.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  @Output() filterPanelClosed = new EventEmitter();
  @Input() closeFilterPanel: any;
  @Input() updatedDesk!: string;
  @Output() appliedFilters = new EventEmitter();
  @Output() deskList = new EventEmitter();
  isFilterApplied: boolean = false;
  valueChangesSubscription!: Subscription;
  @ViewChildren('dropdown', { read: ElementRef })
  dropdown!: QueryList<ElementRef>;
  deskListResponse: any = [];
  filtersResponse: any = [];
  defaultTRFilter: any;
  selectedCustomers: any = [];
  getProductFromEvent: any;
  getFromDateFromEvent: any;
  getToDateFromEvent: any;
  getCCAFromDateFromEvent: any;
  getCCAToDateFromEvent: any
  getUncheckedCustomer?: any;
  getCheckedCustomer?: any;
  cargoFiltersCCAClone:any;
  getFromDateFromEventClone:any;
  getToDateFromEventClone:any;
  allOfftakers: any = [];
  defaultFromDate!: string;
  defaultToDate!: string;
  getEventFilterName: any
  updateisChecked: any
  locationFilters: any = [];
  routeFilters: any = [];
  locationFilterSelection!: any;
  searchValue: any = [];
  filteredProductsList: any = [];
  getudatedDateRangeFlag: boolean = true
  selectedDesk: any;
  selectedDeskParameter: any;
  region: any
  dateRangeData: { fromDate: any, toDate: any };
  ethanolDateRangeData: { fromDate: any, toDate: any };
  pipelineDateRangeData: { fromDate: any, toDate: any };
  cargoCCADateRangeData: { fromDate: any, toDate: any } | any;
  ccaDateRangeData: { fromDate: any; toDate: any; } | any;
  updateCheckbox: any
  trSave!: boolean;
  filtersInitialized: boolean = false;
  searchText: any = [];
  updateSearchData: any = [];
  getEhtanolFilterResponse: any = []
  updateEhtanolFilterResponse: any;
  filterResponse: any;
  testText = ''
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedTRView$ = this.store.pipe(select(selectDefaultTRFilters));
  selectedDefaultFilters$ = this.store.pipe(select(selectDefaultTRFilters));
  urlParams$ = this.store.pipe(select(selectUpdateURLParams));
  selectSwitchView$ = this.store.pipe(select(selectSwitchView));
  getEthanolTableHeaders$ = this.store.pipe(select(getEthanolTableHeaders));
  getIsPipelineNomFilterInitialised$ = this.store.pipe(select(selectGetIsPipelineNomFilterInitialised));
  customerDetails$ = this.store.pipe(select(selectCustomerDetails));
  trData$ = this.store.pipe(select(selectTRData));
  desk$ = this.store.pipe(select(selectDesk))
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  getSearchNom$ = this.store.pipe(select(searchNomResponse));
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))
  selectEthanolNomFiltersResponse$ = this.store.pipe(select(selectEthanolNomFiltersResponse))
  selectedFilter?: any;
  getUpdatedFiltersSelection: any;
  appData$ = this.store.pipe(select(selectAppState));
  nominationList$ = this.store.pipe(select(selectNominationList));
  reInitiateFiltersForLinkingNomWithCargo$ = this.store.pipe(select(reinitiateFiltersForNomLink));
  switchView: any;
  ethanolAllTableHeaders: any = []
  currentScreen: any;
  isSearchOpened: boolean = false;
  iscargoScreenOpened: boolean = false
  selectedMOTCount: any;
  truck_unit_option: any = 'bbl';
  ethanolTruckUOMSwitch: any = false;
  isPipelineFiltersEnabled = false;
  pipelineFilterResponse: any = []
  updatedPipelineFilterResponse: any;
  storedPipelineFilterResponse: any;
  pipelineRPFilterListState: any = [];
  pipelineNomFilterListState: any = [];
  selectedPipelineTRFiltersResponse$ = this.store.pipe(select(selectPipelineTRFiltersResponse));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  selectedPipelineSwitchView$ = this.store.pipe(select(selectPipelineSwitchView));
  pipelineSwitchView: any;
  cbHeaderCheckboxes: any[] = [];
  cbTableHeaderMappingWithFlags: any = {
    "contract details table": "refreshContractDetailsTable",
    "mtd tracking": "refreshMTDTrackingTable",
    "monthly performance review": "refreshMPRTable",
    "deal performance review": "refreshDPRTable",
  };
  selectedView: any;
  isDeskUpdated: boolean = false;
  checkedPipelineFilters: any = {};
  isRouteParam: boolean = false;
  isSeasonalGroupedProduct: boolean = false;
  filteredLocation: any;
  filteredProduct: any;
  selectedDeskId: any;
  filteredProductNumber: any;
  filteredProductGroupNumber: any;
  checkedCargoFilters: any = {};
  isCargoScreen: boolean = false;
  pipelineFilterCheckboxClicked: boolean = false;
  searchDropdown: any = '';
  searchTextValue: any = '';
  globalAggregatorResponse: any;
  masterPipelineAggregatorResponse: any;
  alreadyRanOnce: boolean = false;
  selectedDeskParams: any;
  cargoFiltersStartDateCCA: any;
  cargoFiltersEndDateCCA: any;
  isCCAPanamaView: boolean = false;
  public cargoFiltersCCA: any = [
    {
      filterName: "plan name",
      list: [
        {
          value: 'All Desk',
          checked: false,
        },
        {
          value: 'ECCA',
          checked: false,
        },
        {
          value: 'WCCA',
          checked: false,
        },
        {
          value: 'Panama',
          checked: false,
        }
      ]
    },
    {
      filterName: "cca status",
      list: [
        {
          value: 'all',
          checked: false
        },
        {
          value: 'Approved',
          checked: true,
        },
        {
          value: 'Draft',
          checked: false,
        },
        {
          value: 'Pending',
          checked: true
        },
        {
          value: 'Completed',
          checked: false
        },
      ]
    }
  ];

  constructor(
    private store: Store,
    private appStore: Store<AppState>,
    private replenishmentService: ReplenishmentPlanningService,
    private datePipe: DatePipe,
    private utilities: Utilities,
    public router: Router,
    public nominationService: NominationService,
    private homeComponent: HomeComponent,
    private ethanolService: EthanolTruckService,
    private contractBalancingService: ContractBalancingService,
    private mdmService: MdmService,
    public cpService: CargoPlanningService,
    private toasterService: ToastService
  ) {
    this.getDefaultDates();
    this.getDefaultCCADates();
    this.dateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultToDate }
    this.ethanolDateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultToDate }
    this.pipelineDateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultToDate };
    this.cargoCCADateRangeData = { fromDate: this.getCCAFromDateFromEvent, toDate: this.getCCAToDateFromEvent };

    this.urlParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.routeFilters = response;
          this.getUpdatedFiltersSelection = response.updatedFilterSelection;
          if (response.params.filteredLocation != "" && response.params.filteredLocation != undefined) {
            this.isRouteParam = true;
            this.filteredLocation = response.params.filteredLocation;
            this.filteredProduct = response.params.filteredProduct;
            this.filteredProductNumber = response.params.filteredProductNumber;
            this.filteredProductGroupNumber = response.params.filteredMaterialGroupNumber;
            this.isSeasonalGroupedProduct = this.filteredProductGroupNumber.includes('-');
          } else {
            this.isRouteParam = false;
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
  }
  customerNames: any[] = [];

  ethanolFilterResponse = {}

  ngOnInit(): void {
    if (this.router.url === "/nominations") {
      let defaultClick = document.getElementById("defaultOpen") as HTMLElement
      defaultClick.click();
    }
    this.getListOfDesks();
    this.selectSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.isSwitchUpdated) {
            this.switchView = response.switchView.split('_')[0]?.toLowerCase();
            this.searchText = [];
            this.getEthanolFilterList()
            this.store.dispatch(setSwitchView({ switchView: response.switchView, isSwitchUpdated: false }));
          }

        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.nominationService.triggerClearFilter$.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (this.selectedDesk === 'panama' || this.isCCAPanamaView) {
            this.clearFilter()
          }
          else if (this.selectedDesk === 'ethanol + uswc' || this.selectedDesk === 'ethanol + usec') {
            this.ehtanolClearFilter()
          } else if (this.selectedDesk?.startsWith("clean products") || this.selectedDesk?.startsWith("pipeline")) {
            this.pipelineClearFilter();
          }
        }
      });

    this.nominationService.applyDefaultCustomView$.pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res) {
          if (this.selectedDesk === UDMDesk.EthanolUswc || this.selectedDesk === UDMDesk.EthanolUsec) {
            // Removed for resolve the issue regarding reciept total column issue 
            //NOSONAR lightweight logging
            // this.updateEhtanolFilterResponse?.forEach((filterEle: any) => {
            //   if (filterEle.headerName?.toLowerCase() === "terminal ownership") {
            //     filterEle.list.forEach((ele: any) => {
            //      ele.checked = undefined;
            //     });
            //   }
            // });

            if (!this.alreadyRanOnce || !this.router.url.toLowerCase().includes("replenishment")) {
              this.alreadyRanOnce = true;
              this.getEthanolFilterList()
            }
          } else if (this.selectedDesk?.startsWith("clean products")) {
            this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: [], pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineNomFilterUpdated: false }));
            this.pipelineNomFilterListState = [];
            this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: [], pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineFilterUpdated: false }));
            this.pipelineRPFilterListState = [];
            if (this.storedPipelineFilterResponse?.length > 0) {
              this.getPipelineFilters();
            }
          }
        }
      })

    this.nominationService.updatedFIlterValuesFromView$.subscribe(res => {
      if (res.length) {
        this.updateEhtanolFilterResponse = res;
      }
    })

    this.getEthanolTableHeaders$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.isTableHeadersUpdated) {
            this.ethanolAllTableHeaders = response.allTableHeaders;
            this.store.dispatch(setTableHeaders({ allTableHeaders: response.allTableHeaders, isTableHeadersUpdated: false }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.getIsPipelineNomFilterInitialised$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.isPipelineNomFilterInitialised) {
            this.currentScreen = "nominations"
            this.getLatestPipelineFilters(null, null, null, null, response.screenName);
            this.store.dispatch(initialisePipelineNomFilters({ isPipelineNomFilterInitialised: false }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (this.currentScreen !== response.screen) {
            if (localStorage.getItem('selectedView')) {
              this.selectedView = localStorage.getItem('selectedView');
            }
            this.currentScreen = response.screen
            if ((this.selectedDesk === UDMDesk.EthanolUswc || this.selectedDesk === UDMDesk.EthanolUsec) && response.screen === "replenishmentplanning" && !this.selectedView) {
              this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse || [])), ethanolFromDate: this.ethanolDateRangeData.fromDate, ethanolToDate: this.ethanolDateRangeData.toDate, isfilterUpdated: true, ethanolTruckToggle: this.ethanolTruckUOMSwitch, unitOfMeasure: this.truck_unit_option }));
            }
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedDefaultFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.updatedOfftakers) {
            this.filtersResponse = JSON.parse(JSON.stringify(response.totalTRFiltersResponse));
          }
          this.getUpdatedFiltersSelection = response.updatedFilterSelection;

          const checkBox = ['Offtakers'];
          const radioButton = ['Product'];
          const dropdown = ['Terminal'];
          this.filtersResponse.forEach((response: any) => {
            if (
              checkBox.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'checkBox';
              response.filterName = response.filterName?.toLowerCase();
            }
            if (
              radioButton.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'radioButton';
              response.filterName = response.filterName?.toLowerCase();
            }
            if (
              dropdown.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'dropdown';
              response.filterName = response.filterName?.toLowerCase();
            }
          });
          this.enableDisableOfftakers();
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.selectedDesk = response.selectedDesk.toLowerCase();
        this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        this.enablePipelineFilters();
        this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(response.selectedDesk);
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    })

    this.nominationService.setFilterDateRange$.subscribe(res => {
      if (Object.keys(res).length) {
        this.ethanolDateRangeData.fromDate = res.fromDate;
        this.ethanolDateRangeData.toDate = res.toDate;
      }
    })

    this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isfilterUpdated && this.currentScreen === "replenishmentplanning" && this.selectedDesk.includes('ethanol')) {
          this.getEhtanolFilterResponse = response.ethanolFilterValues
          this.ethanolDateRangeData.fromDate = response.ethanolFromDate
          this.ethanolDateRangeData.toDate = response.ehtanolToDate
          this.updateSearchData = JSON.parse(JSON.stringify(this.getEhtanolFilterResponse))
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    })

    this.selectEthanolNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isNomFilterUpdated && this.currentScreen === "nominations" && this.selectedDesk.includes('ethanol')) {
          this.getEhtanolFilterResponse = response.ethanolFilterValues
          this.ethanolDateRangeData.fromDate = response.ethanolFromDate
          this.ethanolDateRangeData.toDate = response.ehtanolToDate
          this.updateSearchData = JSON.parse(JSON.stringify(this.getEhtanolFilterResponse))
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }

    })

    this.selectedPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isPipelineFilterUpdated && this.currentScreen === "replenishmentplanning" && this.selectedDesk.includes('clean products')) {
          this.pipelineFilterResponse = response.pipelineFilterValues;
          this.storedPipelineFilterResponse = response.pipelineFilterValues;
          this.pipelineRPFilterListState = response.pipelineFilterValues;
          this.updatedPipelineFilterResponse = JSON.parse(JSON.stringify(response.pipelineFilterValues));
          this.updateSearchData = JSON.parse(JSON.stringify(this.pipelineFilterResponse));
          if (response.pipelineFromDate !== null) {
            this.pipelineDateRangeData.fromDate = response.pipelineFromDate;
            this.pipelineDateRangeData.toDate = response.pipelineToDate;
          }
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isPipelineNomFilterUpdated && this.currentScreen === "nominations" && this.selectedDesk.includes('clean products')) {
          this.pipelineFilterResponse = response.pipelineNomFilterValues;
          this.storedPipelineFilterResponse = response.pipelineNomFilterValues;
          this.pipelineNomFilterListState = response.pipelineNomFilterValues;
          this.updatedPipelineFilterResponse = JSON.parse(JSON.stringify(response.pipelineNomFilterValues));
          this.updateSearchData = JSON.parse(JSON.stringify(response.pipelineNomFilterValues));
          if (response.pipelineFromDate !== null) {
            this.pipelineDateRangeData.fromDate = response.pipelineFromDate;
            this.pipelineDateRangeData.toDate = response.pipelineToDate;
          }
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.selectedPipelineSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.pipelineSwitchView = response.pipelineSwitchView.split('_')[0].toLowerCase();
          if (response.isPipelineSwitchUpdated) {
            this.searchText = [];
            this.storedPipelineFilterResponse = null;
            this.getPipelineFilters()
            this.store.dispatch(setPipelineSwitchView({ pipelineSwitchView: response.pipelineSwitchView, isPipelineSwitchUpdated: false }));
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.contractBalancingService.updatedDesk$.subscribe(data => {
      this.storedPipelineFilterResponse = null;
    });
    this.nominationService.getSearchNominationAFAPI$.subscribe((res) => {
      this.isSearchOpened = res

    })
    this.nominationService.isCargoScreen.subscribe((res) => {
      this.iscargoScreenOpened = res

    })
  }

  searchClearFilter() {
    this.searchTextValue = ''
    this.searchDropdown = ''
  }
  onValueChange(evt: any) {
    this.searchDropdown = evt
  }

  tabChange(e: any, tabName: any) {
    if (tabName === 'Search') {
      localStorage.setItem("slectedFilterTab", "search");
      this.nominationService.isFilterScreen = "Search";
      this.isSearchOpened = true;
      this.nominationService.getSearchNominationAFAPI$.next(true);

    }
    else if (tabName === 'Filter') {
      this.nominationService.getSearchNominationAFAPI$.next(false);
      this.nominationService.isFilterScreen = "filter";
      localStorage.setItem("slectedFilterTab", "filter");
      this.isSearchOpened = false
      this.applyPipelineFilter();

    }
  }

  locationDisable(value: any) {
    if (value?.name?.toUpperCase() === 'PASCAGOU' || value?.name?.toUpperCase() === 'RICHMOND') {
      return true;
    } else return false;
  }

  isCheckboxDisabled(headerName: any, ethanolFilterValues: any, isChecked: any) {
    if (this.currentScreen === "replenishmentplanning" && headerName?.toLowerCase() !== 'terminal') {
      if (this.ethanolAllTableHeaders[headerName?.toLowerCase()]) {
        let currentIndex = this.ethanolAllTableHeaders[headerName?.toLowerCase()].findIndex((ele: any) => (ele === ethanolFilterValues?.id))
        if ((currentIndex > -1 || !isChecked) || ethanolFilterValues?.name === 'all') {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      if(this.currentScreen === TRScreen.ReplenishmentPlanning && ethanolFilterValues?.id === Location.BOISE){
          return true;}
          else{
          return false;
          }
    }
  }

  indexTracker(index: number) {
    return index;
  }

  updateFilterList(event: any, index: any) {
    this.searchText[index] = event?.toLowerCase()
    this.searchText.forEach((value: any, i: any) => {
      let updatedFilterList: any = []
      if (value) {
        if(this.getEhtanolFilterResponse[i].headerName == 'terminal'){
          updatedFilterList = [...this.getEhtanolFilterResponse[i].list.filter((ele: any) => ele.id?.toLowerCase().includes(value?.toLowerCase()) || ele.name?.toLowerCase() === 'all')];
        }else{
          updatedFilterList = [...this.getEhtanolFilterResponse[i].list.filter((ele: any) => ele.name?.toLowerCase().includes(value?.toLowerCase()) || ele.name?.toLowerCase() === 'all')];
        }
        this.updateSearchData[i].list = [...updatedFilterList]
      } else {
        this.updateSearchData[i].list = [...this.getEhtanolFilterResponse[i].list]
      }
    })
  }

  getEthanolFilterList(parentFilterChange?: any) {
    const getType: any = {
      [UDMDesk.EthanolUswc]: 'ethanolfilterdata',
      [UDMDesk.EthanolUsec]: 'ethanolusecfilterdata'
    }
    let terminalOwnership = 'single entity';
    this.updateEhtanolFilterResponse?.forEach((filterEle: any) => {
      if (filterEle.headerName?.toLowerCase() === "terminal ownership") {
        filterEle.list.forEach((ele: any) => {
          if (ele.checked) {
            terminalOwnership = ele?.name === 'single entity' ? 'single entity' : 'multiple entity'
          }
        })
      }
    });
    if (this.utilities.globalethanolAggregatorResponse === null || this.utilities.globalethanolAggregatorResponse === undefined || this.utilities.globalethanolAggregatorResponse?.deskId !== this.selectedDeskId) {
        this.utilities.getEthanolFilterData().subscribe((results) => {
        this.utilities.ethanolSupplierCarrier = results.value;
        this.ethanolFilterList(parentFilterChange, terminalOwnership)
      });
    } else{
      this.ethanolFilterList(parentFilterChange, terminalOwnership)
    }
  }

  ethanolFilterList(parentFilterChange?: any, terminalOwnership?: any){
    let ethanoljsonReslt = this.utilities.getRequiredEthanolFilterJsonStructure(this.utilities.globalethanolAggregatorResponse, false, false);
      let terminal = ethanoljsonReslt?.terminal?.map((terminal: any) => {
        if (terminal.terminalOwnership === terminalOwnership) {
          return {
            id: terminal.id,
            name: terminal.name,
            volume: terminal.volume
          };
        }
        return null;
      }).filter(Boolean);
      let carrier = ethanoljsonReslt?.carrier?.map((carr: any) => { return { name: carr?.carrierName, id: carr?.carrierCode } });
      let supplier = ethanoljsonReslt?.supplier?.map((supp: any) => { return { name: supp?.supplierName, id: supp?.supplier } });
      let customer = ethanoljsonReslt?.customer?.map((cust: any) => { return { name: cust?.customerName, id: cust?.customer } });
      let startDate = this.datePipe.transform((this.ethanolDateRangeData.fromDate ? this.ethanolDateRangeData.fromDate : this.defaultFromDate), 'yyyy-MM-dd');
      let endDate = this.datePipe.transform(this.ethanolDateRangeData.toDate ? this.ethanolDateRangeData.toDate : this.defaultToDate, 'yyyy-MM-dd');
      let isOnlyTruckSelected;
      this.ethanolService.getTruckVolume$.next(terminal);
      this.updateEhtanolFilterResponse?.forEach((filterEle: any) => {
        if (filterEle.headerName) {
          if (filterEle.headerName?.toLowerCase() === "mode of transport") {
            let selectedElement = filterEle.list.filter((ele: any) => ele?.checked)
            isOnlyTruckSelected = selectedElement[0]?.name === "truck"
          }
        }
      });
      const params = { isOnlyTruckSelected, parentFilterChange, supplier, terminal, carrier, startDate, endDate, customer };
      this.transformFilterList(params);
  }

  transformFilterList(params: any) {
    const { isOnlyTruckSelected, parentFilterChange, supplier, terminal, carrier, startDate, endDate, customer } = params;
    let filterList: any = []
    if ((this.selectedDesk === UDMDesk.EthanolUswc || this.selectedDesk === UDMDesk.EthanolUsec) && ((this.selectedMOTCount === 1 && isOnlyTruckSelected) || parentFilterChange)) {
      this.updateEhtanolFilterResponse?.forEach((element: any) => {
        if (["terminal ownership", "mode of transport", "unit of measure"].includes(element.headerName)) {
          filterList.push(element)
        }
      });
    } else {
      filterList = [
        {
          headerName: "terminal ownership",
          list: [
            { name: "single entity" },
            { name: "multiple entities" }
          ]
        },
        {
          headerName: "mode of transport",
          list: [
            { name: "truck" },
            { name: "rail" },
            { name: "barge" },
            { name: "tank" }
          ]
        },
        {
          headerName: "unit of measure",
          truck: false,
          list: [
            { name: "bbl", value: "br" },
            { name: "mb", value: "mb" },
            { name: "gal", value: "ga" }
          ]
        },
      ]
    }
    const params2 = { isOnlyTruckSelected, parentFilterChange, supplier, terminal, carrier, startDate, endDate, filterList, customer };
    this.transformEthanolSupplierView(params2);
  }

  transformEthanolSupplierView(params: any) {
    const { isOnlyTruckSelected, parentFilterChange, supplier, terminal, carrier, startDate, endDate, filterList, customer } = params;
    if ((this.selectedDesk === UDMDesk.EthanolUswc || this.selectedDesk === UDMDesk.EthanolUsec) && this.switchView === 'supplier' && (this.selectedMOTCount === 1 && isOnlyTruckSelected)) {
      this.transformEthanolSupplierSwitchView(filterList, supplier, terminal);
    } else if (this.switchView === 'carrier' && (this.selectedMOTCount === 1 && isOnlyTruckSelected)) {
      this.transformEthanolCarrierSwitchView(filterList, carrier, terminal, supplier);
    } else {
      this.transformEthanolOtherSwitchView(filterList, terminal, supplier, carrier, customer);
    }
    this.getEthanolFilterResponse(filterList, startDate, endDate, parentFilterChange)
  }

  transformEthanolSupplierSwitchView(filterList: any, supplier: any, terminal: any) {
    filterList.push({ headerName: "supplier", list: [{ name: "all" }, ...supplier] })
    filterList.push({ headerName: "terminal", list: [{ name: "all" }, ...terminal] })
  }

  transformEthanolCarrierSwitchView(filterList: any, carrier: any, terminal: any, supplier: any) {
    filterList.push({ headerName: "carrier", list: [{ name: "all" }, ...carrier] })
    filterList.push({ headerName: "terminal", list: [{ name: "all" }, ...terminal] })
    filterList.push({ headerName: "supplier", list: [{ name: "all" }, ...supplier] })
  }

  transformEthanolOtherSwitchView(filterList: any, terminal: any, supplier: any, carrier: any, customer: any) {
    filterList.push({ headerName: "terminal", list: terminal.length > 0 ? [{ name: "all" }, ...terminal] : [] })
    filterList.push({ headerName: "supplier", list: supplier.length > 0 ? [{ name: "all" }, ...supplier] : [] })
    filterList.push({ headerName: "carrier", list: carrier.length > 0 ? [{ name: "all" }, ...carrier] : [] })
    filterList.push({ headerName: "customer", list: customer.length > 0 ? [{ name: "all" }, ...customer] : [] })
  }

  transformPipelineFilterList(params: any) {
    const { parentFilterChange, product, startDate, endDate, headerName, terminal } = params;
    let filterList: any = [];

    this.transformPipelineFilterListFunctionSplit(params, filterList);
    if (parentFilterChange && (headerName === "mode of transport" || headerName === "transport system")) {
      filterList.push({ headerName: "terminal", list: terminal?.length > 0 ? [{ name: "all" }, ...terminal] : [] });
    }
    filterList.push({ headerName: "product", list: product?.length > 0 ? [{ name: "all" }, ...product] : [] });
    this.getPipelineFilterResponse(filterList, startDate, endDate, parentFilterChange, headerName);
  }

  transformPipelineFilterListFunctionSplit(params: any, filterList: any) {
    const { parentFilterChange, terminal, transportsystem, headerName, modeOfTransport } = params;
    let isCargoDesks = this.selectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.selectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.selectedDesk === UDMDesk.PipelineUswcPNWDesk || this.selectedDesk === UDMDesk.PipelineUsecRichmondDesk;
    if (parentFilterChange) {
      filterList = this.updatedPipelineFilterList(headerName, filterList, transportsystem, terminal) //NOSONAR lightweight logging
    }
    else {
      this.transformPipelineFilterListFunctionSplitForNonParentFilterChange(isCargoDesks, modeOfTransport, filterList, transportsystem, terminal);
    }
  }

  transformPipelineFilterListFunctionSplitForNonParentFilterChange(isCargoDesks: any, modeOfTransport: any, filterList: any, transportsystem: any, terminal: any) {
    if (isCargoDesks || this.selectedDesk === "clean products + latam + panama desk" || this.selectedDesk === "clean products + latam + ecca desk" || this.selectedDesk === "clean products + latam + wcca desk") {
      filterList?.push({ headerName: "mode of transport", list: modeOfTransport?.length > 0 ? [{ name: "all" }, ...modeOfTransport] : [] });
    }
    filterList.push({ headerName: "transport system", list: transportsystem?.length > 0 ? [{ name: "all" }, ...transportsystem] : [] });
    filterList.push({ headerName: (this.currentScreen === "replenishmentplanning" || (this.currentScreen === "nominations")) ? "terminal" : "location", list: terminal?.length > 0 ? [{ name: "all" }, ...terminal] : [] });
  }

  updatedCargoFilterList(headerName: any, filterList: any, transportsystem: any, terminal: any) {
    this.checkedCargoFilters = {};
    this.updatedPipelineFilterResponse.forEach((element: any) => {
      this.checkedCargoFilters[element?.headerName] = element?.list?.filter((ele: any) => ele.checked);
      this.updateCargoFilterListWithCheckedCargoFilter(headerName, element, filterList, transportsystem, terminal);
    });
    return filterList;
  }

  updateCargoFilterListWithCheckedCargoFilter(headerName: any, element: any, filterList: any, transportsystem: any, terminal: any) {
    if (headerName === "mode of transport" && element?.headerName === "mode of transport") {
      this.updateCargoFilterListWithHeaderNameMOT(filterList, element, transportsystem, terminal);
    } else {
      this.updateCargoFilterListWithHeaderNameTransportSystemOrLocation(headerName, element, filterList);
    }
  }

  updateCargoFilterListWithHeaderNameMOT(filterList: any, element: any, transportsystem: any, terminal: any) {
    filterList.push(element);
    filterList.push({ headerName: "transport system", list: transportsystem?.length > 0 ? [{ name: "all" }, ...transportsystem] : [] });
    filterList.push({ headerName: "location", list: terminal?.length > 0 ? [{ name: "all" }, ...terminal] : [] });
  }

  updateCargoFilterListWithHeaderNameTransportSystemOrLocation(headerName: any, element: any, filterList: any) {
    if ((headerName === "transport system" && (element?.headerName === "transport system" || element?.headerName === "mode of transport" || element?.headerName === "location")) ||
      headerName === "location" && (element?.headerName === "location" || element?.headerName === "mode of transport" || element?.headerName === "transport system")) {
      if (filterList?.map((el: any) => el?.headerName).indexOf(element?.headerName) === -1) {
        filterList.push(element);
      }
    }
  }

  updatedPipelineFilterList(headerName: any, filterList: any, transportsystem: any, terminal: any) {
    this.checkedPipelineFilters = {};
    this.updatedPipelineFilterResponse.forEach((element: any) => {
      this.checkedPipelineFilters[element?.headerName] = element?.list?.filter((ele: any) => ele.checked);
      if (headerName === "mode of transport" && element?.headerName === "mode of transport") {
        filterList.push(element);
        filterList.push({ headerName: "transport system", list: transportsystem?.length > 0 ? [{ name: "all" }, ...transportsystem] : [] });
        filterList.push({ headerName: "terminal", list: terminal?.length > 0 ? [{ name: "all" }, ...terminal] : [] });
      } else {
        this.updatePipelineFilterListWithHeaderNameTransportSystemAndTerminal(headerName, element, filterList);
      }
    });
    return filterList;
  }

  updatePipelineFilterListWithHeaderNameTransportSystemAndTerminal(headerName: any, element: any, filterList: any) {
    if ((headerName === "transport system" && (element?.headerName === "transport system" || element?.headerName === "mode of transport")) ||
      headerName === "terminal" && (element?.headerName === "terminal" || element?.headerName === "mode of transport" || element?.headerName === "transport system")) {
      if (filterList?.map((el: any) => el?.headerName).indexOf(element?.headerName) === -1) {
        filterList.push(element);
      }
    }
  }

  enableDisableOfftakers(): void {
    this.appData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.screen?.toLowerCase() === "replenishmentplanning") {
        this.processOffTakersForTR(data);
      } else if (data.screen?.toLowerCase() === "nominations") {
        this.processOffTakersForNomination(data);
      }
    });
  }

  processOffTakersForTR(data: any) {
    if (data.screen?.toLowerCase() === "replenishmentplanning") {
      this.trData$
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: any) => {
            const customerNames = response?.value?.customerDetails?.map((x: any) => {
              return x.customerName;
            });
            this.processOfftakersFilterResponse(customerNames);
          },
          error: (err: any) => {
            console.log(err);
          },
          complete: () => { },
        });
    }
  }

  processOffTakersForNomination(data: any) {
    if (data.screen?.toLowerCase() === "nominations") {
      setTimeout(() => {
        this.nominationList$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (response: any) => {
            const customerNames = response?.map((x: any) => {
              if (x.counterPart) {
                return x.counterPart;
              } else {
                return "N/A"
              }
            });
            this.processOfftakersFilterResponse(customerNames);
          }
        });
      }, 3000);
    }
  }

  processOfftakersFilterResponse(customerNames: any) {
    if (this.filtersResponse.find(
      (filter: any) => filter.filterName?.toLowerCase() === "offtakers"
    )?.filterValues.length) {
      this.filtersResponse.find(
        (filter: any) => filter.filterName?.toLowerCase() === 'offtakers'
      ).filterValues.forEach((response: any) => {
        if (customerNames?.length) {
          if (customerNames.find((name: any) => name === response.name)) {
            response["isDisabled"] = false;
          } else if (response.checked) {
            response["isDisabled"] = true;
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['updatedDesk']?.currentValue) {
      this.searchText = [];
      this.selectedDesk = changes['updatedDesk'].currentValue?.deskValue?.toLowerCase();
      this.selectedDeskParameter = changes['updatedDesk'].currentValue?.deskParameter;
      this.selectedDeskId = changes['updatedDesk'].currentValue?.id;
      this.isDeskUpdated = true;
      this.storedPipelineFilterResponse = null;
      this.enablePipelineFilters();
      this.getSelectedDeskFilters();
      if (this.selectedDeskParameter === "panamadesk") {
        this.getPipelineFilters();
      }
      let saveCargoFilters = true;
      this.reInitiateFiltersForLinkingNomWithCargo$?.pipe(takeUntil(this.destroy$))?.subscribe(response => {
        if (response.reInitiateFiltersForCCA) {
          this.store.dispatch(reInitiateFiltersForCCANomLinking({ reInitiateFilters: false }));
          const updatedDeskValue = response?.deskFilterValues;
          this.selectedDesk = updatedDeskValue?.deskValue;
          localStorage.setItem('desk', updatedDeskValue?.deskValue);
          this.selectedDeskParameter = updatedDeskValue?.deskParameter;
          localStorage.setItem('deskParam', updatedDeskValue?.deskParameter);
          this.selectedDeskId = updatedDeskValue?.id;
          localStorage.setItem('deskId', updatedDeskValue?.id);
          if(updatedDeskValue?.screenName === 'closenominationpopupincargoscreen' && this.router.url === "/cargoPlanning"){
            this.getPipelineFilters();
            return
          }
          this.currentScreen = updatedDeskValue?.screenName;
          if(this.currentScreen === 'cargoPlanning'){
            this.store.dispatch(saveCargoFiltersCCA({cargoCCAFilters: this.cargoFiltersCCA, cargoCCAStartDate: this.cargoFiltersStartDateCCA, cargoCCAEndDate: this.cargoFiltersEndDateCCA, isCargoCCAUpdated: true}));
          }
          this.getSelectedDeskFilters();
        }
      });
      const ccaCargoFilters = JSON.parse(JSON.stringify(this.cargoFiltersCCA));
      ccaCargoFilters?.forEach((el: any) => {
        if (el.filterName === "plan name") {
          el.list.forEach((dt: any) => {
            if (this.selectedDesk?.includes(dt.value.toLowerCase()) || this.selectedDesk?.includes("all desk")) {
              dt.checked = true;
            } else {
              dt.checked = false;
            }
          });
        } else {
          el.list.forEach((dt: any) => {
            if (dt.value === "Approved" || dt.value === "Pending") {
              dt.checked = true;
            } else {
              dt.checked = false;
            }
          });
        }
      });
      this.cargoFiltersCCA = JSON.parse(JSON.stringify(ccaCargoFilters));
      this.cargoFiltersStartDateCCA = this.getCCAFromDateFromEvent;
      this.cargoFiltersEndDateCCA = this.getCCAToDateFromEvent;
      this.nominationService.cargoCCAFilterOnFirstLoad = JSON.parse(JSON.stringify({ cargoCCAFilters: this.cargoFiltersCCA, cargoCCAStartDate: this.getFromDateFromEvent, cargoCCAEndDate: this.getToDateFromEvent, isCargoCCAUpdated: true }));
      this.cargoFiltersStartDateCCA = this.getCCAFromDateFromEvent;
      this.cargoFiltersEndDateCCA = this.getCCAToDateFromEvent;
      this.cpService.cargoCCAFilters = {
        filterValues: this.cargoFiltersCCA,
        fromDate: this.datePipe.transform(this.cargoFiltersStartDateCCA, 'yyyy-MM-dd'),
        toDate: this.datePipe.transform(this.cargoFiltersEndDateCCA, 'yyyy-MM-dd'),
      }
      if ((this.selectedDesk?.includes("clean products + latam") && this.router.url === '/cargoPlanning') || this.selectedDesk?.includes("clean products + latam + all")) {
        this.store.dispatch(saveCargoFiltersCCA({cargoCCAFilters: this.cargoFiltersCCA, cargoCCAStartDate: this.datePipe.transform(this.getCCAFromDateFromEvent, 'yyyy-MM-dd'), cargoCCAEndDate: this.datePipe.transform(this.getCCAToDateFromEvent, 'yyyy-MM-dd'), isCargoCCAUpdated: true}));
      }
    }
  }

  getSelectedDeskFilters() {
    if (this.selectedDesk === 'panama' || this.isCCAPanamaView) {
      this.getLocationFilters();
      this.getPipelineFilters();
    } else if (this.selectedDesk === UDMDesk.EthanolUswc || this.selectedDesk === UDMDesk.EthanolUsec) {
      this.updateEhtanolFilterResponse?.forEach((filterEle: any) => {
        if (filterEle.headerName?.toLowerCase() === "terminal ownership") {
          filterEle.list.forEach((ele: any) => {
            ele.checked = undefined;
          });
        }
      });
      if (this.router.url !== '/contract-balancing') {
        // if (!this.alreadyRanOnce) {
        this.alreadyRanOnce = true;
        this.getEthanolFilterList()
        // }
      }
    } else {
      this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: [], pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineNomFilterUpdated: false }));
      this.pipelineNomFilterListState = [];
      this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: [], pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineFilterUpdated: false }));
      this.pipelineRPFilterListState = [];
      this.getPipelineFilters();
    }
  }

  closeFilters(): void {
    this.closeFilterPanel = true;
    this.filterPanelClosed.emit(true);
  }

  async getListOfDesks(isFromClearFilter = false) {
    this.mdmService.pavisResponse = await firstValueFrom(this.mdmService.getPavisPort());
    this.mdmService.getAllDesks(true).subscribe({
      next: (response: any) => {
        response.value[2].regionDeskMapping[2].deskMappings.push({ active : true, id: 25,   name :  "All",  udmCreatedBy :  "InitialSeeding",  udmCreatedOn: "2024-06-27T00:57:09.907",  udmLastModifiedBy: "InitialSeeding", udmLastModifiedOn :"2024-06-27T00:57:09.907"});
        if (response.value.length) {
          const storedDesk = localStorage.getItem("desk");
          const storedDeskId = localStorage.getItem("deskId");
          const storeDeskParam = localStorage.getItem("deskParam");
          this.store.dispatch(setAllDeskInfo({deskInfo: response.value}));
          this.deskListResponse = this.getFormatedDeskList(response.value);
          this.selectedDesk = storedDesk && (storedDeskId && storedDeskId !== null && storedDeskId?.toLowerCase() !== 'null') ? storedDesk : this.deskListResponse[0].filterValues[0].name;
          this.selectedDeskParams = storeDeskParam ?? this.deskListResponse[0].filterValues[0].parameter;
          this.selectedDeskId = storedDesk && (storedDeskId && storedDeskId !== null && storedDeskId?.toLowerCase() !== 'null') ? storedDeskId : this.deskListResponse[0].filterValues[0].id;
          localStorage.setItem("desk", this.selectedDesk);
          localStorage.setItem("deskId", this.selectedDeskId);
          this.enablePipelineFilters();
          this.deskList.emit(this.deskListResponse);
          if (isFromClearFilter) {
            this.getLocationFilters();
          }
        } else {
          throw new Error('getListOfDesks is empty');
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {

      },
    });
  }

  getFormatedDeskList(list: any) {
    let deskList: any = [];
    list.forEach((ele: any) => {
      let filterValues: any = [];
      let filterHeader = ele.functionalGroup;
      ele?.regionDeskMapping.forEach((regionDesk: any) => {
        if (regionDesk.region === null) {
          filterValues = this.getDeskDetail(regionDesk, ele);
        } else {
          let regionDeskName = regionDesk.region.split(' ')[0]
          let children: any = [];
          regionDesk.deskMappings.forEach((desk: any) => {
            let deskName = desk.name;
            let d = { id: desk?.id, name: deskName.toLowerCase() + ' desk', label: `${filterHeader.toLowerCase()} + ${regionDeskName.toLowerCase()} + ${deskName.toLowerCase()} desk`, parameter: deskName.split(' ').join('').toLowerCase() + 'desk' };
            children.push(d);
          });
          filterValues.push({ id: regionDesk?.id, name: regionDeskName.toLowerCase(), label: `${filterHeader.toLowerCase()} + ${regionDeskName.toLowerCase()}`, children: children });
        }
      });
      deskList.push({ id: ele?.id, filterName: filterHeader.toLowerCase(), filterValues: filterValues })
    })
    return deskList;
  }

  getDeskDetail(element: any, ele: any) {
    let filterValues: any = [];

    element.deskMappings.forEach((desk: any) => {
      let deskName = desk.name.split(' ')[0]
      let d = { id: desk?.id, name: deskName.toLowerCase(), label: `${ele.functionalGroup.toLowerCase()} + ${deskName.toLowerCase()}`, children: [], parameter: `${ele.functionalGroup.toLowerCase()}${deskName.toLowerCase()}` }
      filterValues.push(d);
    });
    return filterValues;
  }

  getLocationFilters() {
    this.replenishmentService.getRefData(this.isCCAPanamaView ? 'panama' : this.selectedDesk).subscribe({
      next: (response: any) => {
        if ((response.value.length && response.value[0].values.length) && !this.isRouteParam) {
          this.locationFilters = response.value[0].values;
          this.locationFilterSelection = this.locationFilters[0].name?.toLowerCase();
          this.getDeskMappings();
          localStorage.setItem('locationSelected', this.locationFilters[0].name)
        }
        else if (this.isRouteParam) {
          this.locationFilters = response.value[0].values;
          for (let i in this.locationFilters) {
            if (this.locationFilters[i].name.toLowerCase() === this.routeFilters.params.filteredLocation.toLowerCase()) {
              this.locationFilterSelection = this.routeFilters.params.filteredLocation.toLowerCase();
            }
          }
          this.getParamMappings();
        }
        else {
          this.locationFilterSelection = "";
          this.filtersResponse = [];
          this.locationFilters = [];
          localStorage.removeItem('locationSelected');
        }
      }
    })
  }

  getParamMappings() {
    this.replenishmentService.getRefData(this.locationFilterSelection).subscribe({
      next: (response: any) => {
        if (response.value.length && response.value[0].filters.length && this.isRouteParam) {
          const formattedResponse = response.value[0].filters;

          this.filtersResponse = formattedResponse;

          const locationFilter = { filterName: "location", filterValues: this.locationFilters };
          this.filtersResponse.push(locationFilter);
          this.formatFilterResponse();

          this.filtersResponse.push({ filterName: "location", filterValues: this.locationFilterSelection });
          const routeFiltersValue = this.filtersResponse;

          const customers = routeFiltersValue.find(
            (filter: any) => filter.filterName?.toLowerCase() === 'offtakers'
          );
          this.selectedCustomers = customers.filterValues.map(
            (value: any) => value.name
          );
          this.allOfftakers = [...this.selectedCustomers];
          /*  */
          const product = routeFiltersValue.find(
            (filter: any) => filter.filterName?.toLowerCase() === 'product'
          );

          const updatedFilters = JSON.parse(JSON.stringify(this.getUpdatedFiltersSelection));
          const routeproductName = product.filterValues.find(
            (filter: any) => filter.name.toLowerCase() === this.routeFilters.params.filteredProduct.toLowerCase()
          )
          updatedFilters.updatedProduct = this.routeFilters.params.filteredProduct === "" ? product.filterValues[0].name : routeproductName.name;
          updatedFilters.updatedfromDate = this.getFromDateFromEvent;
          updatedFilters.updatedToDate = this.getToDateFromEvent;
          this.appStore.dispatch(modifyUpdatedFiltersSelection({ updatedFilters: updatedFilters, updatedOfftakers: false, }));
          this.appStore.dispatch(saveDefaultTRFilters({ totalTRFiltersResponse: JSON.parse(JSON.stringify(routeFiltersValue)), receivedTotalFiltersResponse: true, updatedOfftakers: false, receivedUpdatedFilterSelection: false }));
          this.getEventRouteFunction(product);

          const checkBox = ['Offtakers'];
          const radioButton = ['Product'];
          const dropdown = ['Terminal'];
          this.filtersResponse.forEach((response: any) => {
            if (
              checkBox.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'checkBox';
              response.filterName = response.filterName?.toLowerCase();
            }
            if (
              radioButton.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'radioButton';
              response.filterName = response.filterName?.toLowerCase();
            }
            if (
              dropdown.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'dropdown';
              response.filterName = response.filterName?.toLowerCase();
            }
          });
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
      },
    });
  }

  getEventRouteFunction(product: any) {
    for (let i in product.filterValues) {
      if (product.filterValues[i].name.toLowerCase() === this.routeFilters.params.filteredProduct.toLowerCase() || this.routeFilters.params.filteredProduct === "") {
        this.getProductFromEvent = product.filterValues[i].name;
        return this.getProductFromEvent;
      }
    }
  }

  getDeskMappings() {
    this.replenishmentService.getRefData(this.locationFilterSelection).subscribe({
      next: (response: any) => {

        if (response.value.length && response.value[0].filters.length) {
          const formattedResponse = response.value[0].filters;

          this.filtersResponse = formattedResponse;

          const locationFilter = { filterName: "location", filterValues: this.locationFilters };
          this.filtersResponse.push(locationFilter);

          this.formatFilterResponse();

          this.filtersResponse.push({ filterName: "location", filterValues: this.locationFilterSelection });
          const defaultFiltersValue = this.filtersResponse;

          const customers = defaultFiltersValue.find(
            (filter: any) => filter.filterName?.toLowerCase() === 'offtakers'
          );
          this.selectedCustomers = customers.filterValues.map(
            (value: any) => value.name
          );
          this.allOfftakers = [...this.selectedCustomers];
          /*  */
          const product = defaultFiltersValue.find(
            (filter: any) => filter.filterName?.toLowerCase() === 'product'
          );

          const updatedFilters = JSON.parse(JSON.stringify(this.getUpdatedFiltersSelection));
          updatedFilters.updatedProduct = product.filterValues[0].name;
          updatedFilters.updatedfromDate = this.getFromDateFromEvent;
          updatedFilters.updatedToDate = this.getToDateFromEvent;
          this.appStore.dispatch(modifyUpdatedFiltersSelection({ updatedFilters: updatedFilters, updatedOfftakers: false, }));
          this.filtersInitialized = true;
          this.appStore.dispatch(saveDefaultTRFilters({ totalTRFiltersResponse: JSON.parse(JSON.stringify(defaultFiltersValue)), receivedTotalFiltersResponse: true, updatedOfftakers: false, receivedUpdatedFilterSelection: false }));
          const checkBox = ['Offtakers'];
          const radioButton = ['Product'];
          const dropdown = ['Terminal'];
          this.filtersResponse.forEach((response: any) => {
            if (
              checkBox.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'checkBox';
              response.filterName = response.filterName?.toLowerCase();
            }
            if (
              radioButton.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'radioButton';
              response.filterName = response.filterName?.toLowerCase();
            }
            if (
              dropdown.find(
                (value) =>
                  value?.toLowerCase() === response.filterName?.toLowerCase()
              )
            ) {
              response['inputType'] = 'dropdown';
              response.filterName = response.filterName?.toLowerCase();
            }
          });
        } else {
          this.filtersResponse = [];
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
      },
    });
  }

  formatFilterResponse() {
    this.filtersResponse.forEach((data: any) => {
      if (data.filterName?.toLowerCase() === 'offtakers') {
        data.filterValues.forEach((offtakersFilterValue: any) => {
          offtakersFilterValue["checked"] = true;
        });
      }
      if (data.filterName?.toLowerCase() === 'product') {
        data.filterValues.forEach((productValues: any) => {
          productValues["checked"] = true;
        });
      }
      if (data.filterName?.toLowerCase() === 'location') {
        data.filterValues.forEach((value: any) => {
          if (this.locationFilterSelection === "") {
            value["checked"] = false;
          } else if (value.name?.toLowerCase() === this.locationFilterSelection?.toLowerCase()) {
            value["checked"] = true;
          } else {
            value["checked"] = false;
          }
        });
      }
    })
  }

  dateRangegroup(event: any) {
    this.getudatedDateRangeFlag = event.valid;
    this.getFromDateFromEvent = event.controls.start.value;
    this.getToDateFromEvent = event.controls.end.value;
    let selectedProduct = "";
    this.appStore.select(selectAppliedTRFilters).subscribe(state => selectedProduct = state.updatedFilterSelection.updatedProduct);

    const values = {
      desk: this.selectedDesk,
      offtakers: this.allOfftakers,
      updatedProduct: selectedProduct,
      updatedfromDate: this.getFromDateFromEvent,
      updatedselectedCustomer: this.selectedCustomers,
      updateCheckedCustomer: this.getCheckedCustomer,
      updatedToDate: this.getToDateFromEvent,
      updateUncheckedCustomer: this.getUncheckedCustomer,
      getFilterName: "daterange",
      udatedDateRangeFlag: this.getudatedDateRangeFlag,
      updateSelectedFilter: this.selectedFilter
    };

    this.appliedFilters.emit(values)
    this.store.dispatch(saveAppliedTRFilters({ updatedFilterSelection: JSON.parse(JSON.stringify(values)), receivedUpdatedFilterSelection: true, isClearFilter: false, isTRSaveEnabled: false, isTRGrid: false }));
  }

  getEventFunction = (event: any, filterName: any) => {
    if (event?.target && filterName?.toLowerCase() === 'product') {
      if (event.target.value === "ULSD" && this.router.url === "/nominations") {
        this.getProductFromEvent = "ULSD"
        return this.getProductFromEvent;
      } else {
        this.getProductFromEvent = event.target.value
        return this.getProductFromEvent;
      }
    }
    if (event?.target && filterName?.toLowerCase() === 'offtakers') {
      if (event.target.checked) {
        if (this.selectedCustomers.find((customer: any) => customer?.toLowerCase() !== event.target.value?.toLowerCase())) {
          this.selectedCustomers.push(event.target.value);
        } else {
          const findCustomerIndex = this.selectedCustomers.findIndex(
            (customer: any) => customer === event.target.value
          );
          this.selectedCustomers.splice(
            findCustomerIndex,
            1
          );
        }
        this.getCheckedCustomer = event.target.value;
        this.getUncheckedCustomer = "";
      } else {
        const findCustomerIndex = this.selectedCustomers.findIndex(
          (customer: any) => customer === event.target.value
        );
        this.getUncheckedCustomer = this.selectedCustomers.splice(
          findCustomerIndex,
          1
        );
        this.getCheckedCustomer = "";
      }
    }

  }

  filteByLocation(filter: any) {
    localStorage.setItem("locationSelected", filter.name);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
    this.locationFilterSelection = filter.name?.toLowerCase();
    this.getDeskMappings();
  }


  filterTRData(event: any, filterName: any) {
    // clearing saved agGridState and updatedCellNode from sessionStorage for Panama desk while switching products
    if(this.selectedDesk === 'panama'){
      sessionStorage.removeItem("updatedCellNode");
      sessionStorage.removeItem("agGridState");
    }
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
    this.nominationService.sidePanel$.next(false);
    this.selectedCustomers.push(event.target.value);
    this.getEventFilterName = filterName;
    this.updateisChecked = event.target.checked;
    this.selectedFilter = filterName;

    let selectedProduct = "";
    this.appStore.select(selectAppliedTRFilters).subscribe(state => selectedProduct = state.updatedFilterSelection.updatedProduct);

    this.getProductFromEvent = selectedProduct;
    this.getEventFunction(event, filterName);
    
    const values = {
      desk: this.selectedDesk,
      offtakers: this.allOfftakers,
      updatedProduct: this.getProductFromEvent,
      updatedfromDate: this.getFromDateFromEvent,
      updatedselectedCustomer: this.selectedCustomers,
      updateCheckedCustomer: this.getCheckedCustomer,
      updatedToDate: this.getToDateFromEvent,
      updateUncheckedCustomer: this.getUncheckedCustomer,
      getFilterName: this.getEventFilterName,
      getIsChecked: this.updateisChecked,
      udatedDateRangeFlag: this.getudatedDateRangeFlag,
      updateSelectedFilter: this.selectedFilter
    };
    this.appliedFilters.emit(values);

    this.formatNonOffTakersData(event);
    this.formatOffTakersData(event, values);

    this.store.dispatch(saveAppliedTRFilters({ updatedFilterSelection: JSON.parse(JSON.stringify(values)), receivedUpdatedFilterSelection: true, isClearFilter: false, isTRSaveEnabled: this.trSave, isTRGrid: false }));
    this.getUncheckedCustomer = '';
  }

  formatNonOffTakersData(event: any) {
    this.trSave = true;
    if (this.getEventFilterName !== 'offtakers') {
      this.trSave = false;

      let getFilterResponse = JSON.parse(JSON.stringify(this.filtersResponse))
      getFilterResponse.map((data: any) => {
        if (data.filterName?.toLowerCase() === 'product') {
          this.filtersInitialized = false;
          data.filterValues.forEach((response: any) => {
            if (!(event.target.checked) && response.name?.toLowerCase() === this.getProductFromEvent?.toLowerCase()) {
              response.checked = false
            }
            else if (event.target.checked && response.name?.toLowerCase() === this.getProductFromEvent?.toLowerCase()) {
              response.checked = true;
            }
          }
          )
        }
      });
      this.filtersResponse.forEach((item: any) => {
        if (item.filterName === 'offtakers') {
          item.filterValues.forEach((offtaker: any) => {
            offtaker.checked = true;
          })
        }
      });
      this.store.dispatch(saveDefaultTRFilters({ totalTRFiltersResponse: JSON.parse(JSON.stringify(this.filtersResponse)), receivedTotalFiltersResponse: false, updatedOfftakers: true, receivedUpdatedFilterSelection: false }));
    }
  }

  formatOffTakersData(event: any, values: any) {
    if (this.getEventFilterName === 'offtakers') {
      let getFilterResponseForOfftakers = JSON.parse(JSON.stringify(this.filtersResponse))
      getFilterResponseForOfftakers.map((data: any) => {
        if (data.filterName?.toLowerCase() === 'offtakers') {
          data.filterValues.forEach((response: any) => {
            if (!(event.target.checked) && response.name === values.updateUncheckedCustomer.join("")) {
              response.checked = false;
            }
            else if (event.target.checked && response.name === this.getCheckedCustomer) {
              response.checked = true
            }
          }
          )
        }
      })
      this.store.dispatch(saveDefaultTRFilters({ totalTRFiltersResponse: JSON.parse(JSON.stringify(getFilterResponseForOfftakers)), receivedTotalFiltersResponse: false, updatedOfftakers: true, receivedUpdatedFilterSelection: false }));
    }
  }

  clearFilter(): void {
    this.getDefaultDates();
    const isFromClearFilter = true;
    this.getListOfDesks(isFromClearFilter);
    this.dateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultToDate };
    this.nominationService.clearFilter.next(true);
    this.store.dispatch(enableClearFilter({ isClearFilter: true }));
  }

  getDefaultDates(): void {
    this.getTMinusSevenDays();
    this.getTPlusSixtyDays();
  }

  getDefaultCCADates(): void {
    const getCurrentDate: any = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
    const fromDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth(), 1);
    const toDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 5, 0);
    this.getCCAFromDateFromEvent = fromDate;
    this.getCCAToDateFromEvent = toDate;
    console.log(fromDate, toDate);
  }

  getTMinusSevenDays = () => {
    this.getDefaultCCADates();
    const getCurrentDate: any = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
    console.log(typeof getCurrentDate);
    const getCurrentDay = getCurrentDate.getDay();
    if (getCurrentDay === 0) {
      this.defaultFromDate = this.datePipe.transform(
        getCurrentDate.setDate(this.utilities.convertDatesToCurrentTZ(new Date().toISOString()).getDate() - 7),
        'yyyy-MM-dd'
      )!;
      this.getFromDateFromEvent = this.defaultFromDate;
    } else {
      const getDayDifference =
        getCurrentDate.getDate() -
        7 -
        getCurrentDay +
        (getCurrentDay === 0 ? -6 : 0);
      this.defaultFromDate = this.datePipe.transform(
        new Date(getCurrentDate.setDate(getDayDifference)),
        'yyyy-MM-dd'
      )!;
      this.getFromDateFromEvent = this.defaultFromDate;
      this.nominationService.defaultFromDate = this.defaultFromDate;
    }
    return this.getFromDateFromEvent;
  };

  getTPlusSixtyDays = () => {
    const getCurrentDate: any = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
    let getUpdatedDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 1, 0)
    if (
      getCurrentDate + 60 === getUpdatedDate
    ) {
      this.defaultToDate = this.datePipe.transform(
        getCurrentDate + 60,
        'yyyy-MM-dd'
      )!;
      this.getToDateFromEvent = this.defaultToDate;
    } else {
      const getMonthLastDay = new Date(
        getCurrentDate.getFullYear(),
        getCurrentDate.getMonth() + 3,
        0
      );
      this.defaultToDate = this.datePipe.transform(
        getMonthLastDay,
        'yyyy-MM-dd'
      )!;
      this.getToDateFromEvent = this.defaultToDate;
      this.nominationService.defaultToDate = this.defaultToDate;
    }
    return this.getToDateFromEvent;
  };

  getEthanolFilterResponseSplit(response: any, isOnlyTruckSelected: any, parentFilterChange: any) {
    if ((this.selectedMOTCount === 1 && isOnlyTruckSelected) || parentFilterChange) {
      this.handleModeOfTransportSingleOption(response.list, response.headerName);
    } else {
      this.handleMultipleTransport(response.list, response.headerName);
      response.list.forEach((listValues: any, index: any) => {
        if ((response.headerName?.toLowerCase() !== 'unit of measure' && response.headerName?.toLowerCase() !== 'terminal ownership') || (index === 0)) {
          listValues['checked'] = listValues['name'] === 'all' ? false : index <= 10;
        } else {
          listValues['checked'] = false;
        }
      })
    }
  }

  getEthanolFilterResponse(list: any, startDate: any, endDate: any, parentFilterChange?: any) {
    this.getEhtanolFilterResponse = list
    this.updateEhtanolFilterResponse = JSON.parse(JSON.stringify(list))
    this.updateEhtanolFilterResponse.forEach((response: any) => {
      let isOnlyTruckSelected;
      if (response.headerName && response.headerName?.toLowerCase() === "mode of transport") {
        let selectedElement = response.list.filter((ele: any) => ele?.checked)
        isOnlyTruckSelected = selectedElement[0]?.name === "truck"
      }
      this.getEthanolFilterResponseSplit(response, isOnlyTruckSelected, parentFilterChange);

      this.handleAlertUrlEthanol(response.list, response.headerName);
    })

    this.clearUrlParams();
    this.nominationService.restrictNomApiGetCall$.next(true);
    this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse)), ethanolFromDate: startDate, ethanolToDate: endDate, isfilterUpdated: true, ethanolTruckToggle: this.ethanolTruckUOMSwitch, unitOfMeasure: this.truck_unit_option }));
    this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse)), ethanolFromDate: startDate, ethanolToDate: endDate, isNomFilterUpdated: true }));
  }

  handleModeOfTransportSingleOption(responseList: any, headerName: string) {
    if (!(["terminal ownership", "mode of transport", "unit of measure"].includes(headerName))) {
      responseList.forEach((listValues: any) => {
        listValues['checked'] = true;
      })
    }
  }

  handleMultipleTransport(responseList: any, headerName: string) {
    responseList.forEach((listValues: any, index: any) => {
      if ((headerName?.toLowerCase() !== 'unit of measure' && headerName?.toLowerCase() !== 'terminal ownership') || (index === 0)) {
        listValues['checked'] = listValues['name'] === 'all' ? false : index <= 10;
      } else {
        listValues['checked'] = false;
      }
    })
  }

  handleAlertUrlEthanol(responseList: any, headerName: string): void {

    //when we are applying a "take me to issue" alert link, we only want to filter the one piece 
    if (this.isRouteParam && headerName === "terminal") {
      responseList.forEach((listValues: any) => {
        if (listValues.id == null) {
          listValues['checked'] = false;
          return
        }
        listValues['checked'] = listValues.id.toLowerCase() === this.filteredLocation.toLowerCase();
      })
    }
  }

  // Pipeline view
  getPipelineFilterResponse(list: any, startDate: any, endDate: any, parentFilterChange?: any, headerName?: any) {
    this.pipelineFilterResponse = list
    this.updatedPipelineFilterResponse = JSON.parse(JSON.stringify(list));
    this.pipelineFilterResponseForOtherThanCargoScreen(parentFilterChange, headerName)
    // To reduce the payload for Product>Terminal pivot view
    this.optimizePayload(startDate, endDate, parentFilterChange, headerName);
  }

  pipelineFilterResponseForCargoScreen(parentFilterChange: any, headerName: any) {
    if (parentFilterChange) {
      this.updatedPipelineFilterResponse.forEach((response: any) => {
        this.isCargoConditionalCheck(response, headerName);
      });
    } else {
      this.filterResponseCheck();
    }
  }

  isCargoConditionalCheck(response: any, headerName: any) {
    if ((headerName?.toLowerCase() === "mode of transport" && response.headerName?.toLowerCase() !== "mode of transport") ||
      (headerName?.toLowerCase() === "location" && response.headerName?.toLowerCase() === "product") || (headerName?.toLowerCase() === "transport system" &&
        response?.headerName?.toLowerCase() === "vessel/barge name")) {
      response.list.forEach((listValues: any, index: any) => {
        listValues['checked'] = this.checkedCargoFilters[response.headerName].find((ele: any) => ele.id === listValues?.id)?.checked || false;
        if (!listValues['checked']) {
          response.list[0].checked = false;
        }
      })
    }
  }

  pipelineFilterResponseForOtherThanCargoScreen(parentFilterChange: any, headerName: any) {
    if (parentFilterChange) {
      this.updatedPipelineFilterResponse.forEach((response: any) => {
        if ((headerName?.toLowerCase() === 'mode of transport' && response.headerName?.toLowerCase() !== 'mode of transport') ||
          (headerName?.toLowerCase() === 'transport system') ||
          (headerName?.toLowerCase() === 'terminal' && response.headerName?.toLowerCase() === 'product')) {
          response.list.forEach((listValues: any, index: any) => {

            if (response.headerName === 'mode of transport') {
              listValues['checked'] = this.checkedPipelineFilters[response.headerName]?.find((ele: any) => ele.name === listValues?.name)?.checked || false;
            } else {
              listValues['checked'] = this.checkedPipelineFilters[response.headerName]?.find((ele: any) => ele.id === listValues?.id)?.checked || false;
            }
            if (!listValues['checked']) {
              response.list[0].checked = false;
            }
          })
        }
      });
    } else {
      this.filterResponseCheck();
    }
  }

  filterResponseCheck() {

    let materialWasSelected = { value: false };
    let locationWasSelected = { value: false };
    this.updatedPipelineFilterResponse.forEach((response: any) => {
      response.list.forEach((listValues: any, index: any) => {
        listValues['checked'] = true;

        this.handleTerminalPipelineUrlFilterCheck(listValues, response.headerName, locationWasSelected);

        this.handleProductPipelineUrlFilterCheck(listValues, response.headerName, materialWasSelected);

      })
    })
    this.proivdeAlertRedirectToast(materialWasSelected, locationWasSelected);
  }

  handleTerminalPipelineUrlFilterCheck(filterListOptions: any, headerName: string, locationWasSelected: { value: boolean }): void {
    if (this.isRouteParam && headerName.includes("terminal")) {
      if (filterListOptions.id == null) {
        filterListOptions['checked'] = false;
        return
      }
      filterListOptions['checked'] = filterListOptions.id.toLowerCase() == this.filteredLocation.toLowerCase();

      if (filterListOptions['checked']) {
        locationWasSelected.value = true;
      }
    }
  }

  handleProductPipelineUrlFilterCheck(filterListOptions: any, headerName: string, materialWasSelected: { value: boolean }): void {
    if (this.isRouteParam && headerName.includes("product")) {

      if (filterListOptions.id == null) {
        filterListOptions['checked'] = false;
        return;
      }

      if (this.isSeasonalGroupedProduct) {
        filterListOptions['checked'] = ((filterListOptions.id.toLowerCase() == this.filteredProductGroupNumber?.toLowerCase())
          || (filterListOptions.name.toLowerCase() == this.filteredProduct?.toLowerCase()));

        if (filterListOptions['checked']) {

          materialWasSelected.value = true;
        }
      } else {

        filterListOptions['checked'] = ((filterListOptions.id.toLowerCase() == this.filteredProductNumber?.toLowerCase())
          || (filterListOptions.name.toLowerCase() == this.filteredProduct?.toLowerCase()));

        if (filterListOptions['checked']) {
          materialWasSelected.value = true;
        }
      }
    }
  }

  proivdeAlertRedirectToast(materialWasSelected: any, locationWasSelected: any) {
    if (this.isRouteParam && (!materialWasSelected.value || !locationWasSelected.value)) {

      !materialWasSelected.value ? this.toasterService.setToastNotification({ show: true, type: "error", msg: "- no material was found for given alert. Reverting to pipeline filter defaults" }) :
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "- no location was found for given alert. Reverting to pipeline filter defaults" });
      this.clearUrlParams();
      this.filterResponseCheck();
    }
  }


  getCheckedTerminalsForPayload() {
    this.storedPipelineFilterResponse?.some((val: any) => {
      if (val.headerName.toLowerCase() === 'product') {
        return val.list.find((listValues: any, index: any) => (index !== 1 && listValues.checked));
      }
    });
  }

  optimizePayload(startDate: any, endDate: any, parentFilterChange?: any, headerName?: any) {
    let findIfOtherTerminalsChecked = this.getCheckedTerminalsForPayload();
    const findOtherCheckedTerminals: any = [];
    if (this.storedPipelineFilterResponse) {
      for (let i in this.storedPipelineFilterResponse) {
        if (this.storedPipelineFilterResponse[i]?.headerName.toLowerCase() === 'product') {
          const checkedTerminals = this.storedPipelineFilterResponse[i]?.list.filter((listValues: any, index: any) => (index !== 1 && listValues.checked));
          findOtherCheckedTerminals.push(...checkedTerminals)
        }
      }
    }

    if (!this.isRouteParam) {
      this.resetPipelineFilterResponse(parentFilterChange, findOtherCheckedTerminals, headerName, findIfOtherTerminalsChecked);
      this.resetPipelineFilterTerminalResponse(parentFilterChange, findOtherCheckedTerminals, headerName, findIfOtherTerminalsChecked);

      this.resetPipelineFilterProductResponse(parentFilterChange, findOtherCheckedTerminals, headerName, findIfOtherTerminalsChecked);
    } else {
      let urlParams = {
        filteredDesk: '', filteredDeskId: '',
        filteredLocation: '', filteredProduct: '',
        filteredMaterialGroupNumber: '', filteredProductNumber: ''
      };
      setTimeout(() => { this.store.dispatch(updateURLParams({ updateURLParamsState: urlParams })); }, 500);
    }
    this.checkProductFromProductList();
    this.pipelineFilterResponse = this.updatedPipelineFilterResponse;
    if (!parentFilterChange) {
      this.updateGridData(startDate, endDate)
    } else {
      this.updateSearchData = JSON.parse(JSON.stringify(this.pipelineFilterResponse));
    }
    this.updatedPipelineFilterResponse.forEach((element: any, index: any) => {
      if (element.headerName === headerName) {
        this.updatePipelineFilterList(this.searchText[index], index, headerName)
      }
    });
  }

  checkProductFromProductList() {
    if ((['pascagouladesk', 'richmonddesk', 'pnwdesk', "floridadesk", "eccadesk", "wccadesk", "panamadesk"].includes(this.selectedDeskParameter)) && (this.currentScreen === "nominations" || this.currentScreen === "replenishmentplanning")) {
      this.updatedPipelineFilterResponse.forEach((list: any) => {
        if (list.headerName === 'product') {
          let isanySelected = list.list.some((product: any) => { return product.checked });
          if (!isanySelected && list.list.length) {
            list.list[1].checked = true;
          }
        }
      })
    }
  }

  updateGridData(startDate: any, endDate: any) {
    if (this.currentScreen === "replenishmentplanning") {
      this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: startDate, pipelineToDate: endDate, isPipelineFilterUpdated: true }));
    } else if (this.currentScreen === "nominations") {
      this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: startDate, pipelineToDate: endDate, isPipelineNomFilterUpdated: true }));
    } else if (this.currentScreen === "cargoplanning") {
      this.appStore.dispatch(saveCargoPlanCcaFiltersResponse({ cargoPlanCcaFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), cargoPlanCcaFromDate: startDate, cargoPlanCcaToDate: endDate, isCargoPlanCcaFilterUpdated: true }));
    }
  }

  resetPipelineFilterTerminalResponse(parentFilterChange: any, findOtherCheckedTerminals: any, headerName: any, findIfOtherTerminalsChecked: any) {
    if (!parentFilterChange) {
      this.updatedPipelineFilterResponse.forEach((response: any) => {
        //if (response?.headerName?.toLowerCase() === this.pipelineSwitchView?.toLowerCase() && !(this.pipelineSwitchView?.toLowerCase() === 'terminal' && headerName?.toLowerCase() === 'terminal') && !findIfOtherTerminalsChecked) {
        if (!findIfOtherTerminalsChecked) {
          if (response?.headerName?.toLowerCase() === 'terminal') {
            response.list.forEach((listValues: any, index: any) => {
              if (listValues == null) {
                return;
              }
              this.updateDefaultCheckbox(response, listValues, index)
            });
            this.processPipelineStoreActions('terminal', 0)
          }
        }
        else if (findIfOtherTerminalsChecked) {
          //else if (response?.headerName?.toLowerCase() === this.pipelineSwitchView?.toLowerCase() && !(this.pipelineSwitchView?.toLowerCase() === 'terminal' && headerName?.toLowerCase() === 'terminal') && findIfOtherTerminalsChecked) {
          response.list.forEach((listValues: any, index: any) => {
            this.resetListValues(index, findOtherCheckedTerminals, listValues, response, headerName);
          });
        }
      })
    }


  }

  resetPipelineFilterProductResponse(parentFilterChange: any, findOtherCheckedTerminals: any, headerName: any, findIfOtherTerminalsChecked: any) {
    if (!parentFilterChange) {
      this.updatedPipelineFilterResponse.forEach((response: any) => {
        if (response?.headerName?.toLowerCase() === 'product') {
          response.list.forEach((listValues: any, index: any) => {
            this.updateDefaultProductCheckbox(response, listValues, index);
          });
        }
        else if (findIfOtherTerminalsChecked) {
          //else if (response?.headerName?.toLowerCase() === this.pipelineSwitchView?.toLowerCase() && !(this.pipelineSwitchView?.toLowerCase() === 'terminal' && headerName?.toLowerCase() === 'terminal') && findIfOtherTerminalsChecked) {
          response.list.forEach((listValues: any, index: any) => {
            this.resetListValues(index, findOtherCheckedTerminals, listValues, response, headerName);
          });
        }
      })
    }

  }
  resetPipelineFilterResponse(parentFilterChange: any, findOtherCheckedTerminals: any, headerName: any, findIfOtherTerminalsChecked: any) {
    if (!parentFilterChange) {
      this.updatedPipelineFilterResponse.forEach((response: any) => {
        //if (response?.headerName?.toLowerCase() === this.pipelineSwitchView?.toLowerCase() && !(this.pipelineSwitchView?.toLowerCase() === 'terminal' && headerName?.toLowerCase() === 'terminal') && !findIfOtherTerminalsChecked) {
        if (!findIfOtherTerminalsChecked) {
          if (response?.headerName?.toLowerCase() === 'transport system') {
            response.list.forEach((listValues: any, index: any) => {
              if (listValues == null) {
                return;
              }
              this.updateDefaultCheckbox(response, listValues, index)
            });
            this.processPipelineStoreActions('transport system', 0)
          }
        }
        else if (findIfOtherTerminalsChecked) {
          //else if (response?.headerName?.toLowerCase() === this.pipelineSwitchView?.toLowerCase() && !(this.pipelineSwitchView?.toLowerCase() === 'terminal' && headerName?.toLowerCase() === 'terminal') && findIfOtherTerminalsChecked) {
          response.list.forEach((listValues: any, index: any) => {
            this.resetListValues(index, findOtherCheckedTerminals, listValues, response, headerName);
          });
        }

      });
    }
  }

  resetListValues(index: any, findOtherCheckedTerminals: any, listValues: any, response: any, headerName: any) {
    if (index === 1 || (findOtherCheckedTerminals.find((val: any) => val.name === listValues.name) && headerName)) {
      listValues['checked'] = true;
    } else {
      listValues['checked'] = false;
      response.list[0]['checked'] = false;
    }
  }

  updateDefaultCheckbox(response: any, listValues: any, index: any) {
    if (response?.headerName?.toLowerCase() === 'transport system') {
      this.transportChecked(listValues, response)
    }
    else if (response?.headerName?.toLowerCase() === 'terminal') {
      this.terminalChecked(listValues, response)
    }

  }
  updateDefaultProductCheckbox(response: any, listValues: any, index: any) {
    if (response?.headerName?.toLowerCase() === 'product' && this.pipelineSwitchView?.toLowerCase() === 'product') {
      if (listValues.id === '120245-10' && ['clean products + usec + texas desk', 'clean products + usec + florida desk', 'clean products + usec + colonial plantation desk',
        'clean products + uswc + el paso desk', 'clean products + uswc + pnw desk', 'clean products + uswc + salt lake city desk', 'clean products + usec + pascagoula desk',
        'clean products + uswc + richmond desk'].indexOf(this.selectedDesk) >= 0) {
        listValues['checked'] = true;
      } else if (listValues.id === '120240-10' && ['clean products + uswc + la desk', 'clean products + uswc + bay area desk'].indexOf(this.selectedDesk) >= 0) {
        listValues['checked'] = true;
        
      } else if(listValues.id === '380044990' && ['clean products + latam + ecca desk', 'clean products + latam + wcca desk', 'clean products + latam + all desk']){
        listValues['checked'] = true;
      }
      else if(listValues.id === '262926990' && [  'clean products + latam + panama desk']){
        listValues['checked'] = true;
      }
     
      else {
        listValues['checked'] = false;
      }
    }
  }

  transportChecked(listValues: any, res: any) {
    if (this.validateTransportCheckedForPipeline(listValues)) {
      listValues['checked'] = true;
    }
    else {
      this.updateListValuesTerminal(listValues, 'Transport System', res);
    }

  }

  validateTransportCheckedForPipeline(listValues: any) {
    return (listValues.id === 'EXPL' && ['clean products + usec + texas desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'EVPL1' && ['clean products + usec + florida desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'CP' && ['clean products + usec + colonial plantation desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'SP-1' && ['clean products + uswc + bay area desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'SDP' && ['clean products + uswc + la desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'SLN' && ['clean products + uswc + salt lake city desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'SPO' && ['clean products + uswc + pnw desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'SPE' && ['clean products + uswc + el paso desk'].indexOf(this.selectedDesk) >= 0)||
      (listValues.id === 'GT_MAR' && ['clean products + latam + ecca desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'OTSA_MAR' && ['clean products + latam + wcca desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'LMINAS_PPL' && ['clean products + latam + panama desk'].indexOf(this.selectedDesk) >= 0)
  
  }
  validateTerminalsCheckedForPipline(listValues: any) {
    return (listValues.id.toUpperCase() === 'GALENA T' && ['clean products + usec + texas desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'P EVERGL' && ['clean products + usec + florida desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'DORAVIL' && ['clean products + usec + colonial plantation desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'CHICO' && ['clean products + uswc + bay area desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'S DIEGO' && ['clean products + uswc + la desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'BOISE' && ['clean products + uswc + salt lake city desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'WILLBRG' && ['clean products + uswc + pnw desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id.toUpperCase() === 'PHNXKM' && ['clean products + uswc + el paso desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'GUATEMAR' && ['clean products + latam + ecca desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'OTSATRM' && ['clean products + latam + wcca desk'].indexOf(this.selectedDesk) >= 0) ||
      (listValues.id === 'LMINASCHEV' && ['clean products + latam + panama desk'].indexOf(this.selectedDesk) >= 0)

  }
  terminalChecked(listValues: any, res: any) {
    if (listValues.name !== 'all') {
      if (this.validateTerminalsCheckedForPipline(listValues)) {
        listValues['checked'] = true;
      }
      else {
        this.updateListValuesTerminal(listValues, 'Terminal', res);
      }
    }
    else {
      this.updateListValuesTerminal(listValues, 'Terminal', res);
    }
  }
  updateListValuesTerminal(listValues: any, header: any, res: any) {
    listValues['checked'] = false;
  }

  updateListValues(index: any, listValues: any) {
    if (index === 1) {
      listValues['checked'] = true;
    } else {
      listValues['checked'] = false;
    }
  }

  // Date range for truck

  ethanolDateRangegroup(event: any) {
    let getudatedDateRangeFlag = event.valid
    let getFromDateFromEvent = event.controls.start.value
    let getToDateFromEvent = event.controls.end.value
    this.nominationService.restrictLoader$.next(true);

    if (getudatedDateRangeFlag) {
      this.nominationService.restrictNomApiGetCall$.next(true);
      this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse)), ethanolFromDate: getFromDateFromEvent, ethanolToDate: getToDateFromEvent, isfilterUpdated: true, ethanolTruckToggle: this.ethanolTruckUOMSwitch, unitOfMeasure: this.truck_unit_option }));
      this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse)), ethanolFromDate: getFromDateFromEvent, ethanolToDate: getToDateFromEvent, isNomFilterUpdated: true }));

    }
  }

  ethanolTruckToggle(event: any, headerName: any, index: number) {
    this.ethanolService.motToggleSwitch$.next(event?.target?.checked);
    this.nominationService.restrictLoader$.next(true);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
    this.getUpdatedEthanolResponse(event.target.checked, headerName, index, true)
  }

  getUpdatedEthanolResponse(event: any, headerName: any, index: number, fromTruckToggle?: boolean) {
    this.updateEhtanolFilterResponse.forEach((response: any) => {
      if (response.headerName?.toLowerCase() === headerName?.toLowerCase() && (headerName?.toLowerCase() === 'unit of measure' || headerName?.toLowerCase() === 'terminal ownership')) {
        if (fromTruckToggle) {
          response.truck = event
        } else {
          response.list.forEach((value: any) => {
            if (value.name?.toLowerCase() === event?.toLowerCase()) {
              value.checked = true
            } else {
              value.checked = false
            } 

          })
        }
      }
      this.processDOMForHeaderName(response, headerName, event);
    });

    this.processStoreActions(headerName, index);
  }

  locationCheckedUnCheckedResponse(event: any, headerName: any) {
    this.updateEhtanolFilterResponse.forEach((response: any) => {
      if (response.headerName?.toLowerCase() === headerName?.toLowerCase() && this.currentScreen === TRScreen.ReplenishmentPlanning){
        let isBoisewpt =  event.target.value?.toUpperCase() == Location.BOISEWPT
        let getBoiseValue = response.list.findIndex((element: any) => element.id?.toUpperCase() === Location.BOISE)
        if (getBoiseValue !== -1 && isBoisewpt) {
          response.list[getBoiseValue].checked = event.target.checked;
        }  
        }
      });
  }

  processDOMForHeaderName(response: any, headerName: any, event: any) {
    if (response.headerName?.toLowerCase() === headerName?.toLowerCase() && (headerName?.toLowerCase() !== 'unit of measure' && headerName?.toLowerCase() !== 'terminal ownership')) {
      response.list.forEach((value: any) => {
        if (value.name?.toLowerCase() === (event?.toLowerCase())) {
          value.checked = !value.checked
        }
        if (event === 'all') {
          value.checked = response.list[0].checked
        }
      })

      let isCheckedTrue = response.list.filter((value: any) => value.name !== 'all').every((element: any) => element.checked === true)
      let getValueAll = response.list.findIndex((element: any) => element.name?.toLowerCase() === 'all')
      if (getValueAll !== -1) {
        response.list[getValueAll].checked = isCheckedTrue
      }
    }
    if (response.headerName?.toLowerCase() === "mode of transport") {
      this.selectedMOTCount = response.list.filter((ele: any) => ele.checked).length
    }
  }

  processStoreActions(headerName: any, index: any) {
    if (this.selectedMOTCount > 1) {
      this.store.dispatch(setSwitchView({ switchView: 'terminal_supplier_nomination_carrier', isSwitchUpdated: false }));
      this.switchView = 'terminal'
    }
    if (headerName?.toLowerCase() === 'terminal ownership') {
      this.getEthanolFilterList(true);
    } else {
      this.getEhtanolFilterResponse  = this.updateEhtanolFilterResponse;
      this.updateFilterList(this.searchText[index], index);
    }
  }

  getCheckLength(list: any) {
    return list.filter((ele: any) => ele.checked).length
  }

  updateFilterListForView(filterList: any, supplier: any, carrier: any) {
    if (this.switchView === 'supplier') {
      filterList.unshift({ headerName: "supplier", list: supplier.length > 0 ? [{ name: "all", checked: true }, ...supplier] : [] })
    } else if (this.switchView === 'carrier') {
      filterList.unshift({ headerName: "carrier", list: carrier.length > 0 ? [{ name: "all", checked: true }, ...carrier] : [] })
    } else {    
      filterList.push({ headerName: "supplier", list: supplier.length > 0 ? [{ name: "all", checked: true }, ...supplier] : [] })
      filterList.push({ headerName: "carrier", list: carrier.length > 0 ? [{ name: "all", checked: true }, ...carrier] : [] })
    }
    return filterList;
  }

  ethanolFilterData(event: any, headerName: any, index: number) {
    this.nominationService.restrictLoader$.next(true);  
    this.getUpdatedEthanolResponse(event.target.value, headerName, index);
    this.locationCheckedUnCheckedResponse(event,headerName);
  }

  ehtanolClearFilter() {

    this.updateEhtanolFilterResponse?.forEach((response: any) => {
      response?.list?.forEach((listValues: any, index: any) => {
        if ((response?.headerName?.toLowerCase() !== 'unit of measure' && response?.headerName?.toLowerCase() !== 'terminal ownership') || index === 0) {
          if (response?.headerName?.toLowerCase() === 'unit of measure') {
            response.truck = false
          }
          listValues['checked'] = listValues['name'] === 'all' ? false : index <= 10;
        } else {
          listValues['checked'] = false;
        }
      })
    })
    this.selectedMOTCount = 4;
    this.ethanolDateRangeData = { fromDate: this.defaultFromDate, toDate: this.defaultToDate };
    this.searchText = []
    this.store.dispatch(setSwitchView({ switchView: 'terminal_supplier_nomination_carrier', isSwitchUpdated: false }));
    this.switchView = 'terminal'
    this.getEthanolFilterList();
    this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
    this.nominationService.motCheck$.next(false);
    this.homeComponent.collpaseMode$.next(true);
    this.homeComponent.expandedMode$.next(false);
    this.homeComponent.cpNominations$.next(true);
    this.homeComponent.cpCargoPlanning$.next(false);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.resetToDefaultView$.next(true);
    localStorage.removeItem("selectedView");
    localStorage.removeItem("nomColData");
    localStorage.removeItem("trColExpandData");
    localStorage.removeItem("trColOrder");
  }

  ehtanolApplyFilter(){
    this.nominationService.restrictNomApiGetCall$.next(true);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);  
    
    this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse)), ethanolFromDate: this.ethanolDateRangeData.fromDate, ethanolToDate: this.ethanolDateRangeData.toDate, isfilterUpdated: true, ethanolTruckToggle: this.ethanolTruckUOMSwitch, unitOfMeasure: this.truck_unit_option }));
    this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.updateEhtanolFilterResponse)), ethanolFromDate: this.ethanolDateRangeData.fromDate, ethanolToDate: this.ethanolDateRangeData.toDate, isNomFilterUpdated: true }));
  }

  pipelineClearFilter() {
    let resetFromData!: any, resetToDate!: any;
    const getCurrentDate: any = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
    let getUpdatedDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 1, 0)
    const getCurrentDay = getCurrentDate.getDay();
    if (getCurrentDay === 0) {
      resetFromData = this.datePipe.transform(
        getCurrentDate.setDate(this.utilities.convertDatesToCurrentTZ(new Date().toISOString()).getDate() - 7),
        'yyyy-MM-dd'
      )!;
    } else {
      const getDayDifference =
        getCurrentDate.getDate() -
        7 -
        getCurrentDay +
        (getCurrentDay === 0 ? -6 : 0);
      resetFromData = this.datePipe.transform(
        new Date(getCurrentDate.setDate(getDayDifference)),
        'yyyy-MM-dd'
      )!;
    }
    if (
      getCurrentDate + 60 === getUpdatedDate
    ) {
      resetToDate = this.datePipe.transform(
        getCurrentDate + 60,
        'yyyy-MM-dd'
      )!;
    } else {
      const getMonthLastDay = new Date(
        getCurrentDate.getFullYear(),
        getCurrentDate.getMonth() + 3,
        0
      );
      resetToDate = this.datePipe.transform(
        getMonthLastDay,
        'yyyy-MM-dd'
      )!;
    }
    this.pipelineDateRangeData = { fromDate: resetFromData, toDate: resetToDate };
    this.searchText = [];
    this.storedPipelineFilterResponse = null;
    this.getPipelineFilters(false);
  }

  applyPipelineFilter() {
    this.nominationService.restrictLoader$.next(true);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
    this.searchText = [];
    if (this.currentScreen === "replenishmentplanning") {   
      this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineFilterUpdated: true }));
    } else if (this.currentScreen === "nominations") {
      this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineNomFilterUpdated: true }));
    }
  }

  getCheckedIds(element: any) {
    const list = element.list.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.id);
    return list;
  }

  getCheckedNames(element: any) {
    const list = element.list.filter((ele: any) => ele.name !== "all" && ele.checked).map((item: any) => item.name);
    return list;
  }

  formPipelineMasterDataPayload(headerName: any, parentFilterChange: any, selectedModeOfTransport: any, transportSystem: any, slectedTerminal: any) {
    let payload!: any
    this.cpService.isCargoScreen = false;
    payload = this.getUpdatedPipelinePayload(headerName, parentFilterChange, selectedModeOfTransport, transportSystem, slectedTerminal)
    return payload;
  }

  async getPipelineFilters(parentFilterChange?: any, headerName?: any) {

    const { transportSystem, slectedTerminal, selectedModeOfTransport } = this.generateValuesFromExistingFilterValues()

    let urBody = this.formPipelineMasterDataPayload(headerName, parentFilterChange, selectedModeOfTransport, transportSystem, slectedTerminal);
    if (urBody?.desk !== "pl") {
      let response: any;
      let isTrackInv = this.currentScreen !== 'nominations';
      let payload = []; 
      if (isTrackInv) {
        payload.push({
          "field": "trackInv",
          "operator": "eq",
          "value": isTrackInv
        })
      }
        if(this.selectedDesk === "clean products + latam + panama desk" || this.selectedDesk === "clean products + latam + ecca desk" || this.selectedDesk === "clean products + latam + wcca desk"){
          response = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId))
        }
        else if(!(this.selectedDeskId === 25 ||  this.selectedDeskId === '25')){
          response = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId, payload))      
        }
      let res = response.value.value[0]
      this.globalAggregatorResponse = res;
      this.utilities.globalPipelineAggregatorResponse = res
      if (this.currentScreen === "replenishmentplanning") {
        if(this.selectedDeskId !== '25' && (+this.utilities.globalPipelineAggregatorResponseForDropdown?.deskId) !== (+this.selectedDeskId)){
        let dropdownList = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId))
        this.utilities.globalPipelineAggregatorResponseForDropdown = dropdownList?.value.value[0]

        }
      } else {
        this.utilities.globalPipelineAggregatorResponseForDropdown = res;
      }
      let {filterObj, copyOfFilterObj} = this.utilities.getRequiredPipelineFilterJsonStructure(res, true, this.currentScreen === "replenishmentplanning")      

      this.masterPipelineAggregatorResponse = filterObj;
      response.value = filterObj;
      this.getPipelineFilterSplit(response, headerName, parentFilterChange, copyOfFilterObj);
    }
  }

  getPipelineFilterSplit(response: any, headerName: any, parentFilterChange: any, responseCopy?: any) {
    if (response.value) {
      if (!localStorage.getItem("selectedView") || this.pipelineFilterCheckboxClicked) {
        this.pipelineFilterCheckboxClicked = false;
        const params = this.getParamsFromPipelineFiltersApi(response, headerName, parentFilterChange);
        this.transformPipelineFilterList(params);
      } else {
        if (this.currentScreen === "replenishmentplanning") { //NOSONAR lightweight logging
          this.updateSearchData = this?.replenishmentService?.rpSetDefaultCustomViewFilterDetails?.pipelineFilterValues;
          this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: JSON.parse(JSON.stringify(this.updateSearchData)), pipelineFromDate: this?.replenishmentService?.rpSetDefaultCustomViewFilterDetails?.pipelineFromDate, pipelineToDate: this?.replenishmentService?.rpSetDefaultCustomViewFilterDetails?.pipelineToDate, isPipelineFilterUpdated: true }));
        } else if (this.currentScreen === "nominations") {
          this.updateSearchData = this.nominationService?.nomDefaultSetCustomView?.pipelineFilterValues;
          this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: this.nominationService?.nomDefaultSetCustomView?.pipelineFromDate, pipelineToDate: this.nominationService?.nomDefaultSetCustomView?.pipelineToDate, isPipelineNomFilterUpdated: true }));
        }
      }
      this.store.dispatch(setPipelineFiltersResponse({ value: responseCopy, isUpdated: true }));


    }
    else {
      this.updateSearchData = [];
    }
  }

  getParamsFromPipelineFiltersApi(response: any, headerName: any, parentFilterChange: any) {
    let terminal = response?.value?.terminal?.map((terminal: any) => { return { name: terminal?.terminalName, id: terminal?.terminalCode } }) ?? []
    let product = response?.value?.product?.map((prod: any) => { return { name: prod?.productName, id: prod?.productCode } }) ?? []
    let transportsystem = response?.value?.transportsystem?.map((ts: any) => { return { name: ts?.transportSystemName, id: ts?.transportSystemCode } }) ?? []
    let startDate = this.datePipe?.transform((this.pipelineDateRangeData?.fromDate ? this.pipelineDateRangeData?.fromDate : this.defaultFromDate), 'yyyy-MM-dd')
    let endDate = this.datePipe?.transform(this.pipelineDateRangeData?.toDate ? this.pipelineDateRangeData?.toDate : this.defaultToDate, 'yyyy-MM-dd')
    let modeOfTransport = response?.value?.modeoftransport?.map((mot: any) => { return { name: mot?.modeOfTransportDesc, id: mot?.modeOfTransportCode } }) ?? [];
    return { parentFilterChange, terminal, product, transportsystem, startDate, endDate, headerName, modeOfTransport };
  }

  generateValuesFromExistingFilterValues() {
    let transportSystem: any = []
    let slectedTerminal: any = [];
    let selectedModeOfTransport: any;
    this.updatedPipelineFilterResponse?.forEach((element: any) => {
      if (element.headerName === 'transport system') {
        transportSystem = this.getCheckedIds(element)
      } else if (element.headerName === 'terminal' || element.headerName === 'location') {
        slectedTerminal = this.getCheckedIds(element)
      } else if (element?.headerName === "mode of transport") {
        selectedModeOfTransport = this.getCheckedNames(element);
      }
    });
    return { transportSystem, slectedTerminal, selectedModeOfTransport };
  }

  async getSelectedList(name: any) {
    let selectedTransportSystem: any = []
    let newlist: any = [];
    this.updatedPipelineFilterResponse?.forEach((element: any) => {
      if (element.headerName === name || (element.headerName === "location" && name === "terminal")) {
        if (element.headerName === "mode of transport") {
          selectedTransportSystem = this.getCheckedNames(element)
        } else {
          selectedTransportSystem = this.getCheckedIds(element)
        }
      }
    });
    let listName = name;
    newlist = await this.getCheckedFilterList(listName, selectedTransportSystem, newlist)

    return newlist.length > 0 ? newlist : selectedTransportSystem;
  }

  getTheSelectedTerminaLists(list: any, listName: any, selectedTransportSystem: any, newlist: any) {
    list.forEach((element: any) => {
      const condition = (selectedTransportSystem.includes(element.id) || (element.checked && element.name !== 'all'));
      if (condition && listName !== "mode of transport") {
        newlist.push(element.id)
      } else if (condition && listName === "mode of transport") {
        newlist.push(element.name);
      }
    });
  }

  getCheckedFilterList(listName: any, selectedTransportSystem: any, newlist: any) {
    if (this.currentScreen === "replenishmentplanning") {
      let currentRPTerminalList = JSON.parse(JSON.stringify(this.pipelineRPFilterListState.filter((ele: any) => ele.headerName?.toLowerCase() === listName?.toLowerCase())[0]?.list ?? []));
      this.getTheSelectedTerminaLists(currentRPTerminalList, listName, selectedTransportSystem, newlist)
    } else if (this.currentScreen === "nominations") {
      let currentNomTerminalList = JSON.parse(JSON.stringify(this.pipelineNomFilterListState.filter((ele: any) => ele.headerName?.toLowerCase() === listName?.toLowerCase())[0]?.list ?? []));
      this.getTheSelectedTerminaLists(currentNomTerminalList, listName, selectedTransportSystem, newlist)

    }
    return newlist;
  }

  getLatestPipelineFiltersParameter(parameter: any) {
    if (parameter?.length > 0) {
      return parameter;
    } else {
      return undefined;
    }
  }

  async getLatestPipelineFilters(headerName?: any, parentFilterChange?: any, transportSystem?: any, slectedTerminal?: any, screenName?: any) {
    let selectedTransportSystem: any = await this.getSelectedList('transport system');
    let selectedTerminal: any = await this.getSelectedList('terminal');
    let selectedMOT: any = await this.getSelectedList('mode of transport');
    let isCargoDesks = this.selectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.selectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.selectedDesk === UDMDesk.PipelineUswcPNWDesk || this.selectedDesk === UDMDesk.PipelineUsecRichmondDesk;
    let urBody = this.getUpdatedPipelinePayload(headerName, parentFilterChange, selectedMOT, selectedTransportSystem, slectedTerminal, screenName);
    urBody.modeOfTransport = this.getLatestPipelineFiltersParameter(selectedMOT);
    urBody.transportsystem = this.getLatestPipelineFiltersParameter(selectedTransportSystem);
    urBody.terminal = this.getLatestPipelineFiltersParameter(selectedTerminal);
    if (urBody.desk !== "pl" && urBody.desk !== "plundefined" && urBody.desk !== "plnull" && !!this.selectedDeskParameter) {
      let isCargoDesks = this.selectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.selectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.selectedDesk === UDMDesk.PipelineUswcPNWDesk || this.selectedDesk === UDMDesk.PipelineUsecRichmondDesk;
      let {response, responseCopy} = await this.getPipelineFilterResponseData(isCargoDesks, urBody);
      let newTerminalFilterList = this.getUpdatedPipelineFilterList(response, 'terminal', 'transportSystem')
      let newProductFilterList = this.getUpdatedPipelineFilterList(response, 'product', 'terminalCode')
      let newTransportSystemFilterList = this.getUpdatedPipelineFilterList(response, 'transport system')
      let newModeOfTransportSystemFilterList = this.getUpdatedPipelineFilterList(response, 'modeOfTransport')
      let latestPipelineFilterList = this.getLatestPipelineFilterList(newTerminalFilterList, newProductFilterList, newTransportSystemFilterList, newModeOfTransportSystemFilterList)

      this.updatedPipelineFilterResponse = JSON.parse(JSON.stringify(latestPipelineFilterList))
      if (this.currentScreen === "replenishmentplanning") {
        this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineFilterUpdated: true }));
      } else if (this.currentScreen === "nominations") {
        this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: this.pipelineDateRangeData.fromDate, pipelineToDate: this.pipelineDateRangeData.toDate, isPipelineNomFilterUpdated: true }));
      } else if (this.currentScreen === "cargoplanning") {
        this.appStore.dispatch(saveCargoPlanCcaFiltersResponse({ cargoPlanCcaFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), cargoPlanCcaFromDate: this.pipelineDateRangeData.fromDate, cargoPlanCcaToDate: this.pipelineDateRangeData.toDate, isCargoPlanCcaFilterUpdated: true }));
      }
      
      this.store.dispatch(setPipelineFiltersResponse({ value: responseCopy.value, isUpdated: true }));
    }
  }

  async getPipelineFilterResponseData(isCargoDesks: any, urBody: any) {
    let response, responseCopy;
    let isTrackInv = this.currentScreen !== 'nominations';
    let payload = [];
    if (isTrackInv) {
      payload.push({
        "field": "trackInv",
        "operator": "eq",
        "value": isTrackInv
      })
    }
    this.selectedDeskId = localStorage.getItem("deskId");
    if(+this.selectedDeskId!==25){
      let mdmRes
      if(this.selectedDesk === "clean products + latam + panama desk" || this.selectedDesk === "clean products + latam + ecca desk" || this.selectedDesk === "clean products + latam + wcca desk"){
        mdmRes = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId));
      }
      else{
       mdmRes = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId, payload));
      }
      let res = mdmRes.value.value[0];
      this.globalAggregatorResponse = res;
      this.utilities.globalPipelineAggregatorResponse = res;
    if (this.currentScreen === "replenishmentplanning") {
      let dropdownList = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId));
      this.utilities.globalPipelineAggregatorResponseForDropdown = dropdownList?.value.value[0];
    } else {
      this.utilities.globalPipelineAggregatorResponseForDropdown = res;
    }
    }
    
    
    const {filterObj, copyOfFilterObj} = this.utilities.getRequiredPipelineFilterJsonStructure(this.globalAggregatorResponse, true, this.currentScreen === "replenishmentplanning")
    response = { value: filterObj }
    responseCopy = {value: copyOfFilterObj}
    this.masterPipelineAggregatorResponse = response.value;
    return {response, responseCopy};
  }

  getLatestPipelineFilterList(newTerminalFilterList: any, newProductFilterList: any, newTransportSystemFilterList: any, newModeOfTransportSystemFilterList: any) {
    let getSelectedTransportSystem = this.getCheckedIds({ list: newTransportSystemFilterList })
    let getSelectedTerminal = this.getCheckedIds({ list: newTerminalFilterList })
    let latestPipelineFilterList = JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse));
    latestPipelineFilterList.forEach((ele: any) => {
      this.getElementList(ele, newTerminalFilterList, newProductFilterList, newTransportSystemFilterList, newModeOfTransportSystemFilterList, getSelectedTransportSystem, getSelectedTerminal);
    });
    return latestPipelineFilterList
  }

  getElementList(ele: any, newTerminalFilterList: any, newProductFilterList: any, newTransportSystemFilterList: any, newModeOfTransportSystemFilterList: any, getSelectedTransportSystem: any, getSelectedTerminal: any) {
    if (ele.headerName?.toLowerCase() === 'terminal') {
      let newUpdatedTerminalFilterList = newTerminalFilterList.filter((element: any) => {
        return (this.utilities.findCommonElements(element.transportSystem || [], getSelectedTransportSystem) || element.name === 'all')
      })
      ele.list = newUpdatedTerminalFilterList
      getSelectedTerminal = this.getCheckedIds({ list: newUpdatedTerminalFilterList })

    } else if (ele.headerName?.toLowerCase() === 'product') {
      let newUpdatedProductFilterList = newProductFilterList.filter((element: any) => {
        return (this.utilities.findCommonElements(element.terminalCode || [], getSelectedTerminal) || element.name === 'all')
      })
      ele.list = newUpdatedProductFilterList
    } else if (ele.headerName?.toLowerCase() === 'transport system') {
      ele.list = newTransportSystemFilterList
    } else if (ele.headerName?.toLowerCase() === 'mode of transport') {
      ele.list = newModeOfTransportSystemFilterList
    }
  }

  getUpdatedPipelineFilterList(response: any, listName: any, parentFilterList?: any) {
    let currentTerminalList = this.pipelineFilterResponse.filter((ele: any) => ele.headerName?.toLowerCase() === listName?.toLowerCase())[0]?.list ?? [];
    let currentRPTerminalList = this.pipelineRPFilterListState.filter((ele: any) => ele.headerName?.toLowerCase() === listName?.toLowerCase())[0]?.list ?? [];
    let currentNomTerminalList = this.pipelineNomFilterListState.filter((ele: any) => ele.headerName?.toLowerCase() === listName?.toLowerCase())[0]?.list ?? [];

    let newTerminalFilterList: any = [];
    let filterHeaderName = listName === 'transport system' ? 'transportSystem' : listName;
    response.value[listName === 'transport system' ? 'transportsystem' : listName?.toLowerCase()]?.forEach((element: any) => {
      let isElementPresent = currentTerminalList.findIndex((el: any) => el?.id?.toLowerCase() === element?.[`${filterHeaderName}Code`]?.toLowerCase());
      let isElementPresentInRPFilter = currentRPTerminalList.findIndex((el: any) => el?.id?.toLowerCase() === element?.[`${filterHeaderName}Code`]?.toLowerCase())
      let isElementPresentInNomFilter = currentNomTerminalList.findIndex((el: any) => el?.id?.toLowerCase() === element?.[`${filterHeaderName}Code`]?.toLowerCase())
      if (isElementPresent > -1) {
        let filterEle: any = currentTerminalList[isElementPresent]
        if (element[parentFilterList]) {
          filterEle = { ...filterEle, [parentFilterList]: element[parentFilterList] }
        }
        newTerminalFilterList.push(filterEle)
      } else if (this.currentScreen === "replenishmentplanning" && isElementPresentInRPFilter > -1) {
        let filterEle: any = currentRPTerminalList[isElementPresentInRPFilter]
        if (element[parentFilterList]) {
          filterEle = { ...filterEle, [parentFilterList]: element[parentFilterList] }
        }
        newTerminalFilterList.push(filterEle)
      } else if (this.currentScreen === "nominations" && isElementPresentInNomFilter > -1) {
        let filterEle: any = currentNomTerminalList[isElementPresentInNomFilter]
        if (element[parentFilterList]) {
          filterEle = { ...filterEle, [parentFilterList]: element[parentFilterList] }
        }
        newTerminalFilterList.push(filterEle)
      } else if (filterHeaderName === "modeOfTransport") {
        newTerminalFilterList.push({ name: element?.[`${filterHeaderName}Desc`], id: element?.[`${filterHeaderName}Code`], checked: true })
      } else {
        newTerminalFilterList.push({ name: element?.[`${filterHeaderName}Name`], id: element?.[`${filterHeaderName}Code`], checked: false, [parentFilterList]: element[parentFilterList] })
      }
    });
    let allIndexNum = newTerminalFilterList.findIndex((ele: any) => ele?.name?.toLowerCase() === 'all')
    let isAnyUncheckedElePresent = newTerminalFilterList.findIndex((ele: any) => ele?.checked === false)
    if (allIndexNum === -1) {
      newTerminalFilterList.unshift({ name: 'all', checked: isAnyUncheckedElePresent === -1 })
    }
    return newTerminalFilterList
  }
  getUpdatedPipelinePayload(headerName: any, parentFilterChange: any, modeOfTransport: any, transportSystem: any, slectedTerminal: any, screenName?: any) {
    let urBody: any = {
      desk: `pl${this.selectedDeskParameter}`,
      region: this.region,
      includeXLocations: this.currentScreen === "nominations" || screenName === "nominations",
      isCargoType: this.selectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.selectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.selectedDesk === UDMDesk.PipelineUswcPNWDesk || this.selectedDesk === UDMDesk.PipelineUsecRichmondDesk ? true : undefined,
      isPipelineType: this.selectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.selectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.selectedDesk === UDMDesk.PipelineUswcPNWDesk || this.selectedDesk === UDMDesk.PipelineUsecRichmondDesk ? true : undefined,
    }
    this.addingPayloadBasedOnFilterChecked(urBody, headerName, parentFilterChange, modeOfTransport, transportSystem, slectedTerminal);
    return urBody
  }

  addingPayloadBasedOnFilterChecked(urBody: any, headerName: any, parentFilterChange: any, modeOfTransport: any, transportSystem: any, slectedTerminal: any) {
    if (headerName === "mode of transport") {
      urBody.modeOfTransport = parentFilterChange ? modeOfTransport : undefined;
    } else if (headerName?.toLowerCase() === 'transport system') {
      urBody.modeOfTransport = parentFilterChange ? modeOfTransport : undefined;
      urBody.transportsystem = parentFilterChange ? transportSystem : undefined
      urBody.terminal = parentFilterChange && slectedTerminal.length > 0 ? slectedTerminal : undefined
    } else if (headerName?.toLowerCase() === 'terminal') {
      this.addingPayloadBasedOnTerminalFilterChecked(urBody, parentFilterChange, modeOfTransport, transportSystem, slectedTerminal)
    }
  }

  addingPayloadBasedOnTerminalFilterChecked(urBody: any, parentFilterChange: any, modeOfTransport: any, transportSystem: any, slectedTerminal: any) {
    urBody.modeOfTransport = parentFilterChange ? modeOfTransport : undefined;
    urBody.transportsystem = parentFilterChange ? transportSystem : undefined
    urBody.terminal = parentFilterChange ? slectedTerminal : undefined
  }

  getUpdatedCargoFilterPayload(headerName: any, parentFilterChange: any, modeOfTransport: any, transportSystem: any, selectedTerminal: any) {
    let urBody: any = {
      desk: `pl${this.selectedDeskParameter}`,
      region: this.region,
      isCargoType: true,
      includeXLocations: true,
      requestFor: "dropdown"
    }
    if (headerName === "mode of transport") {
      urBody.modeOfTransport = parentFilterChange ? modeOfTransport : undefined;
    } else if (headerName === "terminal" || headerName === "location") {
      urBody.modeOfTransport = parentFilterChange ? modeOfTransport : undefined;
      urBody.terminal = parentFilterChange ? selectedTerminal : undefined;
    } else if (headerName === "transport system") {
      urBody.modeOfTransport = parentFilterChange ? modeOfTransport : undefined;
      urBody.transportsystem = parentFilterChange ? transportSystem : undefined
    }
    return urBody;
  }

  enablePipelineFilters() {
    this.isPipelineFiltersEnabled = this.selectedDesk?.includes("clean products");
  }

  pipelineFilterData(event: any, headerName: any, index: number, data : any) {
    this.pipelineFilterCheckboxClicked = true;
    this.nominationService.restrictLoader$.next(true);
    const filterValue = event.target.value;
    const isChecked = event?.target?.checked;
    this.updatedPipelineFilterResponse?.forEach((response: any) => {
      if (response.headerName?.toLowerCase() === headerName?.toLowerCase()) {
        response.list.forEach((value: any) => {
          let filterId = (value?.name?.toLowerCase()=='barge') ? 'b' : value?.id?.toLowerCase() ?? value?.name?.toLowerCase()
          if (filterId === filterValue?.toLowerCase()) {
            value.checked = isChecked;
          }
        })
      }
      this.processDOMForPipelineHeaderName(response, headerName, event);
    })
    this.processPipelineStoreActions(headerName, index);
  }

  processDOMForPipelineHeaderName(response: any, headerName: any, event: any) {
    const isChecked = event?.target?.checked;
    const filterValue = event.target.value;
    if (response.headerName?.toLowerCase() === headerName?.toLowerCase()) {
      response.list.forEach((value: any) => {
        if (filterValue === 'all') {
          value.checked = isChecked;
        }
      })

      let isCheckedTrue = response.list.filter((value: any) => value.name !== 'all').every((element: any) => element.checked === true)
      let getValueAll = response.list.findIndex((element: any) => element.name?.toLowerCase() === 'all')
      if (getValueAll !== -1) {
        response.list[getValueAll].checked = isCheckedTrue
      }
    }

  }

  processPipelineStoreActions(headerName: any, index: any) {
    if (headerName?.toLowerCase() === 'mode of transport' || headerName?.toLowerCase() === 'transport system' || headerName?.toLowerCase() === 'terminal') {
      let res = this.updatePipelineFilterSelection(headerName)
      let response = { value: res }
      this.reFormationPipelinefilterJson(response, true, headerName);
    } else if (headerName?.toLowerCase() === 'product') {
      this.pipelineFilterResponse = this.updatedPipelineFilterResponse
      this.updatePipelineFilterList(this.searchText[index], index, headerName);
    } else {
      this.updatePipelineFilterList(this.searchText[index], index, headerName);
    }
  }

  reFormationPipelinefilterJson(response: any, parentFilterChange: any, headerName: any) {
    let terminal = response?.value?.terminal?.map((terminal: any) => { return { name: terminal?.terminalName ?? terminal?.name, id: terminal?.terminalCode ?? terminal?.id } })
    let product = response?.value?.product?.map((prod: any) => { return { name: prod?.productName, id: prod?.productCode } })
    let transportsystem = response?.value?.transportsystem?.map((ts: any) => { return { name: ts?.transportSystemName ?? ts?.name, id: ts?.transportSystemCode ?? ts?.id } })
    let startDate = this.datePipe?.transform((this.ethanolDateRangeData?.fromDate ? this.ethanolDateRangeData?.fromDate : this.defaultFromDate), 'yyyy-MM-dd')
    let endDate = this.datePipe?.transform(this.ethanolDateRangeData?.toDate ? this.ethanolDateRangeData?.toDate : this.defaultToDate, 'yyyy-MM-dd')
    let modeOfTransport = response?.value?.modeoftransport?.map((mot: any) => { return { name: mot?.modeOfTransportDesc ?? mot?.name, id: mot?.modeOfTransportCode ?? mot?.id } });
    let vesselNames = response?.value?.vesselsname?.map((vessel: any) => { return { name: vessel?.vesselName, id: vessel?.vesselId } })
    const params = { parentFilterChange, terminal, product, transportsystem, startDate, endDate, headerName, modeOfTransport, vesselNames };
    this.transformPipelineFilterList(params);
    if (this.selectedDeskParameter !== "wccadesk" && this.selectedDeskParameter !== "eccadesk" && this.selectedDeskParameter !== "panamadesk") {
      this.store.dispatch(setPipelineFiltersResponse({ value: response.value, isUpdated: true }));
    }
  }

  processPipelineStoreActionsForCargoScreen(headerName: any, index: any) {
    if (headerName?.toLowerCase() === 'mode of transport' || headerName?.toLowerCase() === "transport system" || headerName?.toLowerCase() === "location") {
      this.getPipelineFilters(true, headerName);
    } else if (headerName?.toLowerCase() === "product" || headerName?.toLowerCase() === "vessel/barge name") {
      this.pipelineFilterResponse = this.updatedPipelineFilterResponse;
      this.updatePipelineFilterList(this.searchText[index], index, headerName);
    }
  }

  updatePipelineFilterList(event: any, index: any, header: any) {
    this.searchText[index] = event?.toLowerCase();

    let headerNameAttribute = header === ('product') || header === ("mode of transport") ? 'name' : 'id'

    this.searchText.forEach((value: any, i: any) => {
      if (value) {
        let updatedFilterList = [...this.pipelineFilterResponse[i].list.filter((ele: any) => ele[headerNameAttribute]?.toLowerCase().includes(value?.toLowerCase()) || ele.name?.toLowerCase() === 'all')];
        this.updateSearchData[i].list = [...updatedFilterList]
      } else {
        this.updateSearchData[i].list = [...this.pipelineFilterResponse[i].list]
      }
    })
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
  }

  // Date range for pipeline
  pipelineDateRangegroup(event: any) {
    this.nominationService.restrictLoader$.next(true);
    let getupdatedDateRangeFlag = event.valid
    let getFromDateFromEvent = event.controls.start.value
    let getToDateFromEvent = event.controls.end.value

    if (getupdatedDateRangeFlag) {
      if (this.currentScreen === "replenishmentplanning") {
        this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: getFromDateFromEvent, pipelineToDate: getToDateFromEvent, isPipelineFilterUpdated: true }));
      } else if (this.currentScreen === "nominations") {
        this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(this.updatedPipelineFilterResponse)), pipelineFromDate: getFromDateFromEvent, pipelineToDate: getToDateFromEvent, isPipelineNomFilterUpdated: true }));
      }
    }
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
  }

  filterAccord: [boolean] = [false];
  accordionFn(ind: number) {
    this.filterAccord[ind] = !this.filterAccord[ind];
  }

  updatePipelineFilterSelection(headerName: any) {

    let transportSystem: any = []
    let slectedTerminal: any = [];
    let selectedModeOfTransport: any = [];
    let selectedProducts: any;
    let selectedTransportSystemList: any;
    let allMOT: any;
    let selectedTerminalList: any;
    this.updatedPipelineFilterResponse?.forEach((element: any) => {
      if (element.headerName === 'transport system') {
        transportSystem = this.getCheckedIds(element)
        selectedTransportSystemList = element.list
      } else if (element.headerName === 'terminal' || element.headerName === 'location') {
        slectedTerminal = this.getCheckedIds(element)
        selectedTerminalList = element.list
      } else if (element?.headerName === "mode of transport") {
        selectedModeOfTransport = this.getCheckedIds(element)
        allMOT = element.list;
      } else if (element?.headerName === "product") {
        selectedProducts = this.getCheckedNames(element);
      }
    });
    let prdtList;
    let prdtLterminalList: any;
    let trnsptList: any;
    if (headerName === "mode of transport") {

      trnsptList = this.masterPipelineAggregatorResponse['transportsystem'].filter((element: any) => {
        return selectedModeOfTransport.includes(element.modeOfTransportCode)
      }).map((ele: any) => { return { ...ele, checked: transportSystem.includes(ele.transportSystemCode) } });


      let slectedtrnsptList = trnsptList.filter((ele: any) => ele.transportSystemCode && ele.checked).map((item: any) => item.transportSystemCode);

      prdtLterminalList = this.masterPipelineAggregatorResponse['terminal'].filter((element: any) => {
        return this.utilities.findCommonElements(element.transportSystem, slectedtrnsptList)
      }).map((ele: any) => { return { ...ele, checked: slectedTerminal.includes(ele.terminalCode) } });

      let slectedTerminalList = prdtLterminalList.filter((ele: any) => ele.terminalCode && ele.checked).map((item: any) => item.terminalCode);
      prdtList = this.masterPipelineAggregatorResponse['product'].filter((element: any) => {
        return this.utilities.findCommonElements(element.terminalCode, slectedTerminalList)
      })
        .map((ele: any) => { return { ...ele, checked: selectedProducts.includes(ele.productCode) } });




    } else if (headerName === 'transport system') {
      prdtLterminalList = this.masterPipelineAggregatorResponse['terminal'].filter((element: any) => {
        return this.utilities.findCommonElements(element.transportSystem, transportSystem)
      })
        .map((ele: any) => { return { ...ele, checked: slectedTerminal.includes(ele.terminalCode) } });

      let slectedTerminalList = prdtLterminalList.filter((ele: any) => ele.terminalCode && ele.checked).map((item: any) => item.terminalCode);
      prdtList = this.masterPipelineAggregatorResponse['product'].filter((element: any) => {
        return this.utilities.findCommonElements(element.terminalCode, slectedTerminalList)
      })
        .map((ele: any) => { return { ...ele, checked: selectedProducts.includes(ele.productCode) } });


    } else if (headerName === 'terminal' || headerName === 'location') {
      prdtLterminalList = selectedTerminalList
      prdtList = this.masterPipelineAggregatorResponse['product'].filter((element: any) => {
        return this.utilities.findCommonElements(element.terminalCode, slectedTerminal)
      })
        .map((ele: any) => { return { ...ele, checked: selectedProducts.includes(ele.productCode) } });
    }
    let newFilter: any;
    let isCargoDesks = this.selectedDesk === UDMDesk.PipelineUsecFloridaDesk || this.selectedDesk === UDMDesk.PipelineUsecPascagoulaDesk || this.selectedDesk === UDMDesk.PipelineUswcPNWDesk || this.selectedDesk === UDMDesk.PipelineUsecRichmondDesk;
    if (isCargoDesks || this.selectedDesk === "clean products + latam + panama desk" || this.selectedDesk === "clean products + latam + ecca desk" || this.selectedDesk === "clean products + latam + wcca desk") {
      newFilter = { modeoftransport: allMOT, transportsystem: trnsptList ?? selectedTransportSystemList, product: prdtList, terminal: prdtLterminalList }
    } else {
      newFilter = { transportsystem: selectedTransportSystemList, product: prdtList, terminal: prdtLterminalList }
    }
    return newFilter
  }

  clearUrlParams() {
    this.isRouteParam = false;
    this.filteredLocation = false;
    this.filteredProduct = false;
    this.filteredProductNumber = false;
    this.filteredProductGroupNumber = false;
    this.routeFilters.params = {
      filteredDesk: "",
      filteredDeskId: "",
      filteredLocation: "",
      filteredMaterialGroupNumber: "",
      filteredProduct: "",
      filteredProductNumber: "",
    }
    this.store.dispatch(updateURLParams({ updateURLParamsState: this.routeFilters.params }));
  }

  isPipelineCargotabActive(pipelineFilterValues:any, headerName : any){
    return !(this.nominationService.isCargoScreen.value && headerName === 'mode of transport' && !(['vessel', 'barge', 'all'].includes(pipelineFilterValues.name.toLowerCase()) ))
   }

   getTitle(pipelineFilterValues:any, headerName : any) {
    let titleName = '';
    switch (headerName.toLowerCase()) {
      case 'product':
        titleName = pipelineFilterValues.name + ' (' +pipelineFilterValues.id + ')'
        break;
      case pipelineFilterValues?.name?.toLowerCase()=='barge':
        titleName = 'B'
        break;
      case 'terminal':
          titleName = pipelineFilterValues.name + ' (' +pipelineFilterValues.id + ')'
          break;
      default:
        titleName = pipelineFilterValues?.id
        break;
    }
    return titleName;
  }

  async updateAggregatorResponse(resRequired?: boolean) {
    this.selectedDeskId = localStorage.getItem("deskId");
    let isTrackInv = this.currentScreen !== 'nominations';
    let payload = [];
    if (isTrackInv) {
      payload.push({
        "field": "trackInv",
        "operator": "eq",
        "value": isTrackInv
      })
    }
    if (this.selectedDeskId !== '25') {
      const aggrResponse = await firstValueFrom(this.mdmService.getAggregateData(+this.selectedDeskId, payload));
      let res = aggrResponse.value.value[0];
      this.globalAggregatorResponse = res;
      this.utilities.globalPipelineAggregatorResponse = res;
      if (resRequired) {
        return { res, aggrResponse };
      } else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  getHeaderName(response: any) {
    let headerName = '';
    switch (response.headerName.toLowerCase()) {
      case 'location':
        headerName = 'terminal'
        break;
      case 'mode of transport':
        headerName = 'vehicle identifier'
        break;
      default:
        headerName = response.headerName
        break;
    }
    return headerName;
  }

  onChangeCargoCCA(event: any, filterName?: any) {
    this.nominationService.setInitalCargoCCAFilterValues = false;
    const cargoFilters = JSON.parse(JSON.stringify(this.cargoFiltersCCA));
    cargoFilters?.map((el: any) => {
      if (el.filterName === filterName && event.target.value !== "all" && event.target.value !== "All Desk") {
        el.list.map((dt: any) => {
          if (dt.value === event.target.value) {
            dt.checked = event.target.checked;
          }
        });
        let checkIfAllElementsChecked: any = el.list.filter((dt: any) => dt.value !== "all" && dt.value !== "All Desk").every((dt: any) => dt.checked);
        el.list.map((dt: any) => {
          if ((dt.value === "all" || dt.value === "All Desk") && checkIfAllElementsChecked) {
            checkIfAllElementsChecked = false;
            dt.checked = true;
          } else if ((dt.value === "all" || dt.value === "All Desk") && !checkIfAllElementsChecked) {
            dt.checked = false;
          }
        });
      } else if (el.filterName === filterName && (event.target.value === "all" || event.target.value === "All Desk")) {
        el.list.map((dt: any) => dt.checked = event.target.checked);
      }
    });

    this.cargoFiltersCCA = JSON.parse(JSON.stringify(cargoFilters));
    
  }

  dateRangegroupCargo(event: any) {
    this.cargoFiltersStartDateCCA = this.datePipe.transform(event.controls.start.value, 'yyyy-MM-dd');
    this.cargoFiltersEndDateCCA = this.datePipe.transform(event.controls.end.value, 'yyyy-MM-dd');
    this.cargoCCADateRangeData.fromDate = this.datePipe.transform(event.controls.start.value, 'yyyy-MM-dd');
    this.cargoCCADateRangeData.toDate = this.datePipe.transform(event.controls.end.value, 'yyyy-MM-dd');
    this.applyCargoCCAFilter();
  }

  applyCargoCCAFilter() {
    this.cpService.cargoCCAFilters = {
      filterValues: this.cargoFiltersCCA,
      fromDate: this.datePipe.transform(this.cargoFiltersStartDateCCA, 'yyyy-MM-dd'),
      toDate: this.datePipe.transform(this.cargoFiltersEndDateCCA, 'yyyy-MM-dd'),
    }
    this.store.dispatch(saveCargoFiltersCCA({cargoCCAFilters: this.cargoFiltersCCA, cargoCCAStartDate: this.datePipe.transform(this.cargoFiltersStartDateCCA, 'yyyy-MM-dd'), cargoCCAEndDate: this.datePipe.transform(this.cargoFiltersEndDateCCA, 'yyyy-MM-dd'), isCargoCCAUpdated: true}));
  }

  ccaClearFilter() {
    this.getDefaultCCADates();
    let resetFromData!: any, resetToDate!: any;
    const getCurrentDate: any = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
    let getUpdatedDate = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth() + 1, 0)
    const getCurrentDay = getCurrentDate.getDay();
    const ccaCargoFilters = JSON.parse(JSON.stringify(this.cargoFiltersCCA));
    ccaCargoFilters?.forEach((el: any) => {
      if (el.filterName === "plan name") {
        el.list.forEach((dt: any) => {
          if (this.selectedDesk?.includes(dt.value.toLowerCase()) || this.selectedDesk?.includes("all desk")) {
            dt.checked = true;
          } else {
            dt.checked = false;
          }
        });
      } else {
        el.list.forEach((dt: any) => {
          if (dt.value === "Approved" || dt.value === "Pending") {
            dt.checked = true;
          } else {
            dt.checked = false;
          }
        });
      }
    });
    if (getCurrentDay === 0) {
      resetFromData = this.datePipe.transform(
        getCurrentDate.setDate(this.utilities.convertDatesToCurrentTZ(new Date().toISOString()).getDate() - 7),
        'yyyy-MM-dd'
      )!;
    } else {
      const getDayDifference =
        getCurrentDate.getDate() -
        7 -
        getCurrentDay +
        (getCurrentDay === 0 ? -6 : 0);
      resetFromData = this.datePipe.transform(
        new Date(getCurrentDate.setDate(getDayDifference)),
        'yyyy-MM-dd'
      )!;
    }
    if (
      getCurrentDate + 60 === getUpdatedDate
    ) {
      resetToDate = this.datePipe.transform(
        getCurrentDate + 60,
        'yyyy-MM-dd'
      )!;
    } else {
      const getMonthLastDay = new Date(
        getCurrentDate.getFullYear(),
        getCurrentDate.getMonth() + 3,
        0
      );
      resetToDate = this.datePipe.transform(
        getMonthLastDay,
        'yyyy-MM-dd'
      )!;
    }
    this.cargoCCADateRangeData = { fromDate: this.getCCAFromDateFromEvent, toDate: this.getCCAToDateFromEvent };
    this.cargoFiltersCCA = JSON.parse(JSON.stringify(ccaCargoFilters));
    this.cargoFiltersStartDateCCA = this.getCCAFromDateFromEvent;
    this.cargoFiltersEndDateCCA = this.cargoFiltersEndDateCCA;
    this.nominationService.cargoCCAFilterOnFirstLoad = JSON.parse(JSON.stringify({ cargoCCAFilters: this.cargoFiltersCCA, cargoCCAStartDate: this.getFromDateFromEvent, cargoCCAEndDate: this.getToDateFromEvent, isCargoCCAUpdated: true }));
    this.cpService.cargoCCAFilters = {
      filterValues: this.cargoFiltersCCA,
      fromDate: this.datePipe.transform(this.getCCAFromDateFromEvent, 'yyyy-MM-dd'),
      toDate: this.datePipe.transform(this.getCCAToDateFromEvent, 'yyyy-MM-dd'),
    }
    this.store.dispatch(saveCargoFiltersCCA({ cargoCCAFilters: this.cargoFiltersCCA, cargoCCAStartDate: this.datePipe.transform(this.getCCAFromDateFromEvent, 'yyyy-MM-dd'), cargoCCAEndDate: this.datePipe.transform(this.getCCAToDateFromEvent, 'yyyy-MM-dd'), isCargoCCAUpdated: true }));
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
