<div class="panel-content">
    <ng-container>
        <div>
            <div class="content__header">location</div>
             <form class="branded">
                <input class="form-control admin-search" [name]="'searchText0'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('locationName', $any($event.target).value)"
                    [(ngModel)]="searchValue[0]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of updateLocationFilters.locationName; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="locationNameCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('locationName', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name}}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">SIS location code</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText1'" type="search"
                    placeholder="search" (input)="updateSearchFilterList('locationCode', $any($event.target).value)"
                    [(ngModel)]="searchValue[1]">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of updateLocationFilters.locationCode; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="sisLocationCodeCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('locationCode', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">exchange terminal</div>
            <form class="branded">
                <div class="admin-side-panel">
                    <ng-container *ngFor="let obj of updateLocationFilters.exchangeTerminal; index as i;">
                        <p *ngIf="obj.show" class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="exchangeDeliveryCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('exchangeTerminal', $event, i)">
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                {{ obj.name }}
                            </label>
                        </p>
                    </ng-container>
                </div>
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">latitude</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText2'" type="search"
                    placeholder="search" (input)="applyCoordinateFilters($event, 0)"
                    [(ngModel)]="searchValue[2]">
            </form>
            <hr>
        </div>
        <div>
            <div class="content__header">longitude</div>
            <form class="branded">
                <input class="form-control admin-search" [name]="'searchText3'" type="search"
                    placeholder="search" (input)="applyCoordinateFilters($event, 1)"
                    [(ngModel)]="searchValue[3]">
            </form>
            <hr>
        </div>
    </ng-container>
</div>

<div class="panel-footer d-flex justify-content-between p-3">
    <span class="clear-filter" (click)="clearFilters()">clear</span>
    <span class="clear-filter" (click)="filterLocations()">apply</span>
</div>
