import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public allGroupAction: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allGroupAction$ = this.allGroupAction.asObservable()

  setAllGroupAction(action:boolean){
    this.allGroupAction.next(action)
  }

}
