import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { LocationData, AdminView, LocationFilters, TextFilter, CoordinateFilter } from '../../../../shared/interface/mdm-admin.interface';
import { selectLocationsData } from '../../../../admin-locations/store/selector/admin-locations.selector';
import { addEditedLocations, setDefaultLocationFilters, updateLocationFilters } from '../../../../admin-locations/store/action/admin-locations.action';
import { selectAdminView } from '../../../../../app/shared/store/selector/app.selector';

@Component({
  selector: 'app-locations-filter',
  templateUrl: './locations-filter.component.html',
  styleUrls: ['./locations-filter.component.scss']
})
export class LocationsFilterComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  locationsData$ = this.store.pipe(select(selectLocationsData));
  selectedAdminView$ = this.store.pipe(select(selectAdminView));
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  adminView: string = "";
  locationsData: LocationData[] = [];
  updateLocationFilters: LocationFilters = {
    locationName: [],
    locationCode: [],
    exchangeTerminal: []
  };
  locationFilters: LocationFilters = {
    locationName: [],
    locationCode: [],
    exchangeTerminal: []
  };
  relatedFilters: string[] = ['locationName', 'locationCode'];
  toggleFilters: string[] = ['exchangeTerminal'];
  coordinateFilters: CoordinateFilter[] = [
    { filterName: 'latitude', filterValue: NaN },
    { filterName: 'longitude', filterValue: NaN }
  ];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedAdminView$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        this.adminView = response.adminView;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });

    this.locationsData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (this.adminView === AdminView.Locations) {
          if (response.locationsData.length && response.initializeLocationFilters) {
            this.locationsData = JSON.parse(JSON.stringify(response.locationsData));
            this.formatDefaultLocationFilters();
            this.store.dispatch(setDefaultLocationFilters({
              locationFilters: JSON.parse(JSON.stringify(this.locationFilters)),
              locationsData: JSON.parse(JSON.stringify(this.locationsData))
            }));
          }
          if (response.addEditedChanges) {
            this.locationsData = JSON.parse(JSON.stringify(response.locationsData));
            this.store.dispatch(addEditedLocations());
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });
  }

  formatDefaultLocationFilters(): void {
    this.locationFilters = {
      locationName: [],
      locationCode: [],
      exchangeTerminal: []
    };
    this.coordinateFilters.forEach((filter: CoordinateFilter) => {
      filter.filterValue = NaN;
    });
    const checkFilter: boolean = true;
    const showFilter: boolean = true;
    const allOption: TextFilter = { name: 'all', checked: checkFilter, show: showFilter };
    this.relatedFilters.forEach((filterName: string) => {
      this.locationFilters[filterName as keyof typeof this.locationFilters].push(allOption);
      this.locationsData.forEach((location: LocationData) => {
        location.show = showFilter;
        if (location[filterName as keyof typeof location]) {
          const filterValue: string = String(location[filterName as keyof typeof location]!);
          this.locationFilters[filterName as keyof typeof this.locationFilters].push({
            name: filterValue,
            checked: checkFilter,
            show: showFilter
          });
        }
      });
    });
    this.initializeToggleFilters(checkFilter, showFilter);
    this.updateLocationFilters = JSON.parse(JSON.stringify(this.locationFilters));
  }

  initializeToggleFilters(checkFilter: boolean, showAllFilter: boolean): void {
    this.toggleFilters.forEach((toggleFilter: string) => {
      this.locationFilters[toggleFilter as keyof typeof this.locationFilters] = [{ name: 'all', checked: checkFilter, show: showAllFilter }];
      let enabledToggleName: string = 'yes';
      let disabledToggleName: string = 'no';
      const showEnabledOption: boolean = this.locationsData.filter((location: LocationData) =>
        location[toggleFilter as keyof typeof location] && location[toggleFilter as keyof typeof location] === true && location.show
      ).length > 0;
      const showDisabledOption: boolean = this.locationsData.filter((location: LocationData) => (
        location[toggleFilter as keyof typeof location] === null ||
        location[toggleFilter as keyof typeof location] === undefined ||
        location[toggleFilter as keyof typeof location] === false)
        && location.show
      ).length > 0;
      this.locationFilters[toggleFilter as keyof typeof this.locationFilters].push({ name: enabledToggleName, checked: checkFilter, show: showEnabledOption});
      this.locationFilters[toggleFilter as keyof typeof this.locationFilters].push({ name: disabledToggleName, checked: checkFilter, show: showDisabledOption});
    });
  }

  filterLocations(): void {
    this.filterLocationsTable();
    this.updateLocationFilters = JSON.parse(JSON.stringify(this.locationFilters));
    this.store.dispatch(updateLocationFilters({
      locationFilters: JSON.parse(JSON.stringify(this.locationFilters)),
      locationsData: JSON.parse(JSON.stringify(this.locationsData))
    }));
  }

  applyCheckboxFilters(filterName: string, filterItem: any, index: number): void {
    // Check if clicked filter is directly related to another filter (ie. locationName and locationCode)
    const relatedFilterGroupCount: number = this.relatedFilters.length;
    const relatedFilterGroup: string[] = this.relatedFilters.filter((relatedFilter: string) => relatedFilter !== filterName);
    const isRelatedFilter: boolean = relatedFilterGroup.length < relatedFilterGroupCount;
    const foundFilter: TextFilter[] = this.locationFilters[filterName as keyof typeof this.locationFilters];
    if (index !== 0) {
      // Check or uncheck clicked filter if filter option selected is not 'all'
      foundFilter[index].checked = filterItem.target.checked;
      // Check or uncheck the all option for clicked filter
      const filters: TextFilter[] = foundFilter.filter((filterOption: TextFilter) => filterOption.show && filterOption.name !== 'all');
      const filterOptionsCount: number = filters.length;
      const checkedFilterOptionsCount: number = filters.filter((filterOption: TextFilter) => filterOption.checked).length;
      foundFilter[0].checked = filterOptionsCount === checkedFilterOptionsCount;
      if (isRelatedFilter) {
        // Check or uncheck for related filter
        relatedFilterGroup.forEach((relatedFilterName: string) => {
          // Check or uncheck the all option for related filter
          const foundRelatedFilter: TextFilter[] = this.locationFilters[relatedFilterName as keyof typeof this.locationFilters];
          foundRelatedFilter[index].checked = filterItem.target.checked;
          foundRelatedFilter[0].checked = filterOptionsCount === checkedFilterOptionsCount;
        });
      }
    } else {
      // Check or uncheck all filter options for clicked filter
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        // Check or uncheck all filter options for filters that are directly related
        relatedFilterGroup.forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.locationFilters[relatedFilterName as keyof typeof this.locationFilters];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
    }
    this.updateLocationFilters = JSON.parse(JSON.stringify(this.locationFilters));
  }

  applyCoordinateFilters(filterItem: any, index: number): void {
    if (filterItem.target.value !== "")
      this.coordinateFilters[index].filterValue = Number(filterItem.target.value);
    else
      this.coordinateFilters[index].filterValue = NaN;
  }

  filterLocationsTable(): void {
    this.locationsData.forEach((location: LocationData, index: number) => {
      location.show = true;
      const foundFilter: TextFilter = this.locationFilters.locationCode[index+1];
      if (!foundFilter.checked)
        location.show = false;
      this.toggleFilters.forEach((filter: string) => {
        this.locationFilters[filter as keyof typeof this.locationFilters]
        .filter((filterOption: TextFilter) => filterOption.name !== 'all')
        .forEach((filterOption: TextFilter) => {
          const locationDisabled: boolean = location[filter as keyof typeof location] === false || location[filter as keyof typeof location] === null || location[filter as keyof typeof location] === undefined;
          if (((filterOption.name === 'active' || filterOption.name === 'yes') && !filterOption.checked && !locationDisabled)
            || ((filterOption.name === 'inactive' || filterOption.name === 'no') && !filterOption.checked && locationDisabled)
          )
            location.show = false;
        });
      });
      this.coordinateFilters.forEach((filter: CoordinateFilter) => {
          if (Number(location[filter.filterName as keyof typeof location]) < filter.filterValue)
            location.show = false;
      });
    });
  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = JSON.parse(JSON.stringify(this.locationFilters[filterName as keyof typeof this.locationFilters]));
        updatedFilterList.forEach((filter: TextFilter) => {
          if (filter.name.toLowerCase().includes(text.value.toLowerCase()) && filter.name !== 'all') {
            filter.show = true;
          } else {
            filter.show = false;
          }
        });
        this.updateLocationFilters[filterName as keyof typeof this.updateLocationFilters] = JSON.parse(JSON.stringify(updatedFilterList));
      } else {
        this.updateLocationFilters[filterName as keyof typeof this.updateLocationFilters] = JSON.parse(JSON.stringify(this.locationFilters[filterName as keyof typeof this.locationFilters]));
      }
    });
  }

  clearFilters(): void {
    this.searchText = [];
    this.searchValue = [];
    this.formatDefaultLocationFilters();
    this.store.dispatch(setDefaultLocationFilters({
      locationFilters: JSON.parse(JSON.stringify(this.locationFilters)),
      locationsData: JSON.parse(JSON.stringify(this.locationsData))
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
