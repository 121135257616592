import { Component, OnInit } from '@angular/core';
import { Utilities } from '../../../shared/utilities/utilities';
import { NominationService } from '../../../services/nomination.service';
import { Router } from '@angular/router';
import { HEEL_DETAIL_TYPE, INVENTORY_DETAIL_TYPE, RESERVE_DETAIL_TYPE, SAFETYSTOCK_DETAIL_TYPE } from 'src/app/shared/constants/nominations.constant';
import { DatePipe } from '@angular/common';
import { ConfirmModalService } from 'src/app/services/comfirm-modal.service';
import { ToastService } from 'src/app/services/toast.service';
import * as NominationConstant from '../../../shared/constants/nominations.constant';
import { select, Store } from '@ngrx/store';
import { invokeTrNominationList, saveAppliedTRFilters, updateNomination } from 'src/app/shared/store/action/app.action';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectTRDataPayload, trNominationList } from 'src/app/shared/store/selector/app.selector';
import { Subject, takeUntil } from 'rxjs';
import { MdmService } from 'src/app/services/mdm.service';

@Component({
  selector: 'app-create-nominations-side-panel',
  templateUrl: './create-nominations-side-panel.component.html',
  styleUrls: ['./create-nominations-side-panel.component.scss']
})
export class CreateNominationsSidePanelComponent implements OnInit {
  isDraftAccordionExpanded = false;
  tankTransferNominations: any[] = [];
  draftNominations: any[] = [];
  isPublishedAccordionExpanded = false;
  isPublishedAccordionExpandedInternal : any;
  isUnPublishedAccordionExpandedInternal : any;
  publishedNominations: any[] = [];
  isUnpublishedAccordionExpanded = false;
  unpublishedNominations: any[] = [];
  editedInventoryVolume!: any;
  editedHeelVolume!: any;
  editedReserveVolume!: any;
  editedSafetyVolume!: any;
  confirmModalStatus!: any;
  dateChangeObj!: any;
  trNominationList: any[] = [];
  public trPayLoad: any = {};
  getClickedLineItem: any;
  getMatchedlineItem: any;
  desk!: string;

  trNominationList$ = this.appState.pipe(select(trNominationList));
  selectedTrDataPayload$ = this.appState.pipe(select(selectTRDataPayload));
  selectedDesk$ = this.store.pipe(select(selectDesk));
  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  selectedDataContent: any = '';
  SelectedDeskName: any = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  userDetails$ = this.appState.pipe(select(getUserDetails));
  userEmail: any;
  isCCAPanamaView: boolean = false;


  constructor(private store: Store, private appState: Store<AppState>, private nominationService: NominationService, private utilities: Utilities, private router: Router, public datePipe: DatePipe, private confirmService: ConfirmModalService, public toasterService: ToastService, private data: NominationService, private mdmService : MdmService) { }

  ngOnInit(): void {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          this.isCCAPanamaView = this.utilities.checkIfCCAPanamaView(this.desk);
          this.SelectedDesk = response.selectedDesk;
          this.SelectedDeskParam = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId;
          this.SelectedDeskName = this.desk.split('+')[this.desk.split('+').length -1];
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
    this.selectedTrDataPayload$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          let data = JSON.stringify(response['trDataPayLoad']);
          this.trPayLoad = data;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.nominationService.sidePanelData$.subscribe((data: any) => {
      this.getClickedLineItem = data

    })

    this.getTerminalNominationAF();
    this.confirmService.checkConfirmModal$.subscribe((obj) => {
      this.confirmModalStatus = obj.msg;
      if (this.confirmModalStatus === NominationConstant.CANCEL) {
        if (this.dateChangeObj.status === NominationConstant.DRAFT) {
          this.draftNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.previousDate;
        } else if (this.dateChangeObj.status === NominationConstant.PUBLISHED) {
          this.publishedNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.previousDate;
        } else if (this.dateChangeObj.status === NominationConstant.UNPUBLISHED) {
          this.unpublishedNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.previousDate;
        }
      } else if (this.confirmModalStatus === NominationConstant.CONFIRM) {
        if (this.dateChangeObj.status === NominationConstant.DRAFT) {
          this.draftNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.updatedDate;
        } else if (this.dateChangeObj.status === NominationConstant.PUBLISHED) {
          this.publishedNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.updatedDate;
        } else if (this.dateChangeObj.status === NominationConstant.UNPUBLISHED) {
          this.unpublishedNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.updatedDate;
        }
      }
    });
  }

  getTerminalNominationAF() {

    this.trPayLoad = JSON.parse(this.trPayLoad);
    let product = this.trPayLoad['product'];
    this.trPayLoad['product'] = [product];
    this.trPayLoad['counterpart'] = this.trPayLoad['customers'];
    this.trPayLoad['desk'] = this.isCCAPanamaView  ? 'newPanama' : this.desk;
    let payload = this.trPayLoad;
    payload['screenName'] =  this.isCCAPanamaView && this.router.url === '/replenishmentPlanning' ? 'TR' : undefined,
    delete this.trPayLoad['customers'];
    this.appState.dispatch(invokeTrNominationList({ payload }));
    this.trNominationList$.subscribe(data => {
      this.trNominationList = JSON.parse(JSON.stringify(data));
      if (this.trNominationList.length >= 1) {
        this.trNominationList.forEach((el: any) => {
          el.destinationDate = this.dateCellRenderer(el?.destinationDate);
        });
      }

      if (this.trNominationList.length) {
        if (Object.keys(this.getClickedLineItem).length) {
          const getOfftakerName = this.getClickedLineItem?.colDef?.field.split('_')[1]
          const perFixNomination = `nomination_${getOfftakerName}_nominationDetails`
          this.getMatchedlineItem = this.trNominationList.find((val: any) => val.id === this.getClickedLineItem.data[perFixNomination].id)
        }
        this.tankTransferNominations = JSON.parse(JSON.stringify(this.trNominationList));
        this.draftNominations = this.tankTransferNominations.filter((nom: any) => nom.status?.toLowerCase() === "draft");
        if (this.getMatchedlineItem?.status?.toLowerCase() === 'draft') {
          this.draftNominations.unshift(...this.draftNominations.splice(this.draftNominations.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
          this.splittingNominations(this.draftNominations);
          this.isDraftAccordionExpanded = true
          this.isPublishedAccordionExpanded = false;
          this.isUnpublishedAccordionExpanded = false;

        }
        else {
          this.splittingNominations(this.draftNominations);
        }
        this.publishedNominations = this.tankTransferNominations.filter((nom: any) => nom.status?.toLowerCase() === "published");
        if (this.getMatchedlineItem?.status?.toLowerCase() === 'published') {
          this.publishedNominations.unshift(...this.publishedNominations.splice(this.publishedNominations.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
          this.splittingNominations(this.publishedNominations);
          this.isPublishedAccordionExpanded = true
          this.isDraftAccordionExpanded = false;
          this.isUnpublishedAccordionExpanded = false;
        }
        else {
          this.splittingNominations(this.publishedNominations);
        }
        this.unpublishedNominations = this.tankTransferNominations.filter((nom: any) => nom.status?.toLowerCase() === "created" || nom.status?.toLowerCase() === "unpublished");
        if (this.getMatchedlineItem?.status?.toLowerCase() === 'created' || this.getMatchedlineItem?.status?.toLowerCase() === 'unpublished') {
          this.unpublishedNominations.unshift(...this.unpublishedNominations.splice(this.unpublishedNominations.findIndex((val: any) => val.id === this.getMatchedlineItem.id), 1))
          this.splittingNominations(this.unpublishedNominations);
          this.isUnpublishedAccordionExpanded = true
          this.isDraftAccordionExpanded = false;
          this.isPublishedAccordionExpanded = false;
        }
        else {
          this.splittingNominations(this.unpublishedNominations);
        }
      }
    });
  }

  splittingNominations(nominations: any) {
    for (let i of nominations) {
      let detailType = i.detailType;
      detailType.forEach((el: any) => {
        el.createdOn = this.datePipe.transform(el.createdOn, "MM/dd/yyyy");
      })
      if (detailType.length > 0) {
        i["inventoryDetailType"] = detailType.filter((el: any) => el.detailType === INVENTORY_DETAIL_TYPE['utilization']);
        i["reserveDetailType"] = detailType.filter((el: any) => el.detailType === RESERVE_DETAIL_TYPE);
        i["heelsDetailType"] = detailType.filter((el: any) => el.detailType === HEEL_DETAIL_TYPE);
        i["safetyDetailType"] = detailType.filter((el: any) => el.detailType === SAFETYSTOCK_DETAIL_TYPE);
      }
    }
  }

  closeNominationsPanel() {
    this.nominationService.sidePanel$.next(false);
  }

  expandDraftAccordion() {
    this.isDraftAccordionExpanded = !this.isDraftAccordionExpanded;
    this.isPublishedAccordionExpanded = false;
    this.isUnpublishedAccordionExpanded = false;
  }

  expandPublishedAccordion() {
    this.isPublishedAccordionExpanded = !this.isPublishedAccordionExpanded;
    this.isDraftAccordionExpanded = false;
    this.isUnpublishedAccordionExpanded = false;
  }

  expandUnpublishedAccordion() {
    this.isUnpublishedAccordionExpanded = !this.isUnpublishedAccordionExpanded;
    this.isDraftAccordionExpanded = false;
    this.isPublishedAccordionExpanded = false;
  }

  expandPublishedAccordionInternal(accordianId: any)
  {
    if(this.isPublishedAccordionExpandedInternal==accordianId)
      {
        this.isPublishedAccordionExpandedInternal=false;
      }else{
  this.isPublishedAccordionExpandedInternal=accordianId;

    }
  }

  expandUnPublishedAccordionInternal(accordianId: any)
  {
    if(this.isUnPublishedAccordionExpandedInternal==accordianId)
      {
        this.isUnPublishedAccordionExpandedInternal=false;
      }else{
  this.isUnPublishedAccordionExpandedInternal=accordianId;

    }
  }

  async onCreateDraftNomination(nominations: any) {
    if (nominations.detailType.length > 0 && (this.isCCAPanamaView && (nominations.mot !== "TankTransfer" || nominations.nominationType !== "IT"))) {
      if (this.editedInventoryVolume) {
        nominations.inventoryDetailType = [this.editedInventoryVolume];
      }
      if (this.editedHeelVolume) {
        nominations.heelsDetailType = [this.editedHeelVolume];
      }
      if (this.editedReserveVolume) {
        nominations.reserveDetailType = [this.editedReserveVolume];
      }
      if (this.editedSafetyVolume) {
        nominations.safetyDetailType = [this.editedSafetyVolume];
      }
    }
    let params = { data: nominations };
    if (this.isCCAPanamaView) {
      if (nominations.status !== 'draft') {
        params = { ...params, ...{ type: 'group' } };
      }
      const pipelineNomination: any = await this.utilities.getPipelineNomPayload(params,this.userEmail,this.desk,true);
      pipelineNomination["screenName"] = 'side-panel-tr';
      nominations = JSON.parse(JSON.stringify(pipelineNomination))
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: this.setSelectedTabFunctionValue(nominations),
      type: '',
      values: nominations,
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
  }

  setSelectedTabFunctionValue(nominations: any) {
    console.log("from side panel", nominations);
    if (this.isCCAPanamaView && (nominations.mot !== "TankTransfer" && nominations.nominationType !== "IT")){
      return 'onCreatePipelineNominationFromTR'
    } else {
      return 'createDraftNomination'
    }

  }

  onKeyPress(event: KeyboardEvent) {
    const keyCode = event.key;
    if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyCode))) {
      event.preventDefault();
    }
  }

  destinationDateChange(event: any, previosDate?: any, index?: any, status?: any) {
    this.dateChangeObj = {
      previousDate: previosDate,
      updatedDate: this.datePipe.transform(event.target.value, "MM/dd/yyyy"),
      index: index,
      status: status
    }
    if (status === NominationConstant.DRAFT) {
      this.draftNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.updatedDate;
    } else if (status === NominationConstant.PUBLISHED) {
      this.publishedNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.updatedDate;
    } else if (status === NominationConstant.UNPUBLISHED) {
      this.unpublishedNominations[this.dateChangeObj.index].destinationDate = this.dateChangeObj.updatedDate;
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'confirmModal',
      type: '',
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);

  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  editedData(id: any, detailType: any, scheduledQty: any) {
    return {
      id: id,
      detailType: detailType,
      scheduledQty: scheduledQty
    }
  }

  onInput(event: any, index: any, id: any, detailType: any) {
    if (detailType === INVENTORY_DETAIL_TYPE['utilization']) {
      this.editedInventoryVolume = this.editedData(id, INVENTORY_DETAIL_TYPE['utilization'], Number(event.target.innerText));
    } else if (detailType === HEEL_DETAIL_TYPE) {
      this.editedHeelVolume = this.editedData(id, HEEL_DETAIL_TYPE, Number(event.target.innerText));
    } else if (detailType === RESERVE_DETAIL_TYPE) {
      this.editedReserveVolume = this.editedData(id, RESERVE_DETAIL_TYPE, Number(event.target.innerText));
    } else {
      this.editedSafetyVolume = this.editedData(id, SAFETYSTOCK_DETAIL_TYPE, Number(event.target.innerText));
    }

    setTimeout(() => {
      this.nominationFormation(index);
    }, 3000);
  }

  nominationFormation(index: any) {
    if (this.draftNominations[index].detailType.length > 0) {
      if (this.editedInventoryVolume) {
        this.draftNominations[index].inventoryDetailType = [this.editedInventoryVolume];
      }
      if (this.editedHeelVolume) {
        this.draftNominations[index].heelsDetailType = [this.editedHeelVolume];
      }
      if (this.editedReserveVolume) {
        this.draftNominations[index].reserveDetailType = [this.editedReserveVolume];
      }
      if (this.editedSafetyVolume) {
        this.draftNominations[index].safetyDetailType = [this.editedSafetyVolume];
      }
    }
  }

  goToNominations(nom: any) {
    // This set of code is for the transfer of desk
        let deskOj = { deskValue:this.SelectedDesk , deskParameter: this.SelectedDeskParam , id: this.SelectedDeskId };
        if(!this.SelectedDesk && !this.SelectedDeskParam){
      deskOj = this.SelectedDeskName;
    }
        localStorage.setItem('gTNDeskValue',JSON.stringify(deskOj));
    // this set of code is for the transfer of nomination 
    localStorage.setItem('nomId', nom.id);
    //this set of code is for the transfer of filter
        localStorage.setItem('gCCAF',JSON.stringify(this.data.ccaFilters));

    window.open("/nominations");
    if (this.desk?.includes("+ latam +")) {
      this.nominationService.goToNominationData$.next({ isTrue: true, data: nom });
      this.nominationService.getTerminalNominationAFAPI$.next(true)
    }
  }

  delete(nominationDetails: any){
    this.data.popupAction("delete");
    this.mdmService.invokeReplenishmentToggleMethod(true);
    this.mdmService.actionConfirmed$.subscribe((actionConfirmed) => {
      if (actionConfirmed) {
        if (nominationDetails !== '') {

          this.deleteAfterConfirm(nominationDetails);
        }
        this.mdmService.actionConfirm(false);
        this.mdmService.invokeReplenishmentToggleMethod(false);
        nominationDetails = '';
      }
    });
  }

  deleteAfterConfirm(nominationDetails: any) {
    const reqPayload = [];
    const requestBody = {
      id: nominationDetails.id,
      sys_status: nominationDetails.status,
      headerToItemNav: [{
        itemId: nominationDetails.itemId,
        udmItemStatus: nominationDetails.status,
        deletionIndicator: 'X',
        textLine: `{Author:${nominationDetails?.createdBy},MdfBy:${this.userEmail},SS:UDM}`
      }]
    }
    reqPayload.push(requestBody)
    this.store.dispatch(updateNomination({ payload: reqPayload, publishOrDeleteNominationApiResponse: {} }));
    this.toasterService.setToastNotification({
      show: true,
      type: 'success',
      msg: "Nomination has been deleted",
    });
    setTimeout(() => {
      this.getTerminalNominationAF();
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'deleteNomination',
        type: '',
      };
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);

    }, 3000);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
