import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { HomeComponent } from '../../../home/home.component';
import { AlertsService } from '../../../services/alerts.service';
import { ToastService } from '../../../services/toast.service';
import { AlertsNotificationsComponent } from '../../../settings-alerts/alerts-notifications/alerts-notifications.component';
import { DeletePayload } from '../../../shared/interface/settings.interface';

@Component({
  selector: 'app-delete-alerts',
  templateUrl: './delete-alerts.component.html',
  styleUrls: ['./delete-alerts.component.scss']
})
export class DeleteAlertsComponent implements OnInit {
  @Output() closeModalPopup = new EventEmitter();

  selectedMaterial = '';
  selectedTerminal = '';
  selectedRuleType = '';
  selectedReference = '';
  selectedNoOfDays = '';
  selectedRules = '';
  selectedParameters = '';
  selectedPercent = '';
  selectedRuleName = '';
  selectedFreq = '';
  selectedFreqFull = '';
  selectedSummary = '';
  selectedId = '';
  selectedActive = false;
  selectedUserList: string[] = [];
  deletePayload!: DeletePayload;
  public terminalDropdown: string[] = [];
  public materialDropdown: string[] = [];
  public rulesDropdown: string[] = [];
  public ruleNamesList: string[] = [];


  constructor(private alertsService: AlertsService, private toasterService: ToastService, private alertsComponent: AlertsNotificationsComponent, private homeComponent: HomeComponent, private authService: CalAngularService) {
    this.alertsService.currentLocation.subscribe((location: string) => this.selectedTerminal = location)
    this.alertsService.currentMaterial.subscribe((material: string) => this.selectedMaterial = material)
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
   }

  ngOnInit(): void {
    this.getAlertRulesData()
    this.alertsService.currentLocation.subscribe((location: string) => this.selectedTerminal = location)
    this.alertsService.currentMaterial.subscribe((material: string) => this.selectedMaterial = material)
    this.alertsService.currentMaterial.subscribe(() => this.getRulesByTerminalMaterial(this.selectedTerminal, this.selectedMaterial))
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
    this.alertsService.currentRuleName.subscribe(() => this.getRuleDetails(this.selectedTerminal, this.selectedMaterial, this.selectedRuleName))
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
  }

  getAlertRulesData() {
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        response.result?.forEach((element: any) => {
          this.terminalDropdown.push(element.location);
          for (const i of element.locationMaterials) {
            if (!this.materialDropdown.includes(i.material)) {
              this.materialDropdown.push(i.material);
            } for (const j of i.locationMaterialRules) {
              this.ruleNamesList.push(j.ruleName);
            }
          }
        })
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getRulesByTerminalMaterial(selectedTerminal: string, selectedMaterial: string) {
    this.rulesDropdown = [];
    this.selectedRuleName = '';
    this.selectedRuleType = '';
    this.selectedReference = '';
    this.selectedNoOfDays = '';
    this.selectedRules = '';
    this.selectedParameters = '';
    this.selectedPercent = '';
    this.selectedFreq = '';
    this.selectedSummary = '';
    this.selectedActive = false;
    this.selectedUserList = [];
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        response.result?.forEach((element: any) => {
          if (element.location === selectedTerminal) {
            for (const i of element.locationMaterials) {
              if (i.material === selectedMaterial) {
                for (const j of i.locationMaterialRules) {
                    this.rulesDropdown.push(j.ruleName);
                }
              }
            }
          }

        })
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getRuleDetails(selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    this.selectedTerminal = selectedTerminal
    this.selectedMaterial = selectedMaterial
    this.selectedRuleName = selectedRuleName
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminalsSearchDelete(response, selectedTerminal, selectedMaterial, selectedRuleName)
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getTerminalsSearchDelete(response: any, selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    response.result?.forEach((element: any) => {
      if (element.location === selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterialsSearchDelete(i, selectedMaterial, selectedRuleName)
        }
      }

    })
  }

  getMaterialsSearchDelete(i: any, selectedMaterial: string, selectedRuleName: string) {
    if (i.material === selectedMaterial) {
      for (const j of i.locationMaterialRules) {
        this.getRulesSearchDelete(j, selectedRuleName)
      }
    }
  }

  getRulesSearchDelete(j: any, selectedRuleName: string) {
    if (j.ruleName === selectedRuleName) {

      this.selectedRuleType = j.ruleType;
      for (let i of j.subscription.users) {
        if (i.isActive === true) {
          this.selectedUserList.push(i.userEmail)
        }
      }
      if (this.selectedRuleType === 'Inventory') {
        this.selectedReference = j.daysToValidate.type
        this.selectedNoOfDays = j.daysToValidate.NoOfdays;
        this.selectedParameters = j.parameter;
        this.selectedRules = j.ruleCondition;
        this.selectedFreq = j.alertFrequency.charAt(0)
        this.selectedFreqFull = j.alertFrequency.toLowerCase()
        this.selectedSummary = j.ruleDescription
        this.selectedId = j.ruleId
        this.selectedActive = j.isActive
      } else if (this.selectedRuleType === 'Reconciliation') {
        this.selectedPercent = j.thersold
        this.selectedFreq = j.alertFrequency.charAt(0)
        this.selectedFreqFull = j.alertFrequency.toLowerCase()
        this.selectedSummary = j.ruleDescription
        this.selectedId = j.ruleId
        this.selectedActive = j.isActive
      }
    }
  }

  closePopup() {
    this.alertsService.changeLocation('');
    this.alertsService.changeMaterial('');
    this.alertsService.changeRuleName('');
    this.alertsService.setIsCreateAlertsPopupOpen(false);
    this.closeModalPopup.emit(false);
  }

  deleteRule() {
    if (this.selectedUserList.length > 1 || (this.selectedUserList.length === 1 && this.selectedUserList[0] !== this.authService.cvxClaimsPrincipal.email)) {
      this.closeModalPopup.emit(false);
      this.alertsComponent.toggleDenyDeleteAlertModal()

    } else {
      this.deletePayload = {
        ruleId: this.selectedId,
        ruleType: this.selectedRuleType,
        isActive: false
      }
      this.alertsService.deleteAlert(this.deletePayload).subscribe({
        next: () => {
        },
        error: (err: any) => {
          console.log(err);
          this.toasterService.setToastNotification({ show: true, type: "error", msg: "error - alert could not be deleted" });
          this.closePopup();
        },
        complete: () => {
          this.toasterService.setToastNotification({ show: true, type: 'success', msg: "alert has been deleted"})
          this.closePopup();
          this.alertsComponent.getAlertRulesData()
          this.alertsComponent.gridApi.refreshCells();
          this.homeComponent.expandedMode$.next(false);
          this.homeComponent.collpaseMode$.next(true);
        },
      })
    }

  }
}
