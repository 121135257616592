import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { LocationFilters, AdminView, DeskFilters, DeskFunctionalGroup, RegionDeskLocationMapping, TextFilter, DeskLocationMapping, LocationNoDetails } from '../../../../shared/interface/mdm-admin.interface';
import { selectDeskLocationsData } from '../../../../../app/admin-desks/store/selector/admin-desks.selector';
import { setDefaultDesksFilter, updateDesksFilter } from '../../../../../app/admin-desks/store/action/admin-desks.action';
import { LoaderService } from '../../../../../app/services/loader.service';

@Component({
  selector: 'app-desks-filter',
  templateUrl: './desks-filter.component.html',
  styleUrls: ['./desks-filter.component.scss']
})
export class DesksFilterComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  deskLocationsData$ = this.store.pipe(select(selectDeskLocationsData));
  searchText: { value: string }[] = [];
  searchValue: string[] = [];
  DATA: DeskFunctionalGroup[] = [];
  showAdminView: string = AdminView.Locations;
  locationsFilter: LocationFilters[] = [];
  updateLocationsLocationNameFilter: LocationFilters[] = [];
  updateLocationsLocationCodeFilter: LocationFilters[] = [];
  latitudeFilterValue: number = NaN;
  longitudeFilterValue: number = NaN;
  updateDesksFilter: DeskFilters = {
    functionalGroup: [],
    region: [],
    locationName: [],
    locationCode: [],
  };
  DesksFilter: DeskFilters = {
    functionalGroup: [],
    region: [],
    locationName: [],
    locationCode: [],
  };
  desksTable: DeskFunctionalGroup[] = [];

  constructor(private store: Store, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.deskLocationsData$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        if (response.desksData.length && response.initializeDesksFilter) {
          this.DATA = JSON.parse(JSON.stringify(response.desksData));
          this.formatDefaultDesksFilters();
          this.store.dispatch(setDefaultDesksFilter({
            desksFilter: JSON.parse(JSON.stringify(this.DesksFilter)),
            desksData: JSON.parse(JSON.stringify(this.DATA))
          }));
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });
  }

  updateSearchFilterList(filterName: string, filterItem: any): void {
    const searchObj: { value: string } = { value: filterItem?.toLowerCase() };
    this.searchText.push(searchObj);
    this.searchText.forEach((text: any) => {
      if (text.value.length) {
        let updatedFilterList = [...this.DesksFilter[filterName as keyof typeof this.DesksFilter].filter((filter: TextFilter) =>
          filter.name.toLowerCase().includes(text.value.toLowerCase())
          && filter.name !== 'all')];
        this.updateDesksFilter[filterName as keyof typeof this.DesksFilter] = JSON.parse(JSON.stringify(updatedFilterList));
      } else {
        this.updateDesksFilter[filterName as keyof typeof this.DesksFilter] = JSON.parse(JSON.stringify(this.DesksFilter[filterName as keyof typeof this.DesksFilter]));
      }
    });
  }

  formatDefaultDesksFilters() {
    this.DesksFilter = {
      functionalGroup: [],
      region: [],
      locationName: [],
      locationCode: [],
    };
    const checkFilter: boolean = true;
    const showFilter: boolean = true;
    this.DesksFilter.functionalGroup.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.DesksFilter.region.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.DesksFilter.locationName.push({ name: 'all', checked: checkFilter, show: showFilter });
    this.DesksFilter.locationCode.push({ name: 'all', checked: checkFilter, show: showFilter });

    const uniqueLocationSIS = new Set<string>();
    const uniqueRegions = new Set<string>();
    const uniqueLocations = new Set<string>();
    this.DATA.forEach(functionalGroup => {
      // Functional Group Check
      if (functionalGroup.functionalGroup !== null && functionalGroup.functionalGroup !== '' && functionalGroup.functionalGroup !== 'null') {
        this.DesksFilter.functionalGroup.push({ name: functionalGroup.functionalGroup, checked: checkFilter, show: showFilter });
        functionalGroup.show = showFilter;
      }

      functionalGroup.regionDeskMapping.forEach(region => {
        // Region Check
        if (region.region !== null && region.region !== '' && region.region !== 'null' ) {
          this.DesksFilter.region.push({ name: region.region, checked: checkFilter, show: showFilter });
          region.show = showFilter;
        }
        else if(!uniqueRegions.has(region.region)) {
          uniqueRegions.add(region.region);
          this.DesksFilter.region.push({ name: "n/a", checked: checkFilter, show: showFilter });
          region.show = showFilter;
        }
        this.setDeskLocationFilters(region, showFilter, checkFilter, uniqueLocations, uniqueLocationSIS);
      });
    });
     //set all on first index of array
     const lnIndex =  this.DesksFilter.locationName.findIndex(x => x.name === 'all');
     if (lnIndex) {
     const [ ln ] =  this.DesksFilter.locationName.splice(lnIndex, 1); // delete ln
     this.DesksFilter.locationName.unshift(ln);
     }//add at first index

     const lcIndex =  this.DesksFilter.locationCode.findIndex(x => x.name === 'all');
     if (lcIndex) {
     const [ lc ] =  this.DesksFilter.locationCode.splice(lcIndex, 1); // delete lc
     this.DesksFilter.locationCode.unshift(lc);
     }//add at first index
    this.updateDesksFilter = JSON.parse(JSON.stringify(this.DesksFilter));
  }

  setDeskLocationFilters(region: RegionDeskLocationMapping, showFilter: boolean, checkFilter: boolean, uniqueLocations: Set<string>, uniqueLocationSIS: Set<string>): void {
    region.deskLocationMappings.forEach((deskLocationMapping: DeskLocationMapping) => {
      deskLocationMapping.show = showFilter;
      deskLocationMapping.locations.forEach((location: LocationNoDetails) => {
        if (location.locationCode !== null && location.locationCode !== '' &&location.locationCode !== 'null' ) {
          if (!uniqueLocations.has(location.locationName)) {
            uniqueLocations.add(location.locationName);
            this.DesksFilter.locationName.sort((a, b) => (a.name < b.name ? -1 : 1)).push({ name: location.locationName, checked: checkFilter, show: showFilter });
            }
          if (!uniqueLocationSIS.has(location.locationCode)) {
            uniqueLocationSIS.add(location.locationCode);
            this.DesksFilter.locationCode.sort((a, b) => (a.name < b.name ? -1 : 1)).push({ name: location.locationCode, checked: checkFilter, show: showFilter });
            location.show = showFilter;
          }
        }
      });
    });
  }

  showDeskLocationMappings(region: RegionDeskLocationMapping): void {
    region.deskLocationMappings.forEach((deskLocationMapping: DeskLocationMapping) => {
      deskLocationMapping.show = true
      deskLocationMapping.locations.forEach((location: LocationNoDetails) => {
        location.show = true
      });
    });
  }

  filterTable(filterNames: { filterName: string, propertyName: string }[]): void {
      this.DATA.forEach((functionalGroup: DeskFunctionalGroup) => {
      functionalGroup.show = true
      functionalGroup.regionDeskMapping.forEach((region: RegionDeskLocationMapping) => {
        region.show = true
        this.showDeskLocationMappings(region);
      });
    });

    filterNames.forEach((filter: { filterName: string, propertyName: string }) => {
      this.DesksFilter[filter.filterName as keyof typeof this.DesksFilter].filter((filterOption: TextFilter) => filterOption.name !== 'all' && filterOption.show).forEach((filterOption: TextFilter) => {
        if (!filterOption.checked) {
          if(filter.filterName === "functionalGroup") {
            const functionalGroup: DeskFunctionalGroup = this.DATA.find((functionalGroup: DeskFunctionalGroup) => functionalGroup.functionalGroup === filterOption.name)!;
            functionalGroup.show = filterOption.checked;
          }
          else if(filter.filterName === "region" || filter.filterName === "locationCode" || filter.filterName === "locationName") {
            this.DATA.forEach(functionalGroup => {
              this.hideRegionsAndDeskLocations(functionalGroup, filter, filterOption);
            });
          }
        }
      });
    });
    this.hideNestedTables();
  }

  hideRegionsAndDeskLocations(functionalGroup: DeskFunctionalGroup, filter: { filterName: string, propertyName: string }, filterOption: TextFilter): void {
    functionalGroup.regionDeskMapping.forEach((region: RegionDeskLocationMapping) => {
      if (((filterOption.name !== 'n/a' && region.region === filterOption.name)
        || ((region.region === null || region.region === '' || region.region === 'null') && filterOption.name === 'n/a'))
        && filter.filterName === "region"
      ) {
        region.show = filterOption.checked;
      } else if (filter.filterName === "locationCode" || filter.filterName === "locationName") {
        region.deskLocationMappings.forEach((deskLocationMapping: DeskLocationMapping) => {
          deskLocationMapping.locations.forEach((location: LocationNoDetails) => {
            if((location.locationName === filterOption.name && filter.filterName === "locationName")
            || (location.locationCode === filterOption.name && filter.filterName === "locationCode")
            ) {
              location.show = filterOption.checked;
            }
          })
        });
      }
    });
  }

  hideNestedTables(): void {
    this.DATA.forEach((functionalGroup: DeskFunctionalGroup) => {
      const showFunctionalGroups: boolean = functionalGroup.regionDeskMapping.filter((region: RegionDeskLocationMapping) => region.show).length > 0;
      if (!showFunctionalGroups) {
        functionalGroup.show = showFunctionalGroups;
      }
      functionalGroup.regionDeskMapping.forEach(region => {
        const showRegions: boolean = region.deskLocationMappings.filter((desk: DeskLocationMapping) => desk.show).length > 0;
        if (!showRegions) {
          region.show = showRegions;
        }
      });
    });
  }

  filterDesks(): void {
    this.loaderService.setLoading(true);
    const filterNames: { filterName: string, propertyName: string }[] = [
      { filterName: 'functionalGroup', propertyName: 'functionalGroup' },
      { filterName: 'region', propertyName: 'region' },
      { filterName: 'locationName', propertyName: 'locationName' },
      { filterName: 'locationCode', propertyName: 'locationCode' }
    ];
    this.filterTable(filterNames);
    this.updateDesksFilter = JSON.parse(JSON.stringify(this.DesksFilter));
    this.store.dispatch(updateDesksFilter({
      desksFilter: JSON.parse(JSON.stringify(this.DesksFilter)),
      desksData: JSON.parse(JSON.stringify(this.DATA))
    }));
  }
  applyCheckboxFilters(filterName: string, filterItem: any): void {
    const relatedFilters: { relatedFilterNames: string[] }[] = [
      { relatedFilterNames: [ 'locationName', 'locationCode' ] }
    ];
    // checks for selected filter whether it has a related filter
    const isRelatedFilter: boolean = relatedFilters.filter((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName)).length > 0;
    // gets the set of filters for particular categories
    const foundFilter: TextFilter[] = this.DesksFilter[filterName as keyof typeof this.DesksFilter];
    if (filterItem.target.value !== 'all') {
      // 251 - 255 determine whether or not to keep "all" option selected (e.g. if one deselcts anyone option "all" would no longer be checked)
      const filterOption: TextFilter = foundFilter.find((filterOption: TextFilter) => filterOption.name === filterItem.target.value)!; // for set of filters in section finds particular item selected and stores for later change
      filterOption.checked = filterItem.target.checked; // change selected filter to match user selection (check or unchecked)
      const allFilterCount: number = foundFilter.filter((filterOption: TextFilter) => filterOption.show && filterOption.name !== 'all').length; // see how many of items in filter exist besides "all"
      const checkedFilterCount: number = foundFilter.filter((filterOption: TextFilter) => filterOption.checked && filterOption.show && filterOption.name !== 'all').length; // counts filters that are checked as well (in order to verify if "all" needs to be selected)
      foundFilter.find((filterOption: TextFilter) => filterOption.name === 'all')!.checked = allFilterCount === checkedFilterCount; //update all option to be checked or unchecked based on comparison
      if (isRelatedFilter) { // if there is a related filter to one being updated and duplicates logic for unchecking "all"
        const relatedFilterOptionIndex: number = this.DesksFilter[filterName as keyof typeof this.DesksFilter].findIndex((filterOption: TextFilter) => filterOption.name === filterItem.target.value); // gets position in list of filters of filter item being updated
        const relatedFilterGroup: { relatedFilterNames: string[] } = relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.DesksFilter[relatedFilterName as keyof typeof this.DesksFilter];
          foundRelatedFilter[relatedFilterOptionIndex].checked = filterItem.target.checked; //use position to change properties
          const allRelatedFilterCount: number = foundRelatedFilter.filter((filterRelatedOption: TextFilter) => filterRelatedOption.show && filterRelatedOption.name !== 'all').length;
          const checkedRelatedFilterCount: number = foundRelatedFilter.filter((filterRelatedOption: TextFilter) => filterRelatedOption.checked && filterRelatedOption.show && filterRelatedOption.name !== 'all').length;
          foundRelatedFilter.find((filterRelatedOption: TextFilter) => filterRelatedOption.name === 'all')!.checked = allRelatedFilterCount === checkedRelatedFilterCount;
        });
      }
    } else { //if all was checked it will either check or uncheck the set and related filters
      foundFilter.forEach((filterOption: TextFilter) => {
        filterOption.checked = filterItem.target.checked
      });
      if (isRelatedFilter) {
        const relatedFilterGroup: { relatedFilterNames: string[] } = relatedFilters.find((relatedFilter: { relatedFilterNames: string[] }) => relatedFilter.relatedFilterNames.includes(filterName))!;
        relatedFilterGroup.relatedFilterNames.filter((relatedFilterName: string) => relatedFilterName !== filterName).forEach((relatedFilterName: string) => {
          const foundRelatedFilter: TextFilter[] = this.DesksFilter[relatedFilterName as keyof typeof this.DesksFilter];
          foundRelatedFilter.forEach((relatedFilterOption: TextFilter) => {
            relatedFilterOption.checked = filterItem.target.checked;
          });
        });
      }
    }
    setTimeout(() => {
      this.loaderService.setLoading(false);
    }, 7000);
    this.updateDesksFilter = JSON.parse(JSON.stringify(this.DesksFilter));
  }

  clearFilters(): void {
    this.loaderService.setLoading(true);
    this.searchText = [];
    this.searchValue = [];
    this.formatDefaultDesksFilters();
    this.store.dispatch(setDefaultDesksFilter({
      desksFilter: JSON.parse(JSON.stringify(this.DesksFilter)),
      desksData: JSON.parse(JSON.stringify(this.DATA))
    }));
    setTimeout(() => {
      this.loaderService.setLoading(false);
    }, 7000);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
