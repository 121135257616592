<div class="more-menu">
  <ng-container *ngIf="!nominationTab">
    <ng-container *ngIf="details;else showOnlyValue">
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    </ng-container>
    <ng-template #showOnlyValue><span [ngClass]="[moremenuIconShow ? '' : '']">{{value}}</span></ng-template>
  </ng-container>
  <div class="more-menu-dropdown">
    
    <button *ngIf="moremenuIconShow && ( (details?.id!== '' && details?.status!== ''))" class="ellipses-icon"
      (click)="moremwnu()"></button>
    <div *ngIf="!isOpen" class="dropdown-content" [ngClass]="[compactView ? 'compact-dropdown':'',(rowIndex < 8 && !isDetailGrid) || (rowIndex < 2 && isDetailGrid) ? '' : 'place-top']">
      <a *ngIf="details?.status?.toLowerCase()==='unpublished' || details?.status?.toLowerCase()==='created' || details?.status?.toLowerCase()==='published'"
        class="dropdown-item create-nomination" (click)="createNomWithRef()">create nomination from
        reference</a>
      <a *ngIf="details?.status?.toLowerCase() ==='draft' || (!details?.status && !nominationTab)"
        class="dropdown-item create-nomination" (click)="onCreateDraftNomination()">create nomination</a>
      <a *ngIf="details?.status?.toLowerCase()==='unpublished' || details?.status?.toLowerCase()==='created'" class="dropdown-item goToNominations"
        (click)="goToNominations()">go to nominations</a>
      <a *ngIf="details?.status" class="dropdown-item seeInNominationPannel" (click)="seeInNominationPannel()">see
        in nomination
        pannel</a>
        <a *ngIf="(!(desk?.includes('ethanol') && nominationTab) && ((details?.status && isEditable && details?.modeOfTransport?.toLowerCase() !== 'pipeline')||(isEditable && (details?.sys_status?.toLowerCase() === 'unpublished' || details?.sys_status?.toLowerCase() === 'published'|| details?.sys_status?.toLowerCase() === 'multiple'|| params.data['sys_status']?.toLowerCase() === 'failed')))) || ethanolMoreMenuEditable" class="dropdown-item edit-nomination" (click)="editNomination(details)">edit
          nomination</a>
      <a *ngIf="nominationTab && !lineItem" class="dropdown-item create-nom-with-ref"
        (click)="createNomWithRef()">create nom. with
        reference</a>
      <a *ngIf="nominationTab && !lineItem && desk !== 'clean products + usec + florida desk'" class="dropdown-item contract-balancinf"
        (click)="contractBalancing()">contract
        balancing</a>
      <a *ngIf="nominationTab && !lineItem && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk || desk === PipelineUswcPNWDesk)" class="dropdown-item contract-balancinf"
        (click)="vesselScheduleUpdate()" [ngClass]="{'disableVesselOption': disableVesselOption(details)}">vessel schedule update</a>
      <a *ngIf="nominationTab" class="dropdown-item publish" [ngClass]="{'disablePublish': disablePublish}" (click)="publish()">publish</a>
      <a *ngIf="(details?.status && isEditable) || nominationTab" class="dropdown-item delete"
        (click)="delete()">delete</a>
      <a *ngIf="nominationTab && !lineItem" class="dropdown-item see-details" (click)="seeDetails()">see details</a>
      <a class="dropdown-item see-details" (click)="openActivityTracker()"> see nomination activity </a>
    </div>
  </div>


</div>