import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import { ComponentPortal, DomPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import { ActivityTrackerComponent } from '../core/components/activity-tracker/activity-tracker.component';

/*
 * Service used to generate non-modal popup window via new popup browser window
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class PopupWindowService implements OnDestroy {

  constructor(private componentFactor: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { }

  ngOnDestroy(): void { }

  openWindow(componentUrl: string, dataToPass?: any){

    const windowToOpen = this.ensureOnlyOneInstanceOpens(componentUrl);

    /* TEMP SOLUTION TO RESIZE ISSUE, INVESTIGATE
    windowToOpen!.addEventListener('resize', function() {

      if (windowToOpen!.innerWidth < 800 && windowToOpen!.innerHeight < 500){
        windowToOpen!.resizeTo(800, 500);

      }else if (windowToOpen!.innerWidth < 800) {
        windowToOpen!.resizeTo(800, windowToOpen!.innerHeight);

      }else if (windowToOpen!.innerHeight < 500) {
        windowToOpen!.resizeTo(windowToOpen!.innerWidth, 500);
      }
    });
    */
      
    setTimeout(() => {
      this.createCDKPortal(windowToOpen, dataToPass);
    }, 1500)
  }

  //Reloads popup when mutli-click on the the open window button
  ensureOnlyOneInstanceOpens(componentUrl: string){

    const windowReference = window.open(componentUrl, componentUrl, "popup,width=800,height=500");
    windowReference!.location.href = componentUrl
    return windowReference
  }

  createCDKPortal(windowReference: Window | null, data?: any, ){

    if(windowReference){
      windowReference.document.body.innerText = '';
    }

    const outlet = new DomPortalOutlet(windowReference!.document.body, this.componentFactor, this.applicationRef, this.injector)
  
    const injector = this.createInjector(data);

    let componentInstance;
    windowReference!.document.title = 'Activity Tracker';
    componentInstance = this.attachComponentToPortal(outlet, injector);

    this.appendCSSStyles(windowReference);
  }
  
  createInjector(data: any){

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {provide: WINDOW_DATA, useValue: data}
      ]
    })
    return injector;
  }

  attachComponentToPortal(outlet: any, injector: any){
    const containerPortal = new ComponentPortal(ActivityTrackerComponent, null, injector)
    const containerRef: ComponentRef<ActivityTrackerComponent> = outlet.attach(containerPortal);
    return containerRef.instance;

  }

  appendCSSStyles(window: Window | null){
    document.querySelectorAll('link, style').forEach(htmlElement => {
      window!.document.head.appendChild(htmlElement.cloneNode(true));
    });

  }

}


export const WINDOW_DATA = new InjectionToken<any>("WINDOW_DATA");
