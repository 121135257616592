import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { activityTrackerAPIBody } from '../shared/interface/activity-tracker';
import { activitySelector } from '../core/components/activity-tracker/activity-tracker.component';

@Injectable({
  providedIn: 'root'
})
export class ActivityTrackerService {


  private headerValues: Set<string> = new Set<string>(
    ["transportsystem",
    "transportsystemname",
    "tdshipmenttype",
    "tdvehno",
    "carrier",
    "tdvehidentifier",
    "carrierName",
    "modeOfTransport",
    "udmmodeoftransportdesc",                                 
    "transportplanningpoint",
    "vehicleCode",
    "vehiclename",]
);

  constructor(private apiService: ApiService) { }

  isHeaderValue(checkValue: string): boolean{
    return this.headerValues.has(checkValue);
  }

  getBulkActivityTracking(nomId: string, itemId?: string, nominationKey?: string, Fields?: string[] ): Observable<any>{

    
    let payload: activityTrackerAPIBody = {
      Id: nomId,
      nominationItemId: itemId,
      NominationKey: nominationKey,
      Fields: Fields,
    }

    return this.apiService
      .post(
        `${environment.activityAPI}/GetNominationActivity`,
        'activityTracker',
        payload,
      ).pipe(
        map((data) => {
          return data;
        })
      )
  }
}
