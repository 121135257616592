<div class="more-menu">
  <ng-container>
    <!-- <ng-container *ngIf="details">
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    </ng-container> -->
  </ng-container>
  <div class="more-menu-dropdown">
    <button  class="ellipses-icon"
      (click)="moremwnu()"></button>
    <div *ngIf="!isOpen" class="dropdown-content" [ngClass]="[(rowIndex < 2) ? '' : 'place-top']">
      <a class="dropdown-item create-nom-with-ref"
        (click)="createNomWithRef(pipelineParentObj)">new nomination</a>
        <a class="dropdown-item create-nom-with-ref"
        (click)="navigateToNom(pipelineParentObj, '/nominations', pipelineParentObj?.udmItineraryPlanName)">link to existing nomination</a>
        <a class="dropdown-item edit-nomination" (click)="deleteCargoCca(pipelineParentObj)">delete</a>
    </div>
  </div>


</div>
