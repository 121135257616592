import { Component } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SubscriptionUsers, UpdateRuleInventory, UpdateRuleReconciliation } from '../../shared/interface/settings.interface';
import { AlertsNotificationsComponent } from '../alerts-notifications/alerts-notifications.component';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';
import { AlertsService } from 'src/app/services/alerts.service';

@Component({
  selector: 'app-alert-checkbox',
  templateUrl: './alert-checkbox.component.html',
  styleUrls: ['./alert-checkbox.component.scss']
})
export class AlertCheckboxComponent implements ICellRendererAngularComp {

  constructor( private alertsComponent: AlertsNotificationsComponent, private authService: CalAngularService, private alertSaveService: AlertSaveGuardService, private alertsService: AlertsService) { }

  public cellValue!: string;
  public isOpen = false;
  public rowIndex: any;
  public teamsBool!: boolean;
  public workspaceBool!: boolean;
  public emailBool!: boolean;
  public headerName: any;
  public updateRuleCheckboxInventory!: UpdateRuleInventory;
  public updateRuleCheckboxReconciliation!: UpdateRuleReconciliation;
  public subscriptions!: SubscriptionUsers[];
  public newActive!: { userEmail: string, isEmail: boolean, isTeams: boolean, isWorkspace: boolean, isActive: boolean };
  public newNotifList: string[] = [];
  public params!: ICellRendererParams
  public emailList: string[] = [];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params.data.subscription?.users == null) {
      this.headerName = params.colDef?.headerName;
      this.emailBool = false;
      this.workspaceBool = false;
      this.teamsBool = false;
    }
    else {
      for (const element of params.data.subscription.users) {
        this.rowIndex = params.rowIndex;
        this.headerName = params.colDef?.headerName;
        if (element.userEmail === this.authService.cvxClaimsPrincipal.email) {
            this.emailBool = element.isEmail;
            this.workspaceBool = element.isWorkspace;
            this.teamsBool = element.isTeams;
          }
      }
    }


  }

  onCheckboxClick(params: ICellRendererParams) {

    this.alertSaveService.addContent(this.generateIDText());
    
    for (let element of params.data.subscription.users) {
      this.emailList.push(element.userEmail);
      if (element.userEmail === this.authService.cvxClaimsPrincipal.email) {
        if (this.headerName === 'workspace') {
          element.isWorkspace = !element.isWorkspace
        } else if (this.headerName === 'email') {
          element.isEmail = !element.isEmail
        } else if (this.headerName === 'teams') {
          element.isTeams = !element.isTeams
        }
      }

    }

    if (!this.emailList.includes(this.authService.cvxClaimsPrincipal.email)) {
      if (this.headerName === 'workspace') {
        params.data.subscription.users.push({
          userEmail: this.authService.cvxClaimsPrincipal.email,
          isActive: false,
          isTeams: false,
          isWorkspace: true,
          isEmail: false,
        })
      } else if (this.headerName === 'email') {
        params.data.subscription.users.push({
          userEmail: this.authService.cvxClaimsPrincipal.email,
          isActive: false,
          isTeams: false,
          isWorkspace: false,
          isEmail: true,
        })
      } else if (this.headerName === 'teams') {
        params.data.subscription.users.push({
          userEmail: this.authService.cvxClaimsPrincipal.email,
          isActive: false,
          isTeams: true,
          isWorkspace: false,
          isEmail: false,
        })
      }
    }

    this.emailList = [];

    if (params.data.ruleType === 'Inventory' ) {
      this.updateRuleCheckboxInventory = {
          ruleId: params.data.ruleId,
          ruleName: params.data.ruleName,
          ruleDescription: params.data.ruleDescription,
          location: params.data.location,
          material: params.data.material,
          materialNumber: params.data.materialNumber,
          materialGroupNumber: params.data.materialGroupNumber,
          desk: params.data.desk,
          deskId: params.data.deskId,
          deskNickName: params.data.deskNickName,
          deskRegion: params.data.deskRegion,
          deskTag: params.data.deskTag,
          udmNickname: params.data.udmNickname,
          ruleType: params.data.ruleType,
          daysToValidate: {
            type: params.data.daysToValidate.type,
            NoOfdays: params.data.daysToValidate.NoOfdays,
          },
          ruleCondition: params.data.ruleCondition,
          parameter: params.data.parameter,
          alertFrequency: params.data.alertFrequency,
          subscription: {
            users: params.data.subscription.users
          },
          createdBy: params.data.createdBy,
          createdDate: params.data.createdDate,
          updatedBy: params.data.updatedBy,
          updatedDate: params.data.updatedDate,
          isActive: params.data.isActive
        }

      this.alertsComponent.updateNotifsInventoryPayload.push(this.updateRuleCheckboxInventory)

    } else if (params.data.ruleType === 'Reconciliation') {
      this.updateRuleCheckboxReconciliation = {
        ruleId: params.data.ruleId,
        ruleName: params.data.ruleName,
        ruleDescription: params.data.ruleDescription,
        location: params.data.location,
        material: params.data.material,
        materialNumber: params.data.materialNumber,
        udmNickname: params.data.udmNickname,
        ruleType: params.data.ruleType,
        alertFrequency: params.data.alertFrequency,
        thersold: params.data.thersold,
        subscription: {
          users: params.data.subscription.users
        },
        createdBy: params.data.createdBy,
        createdDate: params.data.createdDate,
        updatedBy: params.data.updatedBy,
        updatedDate: params.data.updatedDate,
        isActive: params.data.isActive
        };
      this.alertsComponent.updateNotifsReconciliationPayload.push(this.updateRuleCheckboxReconciliation)

      }

  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  generateIDText(): string{
    return this.headerName + " for " + this.params.data.ruleName + ": " + this.params.data.location + ", " + this.params.data.material;
  }
}
