import { DatePipe,Location } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject,Subject, takeUntil } from 'rxjs';
import { NominationService } from 'src/app/services/nomination.service';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { ToastService } from 'src/app/services/toast.service';
import { TRScreen } from 'src/app/shared/constants/terminal-replenishment.constant';
import { BCPFilters, BCPLocationsFilter, BCPProductsFilter } from 'src/app/shared/interface/bcp-dashboard.interface';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { selectDesk, selectDeskWiseLocationsAndProducts, selectEthanolTRFiltersResponse } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
@Component({
  selector: 'app-edit-custom-view',
  templateUrl: './edit-custom-view.component.html',
  styleUrls: ['./edit-custom-view.component.scss']
})
export class EditCustomViewComponent implements OnDestroy, OnChanges, OnInit {
  @Input() customViewDts: any;
  @Input() selectedCustomView!: any;
  @Input() updatedDesk: any;
  @Input() deskParam: any;
  setExpanded:any = true;
  setColOrder:any = true;
  filterValues$ = this.appStore.pipe(select(selectEthanolTRFiltersResponse));
  deskWiseDetails$ = this.store.pipe(select(selectDeskWiseLocationsAndProducts));
  desk$ = this.store.pipe(select(selectDesk));
  selectedDesk: any;
  region: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  filterHeaderName: any = [];
  cFilters: BCPFilters = { locationsFilter: [], productsFilter: [], desksFilter:[],offTakers:[] };
  saveUpdatedViewOfCategory: any;
  updateFilters: any = {};
  customCurrentViewControls: any = {};
  editCustomViewsForm!: FormGroup;
  responseData: any;
  showFormWarning: boolean = false;
  isshow: boolean = false;
  currentEditingViewName:any;
  selectedDeskParameter: any;
  checkedPipelineFilters: any;
  path$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.location.path()
  );
  DeskfilterObj:any;
  constructor(private appStore: Store<AppState>, private nominationService: NominationService, private router: Router, private utilities: Utilities,private toasterService: ToastService, private store: Store, private replenishmentService: ReplenishmentPlanningService, private datePipe: DatePipe,private location: Location) {
    this.editCustomViewsForm = new FormGroup({
      viewName: new FormControl(""),
      columnOrder: new FormControl(""),
      columnExpansion: new FormControl(""),
    });
  }

  setTogglesOffOn(data:any){
   
   if(data.terminalStatus){
    this.editCustomViewsForm.get("terminal")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("terminal")?.setValue(false);
   }

   if(data.supplierStatus){
    this.editCustomViewsForm.get("supplier")?.setValue(true);
   } else{
    this.editCustomViewsForm.get("supplier")?.setValue(false);
   }

   if(data.carrierStatus){
    this.editCustomViewsForm.get("carrier")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("carrier")?.setValue(false);
   }

   if(data.productStatus){
    this.editCustomViewsForm.get("product")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("product")?.setValue(false);
   }

   if(data.transportSystemStatus){
    this.editCustomViewsForm.get("transportSystem")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("transportSystem")?.setValue(false);
   }
   if(data.multiDeskStatus){
    this.editCustomViewsForm.get("multiDesks")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("multiDesks")?.setValue(false);
   }
   if(data.locationStatus){
    this.editCustomViewsForm.get("locations")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("locations")?.setValue(false);
   }
   if(data.productStatus){
    this.editCustomViewsForm.get("products")?.setValue(true);
   }
   else{
    this.editCustomViewsForm.get("products")?.setValue(false);
   }
  }

  ngOnInit(): void {
    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.selectedDesk = response.selectedDesk.toLowerCase();
        this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    })

    this.deskWiseDetails$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next:(response: any) => {
       this.DeskfilterObj = response.deskWiseInfo.terminal;
        }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterHeaderName = [];
    if (changes['updatedDesk']?.currentValue) {
      this.selectedDesk = changes['updatedDesk'].currentValue.deskValue.toLowerCase();
      this.selectedDeskParameter = changes['updatedDesk'].currentValue.deskParameter;
    }
    if (this.customViewDts.emailId && this.customViewDts.viewName) {

      this.currentEditingViewName = this.customViewDts.viewName;
      this.nominationService.getViewsByName(this.customViewDts, this.utilities.updateAPIParams()).subscribe(res => {
        this.responseData = JSON.parse(JSON.stringify(res.value));
        this.formHeaderSwitchCondition(res);

        this.filterHeaderName.map((control: any) => {
          this.addControlToForm(control);
        });
        this.setColOrderAndExpansionCondition(res);
        this.addControlToForm("columnOrder");
        if(this.path$.value !== '/bcpDashboard')
        this.addControlToForm("columnExpansion");
        this.updateFilters = JSON.parse(JSON.stringify(this.responseData));
        this.editCustomViewsForm.get("viewName")?.setValue(res?.value?.customViews[0]?.viewName);
        this.setTogglesOffOnCondition();
      })
    }
  }

  formHeaderSwitchCondition(res: any){
    if(this.path$.value === '/bcpDashboard'){
      this.formHeaderSwitch(res?.value?.customViews[0]?.bcpFilters)
      if(!this.selectedDesk.startsWith('clean products')){
        this.filterHeaderName = this.filterHeaderName.filter((item:any) => item !== 'multiDesks');
      }
    }else {
      if (res?.value?.desk.startsWith("clean products") || res?.value?.desk.startsWith("pipeline")) {
        this.formHeaderSwitch(res?.value?.customViews[0]?.pipelineFilters);
      } else if (res?.value?.desk.startsWith("ethanol")) {
        this.formHeaderSwitch(res?.value?.customViews[0]?.ethanolFilters);
      }
    }
  }

  setColOrderAndExpansionCondition(res: any){
    if(this.path$.value === '/bcpDashboard'){
      this.setBcpColOrderAndExpansion(res?.value?.customViews[0]?.bcpFilters)}
    else {if (res?.value?.desk.startsWith("clean products") || res?.value?.desk.startsWith("pipeline")) {
        this.setColOrderAndExpansion(res?.value?.customViews[0]?.pipelineFilters); 
    } else if (res?.value?.desk.startsWith("ethanol")) {
      this.setColOrderAndExpansion(res?.value?.customViews[0]?.ethanolFilters);
    }
  }
  }

  setTogglesOffOnCondition(){
    if(this.path$.value === '/bcpDashboard'){
      this.setTogglesOffOn(this.responseData.customViews[0].bcpFilters)}
    else { 
      if (this.responseData.desk.startsWith("clean products") || this.responseData.desk.startsWith("pipeline")) {
          this.setTogglesOffOn(this.responseData.customViews[0].pipelineFilters);
      } else if (this.responseData.desk.startsWith("ethanol")) {
        this.setTogglesOffOn(this.responseData.customViews[0].ethanolFilters);
      }
    }
  }

  formHeaderSwitch(filters: any) {
    for (const [key, value] of Object.entries(filters)) {
      if (key === "terminal" || key === "supplier" || key === "carrier" || key === "transportSystem" || key === "product"
            || key === "multiDesks" || key === "locations"|| key === "products") {
              let valuenullorempty:any = value
              if(valuenullorempty.length >0){
                this.filterHeaderName.push(key);
                this.customCurrentViewControls[key] = value;
              }
      }
    }
  }

  
  setColOrderAndExpansion(filters: any) {
    if (!filters?.expandedColumns.length) {
      this.setExpanded = false;
      this.editCustomViewsForm.get('columnExpansion')?.setValue(false);
    }
    else {
      this.editCustomViewsForm.get('columnExpansion')?.setValue(true);
    }
    if (filters?.columnOrders?.nominationColumnOrder?.length === 0 && filters?.columnOrders?.replenishmentColumnOrder?.length === 0) {
      this.setColOrder = false;
      this.editCustomViewsForm.get('columnOrder')?.setValue(false);
    } else {
      this.editCustomViewsForm.get('columnOrder')?.setValue(true);
    }
  }
  setBcpColOrderAndExpansion(filters:any){
    if(filters?.columnOrders?.bcpColumnOrder?.length === 0){
      this.setColOrder = false;
      this.editCustomViewsForm.get('columnOrder')?.setValue(false);
    } else {
      this.editCustomViewsForm.get('columnOrder')?.setValue(true);
    }
  }
  addControlToForm(headerName: any) {
    this.editCustomViewsForm.addControl(headerName, new FormControl(true));
  }
  closePopup() {
    this.nominationService.editCustomView$.next(false);
  }

  validateToggles() {
    let formmValues = Object.values(this.editCustomViewsForm.value);
    let name = this.editCustomViewsForm.value['viewName'];
    let index = formmValues.indexOf(name);
    formmValues.splice(index, 1);
    let isAllTurnedOff = formmValues.every(val => { return val === false; });
    if(isAllTurnedOff){
      this.showFormWarning = true;
      setTimeout(() => {
        this.showFormWarning = false;
      },8000);
    }
    return !isAllTurnedOff;
  }

  allValuesAreChecked(el:any,index:any,arr:any){
     if(index !== 0 && el.checked){
      return true
     } else if(index !== 0 && !el.checked){
      return false;
     } 
     else{
      return true
     }
  
  }

  onBcpUpdateFilters(event: any, values: any, category: any) {
    
    let filterChangeValue:any;
    if(category === 'multiDesks')
     filterChangeValue = { name: event.target.value, id: values.deskId, checked: event.target.checked };
    else if(category === 'locations')
     filterChangeValue = { name: event.target.value, id: values.deskId, checked: event.target.checked }; 
    else if(category === 'products')
     filterChangeValue = { name: event.target.value, id: values.deskId, checked: event.target.checked };
    
    if(filterChangeValue.name === 'all' && filterChangeValue.checked ){
      this.customCurrentViewControls[category].map((el:any) => {
        el.checked = true;
      })
    }
    else if(filterChangeValue.name === 'all' && !filterChangeValue.checked ){
      this.customCurrentViewControls[category].map((el:any) => {
        el.checked = false;
      })
    }
    else if(filterChangeValue.name !== 'all'){
      let checkedstatus : any;
      if(category === 'multiDesks'){
          this.customCurrentViewControls['multiDesks'].forEach((el: any) => {
                if(el.deskName === filterChangeValue.name)
                    el['checked'] = event.target.checked;
        });
      
    }
    if(category === 'locations'){
         this.customCurrentViewControls['locations'].forEach((loc:any)=>{ 
          if(loc.location === filterChangeValue.name)
            loc['checked'] = event.target.checked;
         });
      //checkedstatus = this.customCurrentViewControls[category].filter((item:any)=>item.location  !== 'all').every((item:any) =>item.checked);
    
    }
      if(category === 'products'){
        this.customCurrentViewControls['products'].forEach((pro:any)=>{ 
          if(pro.productName === filterChangeValue.name && pro.product === values.product)
            pro['checked'] = event.target.checked;
        });
       // checkedstatus = this.customCurrentViewControls[category].filter((item:any)=>item.productName  !== 'all').every((item:any) =>item.checked);
      }

  }
    if (this.path$.value === '/bcpDashboard'){
        this. updateBcpDeskFiltersList(event, values, category);
    }
  }

  onUpdateFilters(event: any, values: any, category: any) {
    const filterChangeValue = { name: event.target.value, id: values.id, checked: event.target.checked };
    
    if(filterChangeValue.name === 'all' && filterChangeValue.checked ){
      this.customCurrentViewControls[category].map((el:any) => {
        el.checked = true;
      })
    }
    else if(filterChangeValue.name === 'all' && !filterChangeValue.checked ){
      this.customCurrentViewControls[category].map((el:any) => {
        el.checked = false;
      })
    }
    else if(filterChangeValue.name !== 'all' && !filterChangeValue.checked){
      this.customCurrentViewControls[category][0]["checked"] = false;
    }
    else if(this.customCurrentViewControls[category].every(this.allValuesAreChecked)){
      this.customCurrentViewControls[category][0]["checked"] = true;
    }
    if (this.updateFilters.desk.startsWith("clean products") || this.updateFilters.desk.startsWith("pipeline")) {
      this.updateFiltersList(event, values, category);
    } else if (this.updateFilters.desk.startsWith("ethanol")) {
      this.updateFilterValues(this.updateFilters.customViews[0].ethanolFilters, category, filterChangeValue, event, values);
    }
  }

  dateCellRenderer(date: any, format: any) {
    if (date) {
        const year = Number(date.substr(0, 4));
        const month = Number(date.substr(4, 2));
        const day = Number(date.substr(6, 2));
        const dateFormat = new Date(year, month - 1, day);
        return this.datePipe.transform(dateFormat, format);
    }
    return '';
}

  updateBcpDeskFiltersList(event: any, values: any, category: any) {
    this.updateFilters.customViews[0].bcpFilters[category].forEach((el: any) => {
      if(category === 'multiDesks'){
        if (el.deskId && el.deskId === values.deskId) {
          el["checked"] = event.target.checked;
        }
      }else if(category === 'locations'){
        if(el.location.toLowerCase() === values.location.toLowerCase()){
          el["checked"] = event.target.checked;
        }
      }else if(category === 'products'){
        if(el.productName.toLowerCase() === values.productName.toLowerCase()){
          el["checked"] = event.target.checked;
       }
      }
    });
    this.getBcpFilters(true, category, values);
    
  }

  updateFiltersList(event: any, values: any, category: any) {
    this.updateFilters.customViews[0].pipelineFilters[category].forEach((el: any) => {
      if (el.id && el.id === values.id) {
        el["checked"] = event.target.checked;
      }

      if (values.name === "all" && !values.checked) {
        el["checked"] = false;
      } else if (values.name === "all" && values.checked) {
        el["checked"] = true;
      }
    });
    if (category === "transportSystem" || category === "terminal") {
      this.getPipelineFilters(true, category);
    }
  }

  getPipelineFilters(parentFilterChange: any, headerName: any) {
    const filters = this.updateFilters.customViews[0].pipelineFilters;
    const transportSystemIds = this.getCheckedIds(filters?.transportSystem);
    const terminalIds = this.getCheckedIds(filters?.terminal);
    const productIds = this.getCheckedIds(filters?.product);
    let urBody = this.getUpdatedPipelinePayload(headerName, parentFilterChange, transportSystemIds, terminalIds);
    let data = this.utilities.getFilterDataList(false, transportSystemIds, terminalIds)
      if (data?.value && (data?.value?.terminal?.length || data?.value?.product?.length || data?.value?.transportsystem?.length)) {
        let terminal = data?.value?.terminal.map((terminal: any) => { return { name: terminal.terminalName, id: terminal.terminalCode } });
        let product = data?.value?.product.map((prod: any) => { return { name: prod.productName, id: prod.productCode } });
        let transportsystem = data?.value?.transportsystem.map((ts: any) => { return { name: ts.transportSystemName, id: ts.transportSystemCode } });
        const params = { parentFilterChange, terminal, product, transportsystem };
        console.log(params);
        if (headerName === "transportSystem") {
          this.customCurrentViewControls["terminal"] = urBody.transportSystem?.length > 0 ? [{name: "all"}, ...terminal] : [];
          this.updateFilters.customViews[0].pipelineFilters["terminal"] = this.customCurrentViewControls["terminal"];
          this.updateFilters.customViews[0].pipelineFilters["terminal"].forEach((el: any) => el["checked"] = terminalIds.find((ele: any) => ele === el?.id) || false);
          this.customCurrentViewControls["product"] = urBody.transportSystem?.length > 0 ? [{name: "all"}, ...product] : [];
          this.updateFilters.customViews[0].pipelineFilters["product"] = this.customCurrentViewControls["product"];
          this.updateFilters.customViews[0].pipelineFilters["product"].forEach((el: any) => el["checked"] = productIds.find((ele: any) => ele === el?.id) || false);
        } else if (headerName === "terminal") {
          this.customCurrentViewControls["product"] = [{name: "all"}, ...product];
          this.updateFilters.customViews[0].pipelineFilters["product"] = this.customCurrentViewControls["product"];
          this.updateFilters.customViews[0].pipelineFilters["product"].forEach((el: any) => el["checked"] = productIds.find((ele: any) => ele === el?.id) || false);
        }
        console.log(this.customCurrentViewControls);
      }
  }


  getBcpFilters(parentFilterChange: any, headerName: any,actualValue:any) {
    const filters = this.updateFilters.customViews[0].bcpFilters;
    const deskIds = this.getBcpCheckedIds(filters?.multiDesks,'multiDesks');
    const locationIds = this.getBcpCheckedIds(filters?.locations,'locations');
    const productIds = this.getBcpCheckedIds(filters?.products,'products');
    let urBody :any;
    const currdesk = this.selectedDeskParameter;
    if(headerName === 'multiDesks'|| headerName ==='locations'){
      urBody = this.getUpdatedBcpPayload(headerName, parentFilterChange, locationIds, productIds);
    }
    if(headerName === "multiDesks"){
      const selectdeskLocations = this.DeskfilterObj.filter((item:any)=>deskIds.includes(item.deskId)).sort((a:any,b:any)=>a.deskId -b.deskId);
      this.formatingBcpFilter(selectdeskLocations);
      this.cFilters.locationsFilter.forEach((loc:any)=>{
        if(locationIds.length<=0)
        {
            if(loc.checked == true)
              locationIds.push(loc.location);
        }
        else if(locationIds.includes(loc.location)){
          loc.checked =true;
        }
      });
      let finalLocationId = this.cFilters.locationsFilter.filter((item:any) => item.checked == true).map((loc:any) => loc.location);

      let listofProduct:any = [];  
      let selectLocationsPro = this.DeskfilterObj.filter((item:any)=>finalLocationId.includes(item.terminalCode)).sort((a:any,b:any)=>a.deskId -b.deskId);
      selectLocationsPro.forEach((loc:any)=>{
             loc.products.forEach((pro:any)=>{
              listofProduct.push({product:pro.productCode,productName:pro.productName, checked:true,deskName:pro.deskName,deskId:pro.deskId}); 
             });  
      });
      
      const uniqueProducts = Array.from(new Set(listofProduct.map((item:any) => item.product)))
      .map(product => {
        return listofProduct.find((item:any) => item.product === product);
      });
      
      this.cFilters.productsFilter = [...uniqueProducts]

      this.customCurrentViewControls["locations"] = deskIds.length > 0 ? [{location: "all",checked:false ,deskIds:'all',deskName:'all'}, ...this.cFilters.locationsFilter] : [];      
      this.customCurrentViewControls["products"] = finalLocationId.length > 0 ? [{product:"all",productName: "all",checked:false, deskName : "all",deskId: "all"}, ...this.cFilters.productsFilter] : [];
      this.updateFilters.customViews[0].bcpFilters['locations'] = this.customCurrentViewControls["locations"];
      this.updateFilters.customViews[0].bcpFilters['multiDesks'] = this.customCurrentViewControls['multiDesks'];
      this.updateFilters.customViews[0].bcpFilters["products"] = this.customCurrentViewControls["products"];
    }
    else if(headerName === "locations"){
      this.cFilters = { locationsFilter: [], productsFilter: [], desksFilter:[],offTakers:[] };
      let listofProduct:any = [];  
      let selectLocationsPro = this.DeskfilterObj.filter((item:any)=>locationIds.includes(item.terminalCode)).sort((a:any,b:any)=>a.deskId -b.deskId);
      selectLocationsPro.forEach((loc:any)=>{
             loc.products.forEach((pro:any)=>{
              listofProduct.push({product:pro.productCode,productName:pro.productName, checked:true,deskName:pro.deskName,deskId:pro.deskId}); 
             });  
      });
      
      const uniqueProducts = Array.from(new Set(listofProduct.map((item:any) => item.product)))
      .map(product => {
        return listofProduct.find((item:any) => item.product === product);
      });

      this.cFilters.productsFilter.push(...uniqueProducts);
      
      this.customCurrentViewControls["products"] = urBody.locations.length > 0 ? [{product:"all", productName: "all",checked:false, deskName : "all",deskId: "all"}, ...this.cFilters.productsFilter] : [];
      this.updateFilters.customViews[0].bcpFilters['locations'] = this.customCurrentViewControls["locations"];
      this.updateFilters.customViews[0].bcpFilters["products"] = this.customCurrentViewControls["products"];
    } else if(headerName === 'products'){
      this.updateFilters.customViews[0].bcpFilters["products"] = this.customCurrentViewControls["products"];
    }
    if(this.customCurrentViewControls['products'].length > 0)
      this.customCurrentViewControls['products'][0]["checked"] = this.customCurrentViewControls['products'].filter((item:any)=>item.productName  !== 'all').every((item:any) =>item.checked) ? true : false;
    if(this.customCurrentViewControls['locations'].length > 0)
      this.customCurrentViewControls['locations'][0]["checked"] = this.customCurrentViewControls['locations'].filter((item:any)=>item.productName  !== 'all').every((item:any) =>item.checked) ? true : false;
  }

  formatingBcpFilter(filterData:any){ 
    this.cFilters = { locationsFilter: [], productsFilter: [], desksFilter:[], offTakers:[] };
   filterData.forEach((terminal:any) =>{ 
      let listofProduct:any = [];      
      let checkProduct:boolean = false;       
        if(this.cFilters.locationsFilter.length<1)
        { 
          checkProduct = true;
          terminal?.products?.forEach((element :any)=> {
            listofProduct.push({product:element.productCode,productName:element.productName, checked:checkProduct,deskName:terminal.deskName,deskId:terminal.deskId}); 
          });                        
          this.cFilters.locationsFilter?.push({location:terminal.terminalCode, checked:true,products:listofProduct,deskName:terminal.deskName,deskId:terminal.deskId});            
        }
        else
        { 
          terminal?.products?.forEach((element :any)=> {
            listofProduct.push({product:element.productCode,productName:element.productName, checked:true,deskName:terminal.deskName,deskId:terminal.deskId}); 
          });  
          this.cFilters.locationsFilter?.push({location:terminal.terminalCode, checked:false,products:listofProduct,deskName:terminal.deskName,deskId:terminal.deskId});        
        }   
    });

    if(this.cFilters.locationsFilter.length > 0)
      {
        this.cFilters.productsFilter = [...this.cFilters.locationsFilter[0].products]; 
      }
  }
  
  getBcpCheckedIds(element: any,filtername:any) {
    let list:any;
    if(filtername === 'multiDesks')
       list = element.filter((ele: any) => ele.deskId && ele.checked).map((item: any) => item.deskId);
     else if(filtername === 'locations')
       list = element.filter((ele: any) => ele.location && ele.checked).map((item: any) => item.location);
     else if(filtername === 'products')
       list = element.filter((ele: any) => ele.product && ele.checked).map((item: any) => item.product);
    return list;
  }

  getCheckedIds(element:any){
    const list = element.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.id);
    return list;
  }

  getUpdatedBcpPayload(headerName: any, parentFilterChange: any, locations: any, products: any) {
    let urBody: any = {
      desk: `pl${this.selectedDeskParameter}`,
      region: this.region,
      includeXLocations: true
    }
    if (headerName?.toLowerCase() === 'multidesks') {
      urBody.locations = parentFilterChange ? locations : undefined;
    } else if (headerName?.toLowerCase() === 'locations') {
      urBody.locations = parentFilterChange ? locations : undefined;
      urBody.products =  parentFilterChange ? products : undefined;
    } else if (headerName?.toLowerCase() === 'products'){
      urBody.products =  parentFilterChange ? products : undefined;
    }
    return urBody
  }

  getUpdatedPipelinePayload(headerName: any, parentFilterChange: any, transportSystem: any, slectedTerminal: any) {
    let urBody: any = {
      desk: `pl${this.selectedDeskParameter}`,
      region: this.region,
      includeXLocations: true
    }
    if (headerName?.toLowerCase() === 'transportsystem') {
      urBody.transportSystem = parentFilterChange ? transportSystem : undefined
    } else if (headerName?.toLowerCase() === 'terminal') {
      urBody.transportsystem = parentFilterChange ? transportSystem : undefined
      urBody.terminal = parentFilterChange ? slectedTerminal : undefined
    }
    return urBody
  }

  updateFilterValues(filters: any, category: any, filterChangeValue: any, event: any, values: any) {
    filters[category].map((el: any) => {
      if (el.name === filterChangeValue.name && this.editCustomViewsForm.get(category)?.value) {
        el.checked = filterChangeValue.checked;
      }
    });

    if(event.currentTarget.defaultValue === 'all' && !event.currentTarget.checked && this.editCustomViewsForm.get(category)?.value){
      filters[category].map((el: any) => {
          el.checked = false;
      });
    }
    else if (event.currentTarget.defaultValue === 'all' && event.currentTarget.checked && this.editCustomViewsForm.get(category)?.value) {
      filters[category].map((el: any) => {
        el.checked = true;
    });
    }

    this.updateFiltersOptionValues(filters, event, values, category);
  }

  updateFiltersOptionValues(filters: any, event: any, values: any, category: any){
    if(values.name && !event.currentTarget.checked){
      filters[category].map((el: any) => {
        if(el.name === values.name){
          filters[category][0].checked = false;
          el.checked = false;
        }
    });
    }

    if(values.name && event.currentTarget.checked && this.editCustomViewsForm.get(category)?.value){
      filters[category].map((el: any) => {
        if(el.name === values.name){
          el.checked = true;
        }
    });
    }
  }

  confirm() {
    let validated: any = this.validateToggles();
    if (validated) {
      if (this.editCustomViewsForm.get("columnOrder")?.value || this.editCustomViewsForm.get("columnExpansion")?.value) {
        const sendDataToComponent = {
          tab: this.router.url,
          function: "editCustomViewFilters",
          type: '',
          values: { filterValues: this.updateFilters.customViews[0], columnOrderChecked: this.editCustomViewsForm.get("columnOrder")?.value, columnExpansionChecked: this.editCustomViewsForm.get("columnExpansion")?.value },
        };
        const sendData = this.utilities.sendDataToChild(sendDataToComponent);
        this.utilities.addUnsubscribableList(sendData);
      }
      if(this.path$.value !== "/bcpDashboard")
      {
        if (this.updateFilters.desk.startsWith("clean products") || this.updateFilters.desk.startsWith("pipeline")) {
          this.setColumnOrderColumnExpansion(this.updateFilters.customViews[0].pipelineFilters);
        } else if (this.updateFilters.desk.startsWith("ethanol")) {
          this.setColumnOrderColumnExpansion(this.updateFilters.customViews[0].ethanolFilters);
        }
      }
      else{
        this.setBcpColumnOrderColumnExpansion(this.updateFilters.customViews[0].bcpFilters);
      }


      this.updateFilters.customViews[0].viewName = this.editCustomViewsForm.get("viewName")?.value;
      this.nominationService.editCustomView$.next(false);
      this.updateApi(this.updateFilters);
      this.toasterService.setToastNotification({
        show: true,
        type: 'success',
        msg: "Your custom view was edited. You can manage your custom content using the User Menu at the top right.",
      });
    }
  }

  setColumnOrderColumnExpansion(filters: any) {
    if (!this.editCustomViewsForm.get("columnOrder")?.value) {
      filters.columnOrders.nominationColumnOrder = [];
      filters.columnOrders.replenishmentColumnOrder = [];
    }
    if (!this.editCustomViewsForm.get("columnExpansion")?.value) {
      filters.expandedColumns = [];
    }
  }

  setBcpColumnOrderColumnExpansion(filters: any) {
    if (!this.editCustomViewsForm.get("columnOrder")?.value) {
      filters.columnOrders.bcpColumnOrder = [];
    }
  }

  updatedCustomStatusInput(filters: any, event: any) {
    if (event === "ethanol") {
      filters.terminalStatus = this.editCustomViewsForm.get("terminal")?.value;
      filters.supplierStatus = this.editCustomViewsForm.get("supplier")?.value;
      filters.carrierStatus = this.editCustomViewsForm.get("carrier")?.value;
    } else if (event === "pipeline") {
      filters.terminalStatus = this.editCustomViewsForm.get("terminal")?.value;
      filters.productStatus = this.editCustomViewsForm.get("product")?.value;
      filters.transportSystemStatus = this.editCustomViewsForm.get("transportSystem")?.value;
    } else if (event == "bcpView"){
      filters.multiDeskStatus = this.editCustomViewsForm.get('multiDesks')?.value;
      filters.locationStatus = this.editCustomViewsForm.get('locations')?.value;
      filters.productStatus = this.editCustomViewsForm.get('products')?.value;
    }
  }

  updateApi(updatedValues: any) {
    if(this.path$.value !== "/bcpDashboard")
    {
      if (updatedValues.desk.startsWith("clean products") || updatedValues.desk.startsWith("pipeline")) {
        this.updatedCustomStatusInput(updatedValues.customViews[0].pipelineFilters, "pipeline");
      } else if (updatedValues.desk.startsWith("ethanol")) {
        this.updatedCustomStatusInput(updatedValues.customViews[0].ethanolFilters, "ethanol");
      } 
    }
    else{
      this.updatedCustomStatusInput(updatedValues.customViews[0].bcpFilters, "bcpView");
    } 
    const payload = {
      id: updatedValues.id,
      emailId: updatedValues.emailId.toString(),
      desk: this.utilities.updateAPIParams(),
      screen: this.path$.value == "/bcpDashboard" ? TRScreen.BCPDashboard : null,
      customView: updatedValues.customViews[0]      
    };
    this.nominationService.updateCustomView(payload).subscribe({
      next: data => {
        if (payload.customView.viewName === this.selectedCustomView && this.selectedCustomView !== 'default view') {
          this.nominationService.applyEditedViewToUi$.next(payload.customView.viewName);
          this.nominationService.setSelectedView = payload.customView.viewName;
          localStorage.setItem("selectedView", payload.customView.viewName);
        }

        localStorage.removeItem("nomColData");
        localStorage.removeItem("trColExpandData");
        localStorage.removeItem("trColOrder");
        localStorage.removeItem("pivotByParent");
        localStorage.removeItem("pivotByChild");
        this.nominationService.customViewSidePanel$.next(false);

        if (this.selectedCustomView === this.currentEditingViewName) {
          this.nominationService.setSelectedView = payload.customView.viewName;
          localStorage.setItem("selectedView", payload.customView.viewName);
          this.nominationService.triggerGetAllFromCreateView$.next({ isTrigger: true, selectedView: payload.customView.viewName });
          this.nominationService.applyEditedViewToUi$.next(payload.customView.viewName);
        }
        else {
          this.nominationService.triggerGetAllFromCreateView$.next({ isTrigger: true, selectedView: null });
        }
        this.nominationService.triggerUpdateViewname.next("update");
      }, error: err => {
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message
        });
      }
    });
  }

  
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}