import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CalAngularService } from '@cvx/cal-angular';
import { AlertsService } from 'src/app/services/alerts.service';
import { ToastService } from 'src/app/services/toast.service';
import { AlertsNotificationsComponent } from 'src/app/settings-alerts/alerts-notifications/alerts-notifications.component';
import { HomeComponent } from 'src/app/home/home.component';
import { AlertLocationData, CreateRuleInventory, CreateRuleReconciliation } from 'src/app/shared/interface/settings.interface';
import { UpdateRuleInventory, UpdateRuleReconciliation } from '../../../shared/interface/settings.interface';
import { MdmService } from 'src/app/services/mdm.service';
import { MDMDeskLocations, MDMDeskValues, MDMLocationMaterials } from 'src/app/shared/interface/mdm.interface';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { Subject, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectDesk } from 'src/app/shared/store/selector/app.selector';

@Component({
  selector: 'app-create-alerts',
  templateUrl: './create-alerts.component.html',
  styleUrls: ['./create-alerts.component.scss']
})
export class CreateAlertsComponent implements OnInit {
  @Output() closeModalPopup = new EventEmitter();
  deleteRuleModal = { open: false, modal: 'deleteRule' }
  updateRuleModal = { open: false, modal: 'updateRule' }
  public createAlertForm!: FormGroup;
  public terminalDropdown: string[] = [];
  public materialDropdown: string[] = [];
  public rulesDropdown: string[] = [];
  public ruleNamesList: MinimumRuleData[] = [];
  public activeRulesDropdown: MinimumRuleData[] = [];
  public selectedRuleForm!: MinimumRuleData;
  public currentMaxRuleNum: number = 0;
  public allowCreate: boolean = true;
  public disableCreate: boolean = false;
  public disableRuleType: boolean = false;
  alertData!: any;
  public createRuleInventory!: CreateRuleInventory;
  public createRuleReconciliation!: CreateRuleReconciliation;
  public updateRuleInventory!: UpdateRuleInventory;
  public updateRuleReconciliation!: UpdateRuleReconciliation;
  public selectedFreqFull: string = '';
  public initializeFillIn!: number;
  public ruleId!: string;
  public createdBy!: string;
  public createdDate!: string;
  public materialNumber!: string;
  public materialGroup!: string;
  public udmNickname!: string;
  public isActive!: boolean;
  public subscription!: { users: [{ userEmail: string; isEmail: boolean; isTeams: boolean; isWorkspace: boolean; isActive: boolean; }]; };
  selectedDesk$ = this.store.pipe(select(selectDesk));
  destroy$: Subject<boolean> = new Subject<boolean>();


  ruleType = '';
  ruleReference = '';
  ruleDays = '';
  ruleCompare = '';
  ruleParams = '';
  ruleFreq = '';
  public ruletypeDropdown = ["Inventory"]; //Commenting Reconciliation
  public referenceDropdown = ["next"]; //Removed "previous"
  public amountOfDaysDropdown = ["5", "10"];
  public ruleDropdown = ["<=",">="];
  public maxParameterDropdown = ["MaxInventory","MaxESL"];
  public minParameterDropdown = ["MinESL","MinInventory"];
  public percentDropdown = ["15"];
  isDeleteRule = false;

  public rulesGridData: AlertLocationData[] = [];

  alerts: any = {
    inventoryForecastAlert: {
      isAlertActive: false,
      description: 'if inventory is forecasted to drop below min esl in the next 3 days',
      frequency: 'D',
      isEmailChecked: true,
      isNotificationChecked: true,
    },
  };
  selectedMaterial = '';
  selectedTerminal = '';
  selectedRuleType = '';
  selectedReference = '';
  selectedNoOfDays = '';
  selectedRules = '';
  selectedParameters = '';
  selectedPercent = '';
  selectedRuleName = '';
  selectedFreq = '';
  maxRuleNumber: number = 1;
  deskValues: MDMDeskValues[] = [];
  deskList: number[] = [];
  locValues: MDMDeskLocations[] = [];
  locList: string[] = [];
  public matValues: MDMLocationMaterials[] = [];
  public matList: string[] = [];
  public matNameList: string[] = [];
  public matNumList: string[] = [];
  public matUdmList: string[] = [];
  public matGrpList: string[] = [];
  desk: any;
  subscribedUserEmail = '';
  selectedUserList: string[] = [];
  isFirstLoad = true;

  constructor(private alertsService: AlertsService, private store: Store, private toasterService: ToastService, private authService: CalAngularService, private utilities: Utilities, private alertsComponent: AlertsNotificationsComponent, private homeComponent: HomeComponent, private mdmService: MdmService) {
    this.alertsService.currentLocation.subscribe((location: string) => this.selectedTerminal = location)
    this.alertsService.currentMaterial.subscribe((material: string) => this.selectedMaterial = material)
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
   }


  ngOnInit(): void {
    this.getAlertRulesData();
    this.alertsService.currentLocation.subscribe((location: string) => this.selectedTerminal = location)
    this.alertsService.currentLocation.subscribe(() => this.getMaterialData(this.selectedTerminal))
    this.alertsService.currentMaterial.subscribe((material: string) => this.selectedMaterial = material)
    this.alertsService.currentMaterial.subscribe(() => this.getRulesByTerminalMaterial(this.selectedTerminal, this.selectedMaterial))
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
    this.alertsService.currentRuleName.subscribe(() => this.getRuleDetails(this.selectedTerminal, this.selectedMaterial, this.selectedRuleName))
    this.alertsService.currentRuleName.subscribe((ruleName: string) => this.selectedRuleName = ruleName)
    this.getDeskData();

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk
          this.clearOptions();
          if(!this.isFirstLoad) {
            this.getDeskData()

          }
          this.isFirstLoad = false
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });
  }

  getInventoryDetails(inventoryData: any){
    this.setCreateBoolean();
    this.selectedReference = inventoryData.selectedReference;
    this.selectedNoOfDays = inventoryData.selectedNoOfDays;
    this.selectedRules = inventoryData.selectedRules;
    this.selectedParameters = inventoryData.selectedParameters;
  }

  getReconciliationDetails(reconciliationData: any){
    this.setCreateBoolean();
    this.selectedPercent = reconciliationData.selectedPercent;
  }

  getDeskData() {
    this.deskList = [];

    if(localStorage.getItem('deskId') != null){
      this.deskList.push(Number(localStorage.getItem('deskId')!))
      this.getLocationData();
    }else{

      this.mdmService.getAllDesks().subscribe({
        next: (response: any) => {

          this.deskValues = response.value;
          this.deskValues.forEach(currentDesk => {
            if(localStorage.getItem("selectedDesk")!.includes(currentDesk.name.toLowerCase())){
              this.deskList.push(Number(currentDesk.id))
          }})
        },
        error: (err: any) =>{
          console.log(err);
        },
        complete: () => {
          this.getLocationData();
        },
      })
    }
  }

  getLocationData() {
    this.locList = []
    let locSet = new Set<string>()
    const locPayload = {
      "deskIds": this.deskList
    };
    this.mdmService.getDeskLocation(locPayload).subscribe({
      next: (response: any) => {
        this.locValues = response.value
        for (let i of this.locValues) {
          locSet.add(i.locationCode)
        }
      },
      error: (err: any) => {
          console.log(err);
      },
       complete: () => { 
        
        this.locList = [...locSet]

        this.removeDashX();

        this.locList = [...this.locList].sort((a, b) => {

          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
       },
    })
  }

  updateDeskLocations() {
    this.deskList = [];
    for (let k of this.deskValues) {
      if (k.name.toLowerCase() === (this.desk != "" ? this.utilities.formatSelectedDesk(this.desk) : "panama")) {
        this.deskList.push(Number(k.id))
      }
    }
    this.getLocationData();
  }

  getMaterialData(selectedTerminal: string) {
    this.matNameList = [];
    this.matNumList = [];
    this.matGrpList = [];
    this.selectedMaterial = '';
    const matPayload = {
      "deskIds": [],
      "activeOnly": false,
      "locationIds": [selectedTerminal]
    }
    this.mdmService.getMaterialLocationMappings(matPayload).subscribe({
      next: (response: any) => {
        this.matValues = response.value.value
        for (let i of this.matValues) {
          if(i.locationCode === this.selectedTerminal){
            for (let j of i.locationMaterials) {
              this.checkForValidMaterial(j);
            }
          }
        }

        if(this.isEthanolDesk()){
          this.handleEthanol(true);
        }else{
          this.handleEthanol(false);
        }
      },
      error: (err: any) => {
          console.log(err);
      },
       complete: () => { },
    })

  }

  checkForValidMaterial(currentLocationMaterial: any){
    if(!this.selectedTerminal.includes(currentLocationMaterial.materialDescription) && currentLocationMaterial.active){
      this.matNameList.push(currentLocationMaterial.materialDescription);
      this.matNumList.push(currentLocationMaterial.materialNumber)
      this.matUdmList.push(currentLocationMaterial.udmNickname)

      let materialGroup = currentLocationMaterial.materialSubgroup != null? 
            currentLocationMaterial.materialGroup + "-" + currentLocationMaterial.materialSubgroup: 
            currentLocationMaterial.materialGroup;
      this.matGrpList.push(materialGroup); 
    }
  }


  getAlertRulesData() {
    this.terminalDropdown = [];
    this.materialDropdown = [];
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.ruleNamesList = [];
        this.rulesGridData = response.result;
        response.result?.forEach((element: any) => {
          this.terminalDropdown.push(element.location);
          for (const i of element.locationMaterials) {
            if (!this.materialDropdown.includes(i.material)) {
              this.materialDropdown.push(i.material);
            } for (const j of i.locationMaterialRules) {

              let currentRule: MinimumRuleData = {
                ruleName: j.ruleName,
                ruleCreator: j.createdBy,
                ruleId: j.ruleId,
                terminalName: j.location,
                materialName: j.material, 
              }

              this.ruleNamesList.push(currentRule);
              this.activeRulesDropdown.push(currentRule)
            }
          }
        })
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getMaxRuleNumber(): any{

    let number= Number(this.activeRulesDropdown.length == 0? 0: this.activeRulesDropdown.at(-1)?.ruleName.split(" ")[1]);
    return number;
  }


  getMaxRuleNum() {
    this.terminalDropdown = [];
    this.materialDropdown = [];
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.ruleNamesList = [];
        response.result?.forEach((element: any) => {
          for (const i of element.locationMaterials) {
            if(i != null){
              for (const j of i.locationMaterialRules) {
                this.ruleNamesList.push(j.ruleName);
              }
            }
          }
        })
        let numList = []
        for (const element of this.ruleNamesList) {
          if (element.ruleName?.startsWith('rule ')) {
            numList.push(parseInt(element.ruleName.slice(5)))
          }
        }
        if (numList.length > 0) {
          this.currentMaxRuleNum = Math.max(...numList);
        }
        else {
          this.currentMaxRuleNum = 0
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getRulesByTerminalMaterial(selectedTerminal: string, selectedMaterial: string) {
    this.activeRulesDropdown = this.ruleNamesList;
    this.rulesDropdown = [];
    this.selectedRuleType = '';
    this.selectedReference = '';
    this.selectedNoOfDays = '';
    this.selectedRules = '';
    this.selectedParameters = '';
    this.selectedPercent = '';
    this.selectedRuleName = '';
    this.selectedFreq = '';
    this.selectedUserList = [];
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminalsSearchRules(response, selectedTerminal, selectedMaterial)
        this.filterRules(this.rulesDropdown)
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  setCreateBoolean() {
    this.disableCreate = false;
  }


  getTerminalsSearchRules(response: any, selectedTerminal: string, selectedMaterial: string) {
    response.result?.forEach((element: any) => {
      if (element.location === selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterialsSearchRules(i, selectedMaterial)
        }
      }

    })
  }

  getMaterialsSearchRules(i: any, selectedMaterial: string) {
    if (i.material === selectedMaterial) {
      for (const j of i.locationMaterialRules) {
          this.disableRuleType = false;
          if (j.isActive === true) {
            this.rulesDropdown.push(j.ruleName)
          }
      }
    }
  }

  filterRules(rulesDropdown: any) {

    this.activeRulesDropdown = this.ruleNamesList.filter(rule => 
          rule.terminalName != null
          && rule.terminalName.toLowerCase() === this.selectedTerminal.toLowerCase() 
          && rule.materialName.toLowerCase() === this.selectedMaterial.toLowerCase());

    this.activeRulesDropdown = [...this.activeRulesDropdown].sort((a, b) => {

      let aNumber = parseInt(a.ruleName.split(" ")[1]);
      let bNumber = parseInt(b.ruleName.split(" ")[1]);

      return aNumber - bNumber;
    });
  }

  getRuleDetails(selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    this.selectedRuleForm = this.activeRulesDropdown.find(_ => _.ruleName === selectedRuleName)!;

    this.selectedTerminal = selectedTerminal
    this.selectedMaterial = selectedMaterial
    this.selectedRuleName = selectedRuleName
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminalsSearchDetails(response, selectedTerminal, selectedMaterial, selectedRuleName)
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getTerminalsSearchDetails(response: any, selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {
    response.result?.forEach((element: any) => {
      if (element.location === selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterialsSearchDetails(i, selectedMaterial, selectedRuleName)
        }
      }

    })
  }

  getMaterialsSearchDetails(i: any, selectedMaterial: string, selectedRuleName: string) {
    if (i.material === selectedMaterial) {
      for (const j of i.locationMaterialRules) {
        if (j.ruleName === selectedRuleName) {
          this.selectedRuleType = j.ruleType;
          for (let i of j.subscription.users) {
            if (i.isActive === true) {
              this.selectedUserList.push(i.userEmail)
            }
          }
          this.getRuleType(j)
        }
      }
    }
  }

getRuleType(j: any) {
  if (this.selectedRuleType === 'Inventory') {
    this.selectedReference = j.daysToValidate.type
    this.selectedNoOfDays = j.daysToValidate.NoOfdays;
    this.selectedParameters = j.parameter
    this.selectedRules = j.ruleCondition;
    this.selectedFreq = j.alertFrequency.charAt(0).toUpperCase()
  } else if (this.selectedRuleType === 'Reconciliation') {
    this.selectedPercent = j.thersold
    this.selectedFreq = j.alertFrequency.charAt(0).toUpperCase()
  }
}


  closePopup() {
    this.clearOptions();

    this.alertsService.changeLocation(this.selectedTerminal);
    this.alertsService.changeMaterial(this.selectedMaterial);
    this.alertsService.changeRuleName(this.selectedRuleName);

    this.alertsService.setIsCreateAlertsPopupOpen(false);

    this.closeModalPopup.emit(false);

  }

  checkForDuplicatesCreate(selectedTerminal: string, selectedMaterial: string) {
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminalsSearchCreate(response, selectedTerminal, selectedMaterial)
        if (this.selectedRuleType === 'Inventory' && this.disableCreate === false) {
          this.onCreateRuleInventory(selectedTerminal, selectedMaterial, this.selectedRuleType, this.selectedReference, this.selectedNoOfDays, this.selectedParameters, this.selectedRules)
        } else if (this.selectedRuleType === 'Reconciliation' && this.disableCreate === false) {
          this.onCreateRuleReconciliation(selectedTerminal, selectedMaterial, this.selectedRuleType, this.selectedPercent, this.selectedFreq)
        }
      },

      error: (err) => {
        console.log(err);
      },
      complete: () => {

      },
      })


  }

  getTerminalsSearchCreate(response: any, selectedTerminal: string, selectedMaterial: string) {
    response.result?.forEach((element: any) => {
      if (element.location === selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterialsSearchCreate(i, selectedMaterial)
        }
      }

    })
  }

  getMaterialsSearchCreate(i: any, selectedMaterial: string) {
    if (i.material === selectedMaterial) {
      for (const j of i.locationMaterialRules) {
        this.duplicateCheckCreate(j)
        }
      }
    }

  duplicateCheckCreate(j: any) {
    if (this.selectedRuleType === 'Inventory') {
      if (j.ruleType === this.selectedRuleType && j.daysToValidate.type === this.selectedReference && j.daysToValidate.NoOfdays === parseInt(this.selectedNoOfDays) && j.parameter.replace(/\s/g, "") === this.selectedParameters.replace(/\s/g, "") && j.ruleCondition === this.selectedRules && j.alertFrequency.charAt(0).toUpperCase() === this.selectedFreq) {
        this.disableCreate = true;
        this.allowCreate = false;
      }
    } else if (this.selectedRuleType === 'Reconciliation') {
      if (j.ruleType === this.selectedRuleType && j.alertFrequency.charAt(0).toUpperCase() === this.selectedFreq && j.thersold === parseInt(this.selectedPercent)) {
        this.disableCreate = true;
        this.allowCreate = false;
      }
  }
}

checkForDuplicatesUpdate(selectedTerminal: string, selectedMaterial: string, selectedRuleName: string) {

  /*
  * Users are allowed to update the rule if they are: 
  * 1. The creator of the rule
  * 2. The only one subscribed to the rule 
  * 3. there are no subscriptions
  */  
  if (this.selectedRuleForm.ruleCreator !== this.authService.cvxClaimsPrincipal.name && this.selectedUserList.length > 1
    || (this.selectedUserList.length === 1 && this.selectedUserList[0] !== this.authService.cvxClaimsPrincipal.email 
        && this.selectedRuleForm.ruleCreator !== this.authService.cvxClaimsPrincipal.name)) {
    this.closeModalPopup.emit(false);
    this.alertsComponent.toggleDenyUpdateAlertModal();
    this.alertsService.changeLocation(selectedTerminal);
    this.alertsService.changeMaterial(selectedMaterial);
    this.alertsService.changeRuleName(selectedRuleName);

  } else {
    this.duplicateCheck()
  }
}

radioButtonDisable(){
  return this.selectedMaterial == '' || this.selectedTerminal == '' || this.selectedRuleType == '';
}

checkDisableDelete(){

  //No rule type or selected rule name, we know too early to allow user to delete
  if(this.selectedRuleType == '' || this.selectedRuleName == ''){
    return true;
  }else if(this.selectedRuleType == 'Inventory'){
    return this.inventoryDeleteCheck();
  }else if(this.selectedRuleType == 'Reconciliation'){
    return this.reconciliationDeleteCheck();
  }
  return false;
}

  inventoryDeleteCheck(): boolean{

    return (this.selectedTerminal == '' || this.selectedMaterial == '' 
            || this.selectedReference == '' || this.selectedNoOfDays == '' 
            || this.selectedParameters == '' || this.selectedRules == '' || this.selectedFreq == '') && this.selectedRuleName !== '';
  }

  reconciliationDeleteCheck(): boolean{

    return this.selectedTerminal == '' || this.selectedMaterial == '' || this.selectedPercent == '' || this.selectedFreq == '';
  }

  checkDisableCreate(){

    if(this.selectedRuleType == ''){
      return true;
    }else if(this.selectedRuleType == 'Inventory'){
      return this.inventoryAddCheck();
    }else if(this.selectedRuleType == 'Reconciliation'){
      return this.reconciliationAddCheck();
    }
    return false;
  }

  inventoryAddCheck(){
    return this.selectedTerminal == '' || this.selectedMaterial == '' || this.selectedReference == '' 
          || this.selectedNoOfDays == '' || this.selectedParameters == '' || this.selectedRules == '' 
          || this.selectedFreq == '';
  }

  reconciliationAddCheck(){
    return this.selectedTerminal == '' || this.selectedMaterial == '' || this.selectedPercent == '' 
          || this.selectedFreq == '';
  }

  checkDisableUpdate(){

    if(this.selectedRuleType == '' || this.selectedRuleName == ''){
      return true;
    }else if(this.selectedRuleType == 'Inventory'){
      return this.inventoryUpdateCheck();
    }else if(this.selectedRuleType == 'Reconciliation'){
      return this.reconciliationUpdateCheck();
    }
    return false;
  }

  inventoryUpdateCheck(){
    return (this.selectedTerminal == '' || this.selectedMaterial == '' || this.selectedReference == '' 
            || this.selectedNoOfDays == '' || this.selectedParameters == '' || this.selectedRules == '' 
            || this.selectedFreq == '') && this.selectedRuleName !== ''
  }

  reconciliationUpdateCheck(){
    return this.selectedTerminal == '' || this.selectedMaterial == '' || this.selectedPercent == '' || this.selectedFreq == '';
  }


  duplicateCheck() {
    this.alertsComponent.alertRulesData$
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.getTerminalsSearchUpdate(response)
        if (this.selectedRuleType === 'Inventory' && this.disableCreate === false) {
          this.onUpdateRuleInventory(this.selectedTerminal, this.selectedMaterial, this.selectedRuleType, this.selectedReference, this.selectedNoOfDays, this.selectedParameters, this.selectedRules)
        } else if (this.selectedRuleType === 'Reconciliation' && this.disableCreate === false) {
          this.onUpdateRuleReconciliation(this.selectedTerminal, this.selectedMaterial, this.selectedRuleType, this.selectedPercent, this.selectedFreq)
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      },
      })
  }

  getTerminalsSearchUpdate(response: any) {
    response.result?.forEach((element: any) => {
      if (element.location === this.selectedTerminal) {
        for (const i of element.locationMaterials) {
          this.getMaterialsSearchUpdate(i)
        }
      }

    })
  }

  getMaterialsSearchUpdate(i: any) {
    if (i.material === this.selectedMaterial) {
      for (const j of i.locationMaterialRules) {
        this.duplicateCheckUpdate(j)
      }
    }
  }

  duplicateCheckUpdate(j: any) {
    if (this.selectedRuleType === 'Inventory') {
      if (j.ruleType === this.selectedRuleType && j.daysToValidate.type === this.selectedReference && j.daysToValidate.NoOfdays === parseInt(this.selectedNoOfDays) && j.parameter.replace(/\s/g, "") === this.selectedParameters.replace(/\s/g, "") && j.ruleCondition === this.selectedRules && j.alertFrequency.charAt(0).toUpperCase() === this.selectedFreq) {
        this.disableCreate = true;
        this.allowCreate = false;
      }
    } else if (this.selectedRuleType === 'Reconciliation') {
      if (j.ruleType === this.selectedRuleType && j.alertFrequency.charAt(0).toUpperCase() === this.selectedFreq && j.thersold === parseInt(this.selectedPercent)) {
        this.disableCreate = true;
        this.allowCreate = false;
      }
    }
    if (this.selectedRuleName === j.ruleName) {
      this.ruleId = j.ruleId;
      this.subscription = j.subscription;
      this.createdBy = j.createdBy;
      this.createdDate = j.createdDate;

    this.isActive = j.isActive;
    this.materialNumber = j.materialNumber;
    this.materialGroup = j.materialGroupNumber;
    this.udmNickname = j.udmNickname;
    }
  }

  onCreateRuleInventory(selectedLocation: string, selectedMaterial: string, selectedType: string, selectedReference: string, selectedDay: string, selectedParam: string, selectedCompare: string) {
    const index = this.matNameList.indexOf(selectedMaterial)
    if (this.selectedFreq === 'I') {
      this.selectedFreqFull = 'Immediately';
    } else if (this.selectedFreq === 'H') {
      this.selectedFreqFull = 'Hourly'
    } else if (this.selectedFreq === 'D') {
      this.selectedFreqFull = 'Daily'
    }
    //this.getMaxRuleNum()
    this.currentMaxRuleNum = this.getMaxRuleNumber();

    this.createRuleInventory = {
      ruleName: 'rule ' + String(this.currentMaxRuleNum + 1),
      ruleDescription: 'if forecasted inventory in the ' + selectedReference + ' ' + selectedDay + ' days is ' + selectedCompare + ' ' + selectedParam,
      location: selectedLocation,
      material: selectedMaterial,
      materialNumber: this.matNumList[index],
      materialGroupNumber: this.matGrpList[index],
      desk: this.alertsService.getDesk(),
      deskId: localStorage.getItem("deskId")!,
      deskNickName: this.alertsService.getDeskNickname(),
      deskRegion: this.alertsService.getDeskRegion(),
      deskTag: this.alertsService.getDeskTag(),
      udmNickname: this.matUdmList[index],
      ruleType: selectedType.replace(/\s/g, ""),
      daysToValidate: {
        type: selectedReference,
        NoOfdays: parseInt(selectedDay)
      },
      ruleCondition: selectedCompare,
      parameter: selectedParam.replace(/\s/g, ""),
      alertFrequency: this.selectedFreqFull,
      subscription: {
        users: [{
          userEmail: this.authService.cvxClaimsPrincipal.email,
          isTeams: true,
          isEmail: true,
          isWorkspace: true,
          isActive: true
        }]
      },
      createdBy: this.authService.cvxClaimsPrincipal.name,
      createdDate: this.utilities.convertDatesToCurrentTZ(new Date().toISOString())
    }
    this.alertsService.createAlert(this.createRuleInventory).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log(err);
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "error - alert could not be saved" });
        this.closePopup();
      },
      complete: () => {
        this.currentMaxRuleNum = this.currentMaxRuleNum + 1
        this.toasterService.setToastNotification({ show: true, type: 'success', msg: "new alert created"})
        this.closePopup();
        this.getAlertRulesData()
        this.alertsComponent.getAlertRulesData()
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
        window.location.href = '/settings/alerts';
      },
    })
  }



  onCreateRuleReconciliation(selectedLocation: string, selectedMaterial: string, selectedType: string, selectedPercent: string, selectedFreq: string) {
    const index = this.matNameList.indexOf(selectedMaterial)
    if (selectedFreq === 'I') {
      this.selectedFreqFull = 'Immediately';
    } else if (selectedFreq === 'H') {
      this.selectedFreqFull = 'Hourly'
    } else if (selectedFreq === 'D') {
      this.selectedFreqFull = 'Daily'
    }
    //this.getMaxRuleNum()
    this.currentMaxRuleNum = this.getMaxRuleNumber();
    
    this.createRuleReconciliation = {
      ruleName: 'rule ' + String(this.currentMaxRuleNum + 1),
      ruleDescription: 'if difference between estimated inventory and actual inventory exceeds ' + selectedPercent + ' percent',
      location: selectedLocation,
      material: selectedMaterial,
      materialNumber: this.matNumList[index],
      udmNickname: this.matUdmList[index],
      ruleType: selectedType.replace(/\s/g, ""),
      alertFrequency: this.selectedFreqFull,
      thersold: parseInt(selectedPercent),
      subscription: {
        users: [{
          userEmail: this.authService.cvxClaimsPrincipal.email,
          isTeams: true,
          isEmail: true,
          isWorkspace: true,
          isActive: true
        }]
      },
      createdBy: this.authService.cvxClaimsPrincipal.name,
      createdDate: this.utilities.convertDatesToCurrentTZ(new Date().toISOString())
    }
    this.alertsService.createAlert(this.createRuleReconciliation).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log(err);
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "error - alert could not be saved" });
        this.closePopup();
      },
      complete: () => {
        this.currentMaxRuleNum = this.currentMaxRuleNum + 1
        this.toasterService.setToastNotification({ show: true, type: 'success', msg: "new alert created"})
        this.closePopup();
        this.getAlertRulesData()
        this.alertsComponent.getAlertRulesData()
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
      },
    })

  }

  onUpdateRuleInventory(selectedLocation: string, selectedMaterial: string, selectedType: string, selectedReference: string, selectedDay: string, selectedParam: string, selectedCompare: string) {

    if (this.selectedFreq === 'I') {
      this.selectedFreqFull = 'Immediately';
    } else if (this.selectedFreq === 'H') {
      this.selectedFreqFull = 'Hourly'
    } else if (this.selectedFreq === 'D') {
      this.selectedFreqFull = 'Daily'
    }

    this.updateRuleInventory = {
      ruleId: this.ruleId,
      ruleName: this.selectedRuleName,
      ruleDescription: 'if forecasted inventory in the ' + selectedReference + ' ' + selectedDay + ' days is ' + selectedCompare + ' ' + selectedParam,
      location: selectedLocation,
      material: selectedMaterial,
      materialNumber: this.materialNumber,
      materialGroupNumber: this.materialGroup,
      desk: this.alertsService.getDesk(),
      deskId: localStorage.getItem("deskId")!,
      deskNickName: this.alertsService.getDeskNickname(),
      deskRegion: this.alertsService.getDeskRegion(),
      deskTag: this.alertsService.getDeskTag(),      
      udmNickname: this.udmNickname,
      ruleType: selectedType.replace(/\s/g, ""),
      daysToValidate: {
        type: selectedReference,
        NoOfdays: parseInt(selectedDay)
      },
      ruleCondition: selectedCompare,
      parameter: selectedParam.replace(/\s/g, ""),
      alertFrequency: this.selectedFreqFull,
      subscription: this.subscription,
      createdBy: this.createdBy,
      createdDate: this.createdDate,
      updatedBy: this.authService.cvxClaimsPrincipal.name,
      updatedDate: new Date().toISOString(),
      isActive: true
    }
    if (this.updateRuleInventory.ruleName !== null && this.updateRuleInventory.ruleName !== undefined && this.updateRuleInventory.ruleName !== '' && this.updateRuleInventory.ruleName.length > 0) {
    this.alertsService.updateAlert(this.updateRuleInventory).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log(err);
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "error - alert could not be updated" });
        this.closePopup();
      },
      complete: () => {
        this.toasterService.setToastNotification({ show: true, type: 'success', msg: "alert has been updated"})
        this.closePopup();
        this.getAlertRulesData()
        this.alertsComponent.getAlertRulesData()
        this.alertsComponent.gridApi.refreshCells();
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
      },
    })
  }}

  onUpdateRuleReconciliation(selectedLocation: string, selectedMaterial: string, selectedType: string, selectedPercent: string, selectedFreq: string) {

    if (selectedFreq === 'I') {
      this.selectedFreqFull = 'Immediately';
    } else if (selectedFreq === 'H') {
      this.selectedFreqFull = 'Hourly'
    } else if (selectedFreq === 'D') {
      this.selectedFreqFull = 'Daily'
    }
    this.updateRuleReconciliation = {
      ruleId: this.ruleId,
      ruleName: this.selectedRuleName,
      ruleDescription: 'if difference between estimated inventory and actual inventory exceeds ' + selectedPercent + ' percent',
      location: selectedLocation,
      material: selectedMaterial,
      materialNumber: this.materialNumber,
      udmNickname: this.udmNickname,
      ruleType: selectedType.replace(/\s/g, ""),
      alertFrequency: this.selectedFreqFull,
      thersold: parseInt(selectedPercent),
      subscription: this.subscription,
      createdBy: this.createdBy,
      createdDate: this.createdDate,
      updatedBy: this.authService.cvxClaimsPrincipal.name,
      updatedDate: new Date().toISOString(),
      isActive: true
    }
    if (this.updateRuleReconciliation.ruleName != null && this.updateRuleReconciliation.ruleName != undefined && this.updateRuleReconciliation.ruleName != '' && this.updateRuleReconciliation.ruleName.length > 0) {
    this.alertsService.updateAlert(this.updateRuleReconciliation).subscribe({
      next: () => {
      },
      error: (err) => {

          console.log(err);
          this.toasterService.setToastNotification({ show: true, type: "error", msg: "error - alert could not be updated" });
          this.closePopup();
      },
      complete: () => {
        this.toasterService.setToastNotification({ show: true, type: 'success', msg: "alert has been updated"})
        this.closePopup();
        this.getAlertRulesData()
        this.alertsComponent.getAlertRulesData()
        this.alertsComponent.gridApi.refreshCells();
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
      },
    })

  }}

  showDeleteRule(selectedTerminal: string, selectedMaterial: string, selectedRuleName: string){
    this.closeModalPopup.emit(false);
    this.alertsComponent.toggleDeleteAlertModal()
    this.alertsService.changeLocation(selectedTerminal);
    this.alertsService.changeMaterial(selectedMaterial);
    this.alertsService.changeRuleName(selectedRuleName);
  }


  handleEthanol(includeEthanol: boolean){

    let newMatNameList = [];
    let newMatNumList = [];
    let newMatUdmList = [];
    let newMatGroupList = [];

    for(let i = 0; i < this.matNameList.length; i++){
      if((this.matNameList[i].toLowerCase().includes("ethanol") && includeEthanol) || (!this.matNameList[i].toLowerCase().includes("ethanol") && !includeEthanol)){
        newMatNameList.push(this.matNameList[i]);
        newMatNumList.push(this.matNumList[i]);
        newMatUdmList.push(this.matUdmList[i]);
        newMatGroupList.push(this.matGrpList[i]);
      }
    }

    this.matNameList = newMatNameList;
    this.matNumList = newMatNumList;
    this.matUdmList = newMatUdmList;
    this.matGrpList = newMatGroupList;
  }

  isEthanolDesk(){
    return this.desk.toLowerCase() == "ethanol + uswc" || this.desk.toLowerCase() == "ethanol + usec";
  }

  removeDashX(){
    this.locList = this.locList.filter(_ => !_.includes("-X"));
  }

  clearOptions(){
    this.matNameList = [];
    this.matNumList = [];
    this.matUdmList = [];
    this.selectedMaterial = '';
    this.selectedTerminal = '';
    this.selectedRuleType = '';
    this.selectedReference = '';
    this.selectedNoOfDays = '';
    this.selectedRules = '';
    this.selectedParameters = '';
    this.selectedPercent = '';
    this.selectedRuleName = '';
    this.selectedFreq = '';
    this.disableCreate = false;
    this.allowCreate = true;
  }


}

interface MinimumRuleData{
  ruleName: string;
  ruleCreator: string;
  ruleId: string;
  terminalName: string;
  materialName: string;
}